import omit from 'lodash/omit';
import { logsTypes } from '../types';
import { LogsReducer, LogsShape } from '../../interfaces/logsShapes';
import { AnyAction } from 'redux';

const initialState = {
  count: 0,
  previous: null,
  next: null,
  results: [] as Array<LogsShape>,
  filters: null
};

export default function logsReducer(state = initialState, { type, payload }: AnyAction): LogsReducer {
  switch (type) {
    case logsTypes.PUT_LOGS:
      return { ...state, ...omit(payload, ['count']) };

    case logsTypes.PUT_LOGS_COUNT:
      return { ...state, ...payload };

    case logsTypes.PUT_FILTER_DATA:
      return { ...state, filters: payload };

    default:
      return state;
  }
}
