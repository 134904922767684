import React, { ReactElement } from 'react';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import s from './TabsMenu.module.scss';

interface TabsMenuProps {
  tabs: Array<{ label: string }>;
  selectedTab: number;
  onClick: (idx: number) => void;
  wrapperClass?: string;
}

const TabsMenu = ({ tabs, selectedTab, onClick, wrapperClass }: TabsMenuProps): ReactElement => {
  return (
    <Paper className={cn(s.wrapper, { [wrapperClass]: wrapperClass })}>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: s.tabWrapper, indicator: s.tabIndicator }}
      >
        {tabs.map(({ label }, idx) => (
          <Tab
            key={label}
            label={label}
            onClick={() => onClick(idx)}
            classes={{ root: s.tabRoot, selected: s.tabSelected }}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default TabsMenu;
