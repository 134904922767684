import React from 'react';
import { UserManagementIds } from '../constants/userManagementConstants';
import Check from '@material-ui/icons/Check';
import Cancel from '@material-ui/icons/Cancel';
import s from '../components/signalsTable/SignalsTable.module.scss';
import Button from '@material-ui/core/Button';
import { UserRow, UsersShape } from '../interfaces/privilegesShapes';
import GroupSelect from '../components/userManagementTable/groupSelect/GroupSelect';

export const userManagementRenderSwitch = (
  colId: UserManagementIds,
  row: UserRow,
  onUserGroupChange: (userId: number, groupName: string, groupId: number) => void,
  onSave: (id: number) => void,
  onDelete: (id: number) => void
): JSX.Element => {
  const value = row[colId];
  switch (colId) {
    case UserManagementIds.ID:
      return <>#{row.index}</>;

    case UserManagementIds.ACTIVE_STATUS:
      return <>{value ? <Check /> : <Cancel />}</>;

    case UserManagementIds.GROUPS:
      return row.is_active ? (
        <GroupSelect {...row.group} onGroupChange={(name, id) => onUserGroupChange(row.id, name, id)} />
      ) : (
        <>-</>
      );

    case UserManagementIds.SAVE:
      return row.is_active ? (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="submit"
          color="primary"
          classes={{ root: s.btn }}
          onClick={() => onSave(row.id)}
        >
          Save
        </Button>
      ) : (
        <>-</>
      );

    case UserManagementIds.DELETE:
      return row.is_active ? (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="submit"
          color="secondary"
          classes={{ root: s.btn }}
          onClick={() => onDelete(row.id)}
        >
          Delete
        </Button>
      ) : (
        <>-</>
      );

    default:
      return <>{value ?? '-'}</>;
  }
};

export const createUsersRow = (user: UsersShape, index: number): UserRow => ({
  id: user.id,
  email: user.email,
  is_active: user.is_active,
  group: { id: user.group?.id ?? 0, name: user.group?.name ?? 'NO-NAME' },
  index: index + 1,
  action: ['Delete', 'Save']
});
