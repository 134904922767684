import * as Yup from 'yup';

export enum ShowPassNames {
  OLD = 'oldPassShown',
  NEW = 'newPassShown'
}

export enum FieldsNames {
  EMAIL = 'email',
  OLD_PASS = 'old_password',
  NEW_PASS = 'new_password'
}

export const validationSchema = Yup.object({
  [FieldsNames.EMAIL]: Yup.string().email('Please enter the valid email.').required('Email can’t be blank'),
  [FieldsNames.OLD_PASS]: Yup.string()
    .notRequired()
    .min(8, 'At least 8 chars are required')
    .matches(/[a-z]/, 'At least 1 lowercase is required')
    .matches(/[A-Z]/, 'At least 1 uppercase is required')
    .matches(/[0-9]/, 'At least 1 digit is required')
    .matches(/^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/, 'At least 1 symbol character is required.'),
  [FieldsNames.NEW_PASS]: Yup.string()
    .notRequired()
    .min(8, 'At least 8 chars are required')
    .matches(/[a-z]/, 'At least 1 lowercase is required')
    .matches(/[A-Z]/, 'At least 1 uppercase is required')
    .matches(/[0-9]/, 'At least 1 digit is required')
    .matches(/^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/, 'At least 1 symbol character is required.')
});
