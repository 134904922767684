import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import s from './NavTabs.module.scss';

interface NavTabsProps {
  tabs: Array<{ label: string; linkTo: string }>;
  classes?: { wrapperClass?: string };
}

const NavTabs = ({ tabs, classes }: NavTabsProps): JSX.Element => {
  const {
    push,
    location: { pathname }
  } = useHistory();
  const currentIdx = tabs.findIndex(({ linkTo }) => linkTo === pathname);

  return (
    <Paper className={cn(s.wrapper, { [classes?.wrapperClass]: classes?.wrapperClass })}>
      <Tabs
        value={currentIdx}
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: s.tabWrapper, indicator: s.tabIndicator }}
      >
        {tabs.map(({ label, linkTo }) => (
          <Tab
            key={label}
            label={label}
            onClick={() => push(linkTo)}
            classes={{ root: s.tabRoot, selected: s.tabSelected }}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default NavTabs;
