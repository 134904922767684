import { call, put, takeLatest, ForkEffect, select } from 'redux-saga/effects';
import { authTypes } from '../types';
import * as apiHelpers from './apiHelpers/authApiHelpers';
import * as actions from '../actions/authActions';
import * as Shapes from 'interfaces/authShapes';
import { clearAuthAccessToken } from 'helpers/authHelpers';
import { callbackResolver } from 'helpers/callbackHelpers';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';
import { filterPerPageValues } from '../../constants/privilegesConstants';
import { getCurrentUser, initializeSuccess } from '../actions/authActions';
import { authAccessTokenSelector } from '../selectors';

function* captchaWorker({ payload }: Shapes.CaptchaRequestShape) {
  try {
    const { data } = yield call(apiHelpers.captchaRequestHelper, payload.reqData);
    if (!data.is_robot) {
      callbackResolver(payload.onSuccess);
    } else {
      callbackResolver(payload.onError);
    }
  } catch (error) {
    callbackResolver(payload.onError);
    console.error('captchaWorker', error);
  }
}

function* loginWorker({ payload }: Shapes.LoginRequestShape) {
  try {
    yield call(apiHelpers.loginRequestHelper, payload.reqData);
    const { data } = yield call(apiHelpers.loginRequestHelper, payload.reqData);
    yield put(actions.loginSuccess(data?.refresh, data?.access));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    callbackResolver(payload.onError);
    console.error('loginWorker', error);
  }
}

function* getTfaQrWorker({ payload }: Shapes.TfaQrShape) {
  try {
    const { data } = yield call(apiHelpers.tfaQrHelper);
    yield put(actions.tfaQrSuccess(data?.url, data?.is_verified));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    callbackResolver(payload.onError);
    console.error('getTfaQrWorker', error);
  }
}

function* getTfaWorker({ payload }: Shapes.TfaShape) {
  try {
    const { data } = yield call(apiHelpers.tfaHelper, payload.tokenGA);
    yield put(actions.tfaSuccess(data?.refresh, data?.access));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    callbackResolver(payload.onError);
    console.error('getTfaWorker', error);
  }
}

function* logoutWorker({ payload }: Shapes.LogoutRequest) {
  try {
    yield call(apiHelpers.logoutRequestHelper, payload.refreshToken);
    yield put(actions.logoutSuccess());
    clearAuthAccessToken();
  } catch (error) {
    yield put(actions.refreshError());
    console.error('logoutWorker', error);
  }
}

function* accountUpdateWorker({ payload }: Shapes.AccountDataUpdate) {
  try {
    yield call(apiHelpers.accountUpdateHelper, payload.reqData, payload.userId);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('accountUpdateWorker', error);
  }
}

function* getUserWorker({ payload }: Shapes.GetUserWorker) {
  try {
    const { data }: { data: Shapes.UserDataShape } = yield call(apiHelpers.getUserHelper);
    yield put(actions.putCurrentUser(data));
    const profile = data.group.perms.find((permission) => permission.section_name === PrivilegesGroupFields.PROFILES);
    if (profile?.status !== filterPerPageValues.NP) {
      callbackResolver(payload?.onSuccess);
    } else {
      callbackResolver(payload?.onFinally);
    }
  } catch (error) {
    console.error('getUserWorker', error);
  }
}

function* initializeAppWorker() {
  try {
    const accessToken = yield select(authAccessTokenSelector);
    if (accessToken) {
      yield put(getCurrentUser());
      yield put(initializeSuccess(true));
    } else {
      yield put(initializeSuccess(false));
    }
  } catch (error) {
    yield put(initializeSuccess(false));
    console.error('initializeAppWorker', error);
  }
}

export function* authWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(authTypes.CAPTHA_REQUEST, captchaWorker);
  yield takeLatest(authTypes.GET_TFA_QR, getTfaQrWorker);
  yield takeLatest(authTypes.TFA, getTfaWorker);
  yield takeLatest(authTypes.LOGIN_REQUEST, loginWorker);
  yield takeLatest(authTypes.LOGOUT_REQUEST, logoutWorker);
  yield takeLatest(authTypes.ACCOUNT_DATA_UPDATE_REQUEST, accountUpdateWorker);
  yield takeLatest(authTypes.GET_CURRENT_USER, getUserWorker);
  yield takeLatest(authTypes.INITIALIZE_APP, initializeAppWorker);
}
