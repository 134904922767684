import { signalsOrdersTypes } from '../types';
import { AnyAction } from 'redux';
import { RiskControlShape, SignalsOrdersReducer } from 'interfaces/signalsOrdersShapes';

const initialState = {
  signalsData: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  ordersData: {
    count: null,
    next: null,
    previous: null,
    results: []
  },
  adminData: [],
  adminTableData: {
    positions: [],
    tickers: []
  },
  riskControl: [],
  filters: {
    profiles: [],
    pairs: []
  }
};

export default function signalsOrdersReducer(state = initialState, { type, payload }: AnyAction): SignalsOrdersReducer {
  switch (type) {
    case signalsOrdersTypes.PUT_SIGNALS:
      return { ...state, signalsData: payload };

    case signalsOrdersTypes.PUT_ORDERS:
      return { ...state, ordersData: payload };

    case signalsOrdersTypes.PUT_SIGNAL_FILTERS:
      return { ...state, filters: payload };

    case signalsOrdersTypes.PUT_ADMIN:
      return { ...state, adminData: payload };

    case signalsOrdersTypes.PUT_ADMIN_LIST:
      return {
        ...state,
        adminData: state.adminData.map((el) => {
          if (el.id === payload.id) {
            const { deposit_type = '', ...rest } = payload.reqData;
            return { ...el, ...rest };
          }
          return el;
        })
      };

    case signalsOrdersTypes.PUT_ADMIN_TABLE:
      return {
        ...state,
        adminTableData: {
          ...state.adminTableData,
          positions: payload.positions,
          tickers: payload.tickers
        }
      };

    case signalsOrdersTypes.PUT_ADMIN_LOST_POSITIONS:
      return {
        ...state,
        adminTableData: {
          ...state.adminTableData,
          positions: [...state.adminTableData.positions, ...payload]
        }
      };

    case signalsOrdersTypes.PUT_RISK_CONTROL:
      return { ...state, riskControl: payload };

    case signalsOrdersTypes.SET_RISK_PROPERTY:
      return {
        ...state,
        riskControl: state.riskControl.map((el: RiskControlShape) => {
          return el.id === payload.id ? { ...el, [payload.property]: payload.value } : el;
        })
      };

    case signalsOrdersTypes.SET_RISK_CONTROL:
      return {
        ...state,
        riskControl: [...state.riskControl, payload]
      };

    default:
      return state;
  }
}
