import React, { FC, useState } from 'react';
import s from './AutoChangeDepositeForm.module.scss';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import PairedBtnBlock from '../../../components/buttons/pairedBtnBlock/PairedBtnBlock';
import NumberFormatField from '../../../components/fields/numberFormatField/NumberFormatField';
import * as Yup from 'yup';
import { AutoChangeDepo, ChangedAutoDepositFormDTO } from '../../../interfaces/mmProfilesShapes';
import {
  FormAutoDepositValues,
  getAutoDepositChangeDTO,
  getInitValues,
  percentOptions
} from '../../../constants/mmProfilesConstants';
import { ErrorShape } from '../../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../../helpers/randomHelpers';
import { AntSwitch } from '../../../components/ui/AndSwitch/AndSwitch';

interface ChangeDepositFormProps {
  autoDepositDepo: AutoChangeDepo;
  onCancel: () => void;
  onSubmit: (reqData: ChangedAutoDepositFormDTO, onSetError: (err: ErrorShape) => void) => void;
}

const AutoChangeDepositForm: FC<ChangeDepositFormProps> = ({ onCancel, onSubmit, autoDepositDepo }) => {
  const [reqErr, setReqErr] = useState<string | null>(null);
  const onClearRequestError = () => setReqErr(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  return (
    <div className={s.formWrapper}>
      <h2 className={cn('default-title', s.title)}>Re-invest daily</h2>
      <Formik
        initialValues={getInitValues(autoDepositDepo)}
        validationSchema={Yup.object({
          is_auto_change_mm_depo_enabled: Yup.boolean(),
          auto_change_mm_depo_percent: Yup.number().when(['is_auto_change_mm_depo_enabled'], {
            is: (is_auto_change_mm_depo_enabled) => is_auto_change_mm_depo_enabled === 'increase',
            then: Yup.number().min(0.01, 'min 0.01%').required('Field is required'),
            otherwise: Yup.number().min(0.01, 'min 0.01%').max(100, 'max 100%').required('Field is required')
          })
        })}
        onSubmit={(values: FormAutoDepositValues, { setSubmitting }: FormikHelpers<FormAutoDepositValues>) => {
          onSubmit(getAutoDepositChangeDTO(values), onSetError);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <Form className={s.form}>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={6}>
                  <Grid item style={{ fontSize: '22px' }}>
                    Inactive
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      scale={1.7}
                      checked={values.is_auto_change_mm_depo_enabled}
                      onChange={handleChange}
                      name="is_auto_change_mm_depo_enabled"
                    />
                  </Grid>
                  <Grid item style={{ fontSize: '22px' }}>
                    Active
                  </Grid>
                </Grid>
              </Typography>
              <NumberFormatField
                inputProps={{
                  id: 'auto_change_mm_depo_percent',
                  placeholder: 'Type in size',
                  title: 'Deposit is updated if current asset sum differs from the previous one by X%',
                  value: values.auto_change_mm_depo_percent,
                  helperText: touched.auto_change_mm_depo_percent && errors.auto_change_mm_depo_percent,
                  isValid: touched.auto_change_mm_depo_percent && !errors.auto_change_mm_depo_percent,
                  isError: (!!errors.auto_change_mm_depo_percent && touched.auto_change_mm_depo_percent) || !!reqErr,
                  onBlur: handleBlur,
                  onChange: handleChange,
                  onClearRequestError: onClearRequestError,
                  setFieldValue: (name, value) => {
                    setFieldValue(name, value);
                  },
                  options: percentOptions
                }}
              />
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AutoChangeDepositForm;
