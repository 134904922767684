import { systemSettingsTypes } from '../types';
import { AnyAction } from 'redux';
import { SystemSettingsReducer } from 'interfaces/systemSettingsShapes';

const initialState: SystemSettingsReducer = {
  exchangeAccounts: [],
  tradingPairs: [],
  tgSettings: {
    id: null,
    token: '',
    chat_id: '',
    investor_bot_token: '',
    investor_chat_id: '',
    investor_report_time: '00:00:00'
  },
  investorReportSettings: {
    investor_bot_token: '',
    investor_chat_id: ''
  },
  investorReportScheduler: []
};

export default function systemSettingsReducer(
  state = initialState,
  { type, payload }: AnyAction
): SystemSettingsReducer {
  switch (type) {
    case systemSettingsTypes.PUT_EXCHANGE_ACCOUNTS:
      return { ...state, exchangeAccounts: payload };

    case systemSettingsTypes.PUT_TRADING_PAIRS:
      return { ...state, tradingPairs: payload };

    case systemSettingsTypes.CHANGE_TRADING_PAIR_SUCCESS:
      return { ...state, tradingPairs: state.tradingPairs.map((item) => (item.id === payload.id ? payload : item)) };

    case systemSettingsTypes.PUT_TG_SETTINGS:
      return { ...state, tgSettings: payload };

    case systemSettingsTypes.PUT_INVESTOR_REPORT_SETTINGS:
      return { ...state, investorReportSettings: payload.reqData };

    case systemSettingsTypes.PUT_INVESTOR_REPORT_SETTINGS_SCHEDULER:
      return { ...state, investorReportScheduler: payload.reqData };

    default:
      return state;
  }
}
