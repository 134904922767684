import React, { useState } from 'react';
import s from '../../strategyStatsTable/StrategyStatsTable.module.scss';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import NumberFormatField from '../../fields/numberFormatField/NumberFormatField';
import PairedBtnBlock from '../../buttons/pairedBtnBlock/PairedBtnBlock';
import ModalLoader from '../../ui/modalLoader/ModalLoader';
import { ErrorShape } from '../../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../../helpers/randomHelpers';

interface ReducePercentFormProps {
  onCancel: () => void;
  onSubmit: (reducing: number) => void;
  onClearReqError: () => void;
  reqError: string | null;
}

interface FormValues {
  reducing: string;
}

const ReducePercentForm: React.FC<ReducePercentFormProps> = ({
  onCancel,
  onSubmit,
  onClearReqError,
  reqError
}): React.ReactElement => {
  return (
    <div className={s.formWrapper}>
      <h2 className={cn('default-title', s.title)}>Enter % of reducing</h2>
      <Formik
        initialValues={{ reducing: '' }}
        validationSchema={Yup.object({
          reducing: Yup.string().required('Please enter percent(%) of reducing')
        })}
        onSubmit={({ reducing }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          onSubmit(+reducing);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form className={s.form}>
              {isSubmitting && <ModalLoader />}
              <NumberFormatField
                inputProps={{
                  id: 'reducing',
                  placeholder: '% of reducing',
                  isError: (!!errors.reducing && touched.reducing) || !!reqError,
                  helperText: reqError || (touched.reducing && errors.reducing),
                  isValid: touched.reducing && !errors.reducing && !reqError,
                  onBlur: handleBlur,
                  onChange: handleChange,
                  onClearRequestError: onClearReqError,
                  value: values.reducing,
                  inputClass: s.input,
                  disabled: isSubmitting
                }}
              />
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const isEqual = (prevProps: ReducePercentFormProps, nextProps: ReducePercentFormProps) =>
  prevProps.reqError === nextProps.reqError;

export default React.memo(ReducePercentForm, isEqual);
