import React, { ReactNode, useCallback, useEffect, useMemo, useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedTable from '../customizedTable/CustomizedTable';
import { PRIVILEGES_COLUMNS, PrivilegesColIds, StickyContext } from '../../constants/privilegesConstants';
import { createPrivilegesRow, PrivilegesRenderSwitch } from 'helpers/privilegesHelpers';
import MyModal from '../modal/MyModal';
import LimitsForm from './components/LimitsForm';
import { groupsSelector } from '../../redux/selectors';
import { deleteGroupPrivileges, updateGroupPrivileges, updateStatus } from '../../redux/actions/privilegesActions';
import { ButtonColorTypes } from '../../constants/randomConstants';
import { PAGES } from '../../constants/routes';
import { GroupsShape } from '../../interfaces/privilegesShapes';
import { ErrorShape } from '../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../helpers/randomHelpers';
import { showMessage } from '../../redux/actions/utilsActions';
import { MessageType } from '../../interfaces/utilsShapes';
import { Button } from '@material-ui/core';
import s from './PrivilegesTable.module.scss';
import * as actions from '../../redux/actions/privilegesActions';

const PER_PAGE = 40;

const PrivilegesTable: FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const groups = useSelector(groupsSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [editor, setEditor] = useState<null | { id: number; value: string }>(null);
  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const onShowMessage = useCallback(
    (messageText = 'Success', type: MessageType = 'success') => {
      dispatch(showMessage(messageText, type));
    },
    [dispatch]
  );

  const onSetError = useCallback(
    (err: ErrorShape) => {
      const errorMsg = getErrorMessageHelper(err);
      if (errorMsg) {
        const msg = getErrorCutMessageHelper(errorMsg);
        onShowMessage(msg, 'error');
      }
    },
    [onShowMessage]
  );

  useEffect(() => {
    onToggleLoading();
    dispatch(actions.getGroups(onToggleLoading));
  }, [dispatch]);

  const onUpdateStatus = useCallback(
    (name: string, status: string, id: number) => {
      dispatch(updateStatus(name, status, id));
    },
    [dispatch]
  );

  const onSave = useCallback(
    (id: number) => dispatch(updateGroupPrivileges(id, onShowMessage)),
    [dispatch, onShowMessage]
  );
  const onDelete = useCallback(
    (id: number) => dispatch(deleteGroupPrivileges(id, onShowMessage, onSetError)),
    [dispatch, onSetError, onShowMessage]
  );

  const onEdit = (value: string, id: number) => {
    setEditor({ value, id });
    setIsOpen(true);
  };

  const getContent = (colId: string, row): ReactNode => {
    return PrivilegesRenderSwitch(colId, row, onEdit, onUpdateStatus, onSave, onDelete);
  };

  const rows = useMemo(() => {
    return groups.map((group) => createPrivilegesRow(group as GroupsShape));
  }, [groups]);

  const sortedRows = useMemo(() => rows.sort((a, b) => b.id - a.id), [rows]);

  const onCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onAddPrivilegesGroup = useCallback(() => {
    push(PAGES.PRIVILEGES_ADD);
  }, [push]);

  return (
    <>
      <div className={s.groupsHeader}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.DEFAULT}
          classes={{ root: s.addBtn }}
          onClick={onAddPrivilegesGroup}
        >
          Add New Group
        </Button>
      </div>
      <StickyContext.Provider value={{ leftSticky: PrivilegesColIds.NAME, rightSticky: PrivilegesColIds.SAVE }}>
        <CustomizedTable
          count={sortedRows.length}
          columns={PRIVILEGES_COLUMNS}
          rows={sortedRows}
          isLoading={isLoading}
          error={null}
          emptyRowsMsg="There are no privileged groups yet..."
          getContent={getContent}
          getCollapsibleContent={() => null}
          rowsPerPageOptions={[PER_PAGE]}
          customPerPage={PER_PAGE}
          customPage={0}
          onCustomChangePage={() => null}
          offPerPage
          classes={{
            wrapperClass: s.groupContent,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            },
            tableWrapper: s.tableContainer
          }}
        />
      </StickyContext.Provider>
      <MyModal isOpen={isOpen} onToggle={onCloseModal}>
        <LimitsForm onClose={onCloseModal} editor={editor} />
      </MyModal>
    </>
  );
};

export default PrivilegesTable;
