import React, { FC, useContext } from 'react';
import { ListItemText, MenuItem, Select } from '@material-ui/core';
import { PaginationContext } from '../../../constants/randomConstants';
import s from './Pagination.module.scss';

interface PaginationProps {
  children: JSX.Element;
}

export const Pagination: FC<PaginationProps> = ({ children }) => {
  const { value, onChange, options, isLoading } = useContext(PaginationContext);
  return (
    <div className={s.paginationWrapper}>
      <Select name="page_size" value={value} onChange={onChange} disabled={isLoading} className={s.select}>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
      {children}
    </div>
  );
};
