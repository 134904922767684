import React, { ReactElement, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PuffLoader from 'react-spinners/PuffLoader';
import { getProfile } from 'redux/actions/strategyProfilesActions';
import { strategyRootSelector } from 'redux/selectors';
import MainLayout from 'components/mainLayout/MainLayout';
import TabsMenu from 'components/tabsMenu/TabsMenu';
import SignalsTable from 'components/signalsTable/SignalsTable';
import OrdersTable from 'components/ordersTable/OrdersTable';
import StrategyStatistics from 'components/strategyProfileComponents/strategyStatistics/StrategyStatistics';
import Summary from 'components/strategyProfileComponents/summary/Summary';
import { getErrorMessageHelper } from 'helpers/randomHelpers';
import { safeParseInt } from 'helpers/numberHelpers';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import StrategyDetailsNav from './components/strategyDetailsNav/StrategyDetailsNav';
import {
  strategyDetailsInitial,
  strategyDetailsTypes,
  strategyDetailsReducer,
  STRATEGY_DETAILS_TABS
} from './strategyDetailsConstants';
import { StrategyDetailsTabs } from './StrategyDetailsTypes';
import Fade from 'components/reactTransitions/FadeTransition.module.css';
import s from './StrategyProfileDetailsPage.module.scss';

const LOADER_SIZE = 100;

const StrategyProfileDetailsPage = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { profile } = useSelector(strategyRootSelector);
  const [{ isLoading, errors, currentTab }, tinyDispatch] = useReducer(strategyDetailsReducer, strategyDetailsInitial);
  const onSetError = (err: ErrorShape, type: string) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) tinyDispatch({ type, payload: errorMsg });
  };

  const onToggleLoading = () => tinyDispatch({ type: strategyDetailsTypes.TOGGLE_LOADING });

  useEffect(() => {
    dispatch(
      getProfile(
        safeParseInt(id),
        false,
        (err) => {
          onSetError(err, strategyDetailsTypes.SET_PROFILE_ERR);
          onToggleLoading();
        },
        onToggleLoading
      )
    );
  }, [dispatch, id]);

  const onSelectTab = (tabIdx: number) => {
    tinyDispatch({
      type: strategyDetailsTypes.SET_TAB,
      payload: tabIdx
    });
  };

  const isTableTab = currentTab === StrategyDetailsTabs.ORDERS || currentTab === StrategyDetailsTabs.SIGNALS;
  return (
    <MainLayout>
      <SwitchTransition mode="out-in">
        <CSSTransition
          unmountOnExit
          key={isLoading ? 'loader' : 'content'}
          timeout={DEFAULT_TRANSITION_TIMEOUT}
          classNames={{ ...Fade }}
        >
          {isLoading ? (
            <div className={s.loaderWrapper}>
              <PuffLoader loading size={LOADER_SIZE} />
            </div>
          ) : (
            <>
              <StrategyDetailsNav
                profile={profile}
                error={errors?.profileError}
                onSetError={(err) => onSetError(err, strategyDetailsTypes.SET_PROFILE_ERR)}
                wrapperClass={s.navWrapper}
              />

              <TabsMenu
                tabs={STRATEGY_DETAILS_TABS}
                selectedTab={currentTab}
                onClick={onSelectTab}
                wrapperClass={s.tabsWrapper}
              />

              <div className={cn(s.innerContentWrapper, { [s.innerContentTables]: isTableTab })}>
                <SwitchTransition mode="out-in">
                  <CSSTransition unmountOnExit key={currentTab} timeout={DEFAULT_TRANSITION_TIMEOUT} classNames={Fade}>
                    {(() => {
                      switch (currentTab) {
                        case StrategyDetailsTabs.STATISTICS:
                          return <StrategyStatistics />;

                        case StrategyDetailsTabs.ORDERS:
                          return <OrdersTable profileId={id} />;

                        case StrategyDetailsTabs.SIGNALS:
                          return <SignalsTable profileId={id} />;

                        default:
                          return (
                            <Summary
                              hideButtons
                              hideTitle
                              classes={{ wrapperClass: s.summaryRoot, contentClass: s.summaryContent }}
                            />
                          );
                      }
                    })()}
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </MainLayout>
  );
};

export default StrategyProfileDetailsPage;
