export const API_ENDPOINTS = {
  CAPTCHA_VERIFY: 'users/google-captcha-verify/',
  LOGIN: 'users/login/',
  TFA_QR: 'users/totp/create/',
  TFA: (GAtoken: string): string => `users/totp/login/${GAtoken}/`,
  REFRESH: 'users/token-refresh/',
  LOGOUT: 'users/logout/',
  CURRENT_USER: 'users/current-user/',
  USER_UPDATE: (userId: number): string => `users/users/${userId}/`,
  EXCHANGE_ACCOUNTS: 'strategies/exchange-accounts/',
  VALIDATE_EXCHANGE_ACCOUNT: 'strategies/exchange-accounts/validate/',
  EDIT_EXCHANGE_ACCOUNT: (id: number): string => `strategies/exchange-accounts/${id}/`,
  DELETE_EXCHANGE_ACCOUNT: (id: number): string => `strategies/exchange-accounts/${id}/`,
  TG_NOTIFICATIONS: 'notifications/tg-bot-settings/',
  INVESTOR_REPORT_SETTINGS: 'notifications/investor-reports-settings/',
  INVESTOR_REPORT_SETTINGS_SCHEDULER: 'notifications/investor-reports-scheduler/',
  DELETE_INVESTOR_REPORT_SETTINGS_SCHEDULER: (id: number): string => `notifications/investor-reports-scheduler/${id}/`,
  TRADING_PAIRS: 'strategies/pairs/',
  TRADING_PAIR: (id: number): string => `strategies/pairs/${id}/`,
  MM_PROFILES: 'strategies/mm-profiles/',
  MM_PROFILES_FILTERS: 'strategies/mm-profiles/for-filters',
  MM_PROFILES_DEPOSIT: 'strategies/change-deposit-mm-profiles/',
  MM_PROFILES_AUTO_DEPOSIT: 'strategies/auto-change-deposit-mm-profiles/',
  MM_PROFILES_DEPOSIT_INFO: 'strategies/mm-profiles-deposit-info/',
  MM_PROFILE: (id: number): string => `strategies/mm-profiles/${id}/`,
  SIGNALS: 'signals/signals/',
  ORDERS: 'orders/orders/',
  CANCEL_ORDER: (id: number): string => `orders/order/${id}/cancel/`,
  CANCEL_SIGNAL: (id: number): string => `signals/position/${id}/cancel/`,
  CLOSE_SIGNAL_MARKET: (id: number): string => `signals/position/${id}/close-market/`,
  CLOSE_SIGNAL_LIMIT: (id: number): string => `signals/position/${id}/close-limit/`,
  CLOSE_SIGNAL_BEST_LIMIT: (id: number): string => `signals/positions/${id}/close-best-limit/`,
  CLOSE_SIGNAL_BEST_LIMIT_BULK: `signals/positions/bulk-close-best-limit/`,
  SYSTEM_STATUS: 'system-status/',
  SET_SYSTEM_STATUS: (status: string): string => `trading-bot/${status}/`,
  STRATEGY_PROFILES: 'strategies/profiles/',
  PROFILE_STATS_FILTERS: '/strategies/profiles-stats/for-filters/',
  ACCOUNT_STATS: '/strategies/account-stats/',
  ACCOUNT_STATS_FILTERS: '/strategies/account-stats/for-filters/',
  ACCOUNT_STATS_EXPORT: '/strategies/export/account-stats/',
  ACCOUNT_STATS_EXPORT_POLLING: (taskId: string): string => `/strategies/account-stats/celery-result/${taskId}/`,
  MONTHS_RESULT_STATS_ADMIN: '/statistics/statistics-admin/',
  MONTHS_RESULT_STATS_CLIENT: '/statistics/statistics-client/',
  MONTHS_SYSTEM_DATA: '/strategies/system-data/',
  CHARTS: '/statistics/statistics-chart/',
  STRATEGY_PROFILE: (id: number): string => `strategies/profiles/${id}/`,
  STRATEGY_PROFILE_START: (id: number): string => `strategies/profiles/${id}/start/`,
  STRATEGY_PROFILE_STOP: (id: number): string => `strategies/profiles/${id}/stop/`,
  STRATEGY_SETTING: (id: number): string => `strategies/strategy-settings/${id}/`,
  STRATEGY_TICKERS: '/strategies/tickers-configs/',
  GET_STRATEGY_TICKERS: (reqData): string => `/strategies/tickers-configs/?ordering=${reqData.ordering ?? ''}`,
  STRATEGY_TICKER: (id: number): string => `/strategies/tickers-configs/${id}/`,
  GET_NOTIFICATIONS: 'notifications/',
  EXPORT_SIGNALS: 'signals/export/signals/',
  PROFILES_STATS_EXPORT: '/strategies/export/profiles-stats/',
  ACCOUNT_INFO: '/position-administration/account-info/',
  ADMINISTRATION: '/position-administration/',
  RECOVERY_EXIT: (id: number): string => `/position-administration/${id}/recovery-k-exit/`,
  SKIP_CHECK_BY_ID: (id: number): string => `/position-administration/${id}/skip-check-k-exit/`,
  SKIP_CHECK_BULK: `/position-administration/bulk-skip-check-k-exit/`,
  REDUCE_POSITION: (id: number): string => `/position-administration/${id}/reduce-position/`,
  REDUCE_POSITION_BULK: '/position-administration/bulk-reduce-position/',
  CLOSE_SIGNAL_MARKET_BULK: '/signals/positions/bulk-close-market/',
  ADMINISTRATION_LOST_POSITIONS: '/position-administration/lost-positions/',
  ADMINISTRATION_CLOSE_LOST_POSITIONS: '/position-administration/close-lost-position/',
  PROFILES_STATS_EXPORT_POLLING: (taskId: string): string => `/strategies/profiles-stats/celery-result/${taskId}/`,
  RISK_CONTROL: '/strategies/risk-controls/',
  UPDATE_RISK_CONTROL: (id: number): string => `/strategies/risk-controls/${id}/`,
  SIGNALS_PAIR_FILTERS: 'strategies/pairs/for-filters/?is_for_active=true',
  SIGNALS_DASHBOARD_PAIR_FILTERS: 'strategies/pairs/for-filters/',
  SIGNALS_PROFILE_FILTERS: 'strategies/profiles/for-filters/?status=active',
  SIGNALS_DASHBOARD_PROFILE_FILTERS: 'strategies/profiles/for-filters/',
  EXPORT_SIGNALS_POLLING: (taskId: string): string => `signals/celery-result/${taskId}/`,
  LOGS: 'logs/',
  LOGS_FILTERS: 'logs/for_filters/',
  LOGS_COUNT: '/logs/for_count/',
  SIGNALS_STATS: '/strategies/profiles-stats/',
  USERS_GROUPS: '/users/groups/',
  USERS_GROUP: (groupId: number): string => `/users/groups/${groupId}/`,
  USERS: 'users/users/',
  USER: (id: number): string => `/users/users/${id}/update_user/`
};
