import React from 'react';
import cn from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TradingPairShape } from 'interfaces/systemSettingsShapes';
import s from './TradingPairsList.module.scss';

interface TradingPairsListProps {
  list: Array<TradingPairShape>;
  onChange: (e: React.ChangeEvent, tradingPair: TradingPairShape) => void;
  isReadOnly: boolean;
}

const TradingPairsList = ({ list, onChange, isReadOnly }: TradingPairsListProps): JSX.Element => {
  return (
    <div className={s.rootWrapper}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className={s.listWrapper}>
            <List classes={{ root: cn(s.listRoot, { [s.listRootScrollable]: list.length >= 10 }) }}>
              {list.map((item) => (
                <ListItem key={item.id} classes={{ root: s.listItemRoot }}>
                  <ListItemText primary={item.ticker} classes={{ root: s.listTitleRoot }} />
                  {!isReadOnly && (
                    <ListItemSecondaryAction>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.is_active}
                            onChange={(e) => onChange(e, item)}
                            name={item.ticker}
                            color="primary"
                            classes={{ colorPrimary: s.checkboxColorPrimary }}
                          />
                        }
                        label={item.is_active ? 'Active' : 'Not active'}
                        labelPlacement="start"
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TradingPairsList;
