import * as Shapes from '../../interfaces/utilsShapes';
import { utilsTypes } from '../types';

export const showMessage = (
  messageText = 'Success',
  type: Shapes.MessageType = 'success'
): Shapes.ShowMessageShape => ({
  type: utilsTypes.SHOW_MESSAGE,
  payload: { messageText, type }
});

export const hideMessage = (): { type: string } => ({
  type: utilsTypes.HIDE_MESSAGE
});
