import React from 'react';
import { Form, useFormikContext } from 'formik';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FieldsNames } from 'constants/accountSettingsConstants';
import { AccountDetailsShape } from 'interfaces/authShapes';
import s from './AccountSettingsPage.module.scss';

interface AccountSettingsFormProps {
  reqErr: null | string;
  onClearRequestError: () => void;
  isEditing: boolean;
  onCloseEditing: () => void;
  onOpenEditing: () => void;
}

const AccountSettingsForm = ({
  reqErr,
  onClearRequestError,
  isEditing,
  onCloseEditing,
  onOpenEditing
}: AccountSettingsFormProps): JSX.Element => {
  const { errors, values, touched, handleChange, handleBlur } = useFormikContext<AccountDetailsShape>();

  const onFocus = (e) => e.target.setAttribute('autocomplete', 'off');
  return (
    <Form className={s.form}>
      <TextField
        fullWidth
        id={FieldsNames.EMAIL}
        name={FieldsNames.EMAIL}
        label="Your email"
        placeholder="example@gmail.com"
        variant="outlined"
        value={values.email}
        error={(!!errors.email && touched.email) || !!reqErr}
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={onClearRequestError}
        classes={{
          root: cn(s.input, { [s.inputValid]: !!touched.email && !errors.email && !reqErr })
        }}
      />

      <TextField
        autoComplete="off"
        fullWidth
        id={FieldsNames.OLD_PASS}
        name={FieldsNames.OLD_PASS}
        label="Old password"
        placeholder="********"
        variant="outlined"
        value={values.old_password}
        error={(!!errors.old_password && touched.old_password) || !!reqErr}
        helperText={touched.old_password && errors.old_password}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={onClearRequestError}
        onFocus={onFocus}
        classes={{
          root: cn(s.input, {
            [s.inputValid]: !!touched.old_password && !errors.old_password && !reqErr
          })
        }}
      />

      {isEditing ? (
        <>
          <TextField
            autoComplete="off"
            fullWidth
            id={FieldsNames.NEW_PASS}
            name={FieldsNames.NEW_PASS}
            label="New password"
            placeholder="********"
            variant="outlined"
            value={values.new_password}
            error={(!!errors.new_password && touched.new_password) || !!reqErr}
            helperText={touched.new_password && errors.new_password}
            onBlur={handleBlur}
            onChange={handleChange}
            onClick={onClearRequestError}
            onFocus={onFocus}
            classes={{
              root: cn(s.input, {
                [s.inputValid]: !!touched.new_password && !errors.new_password && !reqErr
              })
            }}
          />
          <div className={s.btnBlockWrapper}>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color="secondary"
              classes={{ root: s.btnRoot, text: s.btnText }}
              onClick={onCloseEditing}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="submit"
              color="primary"
              classes={{ root: s.btnRoot, text: s.btnText, outlinedPrimary: s.btnSavePrimary }}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot, text: s.btnText }}
          onClick={onOpenEditing}
        >
          Edit
        </Button>
      )}
    </Form>
  );
};

export default AccountSettingsForm;
