import React, { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  SummaryOrdersTRow,
  OrdersColumnsType,
  SummaryTimeExitsTRow,
  SummaryDistanceExitsTRow,
  SummaryIndicationsOppTRow
} from '../../summaryConstants';
import s from './SummaryTable.module.scss';

interface SummaryTableProps {
  rows:
    | Array<SummaryOrdersTRow>
    | Array<SummaryTimeExitsTRow>
    | Array<SummaryDistanceExitsTRow>
    | Array<SummaryIndicationsOppTRow>;
  columns: OrdersColumnsType;
}
const SummaryTable: FC<SummaryTableProps> = ({ rows, columns }): JSX.Element => {
  return (
    <TableContainer className={s.tableWrapper}>
      <Table className={s.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(({ id, title, align }) => (
              <TableCell key={id} align={align} className={s.tCell}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {columns.map(({ id, align }) => {
                const value = row[id];
                return (
                  <TableCell key={id} align={align} className={s.tCell}>
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTable;
