import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createRiskControl,
  getFilters,
  getRiskControl,
  setRiskProperty,
  updateRiskControl
} from 'redux/actions/signalsOrdersActions';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { createRiskControlRow, ReqValues, riskControlRenderSwitch } from 'helpers/signalOrdersHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { RiskControlTableShape } from 'interfaces/signalsOrdersShapes';
import { HARD_PER_PAGE } from 'constants/randomConstants';
import { ChangeableTriggerProperty, RISK_CONTROL_COLUMNS } from 'constants/signalsOrdersConstants';
import s from './RiskControlTable.module.scss';
import { riskControlSelector } from '../../redux/selectors';
import MyModal from '../modal/MyModal';
import BlockerForm from './blockerFrom/BlockerForm';
import { showMessage } from '../../redux/actions/utilsActions';
import Button from '@material-ui/core/Button';
import AddTriggerForm from './addTriggerForm/AddTriggerForm';

const RiskControlTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const riskControl = useSelector(riskControlSelector);

  const [isModalOpen, setModalOpen] = useState(false);
  const onOpenModal = () => setModalOpen((prev) => !prev);

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const onOpenAddModal = () => setAddModalOpen((prev) => !prev);

  const [triggerId, setTriggerId] = useState<null | number>(null);
  const [changingProperty, setChangingProperty] = useState(ChangeableTriggerProperty.TR_BLOCK);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [reqParams, setReqParams] = useState({ show_hide: false });

  const onGetRiskControl = useCallback(() => {
    onToggleLoading();
    dispatch(getRiskControl(reqParams, onToggleLoading, onSetError));
  }, [reqParams, dispatch]);

  const onGetFilters = useCallback(() => {
    dispatch(getFilters(() => null, onSetError));
  }, [dispatch]);

  useEffect(() => {
    onGetFilters();
    onGetRiskControl();
  }, [onGetRiskControl, onGetFilters]);

  const onAction = (id: number, changingProperty: ChangeableTriggerProperty) => {
    setChangingProperty(changingProperty);
    setTriggerId(id);
    onOpenModal();
  };

  const onShowMessage = () => {
    dispatch(showMessage());
  };

  const onSave = (id: number) => {
    dispatch(updateRiskControl(id, onShowMessage));
  };

  const onChangeProperty = (id: number, value: unknown, property: string) => {
    dispatch(setRiskProperty(id, value, property));
  };

  const getContent = (colId, row: RiskControlTableShape) => {
    return riskControlRenderSwitch(colId, row, onAction, onChangeProperty, onSave);
  };

  const rows = useMemo(
    () => riskControl.sort((a, b) => a.id - b.id).map((item, index) => createRiskControlRow(item, index)),
    [riskControl]
  );

  const onHideItems = () => {
    setReqParams((prev) => ({ ...prev, show_hide: !prev.show_hide }));
  };

  const onSubmitTrigger = (values: ReqValues) => {
    dispatch(createRiskControl(values, onOpenAddModal));
  };

  return (
    <>
      <div className={s.btns}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnHide }}
          onClick={onHideItems}
        >
          {reqParams.show_hide ? 'Hide Unpicked' : 'Show All'}
        </Button>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnHide }}
          onClick={onOpenAddModal}
        >
          Add
        </Button>
      </div>
      <div>
        <CustomizedTable
          count={rows.length}
          columns={RISK_CONTROL_COLUMNS}
          rows={rows}
          isLoading={isLoading}
          error={reqError}
          emptyRowsMsg="There are no RCS data yet..."
          getContent={getContent}
          getCollapsibleContent={() => null}
          rowsPerPageOptions={[HARD_PER_PAGE]}
          customPerPage={HARD_PER_PAGE}
          offPerPage
          classes={{
            tableWrapper: s.tableContainer,
            wrapperClass: s.signalsContent,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            }
          }}
        />
      </div>
      <MyModal isOpen={isModalOpen} onToggle={onOpenModal}>
        <BlockerForm
          onClose={onOpenModal}
          onSubmit={onChangeProperty}
          triggerId={triggerId}
          changingProperty={changingProperty}
        />
      </MyModal>
      <MyModal isOpen={isAddModalOpen} onToggle={onOpenAddModal}>
        <AddTriggerForm onClose={onOpenAddModal} handleSubmit={onSubmitTrigger} />
      </MyModal>
    </>
  );
};

export default RiskControlTable;
