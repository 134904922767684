import React, { FC } from 'react';
import MainLayout from '../../../components/mainLayout/MainLayout';
import AccountStatsTable from '../../../components/accountStatsTable/AccountStatsTable';

const AccountStatsPage: FC = () => {
  return (
    <MainLayout>
      <AccountStatsTable />
    </MainLayout>
  );
};

export default AccountStatsPage;
