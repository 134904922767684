import React, { FC } from 'react';
import s from './StrategyTickers.module.scss';
import MainLayout from 'components/mainLayout/MainLayout';
import NavTabs from 'components/navTabs/NavTabs';
import StrategyTickersTable from 'components/tables/strategyTickers/StrategyTickersTable';
import { getProfileTabs } from '../../../helpers/privilegesHelpers';
import { usePrivileges } from '../../../hooks/usePrivileges';

const StrategyTickers: FC = () => {
  const privileges = usePrivileges();
  const tabs = getProfileTabs(privileges);
  return (
    <MainLayout contentCustomClass={s.content}>
      <div className={s.header}>
        <h2 className="default-title">Ticker Config List</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <StrategyTickersTable />
    </MainLayout>
  );
};

export default StrategyTickers;
