import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getAllowedProfileRoute } from '../../helpers/privilegesHelpers';
import { Loader } from '../../components/loader/Loader';

const StrategyPage = (): JSX.Element => {
  const { profiles, tickers_configs } = usePrivileges();
  const route = getAllowedProfileRoute(profiles, tickers_configs);
  if (route) {
    return <Redirect to={route} />;
  }
  return <Loader />;
};

export default StrategyPage;
