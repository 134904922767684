import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAutoDepositMMProfile,
  changeDepositMMProfile,
  changeMMProfile,
  changeMMProfileVisibility,
  getAutoDepositMMProfile,
  getDepositInfoMMProfile,
  getMMProfiles
} from 'redux/actions/mmProfilesActions';
import MainLayout from 'components/mainLayout/MainLayout';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import Button from '@material-ui/core/Button';
import { getComponentsFieldsSelector, mmProfilesRootSelector } from 'redux/selectors';
import {
  ChangedAutoDepositFormDTO,
  ChangedDepositFormDTO,
  MMExtendedShape,
  MMStatuses
} from 'interfaces/mmProfilesShapes';
import {
  ChipMappingsLabels,
  filterPerPageLabels,
  initialMMProfilesValues,
  MM_PROFILES_COLUMNS,
  MM_PROFILES_FILTERS,
  MMTableColIds,
  MMTableRow,
  pinnedKey,
  pinnedValue,
  READ_ONLY_FORBIDDEN_FIELDS,
  SERVER_CLIENT_COLS_MAP
} from 'constants/mmProfilesConstants';
import { ButtonColorTypes, PaginationContext, ZERO_BASED_PAGE_SHIFT } from 'constants/randomConstants';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { createRow, getMock, profileRenderSwitch } from 'helpers/mmProfilesHelpers';
import { PAGES } from 'constants/routes';
import s from './MMProfilesPage.module.scss';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionLimited, isSectionReadOnly } from '../../helpers/privilegesHelpers';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import MyModal from '../../components/modal/MyModal';
import ChangeDepositForm from './changeDepositeForm/ChangeDepositeForm';
import { StickyContext } from '../../constants/privilegesConstants';
import { removeEmptyParams } from '../../helpers/emptyParamsRemoverHelpers';
import { GetSignalsQuery } from '../../interfaces/signalsOrdersShapes';
import AutoChangeDepositeForm from './autoChangeDepositeForm/AutoChangeDepositeForm';
import { Cancel } from '../../assets/images/Cancel';
import { Check } from '../../assets/images/Check';
import Chip from '@material-ui/core/Chip';

const MMProfilesPage = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const {
    mmProfiles: { summary, count, results: mmProfilesResults },
    autoDepositDepo,
    depositInfo
  } = useSelector(mmProfilesRootSelector);

  const { mm_profiles } = usePrivileges();
  const { fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.MM_PROFILES));

  const [filterParams, setFilterParams] = useState<typeof initialMMProfilesValues>(initialMMProfilesValues);
  const [reqParams, setReqParams] = useState<GetSignalsQuery>(initialMMProfilesValues);
  const [disabledFilter, setDisabledFilter] = useState(true);

  const showChangeDeposit = useMemo(() => {
    return isSectionLimited(mm_profiles) ? fields.some((el) => el === 'change_deposit') : true;
  }, [fields, mm_profiles]);

  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);

  const [isAutoChangeModalOpen, setAutoChangeModalOpen] = useState(false);
  const onToggleAutoChangeModal = () => setAutoChangeModalOpen((prev) => !prev);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [isLoadingAutoDeposit, setLoadingAutoDeposit] = useState(false);
  const onToggleLoadingAutoDeposit = () => setLoadingAutoDeposit((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const onGetMMProfile = useCallback(() => {
    onToggleLoading();
    dispatch(
      getMMProfiles(removeEmptyParams(reqParams), onToggleLoading, (err) => {
        onSetError(err);
        onToggleLoading();
      })
    );
  }, [dispatch, reqParams]);

  useEffect(onGetMMProfile, [onGetMMProfile]);

  const [isDepositInfoError, setDepositInfoError] = useState(false);
  const handleSetDepositInfoError = () => setDepositInfoError(true);

  useEffect(() => {
    dispatch(getAutoDepositMMProfile(onToggleLoadingAutoDeposit, onToggleLoadingAutoDeposit));
    dispatch(getDepositInfoMMProfile(handleSetDepositInfoError));
  }, [dispatch]);

  const onChangeProfile = useCallback(
    (rawData: MMExtendedShape) => {
      const { title, status, all_signals_quota, max_signals_amount, exchange_account, is_show } = rawData;

      const reqData = {
        title,
        status: status === MMStatuses.INACTIVE ? MMStatuses.ACTIVE : MMStatuses.STOPPING,
        all_signals_quota,
        max_signals_amount,
        exchange_account: exchange_account?.id ?? null,
        is_show
      };

      dispatch(changeMMProfile(rawData.id, reqData, onSetError));
    },
    [dispatch]
  );
  const rows = useMemo(() => mmProfilesResults.map(createRow), [mmProfilesResults]);

  const rowsWithTotalRow = useMemo(
    () => [...rows, getMock(summary.sum_one_signal_quota, summary.sum_all_signals_quota)],
    [rows, summary.sum_one_signal_quota, summary.sum_all_signals_quota]
  );

  const getCollapsibleContent = (colId: string, { profiles }: MMTableRow) => {
    if (colId !== MMTableColIds.PROFILES) return <></>;

    const shouldRender = !isEmpty(profiles?.profilesAdditional);
    return (
      shouldRender && (
        <div className={s.profileAddsWrapper}>
          {profiles.profilesAdditional.map(({ title, id }, idx, arr) => {
            const isLast = arr.length - 1 === idx;
            return (
              <span key={id} style={{ whiteSpace: 'nowrap' }}>
                {`${title}${!isLast ? ',' : ''}`}&nbsp;
              </span>
            );
          })}
        </div>
      )
    );
  };

  const onVisibilityClick = useCallback(
    (id: number, isShow: boolean) => {
      dispatch(changeMMProfileVisibility(id, isShow, onGetMMProfile));
    },
    [dispatch, onGetMMProfile]
  );

  const getContent = useCallback(
    (colId, row: MMTableRow, collapseCallback?: () => void, isCollapseOpen?: boolean, isCollapsible?: boolean) =>
      profileRenderSwitch(
        colId,
        row,
        onChangeProfile,
        onVisibilityClick,
        isSectionReadOnly(mm_profiles),
        collapseCallback,
        isCollapseOpen,
        isCollapsible
      ),
    [mm_profiles, onChangeProfile, onVisibilityClick]
  );

  const filteredColumns = useMemo(() => {
    if (isSectionLimited(mm_profiles)) {
      return MM_PROFILES_COLUMNS.filter((col) => fields.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) >= 0);
    }
    if (!isSectionLimited(mm_profiles) && isSectionReadOnly(mm_profiles)) {
      return MM_PROFILES_COLUMNS.filter(
        (col) => READ_ONLY_FORBIDDEN_FIELDS.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) < 0
      );
    }
    return MM_PROFILES_COLUMNS;
  }, [mm_profiles, fields]);

  const handleChangeDeposit = useCallback(
    (reqData: ChangedDepositFormDTO, onError: (err: ErrorShape) => void) => {
      dispatch(
        changeDepositMMProfile(
          reqData,
          () => {
            onGetMMProfile();
            onToggleModal();
          },
          onError
        )
      );
    },
    [dispatch, onGetMMProfile]
  );

  const handleChangeAutoDeposit = useCallback(
    (reqData: ChangedAutoDepositFormDTO, onError: (err: ErrorShape) => void) => {
      dispatch(
        changeAutoDepositMMProfile(
          reqData,
          () => {
            onGetMMProfile();
            onToggleAutoChangeModal();
          },
          onError
        )
      );
    },
    [dispatch, onGetMMProfile]
  );

  const onChangePage = useCallback((newPage: number) => {
    setReqParams((prev) => ({ ...prev, page: newPage + ZERO_BASED_PAGE_SHIFT }));
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;

    const updatedFilterParams = {
      ...filterParams,
      [name]: event.target.value,
      page: 1
    };
    setFilterParams(updatedFilterParams as typeof initialMMProfilesValues);
    setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
  };

  const StatusIcon = useMemo(() => {
    if (isLoadingAutoDeposit) {
      return autoDepositDepo.is_auto_change_mm_depo_enabled ? <Check /> : <Cancel />;
    }
    return null;
  }, [isLoadingAutoDeposit, autoDepositDepo.is_auto_change_mm_depo_enabled]);

  return (
    <MainLayout contentCustomClass={s.contentWrapper}>
      <div>
        <div className={s.titleWrapper}>
          <h2 className="default-title">Money management profiles list</h2>
          {!isSectionReadOnly(mm_profiles) && (
            <div className={s.btnsContainer}>
              {showChangeDeposit && (
                <>
                  {StatusIcon}
                  <Tooltip
                    title={
                      <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
                        Auto change deposit settings
                      </div>
                    }
                  >
                    <Button
                      variant="outlined"
                      size="medium"
                      type="button"
                      classes={{ root: s.changeDeposit }}
                      onClick={onToggleAutoChangeModal}
                    >
                      Re-invest daily
                    </Button>
                  </Tooltip>
                </>
              )}
              <Button
                variant="outlined"
                size="medium"
                type="button"
                classes={{ root: s.btnRoot }}
                onClick={() => push(PAGES.MM_PROFILES_ADD)}
              >
                Add
              </Button>
              {showChangeDeposit && (
                <Tooltip
                  title={
                    <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
                      Обновить all signals quota для всех активных ММ профилей
                    </div>
                  }
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    type="button"
                    classes={{ root: s.changeDeposit }}
                    onClick={onToggleModal}
                  >
                    Change MM Deposit
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        <div className={s.textHelper}>
          *Выключать ММ профиль при inactive Strategy profile или после выключения и включения ММ профиля, заново
          активировать Strategy profile
        </div>
      </div>
      <div className={s.filters}>
        <div className={s.filtersInputs}>
          {MM_PROFILES_FILTERS.map((filter) => (
            <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
              <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
                {filter.label}
              </InputLabel>
              <Select
                id={filter.id}
                name={filter.id}
                displayEmpty
                value={filterParams[filter.id]}
                onChange={handleFilterChange}
              >
                {filter.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
        </div>
        {!isDepositInfoError && (
          <div className={s.depositInfo}>
            {Object.keys(depositInfo).map((depositInfoPiece) => {
              return (
                <Chip
                  key={depositInfoPiece}
                  label={`${ChipMappingsLabels[depositInfoPiece] || depositInfoPiece}: ${
                    depositInfo[depositInfoPiece]
                  }`}
                  variant="outlined"
                  style={{ width: 'max-content' }}
                />
              );
            })}
          </div>
        )}
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.DEFAULT}
          classes={{ root: s.filterButton }}
          onClick={() => {
            setReqParams(filterParams);
            setDisabledFilter(true);
          }}
          disabled={disabledFilter}
        >
          Apply
        </Button>
      </div>
      <PaginationContext.Provider
        value={{
          value: filterParams.page_size,
          options: Object.values(filterPerPageLabels),
          onChange: handleFilterChange,
          isLoading
        }}
      >
        <StickyContext.Provider value={{ bottomSticky: { key: pinnedKey, val: pinnedValue } }}>
          <CustomizedTable
            columns={filteredColumns}
            rows={rowsWithTotalRow}
            isLoading={isLoading}
            error={reqError}
            emptyRowsMsg="There are no money management profiles yet..."
            getContent={getContent}
            getCollapsibleContent={getCollapsibleContent}
            count={count}
            rowsPerPageOptions={[50]}
            customPerPage={50}
            customPage={reqParams.page - ZERO_BASED_PAGE_SHIFT}
            onCustomChangePage={onChangePage}
            offPerPage
            classes={{
              tableWrapper: s.tableWrapper,
              wrapperClass: s.signalsContent,
              tHeadClasses: {
                tHeadCellClass: s.tHeadCellClass
              },
              tBodyClasses: {
                tBodyCellClass: s.tBodyCellClass
              }
            }}
          />
        </StickyContext.Provider>
      </PaginationContext.Provider>
      <MyModal isOpen={isAutoChangeModalOpen} onToggle={onToggleAutoChangeModal}>
        <AutoChangeDepositeForm
          onCancel={onToggleAutoChangeModal}
          onSubmit={handleChangeAutoDeposit}
          autoDepositDepo={autoDepositDepo}
        />
      </MyModal>
      <MyModal isOpen={isModalOpen} onToggle={onToggleModal}>
        <ChangeDepositForm onCancel={onToggleModal} onSubmit={handleChangeDeposit} />
      </MyModal>
    </MainLayout>
  );
};

export default MMProfilesPage;
