export const tradingPairsTypes = {
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  REQUEST_ERROR: 'REQUEST_ERROR',
  TRADING_PAIRS_UPDATE: 'TRADING_PAIRS_UPDATE',
  TRADING_PAIR_CHANGE: 'TRADING_PAIR_CHANGE'
};

export const tradingPairsInitialState = {
  isLoading: true,
  reqError: null
};

interface TradingPairsLocalReducer {
  isLoading: boolean;
  reqError: null | string;
}

export const tradingPairsReducer = (
  state: TradingPairsLocalReducer,
  { type, payload }: { type: string; payload? }
): TradingPairsLocalReducer => {
  switch (type) {
    case tradingPairsTypes.START_LOADING:
      return { ...state, isLoading: true };

    case tradingPairsTypes.STOP_LOADING:
      return { ...state, isLoading: false };

    case tradingPairsTypes.REQUEST_ERROR:
      return { ...state, isLoading: false, reqError: payload };

    default:
      return state;
  }
};
