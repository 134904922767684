import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CALS_MAP,
  COUNT_FIELDS,
  FIELD_DEPENDENCIES_MAP,
  FIELDS_MAP,
  FILTERING_FIELDS,
  ONLY_FINISHED_FILED,
  SORTING_FIELDS
} from '../../../constants/privilegesConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getFieldsSelector } from '../../../redux/selectors';
import {
  updateFieldsPrivileges,
  updateFilteringFieldsPrivileges,
  updateFinishedOnlyFieldsPrivileges,
  updateSignalsCountFieldsPrivileges,
  updateSortingFieldsPrivileges
} from '../../../redux/actions/privilegesActions';
import PrivilegesModal from '../../privilegesModal/PrivilegesModal';

interface LimitsFormProps {
  onClose: () => void;
  editor: null | { id: number; value: string };
}

const LimitsForm: FC<LimitsFormProps> = ({ onClose, editor }): JSX.Element => {
  const {
    fields: groupFields,
    sorting,
    filtering,
    is_only_finished,
    signals_count
  } = useSelector(getFieldsSelector(editor?.id, editor?.value));
  const [sortingValue, setSortingValue] = useState(false);
  const [filteringValue, setFilteringValue] = useState(false);
  const [onlyFinishedValue, setOnlyFinishedValue] = useState(true);
  const [signalsCount, setSignalsCount] = useState(0);
  const [fields, setFields] = useState<string[]>(FIELDS_MAP[editor.value]);

  const fieldsDeps = useMemo(() => FIELD_DEPENDENCIES_MAP[editor.value] || [], [editor.value]);

  useEffect(() => {
    setSortingValue(!!sorting);
    setFilteringValue(!!filtering);
    setOnlyFinishedValue(!!is_only_finished);
    setSignalsCount(signals_count ?? 200);
    setFields(groupFields || []);
  }, [groupFields, sorting, filtering, is_only_finished, signals_count]);

  const dispatch = useDispatch();

  const handleChange = useCallback(
    ({ target }, { props }) => {
      if (fieldsDeps.length > 0 && fieldsDeps.indexOf(props.value) >= 0 && target.value.indexOf(props.value) >= 0) {
        setFields([...target.value.filter((el) => fieldsDeps.indexOf(el) < 0), props.value]);
      } else {
        setFields(target.value);
      }
    },
    [fieldsDeps]
  );

  const onFilteringCheck = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFilteringValue(target.checked);
  }, []);

  const onSortingCheck = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSortingValue(target.checked);
  }, []);

  const onOnlyFinishedClick = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyFinishedValue(target.checked);
  };

  const onSignalsCountChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSignalsCount(+target.value);
  };

  const hasFilteringProperty = useMemo(
    () => FILTERING_FIELDS.some((field) => field === editor?.value),
    [editor?.value]
  );
  const hasSortingProperty = useMemo(() => SORTING_FIELDS.some((field) => field === editor?.value), [editor?.value]);
  const hasOnlyFinishedProperty = useMemo(
    () => ONLY_FINISHED_FILED.some((field) => field === editor?.value),
    [editor?.value]
  );
  const hasSignalsCountProperty = useMemo(() => COUNT_FIELDS.some((field) => field === editor?.value), [editor?.value]);

  const onSave = useCallback(() => {
    dispatch(updateFieldsPrivileges(editor.id, editor.value, fields));
    if (hasFilteringProperty) {
      dispatch(updateFilteringFieldsPrivileges(editor.id, editor.value, filteringValue));
    }
    if (hasSortingProperty) {
      dispatch(updateSortingFieldsPrivileges(editor.id, editor.value, sortingValue));
    }
    if (hasOnlyFinishedProperty) {
      dispatch(updateFinishedOnlyFieldsPrivileges(editor.id, editor.value, onlyFinishedValue));
    }
    if (hasSignalsCountProperty) {
      dispatch(updateSignalsCountFieldsPrivileges(editor.id, editor.value, signalsCount));
    }
    onClose();
  }, [
    dispatch,
    editor.id,
    editor.value,
    fields,
    filteringValue,
    hasFilteringProperty,
    hasOnlyFinishedProperty,
    hasSignalsCountProperty,
    hasSortingProperty,
    onClose,
    onlyFinishedValue,
    signalsCount,
    sortingValue
  ]);

  return (
    <PrivilegesModal
      fields={fields}
      colId={editor.value}
      filteringValue={filteringValue}
      sortingValue={sortingValue}
      onSortingCheck={onSortingCheck}
      onClose={onClose}
      handleChange={handleChange}
      onFilteringCheck={onFilteringCheck}
      onSave={onSave}
      columns={CALS_MAP[editor.value]}
      onlyFinishedValue={onlyFinishedValue}
      onOnlyFinishedClick={onOnlyFinishedClick}
      signalsCount={signalsCount}
      onSignalsCountChange={onSignalsCountChange}
    />
  );
};

export default memo(LimitsForm, (prev: LimitsFormProps, next: LimitsFormProps) => prev.editor.id === next.editor.id);
