import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '../redux/store';
import { refreshRequestHelper } from '../redux/sagas/apiHelpers/authApiHelpers';
import * as actions from '../redux/actions/authActions';
import { API_ENDPOINTS } from '../constants/apiEndpoints';
import { authAccessTokenSelector, authRefreshTokenSelector } from '../redux/selectors';

export const clearAuthAccessToken = (): null => (axios.defaults.headers.common['Authorization'] = null);
const getAccessToken = () => authAccessTokenSelector(store.getState());
const getRefreshToken = () => authRefreshTokenSelector(store.getState());

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const retryOriginalRequest = ({ config }) => {
  return new Promise((resolve) => {
    const accessToken = getAccessToken();

    config.headers.Authorization = `Bearer ${accessToken}`;
    resolve(axios(config));
  }).then((response) => response);
};

const logout = (): void => {
  store.dispatch(actions.logoutSuccess());
  clearAuthAccessToken();
};

const isTokenNotValid = (error) => error.response.status === 401 && error.response.data.code === 'token_not_valid';

const refreshTokenPromiseRef = { current: null };
const failedTokenRequestCountRef = { count: 0 };

const resetFailedTokenRequestCount = () => {
  failedTokenRequestCountRef.count--;

  if (failedTokenRequestCountRef.count <= 0) {
    refreshTokenPromiseRef.current = null;
    failedTokenRequestCountRef.count = 0;
  }
};

const handleRefreshToken = (reqData: {
  refresh: string;
}): Promise<AxiosResponse<{ access: string; refresh: string; is_sec_factor_passed: boolean }>> => {
  if (refreshTokenPromiseRef.current !== null) {
    return refreshTokenPromiseRef.current;
  }

  refreshTokenPromiseRef.current = refreshRequestHelper(reqData);

  return refreshTokenPromiseRef.current;
};

const resetTokenAndReattemptRequest = async (error: AxiosError) => {
  const refreshToken = getRefreshToken();
  failedTokenRequestCountRef.count++;
  try {
    const { data } = await handleRefreshToken({ refresh: refreshToken });

    if (!data.refresh) {
      return Promise.reject(error);
    }
    resetFailedTokenRequestCount();
    store.dispatch(actions.refreshSuccess(data?.refresh, data?.access, data?.is_sec_factor_passed));

    return retryOriginalRequest(error.response);
  } catch (err) {
    resetFailedTokenRequestCount();
    logout();
    return Promise.reject(err);
  }
};

AxiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();

    if (accessToken && config.url !== API_ENDPOINTS.REFRESH) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      config.headers.Authorization = `Basic ${process.env.REACT_APP_BASE_AUTH}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (isTokenNotValid(error)) {
      if (originalRequest.url === API_ENDPOINTS.REFRESH) {
        logout();
        return Promise.reject(error);
      }
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        return resetTokenAndReattemptRequest(error);
      }
    }
    return Promise.reject(error);
  }
);
