import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import * as Shapes from 'interfaces/strategyProfilesShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const createProfileHelper = (
  reqData: Shapes.CreateProfileReqData
): Promise<AxiosResponse<Shapes.StrategyProfileShape>> => {
  return axios.post(API_ENDPOINTS.STRATEGY_PROFILES, reqData);
};

export const updateProfileHelper = (
  id: number,
  reqData: Shapes.CreateProfileReqData
): Promise<AxiosResponse<Shapes.StrategyProfileShape>> => {
  return axios.put(API_ENDPOINTS.STRATEGY_PROFILE(id), reqData);
};

export const updateStrategyProfileVisibilityHelper = (
  id: number,
  reqData: { is_show: boolean }
): Promise<AxiosResponse<Shapes.StrategyProfileShape>> => {
  return axios.put(API_ENDPOINTS.STRATEGY_PROFILE(id), reqData);
};

export const updateStrategyDetailsHelper = (
  id: number,
  reqData: Partial<Shapes.UpdateStrategyData>
): Promise<AxiosResponse<Shapes.UpdateStrategyResponse>> => {
  return axios.put(API_ENDPOINTS.STRATEGY_SETTING(id), reqData);
};

export const getStrategyProfilesHelper = (
  params: Shapes.GetStrategyProfilesReq
): Promise<AxiosResponse<Shapes.StrategyProfilesData>> => {
  return axios.get(API_ENDPOINTS.STRATEGY_PROFILES, { params });
};

export const getSystemStatusHelper = (): Promise<AxiosResponse<Shapes.SystemStatusShape>> => {
  return axios.get(API_ENDPOINTS.SYSTEM_STATUS);
};

export const setSystemStatusHelper = (status: string): Promise<AxiosResponse<Shapes.SystemStatusShape>> => {
  return axios.post(API_ENDPOINTS.SET_SYSTEM_STATUS(status));
};

export const getProfileHelper = (id: number): Promise<AxiosResponse<Shapes.StrategyProfileShape>> => {
  return axios.get(API_ENDPOINTS.STRATEGY_PROFILE(id));
};

export const getStrategySettingsHelper = (id: number): Promise<AxiosResponse<Shapes.UpdateStrategyResponse>> => {
  return axios.get(API_ENDPOINTS.STRATEGY_SETTING(id));
};

export const startStrategyHelper = (id: number): Promise<AxiosResponse<Shapes.StrategyProfileShape>> => {
  return axios.put(API_ENDPOINTS.STRATEGY_PROFILE_START(id));
};

export const stopStrategyHelper = (id: number): Promise<AxiosResponse<Shapes.StrategyProfileShape>> => {
  return axios.put(API_ENDPOINTS.STRATEGY_PROFILE_STOP(id));
};

export const getStrategyTickers = (reqData): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.GET_STRATEGY_TICKERS(reqData));
};

export const saveStrategyTickersHelper = (id: number, reqData: Shapes.PairsShape): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.STRATEGY_TICKER(id), reqData);
};

export const deleteStrategyTickersHelper = (id: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.STRATEGY_TICKER(id));
};

export const addStrategyTickersHelper = (values: Shapes.PairFormValues): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.STRATEGY_TICKERS, values);
};

export const getProfilesHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.PROFILE_STATS_FILTERS);
};
