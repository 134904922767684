import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import s from '../statsStaticGraphs/StatsStaticGraphs.module.scss';
import Chart from 'react-apexcharts';
import { ChartTypeValues, ChartConfigsMap, ChartTypeKeys } from '../../helpers/chartsHelpers';
import { Paper } from '@material-ui/core';
import { useResize } from '../../hooks/useResize';
import { ApexOptions } from 'apexcharts';
import cn from 'classnames';
import { FilterChartTypeValues } from '../../constants/chartStatsConstants';
import { createRandomId } from '../../helpers/randomHelpers';

interface CustomizedChartProps {
  chartType: string;
  chartName: string;
  chartType_2: string;
  chartName2: string;
  timeFrame: string;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  parentRef: { current: HTMLDivElement };
  isSeparated: boolean;
  isIframe: boolean;
  onChangeChart: (selectedChartName: FilterChartTypeValues) => void;
}

const CustomizedChart: FC<CustomizedChartProps> = ({
  isSeparated,
  timeFrame,
  parentRef,
  chartType,
  chartType_2,
  series,
  chartName,
  chartName2,
  isIframe,
  onChangeChart
}) => {
  const { width, height } = useResize(parentRef);
  const [randomId, setRandomId] = useState(createRandomId());
  const handleConfigOptions = !chartType_2 ? ChartConfigsMap[chartType] : ChartConfigsMap[ChartTypeKeys.MIXED];
  const ref = useRef([]);

  const seriesWithUniqueDates = useMemo(() => {
    return (series as ApexAxisChartSeries).map((el) => {
      ref.current = [];
      return {
        ...el,
        data: (el?.data as { x: string; y: number[] }[]).reduce((acc, val) => {
          if (ref.current.indexOf(val.x) < 0) {
            ref.current = [...ref.current, val.x];
            return [...acc, val];
          }
          return acc;
        }, [])
      };
    });
  }, [series]);

  const handleChangeChart = (selectedChartName: FilterChartTypeValues) => {
    if (selectedChartName !== chartName) {
      onChangeChart(selectedChartName);
    }
  };

  const forceUpdateChart = () => {
    const id = createRandomId();
    setRandomId(id);
  };

  useEffect(forceUpdateChart, [chartType, timeFrame, chartName, isIframe]);

  return (
    <div style={{ position: 'relative' }}>
      {isIframe && (
        <div className={s.chatSwitcher}>
          <div
            onClick={() => handleChangeChart(FilterChartTypeValues.VAR)}
            className={cn(s.switcherBlock, { [s.active]: chartName === FilterChartTypeValues.VAR })}
          >
            VAR
          </div>
          <div
            onClick={() => handleChangeChart(FilterChartTypeValues.EQUITY)}
            className={cn(s.switcherBlock, { [s.active]: chartName === FilterChartTypeValues.EQUITY })}
          >
            EQUITY
          </div>
        </div>
      )}
      <Paper className={s.paperClass} elevation={2}>
        <div style={isSeparated ? { display: 'flex', flexDirection: 'column', gap: 10 } : {}}>
          <Chart
            key={randomId}
            options={
              isSeparated
                ? ChartConfigsMap[chartType](timeFrame, chartName, isIframe)
                : handleConfigOptions(timeFrame, chartName, isIframe)
            }
            series={isSeparated ? ([seriesWithUniqueDates[0]] as ApexOptions['series']) : seriesWithUniqueDates}
            type={ChartTypeValues[chartType]}
            width={width}
            height={isSeparated ? height / 2 : height}
          />
          {isSeparated && (
            <Chart
              options={ChartConfigsMap[chartType_2](timeFrame, chartName2, isIframe)}
              series={[seriesWithUniqueDates[1]] as ApexOptions['series']}
              type={ChartTypeValues[chartType_2]}
              width={width}
              height={height / 2 - 20}
            />
          )}
        </div>
      </Paper>
    </div>
  );
};

export default memo(CustomizedChart);
