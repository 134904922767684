import React, { FC, memo, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import { PAGES } from './constants/routes';
import { usePrivileges } from './hooks/usePrivileges';
import { getAllowedRoutes, isSectionAllowed } from './helpers/privilegesHelpers';

interface RoutesProps {
  isAuth: boolean;
}

const Routes: FC<RoutesProps> = ({ isAuth }) => {
  const privileges = usePrivileges();
  const loggedInPath = isSectionAllowed(privileges.signals) ? PAGES.SIGNALS_ORDERS_SIGNALS : PAGES.ACCOUNT_DETAILS;
  const routes = useMemo(() => getAllowedRoutes(privileges), [privileges]);

  return (
    <Switch>
      {routes.map((route) => {
        const { isPrivate, path, exact, hide, component: Component } = route;
        if (!hide) {
          return isPrivate ? (
            <ProtectedRoute
              key={path}
              path={path}
              exact={exact}
              isAuth={isAuth}
              component={Component}
              redirectTo={route.redirectTo}
            />
          ) : (
            <Route key={path} path={path} exact={exact} component={Component} />
          );
        }
        return null;
      })}

      <Route path={PAGES.DEFAULT}>
        <Redirect to={isAuth ? loggedInPath : PAGES.LOGIN} />
      </Route>

      <Route path={PAGES.NO_MATCH}>
        <Redirect to={PAGES.LOGIN} />
      </Route>
    </Switch>
  );
};

export default memo(Routes);
