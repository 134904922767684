import { all, AllEffect, ForkEffect } from 'redux-saga/effects';
import { authWatcher } from './authSaga';
import { systemSettingsWatcher } from './systemSettingsSaga';
import { mmProfilesWatcher } from './mmProfilesSaga';
import { signalsOrdersWatcher } from './signalsOrdersSaga';
import { statsWatcher } from './statsSaga';
import { strategyProfilesWatcher } from './strategyProfilesSaga';
import { notificationsWatcher } from './notificationsSaga';
import { logsWatcher } from './logsSaga';
import { privilegesWatcher } from './privilegesSaga';

export default function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>, void, unknown>>, void, unknown> {
  yield all([
    authWatcher(),
    systemSettingsWatcher(),
    mmProfilesWatcher(),
    signalsOrdersWatcher(),
    statsWatcher(),
    strategyProfilesWatcher(),
    notificationsWatcher(),
    logsWatcher(),
    privilegesWatcher()
  ]);
}
