import MainLayout from 'components/mainLayout/MainLayout';
import React, { FC, useEffect } from 'react';
import MonthsStatsTable from '../../../components/monthsStatsTable/MonthsStatsTable';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearStatsCharts } from '../../../redux/actions/statsActions';

const MonthsStatsPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      history.push({
        pathname: window.location.pathname,
        search: ''
      });
      dispatch(clearStatsCharts());
    };
  }, [dispatch, history]);

  return (
    <MainLayout>
      <MonthsStatsTable />
    </MainLayout>
  );
};

export default MonthsStatsPage;
