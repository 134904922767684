import React, { ReactNode } from 'react';
import * as Shapes from 'interfaces/logsShapes';
import { formatISODate } from 'helpers/randomHelpers';
import { filterIDs, filterIDsFromRequest, LOGS_FILTER, LogsColIds } from '../constants/logsConstants';
import s from 'components/logsTable/LogsTable.module.scss';

export const createLogsRow = ({
  id,
  date,
  type,
  pair_ticker,
  profile_name,
  signal_id,
  order_id,
  log_data
}: Shapes.LogsTableRow): Shapes.LogsTableRow => {
  const formattedDate = formatISODate(date, 'dd.MM.yyyy : HH:mm:ss');
  const formattedLogData = JSON.stringify(log_data) !== 'null' ? JSON.stringify(log_data) : null;

  return {
    id,
    date: formattedDate,
    type,
    pair_ticker,
    profile_name,
    signal_id,
    order_id,
    log_data: formattedLogData
  };
};

const makeString = (str) => (str === 'undefined' || str === 'null' ? '-' : str);

const cutProfileBySlash = (str: string) => {
  const slashIndex = str.indexOf('/');
  return slashIndex >= 0 ? str.substr(0, slashIndex) : str;
};

export const logsPageRenderSwitch = (colId: LogsColIds, row: Shapes.LogsTableRow): ReactNode => {
  const value = row[colId]?.toString();
  let cellValue, cellTitle, cellDate, cellTime: string;

  switch (colId) {
    case LogsColIds.PAIR_TICKER:
      cellValue = makeString(value.split(' : ')[0]);
      cellTitle = makeString(value.split(' : ')[1]);
      return (
        <p className={s.pairContent} title={cellValue}>
          {cellTitle}
        </p>
      );
    case LogsColIds.PROFILE_NAME:
      cellValue = makeString(value.split(' : ')[0]);
      cellTitle = makeString(value.split(' : ')[1]);
      if (row[LogsColIds.TYPE].split(' : ')[1] === 'atr_calculated') {
        cellValue = cutProfileBySlash(cellValue);
      }
      return <p title={cellTitle}>{cellValue}</p>;
    case LogsColIds.TYPE:
      cellValue = makeString(value.split(' : ')[1]);
      cellTitle = makeString(value.split(' : ')[0]);
      return (
        <p className={s.eventContent} title={cellTitle}>
          {cellValue}
        </p>
      );
    case LogsColIds.DATE:
      cellDate = makeString(value.split(' : ')[0]);
      cellTime = makeString(value.split(' : ')[1]);
      return (
        <p className={s.eventContent}>
          {cellDate}
          <br />
          {cellTime}
        </p>
      );
    case LogsColIds.LOG_DATA:
      return <p className={s.messageContent}>{value === '{}' ? '-' : value || '-'}</p>;
    default:
      return <p className={s.messageContent}>{value ?? '-'}</p>;
  }
};

export const createFiltersHelper = (filters: Shapes.FilterData, customFilter: typeof LOGS_FILTER): void => {
  for (const key in filters) {
    let currentFilterIndex;
    switch (key) {
      case filterIDsFromRequest.PAIR:
        currentFilterIndex = customFilter.findIndex((filter) => filter.id === filterIDs.PAIR);
        filters[key]
          .sort((a, b) => ('' + a.title).localeCompare(b.title))
          .forEach((filter) => {
            customFilter[currentFilterIndex].options.push({
              value: filter?.id.toString(),
              label: filter?.title.toString()
            });
          });
        break;
      case filterIDsFromRequest.PROFILE:
        currentFilterIndex = customFilter.findIndex((filter) => filter.id === filterIDs.PROFILE);
        filters[key]
          .sort((a, b) => ('' + a.title).localeCompare(b.title))
          .forEach((filter) => {
            customFilter[currentFilterIndex].options.push({
              value: filter?.id.toString(),
              label: filter?.title.toString()
            });
          });
        break;
    }
  }
};
