import React from 'react';
import cn from 'classnames';
import { Modal, Fade } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { useModalStyles } from './styles';

const MODAL_TRANSITION_TIMEOUT = 300;
const MODAL_BACKDROP_TIMEOUT = 500;

export interface StylesProps {
  modal?: {
    padding?: string;
    alignItems?: string;
    justifyContent?: string;
    transition?: string;
  };
  paper?: {
    top?: string;
    left?: string;
    padding?: string | number;
    display?: string;
    alignItems?: string;
    justifyContent?: string;
    flexDirection?: 'row' | 'column';
    backgroundColor?: string;
    boxShadow?: string;
  };
  backDrop?: {
    backgroundColor?: string;
  };
}

interface ModalProps {
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  stylesProps?: StylesProps;
  paperCustomClass?: string;
}

const MyModal = ({
  children,
  onToggle,
  isOpen,
  disableBackdropClick,
  disableEscapeKeyDown,
  stylesProps,
  paperCustomClass
}: ModalProps): JSX.Element => {
  const { modal, backDrop, paper } = useModalStyles(stylesProps);
  return (
    <Modal
      closeAfterTransition
      open={isOpen}
      onClose={onToggle}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      className={modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: { root: backDrop },
        timeout: MODAL_BACKDROP_TIMEOUT
      }}
    >
      <Fade in={isOpen} timeout={MODAL_TRANSITION_TIMEOUT}>
        <div className={cn(paper, { [paperCustomClass]: paperCustomClass })}>{children}</div>
      </Fade>
    </Modal>
  );
};

export default MyModal;
