import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED } from 'constants/randomConstants';

export const useAsideStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    menuButton: {
      marginLeft: 0,
      transition: 'all 0.3s ease-in-out'
    },
    menuButtonClosed: {
      marginRight: 4
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflowX: 'hidden'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: DRAWER_WIDTH_CLOSED,
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH_CLOSED
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    listItemRoot: {
      paddingLeft: 23,
      paddingTop: 16,
      paddingBottom: 16,
      color: 'rgba(0, 0, 0, 0.54)',
      transition: 'all 0.3s easy-in-out'
    },

    menuWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },

    link: {
      color: 'rgba(0, 0, 0, 0.54)'
    },

    linkActive: {
      color: '#1976d2'
    }
  })
);
