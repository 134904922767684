import * as Yup from 'yup';
import { ButtonColorTypes, ColumnAlignOptions } from 'constants/randomConstants';
import { AutoChangeDepo, ChangedAutoDepositFormDTO, MMExtendedShape, MMStatuses } from 'interfaces/mmProfilesShapes';
import { MMTableColIds as MMTableColPrivilegesIds } from './privilegesConstants';

export interface MMProfilesAddFormValues {
  title: string;
  all_signals_quota: string;
  max_signals_amount: string;
  exchange_account: string;
  max_factor_amount: string;
}

export interface FormAutoDepositValues {
  is_auto_change_mm_depo_enabled: boolean;
  auto_change_mm_depo_percent: string;
}

export enum MMProfilesAddFields {
  TITLE = 'title',
  QUOTA = 'all_signals_quota',
  MAX_AMOUNT = 'max_signals_amount',
  EXCHANGE_ACC = 'exchange_account',
  MAX_FACTOR = 'max_factor_amount'
}

export const validationSchema = Yup.object({
  [MMProfilesAddFields.TITLE]: Yup.string()
    .required('MM Profile name can’t be blank')
    .max(64, 'MM Profile name could be maximum 64 symbols'),
  [MMProfilesAddFields.QUOTA]: Yup.string().required('All signals quota can’t be blank'),
  [MMProfilesAddFields.MAX_AMOUNT]: Yup.string().required('Max signals can’t be blank'),
  [MMProfilesAddFields.EXCHANGE_ACC]: Yup.string().required('Exchange acc name can’t be blank'),
  [MMProfilesAddFields.MAX_FACTOR]: Yup.number().required('Max factor can’t be blank')
});

export enum MMTableColIds {
  TITLE = 'title',
  PROFILES = 'profiles',
  MAX_SIGNALS = 'max_signals_amount',
  ONE_SIGNAL_QUOTA = 'one_signal_quota',
  ALL_SIGNAL_QUOTA = 'all_signals_quota',
  EXCHANGE_ACC_NAME = 'exchange_account',
  MAX_FACTOR = 'max_factor_amount',
  STATUS = 'status',
  ACTION = 'action',
  VISIBILITY = 'visibility'
}

const MM_TABLE_COL_NAMES = {
  [MMTableColIds.TITLE]: 'MM profile name',
  [MMTableColIds.PROFILES]: 'Strategy profiles',
  [MMTableColIds.MAX_SIGNALS]: 'Max signals',
  [MMTableColIds.ONE_SIGNAL_QUOTA]: 'One signal quota($)',
  [MMTableColIds.ALL_SIGNAL_QUOTA]: 'All signals quota($)',
  [MMTableColIds.EXCHANGE_ACC_NAME]: 'Exchange acc name',
  [MMTableColIds.MAX_FACTOR]: 'Max factor',
  [MMTableColIds.STATUS]: 'Status',
  [MMTableColIds.ACTION]: 'Action',
  [MMTableColIds.VISIBILITY]: 'Visibility'
};

export const SERVER_CLIENT_COLS_MAP = {
  [MMTableColIds.TITLE]: MMTableColPrivilegesIds.TITLE,
  [MMTableColIds.PROFILES]: MMTableColPrivilegesIds.PROFILES,
  [MMTableColIds.MAX_FACTOR]: MMTableColPrivilegesIds.MAX_FACTOR,
  [MMTableColIds.MAX_SIGNALS]: MMTableColPrivilegesIds.MAX_SIGNALS,
  [MMTableColIds.ONE_SIGNAL_QUOTA]: MMTableColPrivilegesIds.ONE_SINGLE_QUOTA,
  [MMTableColIds.ALL_SIGNAL_QUOTA]: MMTableColPrivilegesIds.ALL_SIGNAL_QUOTA,
  [MMTableColIds.EXCHANGE_ACC_NAME]: MMTableColPrivilegesIds.EXCHANGE_ACC_NAME,
  [MMTableColIds.STATUS]: MMTableColPrivilegesIds.STATUS,
  [MMTableColIds.ACTION]: MMTableColPrivilegesIds.ACTION,
  [MMTableColIds.VISIBILITY]: MMTableColPrivilegesIds.IS_SHOW
};

export const READ_ONLY_FORBIDDEN_FIELDS = [MMTableColPrivilegesIds.ACTION as string, MMTableColPrivilegesIds.IS_SHOW];

const DEFAULT_COL_TITLE_ALIGN = ColumnAlignOptions.CENTER;

export const MM_PROFILES_COLUMNS = [
  {
    id: MMTableColIds.TITLE,
    title: MM_TABLE_COL_NAMES[MMTableColIds.TITLE],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.PROFILES,
    title: MM_TABLE_COL_NAMES[MMTableColIds.PROFILES],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.MAX_FACTOR,
    title: MM_TABLE_COL_NAMES[MMTableColIds.MAX_FACTOR],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.MAX_SIGNALS,
    title: MM_TABLE_COL_NAMES[MMTableColIds.MAX_SIGNALS],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.ONE_SIGNAL_QUOTA,
    title: MM_TABLE_COL_NAMES[MMTableColIds.ONE_SIGNAL_QUOTA],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.ALL_SIGNAL_QUOTA,
    title: MM_TABLE_COL_NAMES[MMTableColIds.ALL_SIGNAL_QUOTA],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.EXCHANGE_ACC_NAME,
    title: MM_TABLE_COL_NAMES[MMTableColIds.EXCHANGE_ACC_NAME],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.STATUS,
    title: MM_TABLE_COL_NAMES[MMTableColIds.STATUS],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.VISIBILITY,
    title: MM_TABLE_COL_NAMES[MMTableColIds.VISIBILITY],
    align: DEFAULT_COL_TITLE_ALIGN
  },
  {
    id: MMTableColIds.ACTION,
    title: MM_TABLE_COL_NAMES[MMTableColIds.ACTION],
    align: DEFAULT_COL_TITLE_ALIGN
  }
];

interface StrategyProfilesTable {
  title: string;
  id: number;
}

export interface MMTableRow {
  id: number;
  rawData?: MMExtendedShape;
  isCollapsible?: boolean;
  [MMTableColIds.TITLE]: string;
  [MMTableColIds.PROFILES]: {
    profiles: Array<StrategyProfilesTable>;
    profilesAdditional?: Array<StrategyProfilesTable>;
  };
  [MMTableColIds.MAX_SIGNALS]: number;
  [MMTableColIds.MAX_FACTOR]: number;
  [MMTableColIds.ONE_SIGNAL_QUOTA]: string;
  [MMTableColIds.ALL_SIGNAL_QUOTA]: string;
  [MMTableColIds.EXCHANGE_ACC_NAME]: string;
  [MMTableColIds.STATUS]: MMStatuses;
  [MMTableColIds.ACTION]: { label: string; color: ButtonColorTypes };
}

const { ACTIVE, INACTIVE, STOPPING } = MMStatuses;
const { PRIMARY, SECONDARY, DEFAULT } = ButtonColorTypes;

export const ACTION_BTN_COLORS = {
  [ACTIVE]: SECONDARY,
  [INACTIVE]: PRIMARY,
  [STOPPING]: DEFAULT
};

export const ACTION_BTN_LABELS = {
  [ACTIVE]: 'Stop',
  [INACTIVE]: 'Start',
  [STOPPING]: 'Start'
};

export const percentOptions = [
  { label: '5%', value: '5' },
  { label: '10%', value: '10' },
  { label: '15%', value: '15' },
  { label: '20%', value: '20' },
  { label: '25%', value: '25' },
  { label: '30%', value: '30' },
  { label: '35%', value: '35' },
  { label: '40%', value: '40' },
  { label: '45%', value: '45' },
  { label: '50%', value: '50' },
  { label: '55%', value: '55' },
  { label: '60%', value: '60' },
  { label: '65%', value: '65' },
  { label: '70%', value: '70' },
  { label: '75%', value: '75' },
  { label: '80%', value: '80' },
  { label: '85%', value: '85' },
  { label: '90%', value: '90' },
  { label: '95%', value: '95' },
  { label: '100%', value: '100' }
];

export enum MMProfileFilterIDs {
  STATUS = 'status',
  VISIBILITY = 'visibility',
  PAGE = 'page',
  PER_PAGE = 'page_size',
  DIRECTION = 'direction'
}

export const filterPerPageValues = {
  PP_50: '50',
  PP_100: '100',
  PP_200: '200',
  PP_500: '500'
};

export const filterPerPageLabels = {
  [filterPerPageValues.PP_50]: '50',
  [filterPerPageValues.PP_100]: '100',
  [filterPerPageValues.PP_200]: '200',
  [filterPerPageValues.PP_500]: '500'
};

export const MMProfileLabels = {
  [MMProfileFilterIDs.STATUS]: 'Status',
  [MMProfileFilterIDs.VISIBILITY]: 'Visibility',
  [MMProfileFilterIDs.DIRECTION]: 'Direction'
};

export enum FilterStatusValues {
  ALL = '',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export const FilterStatusLabels = {
  [FilterStatusValues.ALL]: 'All',
  [FilterStatusValues.ACTIVE]: 'Active',
  [FilterStatusValues.INACTIVE]: 'Inactive'
};

export enum FilterVisibilityValues {
  ALL = '',
  VISIBLE = 'visible',
  INVISIBLE = 'invisible'
}

export const FilterVisibilityLabels = {
  [FilterVisibilityValues.ALL]: 'All',
  [FilterVisibilityValues.VISIBLE]: 'Visible',
  [FilterVisibilityValues.INVISIBLE]: 'Invisible'
};

export enum FilterDirectionValues {
  ALL = '',
  SHORT = 'short',
  LONG = 'long'
}

export const FilterDirectionLabels = {
  [FilterDirectionValues.ALL]: 'All',
  [FilterDirectionValues.SHORT]: 'Short',
  [FilterDirectionValues.LONG]: 'Long'
};

export const MM_PROFILES_FILTERS = [
  {
    id: MMProfileFilterIDs.STATUS,
    label: MMProfileLabels[MMProfileFilterIDs.STATUS],
    options: [
      { value: FilterStatusValues.ALL, label: FilterStatusLabels[FilterStatusValues.ALL] },
      { value: FilterStatusValues.ACTIVE, label: FilterStatusLabels[FilterStatusValues.ACTIVE] },
      { value: FilterStatusValues.INACTIVE, label: FilterStatusLabels[FilterStatusValues.INACTIVE] }
    ]
  },
  {
    id: MMProfileFilterIDs.VISIBILITY,
    label: MMProfileLabels[MMProfileFilterIDs.VISIBILITY],
    options: [
      { value: FilterVisibilityValues.ALL, label: FilterVisibilityLabels[FilterVisibilityValues.ALL] },
      { value: FilterVisibilityValues.VISIBLE, label: FilterVisibilityLabels[FilterVisibilityValues.VISIBLE] },
      { value: FilterVisibilityValues.INVISIBLE, label: FilterVisibilityLabels[FilterVisibilityValues.INVISIBLE] }
    ]
  },
  {
    id: MMProfileFilterIDs.DIRECTION,
    label: MMProfileLabels[MMProfileFilterIDs.DIRECTION],
    options: [
      { value: FilterDirectionValues.ALL, label: FilterDirectionLabels[FilterDirectionValues.ALL] },
      { value: FilterDirectionValues.SHORT, label: FilterDirectionLabels[FilterDirectionValues.SHORT] },
      { value: FilterDirectionValues.LONG, label: FilterDirectionLabels[FilterDirectionValues.LONG] }
    ]
  }
];

export const initialMMProfilesValues = {
  [MMProfileFilterIDs.STATUS]: FilterStatusValues.ALL as string,
  [MMProfileFilterIDs.DIRECTION]: FilterDirectionValues.ALL,
  [MMProfileFilterIDs.VISIBILITY]: FilterVisibilityValues.VISIBLE,
  [MMProfileFilterIDs.PAGE]: 1,
  [MMProfileFilterIDs.PER_PAGE]: filterPerPageValues.PP_50
};

export type MMReqParams = {
  [MMProfileFilterIDs.STATUS]?: string;
  [MMProfileFilterIDs.VISIBILITY]?: string;
  [MMProfileFilterIDs.PAGE]?: number;
};

export const pinnedKey = MMTableColIds.TITLE;
export const pinnedValue = 'Summary:';

export const getInitValues = (autoDepositDepo: AutoChangeDepo): FormAutoDepositValues => {
  return {
    is_auto_change_mm_depo_enabled: autoDepositDepo.is_auto_change_mm_depo_enabled,
    auto_change_mm_depo_percent: autoDepositDepo.auto_change_mm_depo_percent.toString()
  };
};

export const getAutoDepositChangeDTO = (values: FormAutoDepositValues): ChangedAutoDepositFormDTO => ({
  auto_change_mm_depo_percent: values.is_auto_change_mm_depo_enabled ? values.auto_change_mm_depo_percent : '0',
  is_auto_change_mm_depo_enabled: values.is_auto_change_mm_depo_enabled
});

export const ChipMappingsLabels = {
  ideo_r_long: 'Ideo-R/Long',
  ideo_r_short: 'Ideo-R/Short',
  mm_asset_sum: 'MM Asset Sum',
  mm_deposit: 'MM Deposit'
};
