import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { startStrategy, stopStrategy } from 'redux/actions/strategyProfilesActions';
import { PAGES } from 'constants/routes';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import { ACTION_BTN_COLORS, ACTION_BTN_LABELS } from 'constants/strategyProfilesConstants';
import { StrategyProfileStatuses, StrategyProfileShape } from 'interfaces/strategyProfilesShapes';
import Fade from 'components/reactTransitions/FadeTransition.module.css';
import s from './StrategyDetailsNav.module.scss';
import { usePrivileges } from '../../../../hooks/usePrivileges';
import { isSectionReadOnly } from '../../../../helpers/privilegesHelpers';
import { capitalize } from 'lodash';

interface StrategyDetailsNavProps {
  profile: StrategyProfileShape | null;
  error: null | string;
  onSetError: (err) => void;
  wrapperClass?: string;
}

const StrategyDetailsNav = ({ profile, error, wrapperClass, onSetError }: StrategyDetailsNavProps): ReactElement => {
  const { goBack, push } = useHistory();
  const dispatch = useDispatch();
  const { profile_settings } = usePrivileges();

  const onStartStrategy = (id: number) => {
    dispatch(startStrategy(id, onSetError));
  };
  const onStopStrategy = (id: number) => {
    dispatch(stopStrategy(id, onSetError));
  };

  const onContinueDraft = (profileId: number) => push(PAGES.STRATEGY_PROFILES_ADD, { profileId });

  const onActionProfile = (profileId: number, status: StrategyProfileStatuses) => {
    switch (status) {
      case StrategyProfileStatuses.ACTIVE:
        return onStopStrategy(profileId);

      case StrategyProfileStatuses.INACTIVE:
      case StrategyProfileStatuses.STOPPING:
        return onStartStrategy(profileId);

      case StrategyProfileStatuses.DRAFT:
        return onContinueDraft(profileId);

      default:
        return null;
    }
  };

  return (
    <div className={cn(s.navBlock, { [wrapperClass]: wrapperClass })}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          unmountOnExit
          key={error ? 'error' : 'content'}
          timeout={DEFAULT_TRANSITION_TIMEOUT}
          classNames={{ ...Fade }}
        >
          {error ? (
            <span className={cn(s.error, 'errorText')}>{error}</span>
          ) : (
            <>
              <div className={s.titleBlock}>
                <IconButton aria-label="go back" size="medium" className={s.arrowBtn} onClick={() => goBack()}>
                  <ArrowForwardIosIcon fontSize="inherit" className={s.arrowBack} />
                </IconButton>
                <h2 className={cn(s.title, 'default-title')}>{profile?.title ?? '-'}</h2>
                <span
                  className={cn(s.statusDefault, {
                    [s.statusActive]: profile?.status === StrategyProfileStatuses.ACTIVE,
                    [s.statusInactive]: profile?.status === StrategyProfileStatuses.INACTIVE
                  })}
                >
                  {capitalize(profile?.status) ?? '-'}
                </span>
              </div>
              {!isSectionReadOnly(profile_settings) && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  type="button"
                  color={ACTION_BTN_COLORS[profile?.status]}
                  onClick={() => onActionProfile(profile?.id, profile.status)}
                  classes={{ root: s.btnDraft, outlinedPrimary: s.btnPrimary }}
                >
                  {ACTION_BTN_LABELS[profile?.status]}
                </Button>
              )}
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default StrategyDetailsNav;
