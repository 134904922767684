import React from 'react';
import MainLayout from '../../components/mainLayout/MainLayout';
import { GroupForm } from './components/GroupForm';
import { Formik } from 'formik';
import {
  groupPrivilegesValidationSchema,
  PrivilegesGroupFormFields,
  privilegesGroupInitVales
} from '../../constants/privilegesConstants';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createGroups } from '../../redux/actions/privilegesActions';
import { createPrivilegesGroupValues } from '../../helpers/privilegesHelpers';
import { PAGES } from '../../constants/routes';
import { useHistory } from 'react-router-dom';

export const PrivilegesAdd = (): JSX.Element => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  return (
    <MainLayout>
      <Formik
        initialValues={privilegesGroupInitVales}
        validationSchema={Yup.object().shape(groupPrivilegesValidationSchema)}
        onSubmit={(values: PrivilegesGroupFormFields) => {
          dispatch(createGroups(createPrivilegesGroupValues(values), () => push(PAGES.PRIVILEGES_GROUPS)));
        }}
      >
        <GroupForm />
      </Formik>
    </MainLayout>
  );
};
