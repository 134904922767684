import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getAllowedStatsRoute } from '../../helpers/privilegesHelpers';
import { Loader } from '../../components/loader/Loader';

const StatsPage: FC = () => {
  const { profiles_stats, account_stats, stat_by_months, charts } = usePrivileges();
  const route = getAllowedStatsRoute(profiles_stats, account_stats, stat_by_months, charts);
  if (route) {
    return <Redirect to={route} />;
  }
  return <Loader />;
};

export default StatsPage;
