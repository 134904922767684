import * as Yup from 'yup';

export enum TriggerTypes {
  NAV_ALERTING = 'nav_alerting',
  NAV_REDUCING = 'nav_reducing'
}

export enum FormKeys {
  TRIGGER_TYPE = 'trigger_type',
  TIMER = 'timer',
  TRIGGER_BLOCKER = 'trigger_blocker',
  POSITION_REDUCE = 'position_reduce',
  X_PERCENT_DEPO = 'x_percent_depo',
  Y_PERCENT_DEPO = 'y_percent_depo'
}

export const options = [
  { value: TriggerTypes.NAV_ALERTING, label: 'NAV Alerting' },
  { value: TriggerTypes.NAV_REDUCING, label: 'Hard Risk Control' }
];

export interface FormValues {
  [FormKeys.TRIGGER_TYPE]: string;
  [FormKeys.TIMER]: string;
  [FormKeys.TRIGGER_BLOCKER]: string;
  [FormKeys.POSITION_REDUCE]: string;
  [FormKeys.X_PERCENT_DEPO]: string;
  [FormKeys.Y_PERCENT_DEPO]: string;
}

export const initialValues = {
  [FormKeys.TRIGGER_TYPE]: TriggerTypes.NAV_ALERTING,
  [FormKeys.TRIGGER_BLOCKER]: '',
  [FormKeys.TIMER]: '',
  [FormKeys.POSITION_REDUCE]: '',
  [FormKeys.X_PERCENT_DEPO]: '',
  [FormKeys.Y_PERCENT_DEPO]: ''
};

export const validationSchema = Yup.object({
  [FormKeys.TRIGGER_TYPE]: Yup.string().required(`Kind field can’t be blank`),
  [FormKeys.TRIGGER_BLOCKER]: Yup.number()
    .min(0, 'The value can be only in interval of 0 - 100')
    .max(100, 'The value can be only in interval of 0 - 100')
    .required(`Timer field can’t be blank`),
  [FormKeys.TIMER]: Yup.number()
    .min(0, 'The value can be only in interval of 0 - 300')
    .max(300, 'The value can be only in interval of 0 - 300')
    .required(`Timer field can’t be blank`),
  [FormKeys.POSITION_REDUCE]: Yup.number().when([FormKeys.TRIGGER_TYPE], {
    is: (value) => value === TriggerTypes.NAV_REDUCING,
    then: Yup.number()
      .min(0, 'The value can be only in interval of 0 - 100')
      .max(100, 'The value can be only in interval of 0 - 100')
      .required(`Timer field can’t be blank`)
  }),
  [FormKeys.X_PERCENT_DEPO]: Yup.number().when([FormKeys.TRIGGER_TYPE], {
    is: (value) => value === TriggerTypes.NAV_REDUCING,
    then: Yup.number()
      .min(0, 'The value can be only in interval of 0 - 100')
      .max(100, 'The value can be only in interval of 0 - 100')
      .required(`Timer field can’t be blank`)
  }),
  [FormKeys.Y_PERCENT_DEPO]: Yup.number().when([FormKeys.TRIGGER_TYPE], {
    is: (value) => value === TriggerTypes.NAV_REDUCING,
    then: Yup.number()
      .min(0, 'The value can be only in interval of 0 - 100')
      .max(100, 'The value can be only in interval of 0 - 100')
      .required(`Timer field can’t be blank`)
  })
});
