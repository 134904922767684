import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import Modal from 'components/modal/MyModal';
import Button from '@material-ui/core/Button';
import {
  createInvestorReportSettingsScheduler,
  getInvestorReportSettingsScheduler,
  deteleInvestorReportSettingsScheduler
} from 'redux/actions/systemSettingsActions';
import { getErrorCutMessageHelper, getErrorMessageHelper, getModalContentIndent } from 'helpers/randomHelpers';
import { layoutRootSelector, systemSettingsSelector } from 'redux/selectors';

import { ErrorShape } from 'interfaces/reduxRandomShapes';
import s from './ReportInvestorScheduler.module.scss';
import 'react-time-picker-input/dist/components/TimeInput.css';
import TimePicker from 'react-time-picker-input';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import CustomizableList from 'components/customizableList/CustomizableList';
import { INVESTOR_REPORT_SETTINGS_SCHEDULER_VALUES } from 'interfaces/systemSettingsShapes';

const ReportInvestorScheduler = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isAsideOpen } = useSelector(layoutRootSelector);
  const { investorReportScheduler } = useSelector(systemSettingsSelector);
  const [isReqErr, setReqErr] = useState<string | null>(null);
  const onClearRequestError = () => setReqErr(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);

  const onAddSettingsSuccess = () => {
    onToggleModal();
    onClearRequestError();
  };

  const modalContentIndent = getModalContentIndent(isAsideOpen);

  useEffect(() => {
    dispatch(
      getInvestorReportSettingsScheduler(
        () => null,
        () => null
      )
    );
  }, [dispatch]);

  const schedulerList = investorReportScheduler.map((i) => ({
    id: i.id,
    title:
      i.schedule_type === 'custom'
        ? `${i.schedule_type} at ${i.schedule_crontab}`
        : `${i.schedule_type} at ${i.investor_report_time} (local ${i.local_investor_report_time})`
  }));

  const onDeleteSchedule = (item) => {
    return dispatch(deteleInvestorReportSettingsScheduler(item.id));
  };

  return (
    <div className={s.wrapper}>
      <div className={s.titleWrapper}>
        <h2 className={cn(s.title, 'default-title')}>Investor report scheduler</h2>
        {isReqErr && <span className={cn(s.reqError, 'errorText')}>{isReqErr}</span>}
      </div>

      <CustomizableList isReadOnly={false} list={schedulerList} onDelete={onDeleteSchedule} />

      <hr />

      <Formik
        enableReinitialize
        initialValues={{
          investor_report_time: '00:00',
          schedule_type: 'daily'
        }}
        onSubmit={(values, { setSubmitting }: FormikHelpers<INVESTOR_REPORT_SETTINGS_SCHEDULER_VALUES>) => {
          dispatch(createInvestorReportSettingsScheduler(values, onAddSettingsSuccess, onSetError));
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={s.tgNotificationForm}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #C8C8C8',
                  borderRadius: '4px',
                  margin: '0 0 6px',
                  paddingLeft: 6,
                  height: 50
                }}
              >
                <FormControl style={{ width: 100, marginRight: 6 }}>
                  <Select
                    label="Type"
                    labelId="demo-simple-select-label"
                    value={values.schedule_type}
                    onChange={(e) => setFieldValue('schedule_type', e.target.value as string)}
                  >
                    <MenuItem value={'daily'}>Daily</MenuItem>
                    <MenuItem value={'weekly'}>Weekly</MenuItem>
                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                    <MenuItem value={'custom'}>Custom</MenuItem>
                  </Select>
                </FormControl>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {values.schedule_type === 'custom' ? (
                    <TextField
                      value={values.schedule_crontab}
                      onChange={(e) => setFieldValue('schedule_crontab', e.target.value)}
                    />
                  ) : (
                    <TimePicker
                      onChange={(value) => setFieldValue('investor_report_time', value)}
                      value={values.investor_report_time}
                    />
                  )}
                </div>
              </div>

              <Button fullWidth variant="outlined" size="medium" type="submit" classes={{ root: s.btnRoot }}>
                Add Scheduler
              </Button>
            </Form>
          );
        }}
      </Formik>

      <Modal
        isOpen={isModalOpen}
        onToggle={onToggleModal}
        stylesProps={{
          paper: {
            left: `calc(50% + ${modalContentIndent}px)`,
            padding: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        <h2 className="default-title" style={{ marginBottom: 40 }}>
          Telegram notification was successfully created.
        </h2>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot }}
          onClick={onToggleModal}
        >
          Ok
        </Button>
      </Modal>
    </div>
  );
};

export default ReportInvestorScheduler;
