import { authTypes } from '../types';
import { AnyAction } from 'redux';
import { AuthReducer } from 'interfaces/authShapes';

const initialState = {
  refreshToken: null,
  accessToken: null,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  qrCode: null,
  isVerifiedBefore: false,
  isVerified: false
};

export default function authReducer(state = initialState, { type, payload }: AnyAction): AuthReducer {
  switch (type) {
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        refreshToken: payload.refreshToken,
        accessToken: payload.accessToken,
        isInitialized: true,
        isAuthenticated: true,
        isVerified: false
      };

    case authTypes.REFRESH_SUCCESS:
      return {
        ...state,
        refreshToken: payload.refreshToken,
        accessToken: payload.accessToken,
        isVerified: payload.isVerified,
        isInitialized: true,
        isAuthenticated: true
      };

    case authTypes.TFA_SUCCESS:
      return {
        ...state,
        refreshToken: payload.refreshToken,
        accessToken: payload.accessToken,
        isVerified: true
      };

    case authTypes.PUT_TFA_QR:
      return {
        ...state,
        qrCode: payload.qrCode,
        isVerifiedBefore: payload.isVerifiedBefore
      };

    case authTypes.REFRESH_ERROR:
      return {
        ...state,
        isInitialized: true
      };

    case authTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        refreshToken: null,
        accessToken: null,
        isAuthenticated: false,
        isVerified: false
      };

    case authTypes.PUT_CURRENT_USER:
      return {
        ...state,
        user: payload
      };

    case authTypes.INITIALIZE_APP_SUCCESS:
      return {
        ...state,
        isInitialized: true,
        isVerified: payload.isVerified
      };

    default:
      return state;
  }
}
