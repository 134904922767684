import React from 'react';
import MainLayout from '../../../components/mainLayout/MainLayout';
import PrivilegesTable from '../../../components/privilegesTable/PrivilegesTable';
import s from './PrivilegesPage.module.scss';
import NavTabs from '../../../components/navTabs/NavTabs';
import { getPrivilegesTabs } from '../../../helpers/privilegesHelpers';
import { usePrivileges } from '../../../hooks/usePrivileges';

const PrivilegesPage = (): JSX.Element => {
  const privileges = usePrivileges();
  const tabs = getPrivilegesTabs(privileges);
  return (
    <MainLayout>
      <div className={s.mainHeader}>
        <h2 className="default-title">Privileges Groups</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <PrivilegesTable />
    </MainLayout>
  );
};

export default PrivilegesPage;
