import { call, put, takeLeading, ForkEffect, takeLatest } from 'redux-saga/effects';
import { statsTypes } from '../types';
import * as apiHelpers from './apiHelpers/statsApiHelpers';
import * as actions from '../actions/statsActions';
import * as Shapes from 'interfaces/strategyStatsShapes';
import { callbackResolver } from 'helpers/callbackHelpers';
import * as AcShapes from '../../interfaces/accountStatsShapes';
import * as MoShapes from '../../interfaces/monthsResultsShapes';
import { ChartDTO, getRequestData } from '../../helpers/chartsHelpers';

function* getStrategyStatsWorker({ payload }: Shapes.GetStrategyStatsShape) {
  try {
    const { data } = yield call(apiHelpers.getSignalsStatsHelper, payload.queryObj);
    yield put(actions.putStrategyStats(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload.onError(error);
    callbackResolver(payload.onFinally);
    console.error('getStrategyStatsWorker', error);
  }
}

function* exportStrategyStatsWorker({ payload }: Shapes.GetProfileStatsExportShape) {
  try {
    const { data } = yield call(apiHelpers.exportSignalsStatsHelper, payload.queryObj);
    yield call(apiHelpers.pollingSignalStats, data?.task_id, payload.onFinally, payload.queryObj);
  } catch (error) {
    callbackResolver(payload.onFinally);
    console.error('exportStrategyStatsWorker', error);
  }
}

function* getStrategyStatsFiltersWorker() {
  try {
    const { data } = yield call(apiHelpers.getSignalsStatsFiltersHelper);
    yield put(actions.putStrategyStatsFilters(data));
  } catch (error) {
    console.error('getStrategyStatsFiltersWorker', error);
  }
}

function* getAccountStatsWorker({ payload }: AcShapes.GetAccountStatsShape) {
  try {
    const { data } = yield call(apiHelpers.getAccountStatsHelper, payload.queryObj);
    yield put(actions.putAccountStats(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    payload?.onError(error);
    console.error('getAccountStatsWorker', error);
  }
}

function* getAccountStatsFiltersWorker() {
  try {
    const { data } = yield call(apiHelpers.getAccountStatsFiltersHelper);
    yield put(actions.putAccountStatsFilters(data));
  } catch (error) {
    console.error('getAccountStatsFiltersWorker', error);
  }
}

function* getAccountStatsExportWorker({ payload }: AcShapes.GetAccountStatsExportShape) {
  try {
    const { data } = yield call(apiHelpers.exportAccountsStatsHelper, payload.queryObj);
    yield call(apiHelpers.pollingAccountStats, data?.task_id, payload.onFinally, payload.queryObj);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    console.error('getAccountStatsExportWorker', error);
  }
}

function* getResultByMonthsWorker({ payload }: MoShapes.GetResultStatsByMonthsShape) {
  try {
    const { data } = yield call(apiHelpers.getResultByMonthsStatsHelper, payload.queryObj);
    yield put(actions.putResultByMonthsStats(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    payload?.onError(error);
    console.error('getResultByMonthsWorker', error);
  }
}

function* getResultByMonthsAdminWorker({ payload }: MoShapes.GetResultStatsByMonthsShape) {
  try {
    const { data } = yield call(apiHelpers.getResultByMonthsAdminStatsHelper, payload.queryObj);
    yield put(actions.putResultByMonthsStats(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    payload?.onError(error);
    console.error('getResultByMonthsAdminWorker', error);
  }
}

function* getSystemDataByMonthsWorker({ payload }: MoShapes.GetSystemDataByMonthsShape) {
  try {
    const { data } = yield call(apiHelpers.getSystemDataByMonthsHelper);
    yield put(actions.putSystemDataByMonths(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    payload?.onError(error);
    console.error('getSystemDataByMonthsWorker', error);
  }
}

function* getStatsGraphWorker({ payload }: { type: string; payload: ChartDTO }) {
  try {
    const { reqData, reqDataSec } = getRequestData(payload);
    const { data } = yield call(apiHelpers.getStatsGraphHelper, reqData);
    if (reqDataSec) {
      const res = yield call(apiHelpers.getStatsGraphHelper, reqDataSec);
      yield put(actions.putStatsGraph([data, res.data]));
    } else {
      yield put(actions.putStatsGraph([data]));
    }
  } catch (error) {
    console.error('getStatsGraphWorker', error);
  }
}

export function* statsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLeading(statsTypes.GET_STRATEGY_STATS, getStrategyStatsWorker);
  yield takeLeading(statsTypes.GET_EXPORT_STRATEGY_STATS, exportStrategyStatsWorker);
  yield takeLeading(statsTypes.GET_STRATEGY_STATS_FILTERS, getStrategyStatsFiltersWorker);
  yield takeLatest(statsTypes.GET_ACCOUNT_STATS, getAccountStatsWorker);
  yield takeLeading(statsTypes.GET_ACCOUNT_STATS_FILTERS, getAccountStatsFiltersWorker);
  yield takeLeading(statsTypes.GET_ACCOUNT_STATS_EXPORT, getAccountStatsExportWorker);
  yield takeLeading(statsTypes.GET_RESULT_BY_MONTHS, getResultByMonthsWorker);
  yield takeLeading(statsTypes.GET_RESULT_BY_MONTHS_ADMIN, getResultByMonthsAdminWorker);
  yield takeLeading(statsTypes.GET_SYSTEM_DATA_BY_MONTHS, getSystemDataByMonthsWorker);
  yield takeLeading(statsTypes.GET_STATS_GRAPH, getStatsGraphWorker);
}
