import { privilegesTypes } from '../types';
import * as Shapes from '../../interfaces/privilegesShapes';

export const getGroups = (onFinally?: () => void): Shapes.GetGroupsShape => ({
  type: privilegesTypes.GET_GROUPS,
  payload: { onFinally }
});

export const setGroups = (payload: Array<Shapes.GroupsShape>): Shapes.SetGroups => ({
  type: privilegesTypes.SET_GROUPS,
  payload
});

export const updateStatus = (name: string, status: string, id: number): Shapes.UpdateStatus => ({
  type: privilegesTypes.UPDATE_STATUS,
  payload: { name, status, id }
});

export const updateGroupPrivileges = (id: number, onSuccess: () => void): Shapes.UpdateGroupPrivileges => ({
  type: privilegesTypes.UPDATE_GROUP_PRIVILEGES,
  payload: { id, onSuccess }
});

export const deleteGroupPrivileges = (
  id: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.DeleteGroupPrivilegesWorker => ({
  type: privilegesTypes.DELETE_GROUP_PRIVILEGES,
  payload: { id, onSuccess, onError }
});

export const removeGroupPrivileges = (id: number): Shapes.RemoveGroupPrivilegesWorker => ({
  type: privilegesTypes.REMOVE_GROUP_PRIVILEGES,
  payload: { id }
});

export const updateFieldsPrivileges = (id: number, name: string, fields: string[]): Shapes.UpdateFieldsPrivileges => ({
  type: privilegesTypes.UPDATE_FIELDS,
  payload: { id, name, fields }
});

export const updateFilteringFieldsPrivileges = (
  id: number,
  name: string,
  filtering: boolean
): Shapes.UpdateFilteringFieldsPrivileges => ({
  type: privilegesTypes.UPDATE_FILTERING,
  payload: { id, name, filtering }
});

export const updateSortingFieldsPrivileges = (
  id: number,
  name: string,
  sorting: boolean
): Shapes.UpdateSortingFieldsPrivileges => ({
  type: privilegesTypes.UPDATE_SORTING,
  payload: { id, name, sorting }
});

export const updateFinishedOnlyFieldsPrivileges = (
  id: number,
  name: string,
  finishedOnly: boolean
): Shapes.UpdateFinishedOnlyFieldsPrivileges => ({
  type: privilegesTypes.UPDATE_FINISHED_ONLY,
  payload: { id, name, finishedOnly }
});

export const updateSignalsCountFieldsPrivileges = (
  id: number,
  name: string,
  signalsCount: number
): Shapes.UpdateSignalsCountFieldsPrivileges => ({
  type: privilegesTypes.UPDATE_SIGNALS_COUNT,
  payload: { id, name, signalsCount }
});

export const createGroups = (data: Shapes.GroupPermission, onSuccess: () => void): Shapes.CreateGroup => ({
  type: privilegesTypes.CREATE_GROUPS,
  payload: { data, onSuccess }
});

export const getUsers = (onFinally?: () => void): Shapes.GetUsers => ({
  type: privilegesTypes.GET_USERS,
  payload: { onFinally }
});

export const setUsers = (payload: Shapes.UsersShape[]): { type: string; payload: Shapes.UsersShape[] } => ({
  type: privilegesTypes.SET_USERS,
  payload
});

export const changeUserGroup = (id: number, groupName: string, groupId: number): Shapes.ChangeGroupShape => ({
  type: privilegesTypes.CHANGE_USER_GROUP,
  payload: { id, groupName, groupId }
});

export const updateUser = (id: number, onSuccess: () => void, onError: (err) => void): Shapes.SaveUserShape => ({
  type: privilegesTypes.UPDATE_USER,
  payload: { id, onSuccess, onError }
});

export const deleteUser = (id: number, onSuccess: () => void, onError: (err) => void): Shapes.SaveUserShape => ({
  type: privilegesTypes.DELETE_USER,
  payload: { id, onSuccess, onError }
});

export const deleteUserSuccess = (id: number): Shapes.SaveUserSuccessShape => ({
  type: privilegesTypes.DELETE_USER_SUCCESS,
  payload: { id }
});

export const createUser = (
  email: string,
  password: string,
  group: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CreateUserShape => ({
  type: privilegesTypes.CREATE_USER,
  payload: { email, password, group, onSuccess, onError }
});

export const createUserSuccess = (payload: Shapes.UsersShape): { type: string; payload: Shapes.UsersShape } => ({
  type: privilegesTypes.SET_USER,
  payload
});
