import { ColumnAlignOptions } from 'constants/randomConstants';
import { formatISODate } from '../helpers/randomHelpers';
import { GetStrategyStatsQuery } from 'interfaces/strategyStatsShapes';
import { ProfilesStatsColIds } from './privilegesConstants';

export enum StrategyStatsFilterIDs {
  PAGE = 'page',
  PROFILE = 'profile_id__in',
  STATUS = 'status',
  BEFORE_CREATING = 'created_at__lte',
  AFTER_CREATING = 'created_at__gte'
}

export const StrategyStatsLabels = {
  [StrategyStatsFilterIDs.PAGE]: 'Page',
  [StrategyStatsFilterIDs.PROFILE]: 'Profile',
  [StrategyStatsFilterIDs.STATUS]: 'Strategy Status',
  [StrategyStatsFilterIDs.AFTER_CREATING]: 'From',
  [StrategyStatsFilterIDs.BEFORE_CREATING]: 'Until'
};

enum FilterStrategyStatsValues {
  initValue = ''
}

export const FilterBeforeUntilValues = {
  before: formatISODate(new Date().toISOString(), 'yyyy-MM-dd'),
  until: ''
};

export enum FilterStatusValues {
  ALL = '',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export const FilterStatusLabels = {
  [FilterStatusValues.ALL]: 'All',
  [FilterStatusValues.ACTIVE]: 'Active',
  [FilterStatusValues.INACTIVE]: 'Inactive'
};

export const STRATEGY_STAT_FILTERS = [
  {
    id: StrategyStatsFilterIDs.PROFILE,
    label: StrategyStatsLabels[StrategyStatsFilterIDs.PROFILE],
    options: []
  }
];

export const STRATEGY_STAT_DATE = [
  {
    id: StrategyStatsFilterIDs.STATUS,
    label: StrategyStatsLabels[StrategyStatsFilterIDs.STATUS],
    options: [
      { value: FilterStatusValues.ALL, label: FilterStatusLabels[FilterStatusValues.ALL] },
      { value: FilterStatusValues.ACTIVE, label: FilterStatusLabels[FilterStatusValues.ACTIVE] },
      { value: FilterStatusValues.INACTIVE, label: FilterStatusLabels[FilterStatusValues.INACTIVE] }
    ]
  }
];

export const initialStrategyStatsValues = {
  [StrategyStatsFilterIDs.PAGE]: 1,
  [StrategyStatsFilterIDs.PROFILE]: FilterStrategyStatsValues.initValue as string,
  [StrategyStatsFilterIDs.STATUS]: FilterStatusValues.ALL,
  [StrategyStatsFilterIDs.BEFORE_CREATING]: FilterBeforeUntilValues.before,
  [StrategyStatsFilterIDs.AFTER_CREATING]: FilterBeforeUntilValues.until
};

export type InitialStrategyStatsValuesType = typeof initialStrategyStatsValues;

export const DEFAULT_STRATEGY_STATS_PARAMS: GetStrategyStatsQuery = {
  [StrategyStatsFilterIDs.PAGE]: initialStrategyStatsValues[StrategyStatsFilterIDs.PAGE],
  [StrategyStatsFilterIDs.PROFILE]: initialStrategyStatsValues[StrategyStatsFilterIDs.PROFILE],
  [StrategyStatsFilterIDs.STATUS]: initialStrategyStatsValues[StrategyStatsFilterIDs.STATUS],
  [StrategyStatsFilterIDs.BEFORE_CREATING]: initialStrategyStatsValues[StrategyStatsFilterIDs.BEFORE_CREATING],
  [StrategyStatsFilterIDs.AFTER_CREATING]: initialStrategyStatsValues[StrategyStatsFilterIDs.AFTER_CREATING]
};

export enum StrategyStatsColIds {
  ID = 'id',
  PROFILE = 'profile_title',
  PROFILE_ID = 'profile_id',
  DIRECTION = 'direction',
  TOTAL_PROFIT = 'total_profit',
  TOTAL_LOSS = 'total_loss',
  TOTAL = 'total',
  TOTAL_PERCENT = 'total_percent',
  TOTAL_I = 'total_i',
  AVG_PNL_PERCENT = 'avg_pnl_percent',
  AVG_LOSS_PERCENT = 'avg_loss_percent',
  MOST_LOSS_PERCENT = 'most_loss_percent',
  AVG_PROFIT_PERCENT = 'avg_profit_percent',
  AVG_I = 'avg_i',
  QTY_LOSS = 'qty_loss',
  QTY_PROFIT = 'qty_profit',
  QTY_TOTAL = 'qty_total',
  W_PERCENT = 'w_percent',
  AVG_TIME_PER_SIGNAL = 'avg_time_per_signal',
  MOST_LONGEST_DURATION = 'most_longest_duration',
  MOST_IMP_LOSS = 'most_imp_loss',
  MOST_MM_IMP_LOSS = 'most_mm_imp_loss',
  QTY_MISSED = 'qty_missed',
  STATUS = 'status',
  MOST_LOSS_USD = 'most_loss_usd',
  MOST_IMP_LOSS_USD = 'most_imp_loss_usd',
  ALL_LOSS_SIGNALS_PERCENT = 'all_loss_signals_percent',
  ALL_PROFIT_SIGNALS_PERCENT = 'all_profit_signals_percent',
  ALL_LOSS_SIGNALS_DEPO_PERCENT = 'all_loss_signals_depo_percent',
  ALL_PROFIT_SIGNALS_DEPO_PERCENT = 'all_profit_signals_depo_percent',
  TOTAL_TIME_SIGNALS = 'total_time_signals',
  PROFILES = 'profile'
}

export const STRATEGY_STATS_TABLE_COL_NAMES = {
  [StrategyStatsColIds.PROFILE]: 'Profile',
  [StrategyStatsColIds.DIRECTION]: 'Direction',
  [StrategyStatsColIds.TOTAL_PROFIT]: 'Total profit',
  [StrategyStatsColIds.TOTAL_LOSS]: 'Total loss',
  [StrategyStatsColIds.TOTAL]: 'Total',
  [StrategyStatsColIds.TOTAL_PERCENT]: 'Total percent',
  [StrategyStatsColIds.TOTAL_I]: 'PF',
  [StrategyStatsColIds.AVG_PNL_PERCENT]: 'Avg PNL %',
  [StrategyStatsColIds.AVG_LOSS_PERCENT]: 'Avg loss %',
  [StrategyStatsColIds.MOST_LOSS_PERCENT]: 'Most loss %',
  [StrategyStatsColIds.AVG_PROFIT_PERCENT]: 'Avg profit %',
  [StrategyStatsColIds.AVG_I]: 'Avg i',
  [StrategyStatsColIds.QTY_LOSS]: 'Qty loss',
  [StrategyStatsColIds.QTY_PROFIT]: 'Qty profit',
  [StrategyStatsColIds.QTY_TOTAL]: 'Qty total',
  [StrategyStatsColIds.W_PERCENT]: 'W %',
  [StrategyStatsColIds.AVG_TIME_PER_SIGNAL]: 'Avg time per signal',
  [StrategyStatsColIds.MOST_LONGEST_DURATION]: 'Most longest duration',
  [StrategyStatsColIds.MOST_IMP_LOSS]: 'Most imp loss',
  [StrategyStatsColIds.MOST_MM_IMP_LOSS]: 'Most MM imp loss',
  [StrategyStatsColIds.QTY_MISSED]: 'Qty missed',
  [StrategyStatsColIds.STATUS]: 'Status',
  [StrategyStatsColIds.MOST_LOSS_USD]: 'Most loss usd',
  [StrategyStatsColIds.MOST_IMP_LOSS_USD]: 'Most imp loss usd',
  [StrategyStatsColIds.ALL_LOSS_SIGNALS_PERCENT]: 'All loss signals %',
  [StrategyStatsColIds.ALL_PROFIT_SIGNALS_PERCENT]: 'All profit signals %',
  [StrategyStatsColIds.ALL_LOSS_SIGNALS_DEPO_PERCENT]: 'All loss signals depo %',
  [StrategyStatsColIds.ALL_PROFIT_SIGNALS_DEPO_PERCENT]: 'All profit signals depo %',
  [StrategyStatsColIds.TOTAL_TIME_SIGNALS]: 'Total titme signals'
};

export const SERVER_CLIENT_COLS_MAP = {
  [StrategyStatsColIds.PROFILE]: ProfilesStatsColIds.PROFILE_TITLE,
  [StrategyStatsColIds.DIRECTION]: ProfilesStatsColIds.DIRECTION,
  [StrategyStatsColIds.TOTAL_PROFIT]: ProfilesStatsColIds.TOTAL_PROFIT,
  [StrategyStatsColIds.TOTAL_LOSS]: ProfilesStatsColIds.TOTAL_LOSS,
  [StrategyStatsColIds.TOTAL]: ProfilesStatsColIds.TOTAL,
  [StrategyStatsColIds.TOTAL_PERCENT]: ProfilesStatsColIds.TOTAL_PERCENT,
  [StrategyStatsColIds.TOTAL_I]: ProfilesStatsColIds.TOTAL_I,
  [StrategyStatsColIds.AVG_PNL_PERCENT]: ProfilesStatsColIds.AVG_PLN_PERCENT,
  [StrategyStatsColIds.AVG_LOSS_PERCENT]: ProfilesStatsColIds.AVG_LOSS_PERCENT,
  [StrategyStatsColIds.MOST_LOSS_PERCENT]: ProfilesStatsColIds.MOST_LOSS_PERCENT,
  [StrategyStatsColIds.AVG_PROFIT_PERCENT]: ProfilesStatsColIds.AVG_PROFIT_PERCENT,
  [StrategyStatsColIds.AVG_I]: ProfilesStatsColIds.AVG_I,
  [StrategyStatsColIds.QTY_LOSS]: ProfilesStatsColIds.QTY_LOSS,
  [StrategyStatsColIds.QTY_PROFIT]: ProfilesStatsColIds.QTY_PROFIT,
  [StrategyStatsColIds.QTY_TOTAL]: ProfilesStatsColIds.QTY_TOTAL,
  [StrategyStatsColIds.W_PERCENT]: ProfilesStatsColIds.W_PERCENT,
  [StrategyStatsColIds.AVG_TIME_PER_SIGNAL]: ProfilesStatsColIds.AVG_TIME_PER_SIGNAL,
  [StrategyStatsColIds.MOST_LONGEST_DURATION]: ProfilesStatsColIds.MOST_LONGEST_DURATION,
  [StrategyStatsColIds.MOST_IMP_LOSS]: ProfilesStatsColIds.MOST_IMP_LOSS,
  [StrategyStatsColIds.MOST_MM_IMP_LOSS]: ProfilesStatsColIds.MOST_MM_IMP_LOSS,
  [StrategyStatsColIds.QTY_MISSED]: ProfilesStatsColIds.QTY_MISSED,
  [StrategyStatsColIds.STATUS]: ProfilesStatsColIds.STATUS,
  [StrategyStatsColIds.PROFILE_ID]: ProfilesStatsColIds.PROFILE_ID
};

export const STRATEGY_STATS_COLUMNS = [
  {
    id: StrategyStatsColIds.PROFILE,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.PROFILE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.STATUS,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.STATUS],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: StrategyStatsColIds.DIRECTION,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.DIRECTION],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: StrategyStatsColIds.TOTAL_PROFIT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.TOTAL_PROFIT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.TOTAL_LOSS,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.TOTAL_LOSS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.TOTAL,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.TOTAL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.TOTAL_PERCENT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.TOTAL_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.TOTAL_I,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.TOTAL_I],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.AVG_PNL_PERCENT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.AVG_PNL_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.AVG_LOSS_PERCENT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.AVG_LOSS_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.MOST_LOSS_PERCENT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.MOST_LOSS_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.AVG_PROFIT_PERCENT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.AVG_PROFIT_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.AVG_I,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.AVG_I],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.QTY_LOSS,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.QTY_LOSS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.QTY_PROFIT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.QTY_PROFIT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.QTY_TOTAL,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.QTY_TOTAL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.W_PERCENT,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.W_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.AVG_TIME_PER_SIGNAL,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.AVG_TIME_PER_SIGNAL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.MOST_LONGEST_DURATION,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.MOST_LONGEST_DURATION],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.MOST_IMP_LOSS,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.MOST_IMP_LOSS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.MOST_MM_IMP_LOSS,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.MOST_MM_IMP_LOSS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyStatsColIds.QTY_MISSED,
    title: STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.QTY_MISSED],
    align: ColumnAlignOptions.CENTER
  }
];

export const SORTING_MAP = {
  [STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.STATUS]]: StrategyStatsColIds.STATUS,
  [STRATEGY_STATS_TABLE_COL_NAMES[StrategyStatsColIds.DIRECTION]]: StrategyStatsColIds.DIRECTION
};

export const StickyProfileStrategyStats = {
  key: StrategyStatsColIds.PROFILE,
  val: 'All'
};
