import { PrivilegesGroupFormFields } from '../constants/privilegesConstants';
import { shallowEqual, useSelector } from 'react-redux';
import { authPermissions } from '../redux/selectors';
import { useMemo } from 'react';

export const usePrivileges = (): PrivilegesGroupFormFields => {
  const perms = useSelector(authPermissions, shallowEqual);
  const privileges = useMemo(
    () =>
      perms.reduce((a, v) => {
        return { ...a, [v.section_name]: v.status };
      }, {} as PrivilegesGroupFormFields),
    [perms]
  );
  return privileges;
};
