import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import {
  LoginValues,
  AccountUpdateResponse,
  AccountDetailsShape,
  UserDataShape,
  CaptchaResponseValues,
  CaptchaRequestValues,
  TfaQrResponseValues,
  TfaResponseValues
} from 'interfaces/authShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const captchaRequestHelper = (reqData: CaptchaRequestValues): Promise<AxiosResponse<CaptchaResponseValues>> => {
  return axios.post(API_ENDPOINTS.CAPTCHA_VERIFY, { g_recaptcha_response: reqData.captchaToken });
};

export const tfaQrHelper = (): Promise<AxiosResponse<TfaQrResponseValues>> => {
  return axios.post(API_ENDPOINTS.TFA_QR);
};

export const tfaHelper = (GAtoken: string): Promise<AxiosResponse<TfaResponseValues>> => {
  return axios.post(API_ENDPOINTS.TFA(GAtoken));
};

export const loginRequestHelper = (
  reqData: LoginValues
): Promise<AxiosResponse<{ refresh: string; access: string }>> => {
  return axios.post(API_ENDPOINTS.LOGIN, reqData);
};

export const refreshRequestHelper = (refreshToken: {
  refresh: string;
}): Promise<AxiosResponse<{ access: string; refresh: string; is_sec_factor_passed: boolean }>> => {
  return axios.post(API_ENDPOINTS.REFRESH, refreshToken);
};

export const refreshCachedRequestHelper = (refreshToken: {
  refresh: string;
}): Promise<AxiosResponse<{ access: string; refresh: string; is_sec_factor_passed: boolean }>> => {
  return axios.post(API_ENDPOINTS.REFRESH, refreshToken);
};

export const logoutRequestHelper = (refresh_token: string): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.LOGOUT, { refresh_token });
};

export const accountUpdateHelper = (
  reqData: AccountDetailsShape,
  userId: number
): Promise<AxiosResponse<AccountUpdateResponse>> => {
  return axios.put(API_ENDPOINTS.USER_UPDATE(userId), reqData);
};

export const getUserHelper = (): Promise<AxiosResponse<UserDataShape>> => {
  return axios.get(API_ENDPOINTS.CURRENT_USER);
};
