import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import { FormControl, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import PairedBtnBlock from '../../../buttons/pairedBtnBlock/PairedBtnBlock';
import s from './AddPairListForm.module.scss';
import { PairFormValues, PairsListFormValues, PairsStatusShape } from '../../../../interfaces/strategyProfilesShapes';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { parseTickers } from '../../../../helpers/parseTickers';

interface AddPairListFormProps {
  onSubmit: (values: PairFormValues) => void;
  onCancel: () => void;
  tradingPairsOptions: Array<{ value; label: string }>;
}

const AddPairListForm: FC<AddPairListFormProps> = ({ tradingPairsOptions, onSubmit, onCancel }) => {
  const selectedPairsMap = useMemo(() => {
    return tradingPairsOptions.reduce((acc, { value, label }) => {
      return { ...acc, [label]: value };
    }, {});
  }, [tradingPairsOptions]);

  return (
    <div className={s.modal}>
      <h2 className={cn('default-title', s.modal__title)}>Add Pair List Form</h2>
      <Formik
        initialValues={{
          [PairsListFormValues.TITLE]: '',
          [PairsListFormValues.STATUS]: PairsStatusShape.INACTIVE,
          [PairsListFormValues.PAIRS]: []
        }}
        validationSchema={Yup.object({
          [PairsListFormValues.TITLE]: Yup.string().required('Type title for you list'),
          [PairsListFormValues.STATUS]: Yup.mixed()
            .oneOf([PairsStatusShape.ACTIVE, PairsStatusShape.INACTIVE])
            .required('This field is required'),
          [PairsListFormValues.PAIRS]: Yup.array()
        })}
        onSubmit={(values: PairFormValues) => {
          const reqData = {
            ...values,
            [PairsListFormValues.PAIRS]: values.pairs.map((el) => selectedPairsMap[el])
          };
          onSubmit(reqData);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          const onPasteClick = (event) => {
            const pastedText = event.clipboardData.getData('text/plain');
            const tickers = parseTickers(pastedText);
            const validTickers: string[] = [];
            for (const ticker of tickers) {
              if (selectedPairsMap[ticker]) {
                validTickers.push(ticker);
              }
            }

            const uniquePairs = [...new Set(validTickers)];
            setFieldValue(PairsListFormValues.PAIRS, uniquePairs);
            event.preventDefault();
          };
          return (
            <Form className={s.modal__form}>
              <TextField
                fullWidth
                id={PairsListFormValues.TITLE}
                name={PairsListFormValues.TITLE}
                label="Title"
                placeholder="Type title..."
                variant="outlined"
                value={values[PairsListFormValues.TITLE]}
                error={!!errors[PairsListFormValues.TITLE] && touched[PairsListFormValues.TITLE]}
                helperText={touched[PairsListFormValues.TITLE] && errors[PairsListFormValues.TITLE]}
                onBlur={handleBlur}
                onChange={handleChange}
                classes={{ root: s.input }}
              />
              <div style={{ display: 'flex', width: '100%', gap: '4px' }}>
                <TextField
                  fullWidth
                  id={PairsListFormValues.PAIRS}
                  name={PairsListFormValues.PAIRS}
                  label="Tickers"
                  placeholder="Paste only..."
                  variant="outlined"
                  value={values[PairsListFormValues.PAIRS]}
                  error={!!errors[PairsListFormValues.PAIRS] && touched[PairsListFormValues.PAIRS]}
                  helperText={touched[PairsListFormValues.PAIRS] && errors[PairsListFormValues.PAIRS]}
                  onPaste={onPasteClick}
                  classes={{ root: s.input }}
                  autoComplete="off"
                />
                {values[PairsListFormValues.PAIRS].length > 0 && (
                  <Button
                    variant="outlined"
                    size="medium"
                    type="button"
                    classes={{ root: s.exportBtn }}
                    onClick={() => setFieldValue(PairsListFormValues.PAIRS, [])}
                  >
                    Clear
                  </Button>
                )}
                {values[PairsListFormValues.PAIRS].length > 0 && (
                  <Tooltip
                    arrow
                    placement="right"
                    enterDelay={250}
                    interactive
                    title={
                      <div className={s.tooltipPairs}>
                        {values[PairsListFormValues.PAIRS].map((el) => {
                          return <span key={el}>{el}, </span>;
                        })}
                      </div>
                    }
                  >
                    <Button variant="outlined" size="medium" type="button" classes={{ root: s.exportBtn }}>
                      List
                    </Button>
                  </Tooltip>
                )}
              </div>
              <FormControl variant="outlined">
                <Select
                  labelId={PairsListFormValues.STATUS}
                  id={PairsListFormValues.STATUS}
                  name={PairsListFormValues.STATUS}
                  value={values[PairsListFormValues.STATUS]}
                  onChange={handleChange}
                  className={s.modal__form__select}
                  placeholder="Select pairs"
                >
                  <MenuItem value={PairsStatusShape.ACTIVE}>{PairsStatusShape.ACTIVE}</MenuItem>
                  <MenuItem value={PairsStatusShape.INACTIVE}>{PairsStatusShape.INACTIVE}</MenuItem>
                </Select>
              </FormControl>
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddPairListForm;
