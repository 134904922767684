import React from 'react';
import cn from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PuffLoader from 'react-spinners/PuffLoader';
import s from './FakeRow.module.scss';

interface FakeRowProps {
  msg?: string;
  isError?: boolean;
  isLoader?: boolean;
}

const LOADER_SIZE = 100;

const FakeRow = ({ msg, isError, isLoader }: FakeRowProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell align="center" style={{ borderBottomColor: 'transparent' }}>
        <div className={s.loaderWrapper}>
          {isLoader ? (
            <PuffLoader loading size={LOADER_SIZE} />
          ) : (
            <p className={cn('default-title', { [s.errorMsg]: isError })}>{msg}</p>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default FakeRow;
