import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminInfo, updateAdminList } from 'redux/actions/signalsOrdersActions';
import { administrationSelector } from 'redux/selectors';
import s from './AdministrationList.module.scss';
import AdminItem from './adminItem/AdminItem';
import MyModal from '../modal/MyModal';
import DepositForm from './depositForm/DepositForm';
import { AdministrationLabels, AdministrationValues, DepositFromValue } from '../../interfaces/administrationShapes';

const AdministrationList = (): JSX.Element => {
  const dispatch = useDispatch();
  const adminData = useSelector(administrationSelector);
  const [isOpenModal, setOpenModal] = useState(false);
  const idRef = useRef<number | null>(null);
  const currentPropertyRef = useRef<AdministrationValues.DEPOSIT_MONTHLY | AdministrationValues.DEPOSIT_AT | null>(
    null
  );
  const onToggleModal = useCallback(() => setOpenModal((prev) => !prev), []);

  useEffect(() => {
    dispatch(getAdminInfo());
  }, [dispatch]);

  const onSubmit = (reqData: DepositFromValue) => {
    dispatch(updateAdminList(idRef.current, reqData));
    onToggleModal();
  };

  return (
    <>
      <div className={s.adminList}>
        {adminData.map((el) => {
          return (
            <React.Fragment key={el.id}>
              <div className={s.header}>
                <h3 className="default-text">{el.account_name}</h3>
              </div>
              <AdminItem label={AdministrationLabels[AdministrationValues.NAV]} value={el.nav.toFixed(2)} />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.ASSET_USDT]}
                value={el.asset_usdt.toFixed(2)}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.ASSET_USDC]}
                value={el.asset_usdc.toFixed(2)}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.OTHER_STABLECOIN]}
                value={el.other_stable_coins.toFixed(2)}
              />
              <AdminItem label={AdministrationLabels[AdministrationValues.ASSET_SUM]} value={el.asset_sum.toFixed(2)} />
              <AdminItem label={AdministrationLabels[AdministrationValues.VAR]} value={el.var.toFixed(2)} />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.VAR_ORDERS]}
                value={el.open_orders_volume.toFixed(2)}
              />
              <AdminItem label={AdministrationLabels[AdministrationValues.FREE_VAR]} value={el.free_var.toFixed(2)} />
              <AdminItem label={AdministrationLabels[AdministrationValues.IMP_LOSS]} value={el.imp_loss.toFixed(2)} />
              <AdminItem label={AdministrationLabels[AdministrationValues.MAX_DD]} value={el.max_dd} />
              <AdminItem label={AdministrationLabels[AdministrationValues.MAX_DDD]} value={el.max_ddd} />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.DEPOSIT_MONTHLY]}
                value={el.deposit_monthly}
                isClickable
                onClick={() => {
                  idRef.current = el.id;
                  currentPropertyRef.current = AdministrationValues.DEPOSIT_MONTHLY;
                  onToggleModal();
                }}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.DEPOSIT_AT]}
                value={el.deposit_at}
                isClickable
                onClick={() => {
                  idRef.current = el.id;
                  currentPropertyRef.current = AdministrationValues.DEPOSIT_AT;
                  onToggleModal();
                }}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.BALANCE_DAILY]}
                value={el.balance_daily.toFixed(2)}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.RETURN_DAY]}
                value={el.return_day_percent.toFixed(2)}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.RETURN_MONTH]}
                value={el.return_month_percent.toFixed(2)}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.RETURN_ALL]}
                value={el.return_at_percent.toFixed(2)}
              />
              <AdminItem
                label={AdministrationLabels[AdministrationValues.AVG_CAPITAL_GROWTH_RATE]}
                value={el.avg_capital_growth_rate.toFixed(2)}
              />
            </React.Fragment>
          );
        })}
      </div>
      <MyModal isOpen={isOpenModal} onToggle={onToggleModal}>
        <DepositForm
          onCancel={onToggleModal}
          onSubmit={onSubmit}
          id={idRef.current}
          propertyName={currentPropertyRef.current}
        />
      </MyModal>
    </>
  );
};

export default memo(AdministrationList);
