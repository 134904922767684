import { notificationTypes } from '../types';
import * as Shapes from 'interfaces/notificationShapes';

export const getNotifications = (
  params: Shapes.GetNotificationsReq,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.GetNotifications => ({
  type: notificationTypes.GET_NOTIFICATIONS,
  payload: { params, onSuccess, onError }
});

export const putNotifications = (notifications: Array<Shapes.NotificationShape>): Shapes.PutNotifications => ({
  type: notificationTypes.PUT_NOTIFICATIONS,
  payload: notifications
});
