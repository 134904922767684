import React, { FC, useMemo } from 'react';
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { filterSelector, riskControlObjectSelector } from '../../../redux/selectors';
import s from './ProfileMultiselect.module.scss';
import { ChangeableTriggerProperty } from '../../../constants/signalsOrdersConstants';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 240
    }
  }
};

interface ProfileMultiselectProps {
  triggerId: number;
  isGroupCol: boolean;
  onChangeSelect: (id: number, valueToChange: number, property: ChangeableTriggerProperty) => void;
}

const ProfileMultiselect: FC<ProfileMultiselectProps> = ({ triggerId, isGroupCol, onChangeSelect }) => {
  const riskControl = useSelector(riskControlObjectSelector(triggerId));

  const { profiles } = useSelector(filterSelector);

  const backwardsCompatibleMap = useMemo(() => {
    return profiles?.reduce((acc, { id, title }) => {
      return { ...acc, [id]: title, [title]: id };
    }, {});
  }, [profiles]);

  if (!riskControl) {
    return <></>;
  }
  const { group_profiles, trigger_blocker_active_profile } = riskControl;

  const selectedValues = isGroupCol ? group_profiles : trigger_blocker_active_profile;
  const onChange = ({ target }) => {
    onChangeSelect(
      triggerId,
      target.value.map((selected) => backwardsCompatibleMap[selected]),
      isGroupCol ? ChangeableTriggerProperty.GROUP_PROFILES : ChangeableTriggerProperty.TR_BLOCK_ACTIVATE_PROFILE
    );
  };

  return (
    <FormControl variant="outlined">
      <Select
        labelId="group_profile"
        id="group_profile"
        name="group_profile"
        value={selectedValues.map((selected) => backwardsCompatibleMap[selected])}
        onChange={onChange}
        MenuProps={MenuProps}
        multiple
        renderValue={(selected) => (selected as string[]).join(', ')}
        className={s.select}
      >
        {profiles.map((profile) => (
          <MenuItem key={profile.id} value={profile.title}>
            <Checkbox checked={selectedValues.indexOf(profile.id) >= 0} />
            <ListItemText primary={profile.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProfileMultiselect;
