import { StrategyDetailsState, StrategyDetailsTabs } from './StrategyDetailsTypes';

const INITIAL_ERRORS = {
  profileError: null
};

export const STRATEGY_DETAILS_TABS = [
  {
    label: 'Settings'
  },
  {
    label: 'Statistics'
  },
  {
    label: 'Orders'
  },
  {
    label: 'Signals'
  }
];

export const strategyDetailsInitial = {
  isLoading: true,
  errors: INITIAL_ERRORS,
  currentTab: StrategyDetailsTabs.SETTINGS
};

export const strategyDetailsTypes = {
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  SET_PROFILE_ERR: 'SET_PROFILE_ERR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  SET_TAB: 'SET_TAB'
};

export const strategyDetailsReducer = (
  state: StrategyDetailsState,
  { type, payload }: { type: string; payload? }
): StrategyDetailsState => {
  switch (type) {
    case strategyDetailsTypes.TOGGLE_LOADING:
      return { ...state, isLoading: !state.isLoading };

    case strategyDetailsTypes.SET_PROFILE_ERR:
      return { ...state, isLoading: false, errors: { ...state.errors, profileError: payload } };

    case strategyDetailsTypes.CLEAR_ERRORS:
      return { ...state, errors: INITIAL_ERRORS };

    case strategyDetailsTypes.SET_TAB:
      return { ...state, currentTab: payload };

    default:
      return state;
  }
};
