import s from './ModalLoader.module.scss';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const ModalLoader = (): JSX.Element => {
  return (
    <div className={s.modalLoader}>
      <div className={s.overlay} />
      <div className={s.loader}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default ModalLoader;
