import React from 'react';
import { Form, FieldArray, useFormikContext } from 'formik';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import NumberFormatField from 'components/fields/numberFormatField/NumberFormatField';
import { safeParseInt } from 'helpers/numberHelpers';
import {
  OrderManagementFields,
  OrderManagementNames,
  EnterOrdersNames,
  ENTER_ORDER_FIELDS_SHORT,
  ENTER_ORDER_FIELDS_LONG,
  EXIT_ORDER_FIELDS_COMMON,
  ExitOrdersSwitchPosition,
  ExitOptions,
  EnterExitProperties,
  OrderTimeDistance,
  OrderManagementMap,
  QuantityMap,
  getNewDataItem
} from 'constants/strategyProfilesAddConstants';
import { AddSPErrors } from 'interfaces/strategyProfilesShapes';
import s from './OrderManagement.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import { EditTypes } from '../summary/summaryConstants';
import Button from '@material-ui/core/Button';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

const {
  ORDERS_QUANTITY,
  ENTER_ORDERS,
  EXITS_TIME_QUANTITY,
  EXITS_TIME,
  EXITS_DISTANCE,
  EXITS_DISTANCE_QUANTITY,
  EXITS_DISTANCE_TIME
} = OrderManagementNames;
const { ATR_DISTANCE_COEF, SIZE_PERCENT, TIMER_MINUTES } = EnterOrdersNames;
const { TIME_1, TIME_2, DISTANCE_1, DISTANCE_2 } = EnterExitProperties;
const DEBOUNCE_TIMEOUT = 100;

interface OrderManagementFormProps {
  returnToPrevStep?: EditTypes.PARAMETERS | null;
  target: FormTarget;
  enter_order_fields: typeof ENTER_ORDER_FIELDS_SHORT | typeof ENTER_ORDER_FIELDS_LONG;
  exit_order_fields: typeof EXIT_ORDER_FIELDS_COMMON;
  onCancel: () => void;
  onClearRequestErrors: () => void;
  errors: AddSPErrors;
  customInputClass?: string;
  customWrapperClass?: string;
}

const OrderManagementForm = ({
  returnToPrevStep,
  target,
  enter_order_fields,
  exit_order_fields,
  onCancel,
  onClearRequestErrors,
  errors: { settingsErr },
  customInputClass,
  customWrapperClass
}: OrderManagementFormProps): JSX.Element => {
  const { errors, values, touched, setValues, handleChange, handleBlur, handleSubmit } =
    useFormikContext<OrderManagementFields>();

  const onChangeQuantity = debounce(
    (
      { target },
      itemName: OrderTimeDistance,
      values: OrderManagementFields,
      setValues: (values: React.SetStateAction<OrderManagementFields>, shouldValidate?: boolean) => void
    ) => {
      const items = [...values[OrderManagementMap[itemName]]];
      const numQuantity = target.value ? +safeParseInt(target.value).toFixed() : 0;
      const prevNumQuantity = values[QuantityMap[itemName]] ? safeParseInt(values[QuantityMap[itemName]]) : 0;

      if (prevNumQuantity < numQuantity) {
        for (let i = prevNumQuantity; i < numQuantity; i++) {
          items.push(getNewDataItem(itemName));
        }
      } else {
        for (let i = prevNumQuantity; i >= numQuantity; i--) {
          items.splice(i, 1);
        }
      }

      setValues({
        ...values,
        [QuantityMap[itemName]]: numQuantity === 0 ? '' : numQuantity.toString(),
        [OrderManagementMap[itemName]]: items
      });
    },
    DEBOUNCE_TIMEOUT
  );

  const onToggleExitOption = (
    exitEnum: ExitOptions,
    values: OrderManagementFields,
    setValues: (values: React.SetStateAction<OrderManagementFields>, shouldValidate?: boolean) => void
  ) => {
    setValues({
      ...values,
      [OrderManagementNames.EXITS_DISTANCE_TIME]: exitEnum,
      ...(exitEnum === ExitOptions.BY_TIME && { [EXITS_DISTANCE]: [], [EXITS_DISTANCE_QUANTITY]: '' }),
      ...(exitEnum === ExitOptions.BY_DISTANCE && { [EXITS_TIME]: [], [EXITS_TIME_QUANTITY]: '' }),
      ...(exitEnum === ExitOptions.UNSET && {
        [EXITS_TIME]: [],
        [EXITS_DISTANCE]: [],
        [EXITS_DISTANCE_QUANTITY]: '',
        [EXITS_TIME_QUANTITY]: ''
      })
    });
  };

  const getIsError = (id, touched, errors) => (touched?.[id] && !!errors[id]) || !!settingsErr;
  const getErrorMsg = (id, touched, errors) => touched?.[id] && errors[id];
  const getIsValid = (id, touched, errors) => !!touched?.[id] && !errors[id] && !settingsErr;

  return (
    <Form className={s.form}>
      <div className={customWrapperClass}>
        <div className={s.fieldBlocksWrapper}>
          <div className={s.dynamicOrdersWrapper}>
            <h3 className={cn(s.title, 'default-title')}>Entry orders</h3>

            <NumberFormatField
              inputProps={{
                id: ORDERS_QUANTITY,
                label: 'Quantity of orders',
                placeholder: '5',
                value: values[ORDERS_QUANTITY],
                isError: getIsError(ORDERS_QUANTITY, touched, errors),
                isValid: getIsValid(ORDERS_QUANTITY, touched, errors),
                helperText: getErrorMsg(ORDERS_QUANTITY, touched, errors),
                inputClass: cn(s.input, { [customInputClass]: customInputClass }),
                onBlur: handleBlur,
                onChange: (e) => onChangeQuantity(e, OrderTimeDistance.ORDER, values, setValues),
                onClearRequestError: onClearRequestErrors
              }}
            />
            <FieldArray name={ENTER_ORDERS}>
              {() =>
                values.enter_orders.map(({ atr_distance_coefficient, size_percent, timer_minutes }, idx) => {
                  const orderErrors = errors.enter_orders?.length && errors.enter_orders[idx];
                  const orderTouched = touched.enter_orders?.length && touched.enter_orders[idx];

                  const atrDistanceId = `${ENTER_ORDERS}.${idx}.${ATR_DISTANCE_COEF}`;
                  const sizePercentId = `${ENTER_ORDERS}.${idx}.${SIZE_PERCENT}`;
                  const timerMinutesId = `${ENTER_ORDERS}.${idx}.${TIMER_MINUTES}`;
                  return (
                    <div key={`${ENTER_ORDERS}.${idx}`} className={s.enterOrderRow}>
                      <h3 className={cn(s.orderTitle, 'default-title')}>Order&nbsp;{idx + 1}</h3>

                      <div className={s.orderFieldsWrapper}>
                        <NumberFormatField
                          inputProps={{
                            id: atrDistanceId,
                            label: 'Distance (in ATR)',
                            placeholder: '1',
                            value: atr_distance_coefficient,
                            isError: orderTouched?.[ATR_DISTANCE_COEF] && !!orderErrors?.[ATR_DISTANCE_COEF],
                            isValid: orderTouched?.[ATR_DISTANCE_COEF] && !orderErrors?.[ATR_DISTANCE_COEF],
                            helperText: orderTouched?.[ATR_DISTANCE_COEF] && orderErrors?.[ATR_DISTANCE_COEF],
                            inputClass: s.orderFieldsInput,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            onClearRequestError: onClearRequestErrors
                          }}
                        />

                        <NumberFormatField
                          inputProps={{
                            id: sizePercentId,
                            label: 'Size (%)',
                            placeholder: '100',
                            value: size_percent,
                            isError: orderTouched?.[SIZE_PERCENT] && !!orderErrors?.[SIZE_PERCENT],
                            isValid: orderTouched?.[SIZE_PERCENT] && !orderErrors?.[SIZE_PERCENT],
                            helperText: orderTouched?.[SIZE_PERCENT] && orderErrors?.[SIZE_PERCENT],
                            inputClass: s.orderFieldsInput,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            onClearRequestError: onClearRequestErrors
                          }}
                        />

                        <NumberFormatField
                          inputProps={{
                            id: timerMinutesId,
                            label: 'ROC timer (min)',
                            placeholder: '10',
                            value: timer_minutes,
                            isError: orderTouched?.[TIMER_MINUTES] && !!orderErrors?.[TIMER_MINUTES],
                            isValid: orderTouched?.[TIMER_MINUTES] && !orderErrors?.[TIMER_MINUTES],
                            helperText: orderTouched?.[TIMER_MINUTES] && orderErrors?.[TIMER_MINUTES],
                            inputClass: s.orderFieldsInput,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            onClearRequestError: onClearRequestErrors
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              }
            </FieldArray>
          </div>

          <div className={s.staticOrdersWrapper}>
            <div className={s.entryOrdersWrapper}>
              <h3 className={cn(s.title, 'default-title')}>Entry orders</h3>

              {enter_order_fields.map(({ id, label, placeholder }) => {
                return (
                  <NumberFormatField
                    key={id}
                    inputProps={{
                      id,
                      label,
                      placeholder,
                      value: values[id],
                      isError: getIsError(id, touched, errors),
                      isValid: getIsValid(id, touched, errors),
                      helperText: getErrorMsg(id, touched, errors),
                      inputClass: cn(s.input, { [customInputClass]: customInputClass }),
                      onBlur: handleBlur,
                      onChange: handleChange,
                      onClearRequestError: onClearRequestErrors
                    }}
                  />
                );
              })}
            </div>
            <div className={s.exitOrdersWrapper}>
              <h3 className={cn(s.title, 'default-title')}>Exit orders</h3>

              {exit_order_fields.map(({ id, label, placeholder }) => (
                <NumberFormatField
                  key={id}
                  inputProps={{
                    id,
                    label,
                    placeholder,
                    value: values[id],
                    isError: getIsError(id, touched, errors),
                    isValid: getIsValid(id, touched, errors),
                    helperText: getErrorMsg(id, touched, errors),
                    inputClass: cn(s.input, { [customInputClass]: customInputClass }),
                    onBlur: handleBlur,
                    onChange: handleChange,
                    onClearRequestError: onClearRequestErrors
                  }}
                />
              ))}
              <div>
                <FormControl variant="outlined" className={s.select}>
                  <InputLabel shrink id="by" className={s.selectLabel}>
                    Choose By time or By distance
                  </InputLabel>
                  <Select
                    id="by"
                    displayEmpty
                    value={values[EXITS_DISTANCE_TIME]}
                    onChange={(e) => onToggleExitOption(e.target.value as ExitOptions, values, setValues)}
                    error={getIsError(EXITS_DISTANCE_TIME, touched, errors)}
                  >
                    <MenuItem value={''}>
                      <em>None</em>
                    </MenuItem>
                    {ExitOrdersSwitchPosition.map((e) => {
                      return (
                        <MenuItem key={e.value} value={e.value}>
                          {e.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {getIsError(EXITS_DISTANCE_TIME, touched, errors) && (
                    <FormHelperText style={{ color: 'red' }}>
                      {getErrorMsg(EXITS_DISTANCE_TIME, touched, errors)}
                    </FormHelperText>
                  )}
                </FormControl>
                <div
                  style={values.exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE ? { display: 'flex' } : {}}
                >
                  <div style={{ marginRight: '6px', width: '50%' }}>
                    {(values.exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE ||
                      values.exists_distance_time === ExitOptions.BY_TIME) && (
                      <div>
                        <NumberFormatField
                          inputProps={{
                            id: EXITS_TIME_QUANTITY,
                            label: 'Time quantity',
                            placeholder: '1',
                            value: values[EXITS_TIME_QUANTITY],
                            isError: getIsError(EXITS_TIME_QUANTITY, touched, errors),
                            isValid: getIsValid(EXITS_TIME_QUANTITY, touched, errors),
                            helperText: getErrorMsg(EXITS_TIME_QUANTITY, touched, errors),
                            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
                            onBlur: handleBlur,
                            onChange: (e) => onChangeQuantity(e, OrderTimeDistance.TIME, values, setValues),
                            onClearRequestError: onClearRequestErrors
                          }}
                        />
                      </div>
                    )}
                    <FieldArray name={EXITS_TIME}>
                      {() =>
                        values.exit_timer_coeff_settings?.map(
                          ({ change_exit_timer_minutes, change_exit_coefficient_by_time }, idx) => {
                            const timeErrors =
                              errors.exit_timer_coeff_settings?.length && errors.exit_timer_coeff_settings[idx];
                            const timeTouched =
                              touched.exit_timer_coeff_settings?.length && touched.exit_timer_coeff_settings[idx];

                            const timerId = `${EXITS_TIME}.${idx}.${TIME_1}`;
                            const coefficientId = `${EXITS_TIME}.${idx}.${TIME_2}`;
                            return (
                              <div
                                key={`${EXITS_TIME}.${idx}`}
                                className={s.enterOrderRow}
                                style={{ margin: '10px 0 30px' }}
                              >
                                <div className={s.orderFieldsWrapper}>
                                  <NumberFormatField
                                    inputProps={{
                                      id: timerId,
                                      label: `Exit coefficient timer (min)`,
                                      placeholder: '1',
                                      value: change_exit_timer_minutes,
                                      isError: timeTouched?.[TIME_1] && !!timeErrors?.[TIME_1],
                                      isValid: timeTouched?.[TIME_1] && !timeErrors?.[TIME_1],
                                      helperText: timeTouched?.[TIME_1] && timeErrors?.[TIME_1],
                                      inputClass: s.exitFieldInput,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      onClearRequestError: onClearRequestErrors
                                    }}
                                  />
                                  <NumberFormatField
                                    inputProps={{
                                      id: coefficientId,
                                      label: `Exit coefficient`,
                                      placeholder: '100',
                                      value: change_exit_coefficient_by_time,
                                      isError: timeTouched?.[TIME_2] && !!timeErrors?.[TIME_2],
                                      isValid: timeTouched?.[TIME_2] && !timeErrors?.[TIME_2],
                                      helperText: timeTouched?.[TIME_2] && timeErrors?.[TIME_2],
                                      inputClass: s.exitFieldInput,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      onClearRequestError: onClearRequestErrors
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )
                      }
                    </FieldArray>
                  </div>
                  <div style={{ width: '50%' }}>
                    {(values.exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE ||
                      values.exists_distance_time === ExitOptions.BY_DISTANCE) && (
                      <div>
                        <NumberFormatField
                          inputProps={{
                            id: EXITS_DISTANCE_QUANTITY,
                            label: 'Distance quantity',
                            placeholder: '1',
                            value: values[EXITS_DISTANCE_QUANTITY],
                            isError: getIsError(EXITS_DISTANCE_QUANTITY, touched, errors),
                            isValid: getIsValid(EXITS_DISTANCE_QUANTITY, touched, errors),
                            helperText: getErrorMsg(EXITS_DISTANCE_QUANTITY, touched, errors),
                            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
                            onBlur: handleBlur,
                            onChange: (e) => onChangeQuantity(e, OrderTimeDistance.DISTANCE, values, setValues),
                            onClearRequestError: onClearRequestErrors
                          }}
                        />
                      </div>
                    )}
                    <FieldArray name={EXITS_DISTANCE}>
                      {() =>
                        values.exit_d_threshold_settings?.map(
                          ({ change_exit_coefficient_by_distance, change_exit_d_threshold_by_distance }, idx) => {
                            const distanceErrors =
                              errors.exit_d_threshold_settings?.length && errors.exit_d_threshold_settings[idx];
                            const distanceTouched =
                              touched.exit_d_threshold_settings?.length && touched.exit_d_threshold_settings[idx];

                            const thresholdId = `${EXITS_DISTANCE}.${idx}.${DISTANCE_1}`;
                            const coefficientId = `${EXITS_DISTANCE}.${idx}.${DISTANCE_2}`;
                            return (
                              <div
                                key={`${EXITS_DISTANCE}.${idx}`}
                                className={s.enterOrderRow}
                                style={{ margin: '10px 0 30px' }}
                              >
                                <div className={s.orderFieldsWrapper}>
                                  <NumberFormatField
                                    inputProps={{
                                      id: thresholdId,
                                      label: `Distance threshold`,
                                      placeholder: '1',
                                      value: change_exit_d_threshold_by_distance,
                                      isError: distanceTouched?.[DISTANCE_1] && !!distanceErrors?.[DISTANCE_1],
                                      isValid: distanceTouched?.[DISTANCE_1] && !distanceErrors?.[DISTANCE_1],
                                      helperText: distanceTouched?.[DISTANCE_1] && distanceErrors?.[DISTANCE_1],
                                      inputClass: s.exitFieldInput,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      onClearRequestError: onClearRequestErrors
                                    }}
                                  />
                                  <NumberFormatField
                                    inputProps={{
                                      id: coefficientId,
                                      label: `Exit coefficient`,
                                      placeholder: '100',
                                      value: change_exit_coefficient_by_distance,
                                      isError: distanceTouched?.[DISTANCE_2] && !!distanceErrors?.[DISTANCE_2],
                                      isValid: distanceTouched?.[DISTANCE_2] && !distanceErrors?.[DISTANCE_2],
                                      helperText: distanceTouched?.[DISTANCE_2] && distanceErrors?.[DISTANCE_2],
                                      inputClass: s.exitFieldInput,
                                      onBlur: handleBlur,
                                      onChange: handleChange,
                                      onClearRequestError: onClearRequestErrors
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )
                      }
                    </FieldArray>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {returnToPrevStep ? (
        <div className={cn(s.btnBlockWrapper, { [s.centeredBtnBlockWrapper]: s.centeredBtnBlockWrapper })}>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            type={'submit'}
            onClick={() => handleSubmit}
            disabled={false}
            className={cn(s.btnRoot, { [s.btnSavePrimary]: s.btnSavePrimary })}
            classes={{ root: s.btnRoot, outlinedPrimary: s.btnSavePrimary }}
          >
            {'Save'}
          </Button>
        </div>
      ) : (
        <PairedBtnBlock
          onCancel={onCancel}
          onSubmit={handleSubmit}
          btnTypeSubmit="submit"
          labels={{
            cancelLabel: returnToPrevStep === null ? 'Cancel' : 'Back',
            submitLabel: target === FormTarget.EDITING ? 'Save' : 'Next'
          }}
          classes={{ btnBlockWrapper: s.btnBlockWrapper }}
        />
      )}
    </Form>
  );
};

export default OrderManagementForm;
