import React, { useCallback, useEffect, useRef } from 'react';

export const useClickOutside = (callback: (e) => void): React.MutableRefObject<HTMLDivElement> => {
  const ref = useRef(null);
  const handleClick = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback(e);
      }
    },
    [callback]
  );
  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback, handleClick]);

  return ref;
};
