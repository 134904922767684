import React, { ReactNode } from 'react';
import { MonthsTotal, SingleResultRow } from '../interfaces/monthsResultsShapes';
import { ColumnAlignOptions } from '../constants/randomConstants';
import { COL_DATE_FORMAT, OrderMonthMap, StatisticTableColIds } from '../constants/monthsStatsConstants';
import { TableColumn } from '../interfaces/randomShapes';
import { formatISODate } from './randomHelpers';
import { getColorReinvest } from 'utils/getColorByValue';

export const getMonthsStatsColumns = (date: string, index: number): TableColumn => ({
  id: index.toString(),
  title: formatISODate(date, COL_DATE_FORMAT),
  align: ColumnAlignOptions.CENTER
});

export const createMonthsStatsRowsFlexible = (
  period: string,
  accTitle: string,
  key: string,
  summery: MonthsTotal,
  values: string[],
  addedOrder: number
): SingleResultRow => {
  return {
    period: period,
    account: accTitle,
    data_type: key,
    res_by_months: values,
    total: summery.total,
    average: summery.avg_by_months,
    average_days: summery.avg_by_days,
    order: OrderMonthMap[key] + addedOrder
  };
};

const getPeriodName = (period: string) => {
  switch (period) {
    case 'total':
      return 'Total';
    case 'avgMonth':
      return 'Avg.Month';
    case 'avgDay':
      return 'Avg.Day';
    default:
      return '-';
  }
};

const getPeriodDate = (period: string) => {
  return formatISODate(period, COL_DATE_FORMAT);
};

export const monthsStatsRenderSwitch = (
  colId: StatisticTableColIds,
  row: SingleResultRow,
  tooltip: string
): ReactNode => {
  const value = row[colId];
  const isCustomRow = ['total', 'avgMonth', 'avgDay'].includes(row.period);

  switch (colId) {
    case StatisticTableColIds.PERIOD:
      return <>{isCustomRow ? getPeriodName(value) : getPeriodDate(value)}</>;

    case StatisticTableColIds.RETURN_FIAT_HWM:
    case StatisticTableColIds.RETURN_FIAT_PNL:
      return <>{value}$</>;

    case StatisticTableColIds.RETURN_PERCENT_HWM:
    case StatisticTableColIds.RETURN_PERCENT_PNL:
    case StatisticTableColIds.WINRATE:
      return <>{value}%</>;

    case StatisticTableColIds.REINVEST:
      return <span color={getColorReinvest(value)}>{value ?? '-'}%</span>;

    default:
      return <>{value ?? '-'}</>;
  }
};
