import React from 'react';
import cn from 'classnames';
import s from './SummaryTitleBlock.module.scss';

interface SummaryTitleBlockProps {
  title: string;
  onEditClick: () => void;
  isReadOnly: boolean;
}

const SummaryTitleBlock = ({ title, onEditClick, isReadOnly }: SummaryTitleBlockProps): JSX.Element => {
  return (
    <div className={s.titleBlock}>
      <h2 className={cn('default-title', s.sectionTitle)}>{title}</h2>
      {!isReadOnly && (
        <button type="button" className={cn(s.editBtn)} onClick={onEditClick}>
          Edit
        </button>
      )}
    </div>
  );
};

export default SummaryTitleBlock;
