import { MutableRefObject, useCallback, useEffect, useState } from 'react';

interface Resize {
  width: number;
  height: number;
}

export const useResize = (myRef: MutableRefObject<null | HTMLDivElement>): Resize => {
  const [dimensions, setDimensions] = useState<Resize>({ width: 0, height: 0 });
  useEffect(() => {
    if (myRef.current) {
      const { current } = myRef;
      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    }
  }, [myRef]);

  const handleResize = useCallback(() => {
    setDimensions({ width: myRef.current.offsetWidth, height: myRef.current.offsetHeight });
  }, [myRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, handleResize]);

  return dimensions;
};
