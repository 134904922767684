import React from 'react';
import { CircularProgress } from '@material-ui/core';
import s from './Loader.module.scss';

export const Loader = (): JSX.Element => {
  return (
    <div className={s.loaderContainer}>
      <div className={s.loader}>
        <CircularProgress />
      </div>
    </div>
  );
};
