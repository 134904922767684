import React, { FC } from 'react';
import MainLayout from '../../../components/mainLayout/MainLayout';
import NavTabs from '../../../components/navTabs/NavTabs';
import s from './UserManagementPage.module.scss';
import UserManagementTable from 'components/userManagementTable/UserManagementTable';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { getPrivilegesTabs } from '../../../helpers/privilegesHelpers';

const UserManagementPage: FC = () => {
  const privileges = usePrivileges();
  const tabs = getPrivilegesTabs(privileges);
  return (
    <MainLayout>
      <div className={s.mainHeader}>
        <h2 className="default-title">Users Management</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <UserManagementTable />
    </MainLayout>
  );
};

export default UserManagementPage;
