import { ChartStats } from 'interfaces/strategyStatsShapes';
import {
  ChartStatsFilterIDs,
  DEFAULT_CHART_PARAMS,
  FilterChartTypeLabels,
  FilterChartTypeValues,
  FilterChartValues,
  FilterTimeFrameMap,
  OPTION_WHEN_EQUITY,
  OPTIONS
} from '../constants/chartStatsConstants';
import { Props as ApexchartsProps } from 'react-apexcharts';
import { formatISODate } from './randomHelpers';

interface GetChartData {
  data: { x: string; y: string }[];
  name: string;
  type: string;
}

const ChartTypeIndexMap = {
  0: ChartStatsFilterIDs.CHART_TYPE,
  1: ChartStatsFilterIDs.CHART_TYPE_2
};

export const getChartData = (
  charts: { [chart: string]: ChartStats[] },
  reqParams: typeof DEFAULT_CHART_PARAMS
): GetChartData[] => {
  return Object.values(charts).reduce((acc: GetChartData[], chart, idx) => {
    return [
      ...acc,
      {
        name: `Chart ${idx + 1}`,
        type: ChartTypeValues[reqParams[ChartTypeIndexMap[idx]]],
        data: chart.map((dateDetail) => ({
          x: dateDetail.date,
          y: dateDetail.value
        }))
      }
    ];
  }, []);
};

export interface ChartDTO {
  date_from?: string;
  date_to?: string;
  period: string;
  tf: string;
  chart_type: string;
  data_type: string;
  chart_type_2?: string;
  data_type_2?: string;
}

export const getRequestData = (reqParams: ChartDTO): { reqData: ChartDTO; reqDataSec: ChartDTO | null } => {
  const createReqDataObject = (dataType: string, chartType: string) => ({
    period: reqParams.period,
    tf: reqParams.tf,
    date_from: reqParams.date_from,
    date_to: reqParams.date_to,
    data_type: dataType,
    chart_type: chartType
  });
  return {
    reqData: createReqDataObject(reqParams.data_type, reqParams.chart_type),
    reqDataSec: reqParams.data_type_2 ? createReqDataObject(reqParams.data_type_2, reqParams.chart_type_2) : null
  };
};

export const getInitialFiltersValues = (queryParams: typeof DEFAULT_CHART_PARAMS): typeof DEFAULT_CHART_PARAMS => ({
  [ChartStatsFilterIDs.DATA_TYPE]:
    queryParams?.[ChartStatsFilterIDs.DATA_TYPE] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.DATA_TYPE],
  [ChartStatsFilterIDs.CHART_TYPE]:
    queryParams?.[ChartStatsFilterIDs.CHART_TYPE] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.CHART_TYPE],
  [ChartStatsFilterIDs.DATA_TYPE_2]:
    queryParams?.[ChartStatsFilterIDs.DATA_TYPE_2] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.DATA_TYPE_2],
  [ChartStatsFilterIDs.CHART_TYPE_2]:
    queryParams?.[ChartStatsFilterIDs.CHART_TYPE_2] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.CHART_TYPE_2],
  [ChartStatsFilterIDs.TIME_FRAME]:
    queryParams?.[ChartStatsFilterIDs.TIME_FRAME] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.TIME_FRAME],
  [ChartStatsFilterIDs.BEFORE_CREATING]:
    queryParams?.[ChartStatsFilterIDs.BEFORE_CREATING] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.BEFORE_CREATING],
  [ChartStatsFilterIDs.AFTER_CREATING]:
    queryParams?.[ChartStatsFilterIDs.AFTER_CREATING] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.AFTER_CREATING],
  [ChartStatsFilterIDs.SEPARATED]:
    queryParams?.[ChartStatsFilterIDs.SEPARATED] || DEFAULT_CHART_PARAMS[ChartStatsFilterIDs.SEPARATED]
});

export const prepareDataTransferObject = (reqParams: typeof DEFAULT_CHART_PARAMS): ChartDTO => {
  const convertDate = (date: string) => formatISODate(date, `yyyy-MM-dd HH:mm`);
  return {
    period: `${convertDate(reqParams[ChartStatsFilterIDs.AFTER_CREATING])},${convertDate(
      reqParams[ChartStatsFilterIDs.BEFORE_CREATING]
    )}`,
    tf: reqParams[ChartStatsFilterIDs.TIME_FRAME],
    chart_type: reqParams[ChartStatsFilterIDs.CHART_TYPE],
    data_type: reqParams[ChartStatsFilterIDs.DATA_TYPE],
    ...(reqParams[ChartStatsFilterIDs.DATA_TYPE_2] && { data_type_2: reqParams[ChartStatsFilterIDs.DATA_TYPE_2] }),
    ...(reqParams[ChartStatsFilterIDs.CHART_TYPE_2] && { chart_type_2: reqParams[ChartStatsFilterIDs.CHART_TYPE_2] })
  };
};

export enum ChartTypeKeys {
  CANDLES = 'candles',
  LINE = 'line',
  COLUMN = 'column',
  MIXED = 'mixed'
}

export const ChartTypeValues: ApexchartsProps = {
  [ChartTypeKeys.CANDLES]: 'candlestick',
  [ChartTypeKeys.LINE]: 'line',
  [ChartTypeKeys.COLUMN]: 'bar'
};

const leftBorderColor = '#77707a';
const buttonBorderColor = '#77707a';

export const getChartTypeOptions = (
  dataTypeValue: FilterChartTypeValues
): { value: FilterChartValues; label: string }[] => {
  switch (dataTypeValue) {
    case FilterChartTypeValues.EQUITY:
      return OPTION_WHEN_EQUITY;
    default:
      return OPTIONS;
  }
};

const singleCandlestickConfig = (timeFrame: string, title: string): ApexCharts.ApexOptions => {
  return {
    title: {
      text: FilterChartTypeLabels[title],
      align: 'center'
    },
    chart: {
      type: 'candlestick',
      width: '100%',
      height: 400
    },
    plotOptions: {
      candlestick: {
        wick: {
          useFillColor: true
        },
        colors: {
          upward: '#1b7729',
          downward: '#ff0000'
        }
      }
    },
    colors: ['#009dff'],
    theme: {
      palette: '#247BA0'
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter(value: string): string {
          return formatISODate(value, FilterTimeFrameMap[timeFrame]);
        },
        style: {
          colors: buttonBorderColor
        }
      },
      tooltip: {
        enabled: true
      },
      axisBorder: {
        show: true,
        color: buttonBorderColor
      },
      title: {
        text: 'Time',
        offsetY: 200,
        style: {
          color: buttonBorderColor,
          fontSize: '24px'
        }
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter(value) {
          return title === FilterChartTypeValues.NAV ? `${value.toFixed(0)}$` : `${value.toFixed(2)}%`;
        },
        style: {
          colors: leftBorderColor,
          fontSize: '14px'
        }
      },
      axisBorder: {
        show: true,
        color: leftBorderColor
      },
      title: {
        text: 'Deposit',
        rotate: -90,
        style: {
          color: leftBorderColor,
          fontSize: '20px'
        }
      }
    }
  };
};

const singleLineConfig = (timeFrame: string, title: string, isIframe = false): ApexCharts.ApexOptions => {
  return {
    ...(!isIframe && {
      title: {
        text: FilterChartTypeLabels[title],
        align: 'center'
      }
    }),
    chart: {
      type: 'line',
      width: '100%',
      height: 400
    },
    stroke: {
      curve: 'straight'
    },
    markers: {
      size: 2.7,
      colors: ['#247BA0'],
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    colors: ['#009dff'],
    theme: {
      palette: '#247BA0'
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter(value: string): string {
          return formatISODate(value, FilterTimeFrameMap[timeFrame]);
        },
        style: {
          colors: buttonBorderColor,
          fontSize: '10px'
        }
      },
      tooltip: {
        enabled: true
      },
      axisBorder: {
        show: true,
        color: buttonBorderColor
      },
      ...(!isIframe && {
        title: {
          text: 'Time',
          offsetY: 200,
          style: {
            color: buttonBorderColor,
            fontSize: '24px'
          }
        }
      })
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter(value) {
          return title === FilterChartTypeValues.NAV ? `${value.toFixed(0)}$` : `${value.toFixed(2)}%`;
        },
        style: {
          colors: leftBorderColor,
          fontSize: '14px'
        }
      },
      axisBorder: {
        show: true,
        color: leftBorderColor
      },
      ...(!isIframe && {
        title: {
          text: 'Deposit',
          rotate: -90,
          style: {
            color: leftBorderColor,
            fontSize: '20px'
          }
        }
      })
    }
  };
};

const singleColumnBarConfig = (timeFrame: string, title: string, isIframe = false): ApexCharts.ApexOptions => {
  return {
    ...(!isIframe && {
      title: {
        text: FilterChartTypeLabels[title],
        align: 'center'
      }
    }),
    chart: {
      type: 'bar',
      width: '100%',
      height: 400
    },
    stroke: {
      curve: 'straight'
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 5,
      colors: ['#247BA0'],
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    colors: ['#009dff'],
    theme: {
      palette: '#247BA0'
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter(value: string): string {
          return formatISODate(value, FilterTimeFrameMap[timeFrame]);
        },
        style: {
          colors: buttonBorderColor,
          fontSize: '10px'
        }
      },
      tooltip: {
        enabled: true
      },
      axisBorder: {
        show: true,
        color: buttonBorderColor
      },
      ...(!isIframe && {
        title: {
          text: 'Time',
          offsetY: 200,
          style: {
            color: buttonBorderColor,
            fontSize: '24px'
          }
        }
      })
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter(value) {
          return title === FilterChartTypeValues.NAV ? `${value.toFixed(0)}$` : `${value.toFixed(2)}%`;
        },
        style: {
          colors: leftBorderColor,
          fontSize: '14px'
        }
      },
      axisBorder: {
        show: true,
        color: leftBorderColor
      },
      ...(!isIframe && {
        title: {
          text: 'Deposit',
          rotate: -90,
          style: {
            color: leftBorderColor,
            fontSize: '24px'
          }
        }
      })
    }
  };
};

const pluralConfig = (timeFrame: string, title: string): ApexCharts.ApexOptions => {
  const firstColor = '#1b7729';
  const secondColor = '#ff0000';
  return {
    chart: {
      type: 'line',
      width: '100%',
      height: 400
    },
    stroke: {
      width: [2, 2],
      curve: 'straight',
      colors: [firstColor, secondColor]
    },
    markers: {
      size: 4,
      colors: [firstColor, secondColor],
      strokeColors: '#fff',
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: 'circle',
      radius: 0.3,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    colors: [firstColor, secondColor],
    theme: {
      palette: '#247BA0'
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter(value: string): string {
          return formatISODate(value, FilterTimeFrameMap[timeFrame]);
        },
        style: {
          colors: buttonBorderColor
        }
      },
      tooltip: {
        enabled: true
      },
      axisBorder: {
        show: false,
        color: buttonBorderColor
      },
      title: {
        text: 'Time',
        offsetY: 200,
        style: {
          color: buttonBorderColor,
          fontSize: '24px'
        }
      }
    },
    yaxis: [
      {
        opposite: false,
        tooltip: {
          enabled: true
        },
        labels: {
          formatter(value) {
            return title === FilterChartTypeValues.NAV ? `${value.toFixed(0)}$` : `${value.toFixed(2)}%`;
          },
          style: {
            colors: leftBorderColor,
            fontSize: '14px'
          }
        },
        axisBorder: {
          show: true,
          color: leftBorderColor
        },
        title: {
          text: 'Deposit',
          rotate: -90,
          style: {
            color: firstColor,
            fontSize: '20px'
          }
        }
      },
      {
        opposite: true,
        tooltip: {
          enabled: true
        },
        labels: {
          formatter(value) {
            return `${value.toFixed(2)}%`;
          },
          style: {
            colors: leftBorderColor,
            fontSize: '14px'
          }
        },
        axisBorder: {
          show: true,
          color: leftBorderColor
        },
        title: {
          text: 'Deposit',
          rotate: -90,
          style: {
            color: secondColor,
            fontSize: '20px'
          }
        }
      }
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft',
        offsetY: 30,
        offsetX: 60
      }
    }
  };
};

export const ChartConfigsMap = {
  [ChartTypeKeys.CANDLES]: singleCandlestickConfig,
  [ChartTypeKeys.LINE]: singleLineConfig,
  [ChartTypeKeys.COLUMN]: singleColumnBarConfig,
  [ChartTypeKeys.MIXED]: pluralConfig
};
