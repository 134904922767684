import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  tooltip: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  }
});
