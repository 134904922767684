import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import authReducer from './authReducer';
import layout from './layoutReducer';
import systemSettings from './systemSettingsReducer';
import mmProfilesRoot from './mmProfilesReducer';
import signalsOrders from './signalsOrdersReducer';
import stats from './statsReducer';
import strategyProfiles from './strategyProfilesReducer';
import notifications from './notificationReducer';
import logs from './logsReducer';
import privileges from './privilegesReducer';
import utils from './utilsReducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['refreshToken', 'accessToken']
};

const authPersisted = persistReducer(authPersistConfig, authReducer);

const rootReducer = combineReducers({
  auth: authPersisted,
  logs,
  layout,
  systemSettings,
  mmProfilesRoot,
  signalsOrders,
  stats,
  strategyProfiles,
  notifications,
  privileges,
  utils
});

export default rootReducer;
