import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './StrategyProfileTable.module.scss';
import {
  getProfileTabs,
  isSectionAllowed,
  isSectionFull,
  isSectionLimited,
  isSectionReadOnly
} from '../../../helpers/privilegesHelpers';
import Button from '@material-ui/core/Button';
import cn from 'classnames';
import {
  DEFAULT_REQ_PARAMS,
  FORBIDDEN_RO_COLUMNS,
  SERVER_CLIENT_COLS_MAP,
  STRATEGY_BTN_ACTIONS,
  STRATEGY_BTN_COLORS,
  STRATEGY_BTN_LABELS,
  STRATEGY_COLUMNS,
  STRATEGY_SIGNAL_FILTERS,
  STRATEGY_STATUS,
  STRATEGY_STATUS_VALUE,
  StrategyColIds,
  initialStrategyProfilesValues
} from '../../../constants/strategyProfilesConstants';
import NavTabs from '../../navTabs/NavTabs';
import { PAGES } from '../../../constants/routes';
import CustomizedTable from '../../customizedTable/CustomizedTable';
import { createStrategyRow, strategyRenderSwitch } from '../../../helpers/strategyProfilesHelpers';
import { ZERO_BASED_PAGE_SHIFT } from '../../../constants/randomConstants';
import MyModal from '../../modal/MyModal';
import PairedBtnBlock from '../../buttons/pairedBtnBlock/PairedBtnBlock';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getComponentsFieldsSelector,
  strategyListSelector,
  strategyRootSelector,
  systemSettingsSelector
} from '../../../redux/selectors';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { PrivilegesGroupFields } from '../../../interfaces/privilegesShapes';
import { StrategyColIds as StrategyColPrivilegesIds } from '../../../constants/privilegesConstants';
import { ErrorShape } from '../../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../../helpers/randomHelpers';
import {
  clearStrategyProfile,
  getStrategyProfiles,
  getSystemStatus,
  setSystemStatus,
  startStrategy,
  stopStrategy,
  updateStrategyVisibility
} from '../../../redux/actions/strategyProfilesActions';
import { StrategyProfileShape, StrategyProfileStatuses } from '../../../interfaces/strategyProfilesShapes';
import { editExchangeAccountRequest, getExchangeAccounts } from '../../../redux/actions/systemSettingsActions';
import { ExchangeAccountShape } from '../../../interfaces/systemSettingsShapes';
import { FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Check } from '../../../assets/images/Check';
import { Cancel } from '../../../assets/images/Cancel';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const STRATEGY_HARD_PER_PAGE = 20;

const StrategyProfileTable = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(strategyRootSelector);
  const { count, results } = useSelector(strategyListSelector);
  const { exchangeAccounts } = useSelector(systemSettingsSelector);
  const { bot_status, profiles, profile_settings } = usePrivileges();
  const [filterParams, setFilterParams] = useState<typeof initialStrategyProfilesValues>(initialStrategyProfilesValues);

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const updatedFilterParams = {
      ...filterParams,
      direction: event.target.value
    };

    setFilterParams(updatedFilterParams as typeof initialStrategyProfilesValues);
    setReqParams((prev) => ({ ...prev, ...updatedFilterParams }));
  };

  const handleOrderingToggle = () => {
    setReqParams((prev) => {
      let newOrdering;
      if (prev.ordering === 'profile_direction') {
        newOrdering = '-profile_direction';
      } else if (prev.ordering === '-profile_direction') {
        newOrdering = '';
      } else {
        newOrdering = 'profile_direction';
      }
      return {
        ...prev,
        ordering: newOrdering
      };
    });
  };

  const exchangeAccount: ExchangeAccountShape =
    exchangeAccounts.length > 0 ? exchangeAccounts[0] : ({} as ExchangeAccountShape);

  const { fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.PROFILES));

  const hasVisibilityOption = useMemo(() => {
    return (isSectionLimited(profiles) && fields.indexOf(StrategyColIds.VISIBILITY) >= 0) || isSectionFull(profiles);
  }, [fields, profiles]);

  const hasAddOption = useMemo(() => {
    return (isSectionLimited(profiles) && fields.indexOf(StrategyColPrivilegesIds.ADD) >= 0) || isSectionFull(profiles);
  }, [fields, profiles]);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = useCallback((err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  }, []);

  const [reqParams, setReqParams] = useState(DEFAULT_REQ_PARAMS);
  const onChangePage = (newPage: number) => {
    onToggleLoading();
    setReqParams((prev) => ({ ...prev, page: newPage + ZERO_BASED_PAGE_SHIFT }));
  };

  const handleAddNewStrategy = () => {
    dispatch(clearStrategyProfile());
    push(PAGES.STRATEGY_PROFILES_ADD);
  };

  const onStartStrategy = (id: number) => dispatch(startStrategy(id, onSetError));
  const onStopStrategy = (id: number) => dispatch(stopStrategy(id, onSetError));
  const onContinueDraft = (profileId: number) => push(PAGES.STRATEGY_PROFILES_ADD, { profileId });

  const onAction = (profileId: number, status: StrategyProfileStatuses) => {
    switch (status) {
      case StrategyProfileStatuses.ACTIVE:
        return onStopStrategy(profileId);

      case StrategyProfileStatuses.INACTIVE:
      case StrategyProfileStatuses.STOPPING:
        return onStartStrategy(profileId);

      case StrategyProfileStatuses.DRAFT:
        return onContinueDraft(profileId);

      default:
        return null;
    }
  };

  const onVisibility = useCallback(
    (id: number, isShow: boolean) => {
      dispatch(
        updateStrategyVisibility(id, isShow, () => {
          dispatch(getStrategyProfiles(reqParams, () => null, onSetError));
        })
      );
    },
    [dispatch, reqParams, onSetError]
  );

  const getContent = (colId, row, collapseCallback, isCollapseOpen, isCollapsible) => {
    return strategyRenderSwitch(
      colId,
      row,
      collapseCallback,
      isCollapseOpen,
      onAction,
      onVisibility,
      isCollapsible,
      isSectionAllowed(profile_settings)
    );
  };

  const rows = useMemo(() => results.map((profile: StrategyProfileShape) => createStrategyRow(profile)), [results]);

  const [isModalOpen, setModalOpen] = useState(false);

  const onToggleModal = () => setModalOpen((prev) => !prev);

  const changeSystemStatus = () => {
    dispatch(setSystemStatus(STRATEGY_BTN_ACTIONS[String(status)], onToggleModal, onSetError));
  };
  useEffect(() => {
    onToggleLoading();
    dispatch(getStrategyProfiles(reqParams, () => setLoading(false), onSetError));
    if (isSectionFull(bot_status)) {
      dispatch(getSystemStatus(undefined, onSetError));
    }
  }, [dispatch, reqParams, onSetError, bot_status]);

  useEffect(() => {
    dispatch(getExchangeAccounts());
  }, [dispatch]);

  const onHideItems = () => {
    setReqParams((prev) => ({ ...prev, show_hide: !prev.show_hide }));
  };

  const filteredColumns = useMemo(() => {
    if (isSectionLimited(profiles)) {
      return STRATEGY_COLUMNS.filter((col) => fields.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) >= 0);
    }
    if (!isSectionLimited(profiles) && isSectionReadOnly(profiles)) {
      return STRATEGY_COLUMNS.filter((col) => FORBIDDEN_RO_COLUMNS.indexOf(col.id) < 0);
    }
    return STRATEGY_COLUMNS;
  }, [profiles, fields]);

  const privileges = usePrivileges();
  const tabs = getProfileTabs(privileges);

  const [isBrokerLoading, setBrokerLoading] = useState(false);

  const handleChangeBrokerActivity = (isActive: boolean) => {
    setBrokerLoading(true);
    dispatch(
      editExchangeAccountRequest(
        {
          api_key: exchangeAccount.api_key,
          api_secret: exchangeAccount.api_secret,
          title: exchangeAccount.title,
          leverage: exchangeAccount.leverage,
          broker_id: exchangeAccount.broker_id,
          broker_program_is_active: isActive
        },
        exchangeAccount.id,
        () => setBrokerLoading(false),
        () => setBrokerLoading(false)
      )
    );
  };

  return (
    <div className={s.content}>
      <div className={s.header}>
        <h2 className="default-title">Strategy profiles list</h2>
        <div className={s.btnsContainer}>
          <Tooltip
            title={
              <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
                <span>
                  {exchangeAccount?.broker_program_is_active
                    ? 'Broker program is activated'
                    : 'Broker program is not activated'}
                </span>
              </div>
            }
          >
            <div style={{ alignSelf: 'center' }}>
              <span
                style={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: exchangeAccount?.broker_program_is_active ? '#00fd3f' : '#ff0000',
                  borderRadius: '50%',
                  display: 'inline-block'
                }}
              />{' '}
              <span>{exchangeAccount?.broker_program_is_active ? 'Active' : 'Not Active'}</span>
            </div>
          </Tooltip>
          {exchangeAccount?.broker_program_is_available && (
            <>
              <ToggleButtonGroup
                value={exchangeAccount?.broker_program_is_active ? 'on' : 'off'}
                onChange={() => {
                  if (!isBrokerLoading) {
                    handleChangeBrokerActivity(!exchangeAccount?.broker_program_is_active);
                  }
                }}
                aria-disabled={isBrokerLoading}
              >
                <ToggleButton value="on" aria-label="left aligned">
                  On
                </ToggleButton>
                <ToggleButton value="off" aria-label="centered">
                  Off
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
          <Tooltip
            title={
              <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
                <span>
                  {exchangeAccount?.broker_program_is_available
                    ? 'Broker program is available'
                    : 'Broker program is not available'}
                </span>
              </div>
            }
          >
            <div style={{ alignSelf: 'center' }}>
              {exchangeAccount?.broker_program_is_available ? <Check fill="green" /> : <Cancel fill="red" />}
            </div>
          </Tooltip>
        </div>
        {isSectionFull(bot_status) && status && (
          <div className={s.actionWrapper}>
            <Button
              variant="outlined"
              size="medium"
              type="button"
              classes={{ root: s.btnRoot }}
              className={cn(s.btnRoot, {
                [s.btnPrimary]: STRATEGY_STATUS.DISABLED === String(status)
              })}
              onClick={onToggleModal}
              color={STRATEGY_BTN_COLORS[String(status)]}
            >
              {STRATEGY_BTN_LABELS[String(status)]}
            </Button>
            <span className={s.actionText}>
              Your profile now is {String(STRATEGY_STATUS_VALUE[String(status)]).toLowerCase()}
            </span>
          </div>
        )}
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>

      <Grid container direction="row" alignItems="center">
        <Grid xs>
          <div className={s.filters}>
            <div className={s.filtersInputs}>
              {STRATEGY_SIGNAL_FILTERS.map((filter) => (
                <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
                  <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
                    {filter.label}
                  </InputLabel>
                  <Select
                    id={filter.id}
                    name={filter.id}
                    displayEmpty
                    value={filterParams[filter.id]}
                    onChange={handleFilterChange}
                  >
                    {filter.options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </div>
          </div>
        </Grid>
        <Grid>
          {!isSectionReadOnly(profiles) && (
            <div className={s.btnsContainer}>
              {hasVisibilityOption && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  type="button"
                  classes={{ root: s.btnRoot }}
                  onClick={onHideItems}
                >
                  {reqParams.show_hide ? 'Hide Unpicked' : 'Show All'}
                </Button>
              )}
              {hasAddOption && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  classes={{ root: s.btnRoot }}
                  onClick={handleAddNewStrategy}
                >
                  Add
                </Button>
              )}
            </div>
          )}
        </Grid>
      </Grid>

      <CustomizedTable
        count={count}
        columns={filteredColumns}
        rows={rows}
        isLoading={isLoading}
        error={reqError}
        emptyRowsMsg="There are no strategy profiles yet..."
        getContent={getContent}
        rowsPerPageOptions={[STRATEGY_HARD_PER_PAGE]}
        customPerPage={STRATEGY_HARD_PER_PAGE}
        customPage={reqParams.page - ZERO_BASED_PAGE_SHIFT}
        onCustomChangePage={onChangePage}
        hasSortingOption={true}
        sortHandler={handleOrderingToggle}
        offPerPage
        deactivated={STRATEGY_STATUS.DISABLED === String(status)}
      />
      <MyModal
        isOpen={isModalOpen}
        onToggle={onToggleModal}
        stylesProps={{
          paper: { padding: '40px 50px 50px', top: '43%' }
        }}
      >
        <div className={s.modalWrapper}>
          <span className={s.modalText}>
            Are you sure you want to {STRATEGY_BTN_LABELS[String(status)]} your profile?
          </span>
          <PairedBtnBlock
            onCancel={onToggleModal}
            onSubmit={changeSystemStatus}
            labels={{ cancelLabel: 'Cancel', submitLabel: 'Confirm' }}
          />
        </div>
      </MyModal>
    </div>
  );
};

export default StrategyProfileTable;
