import * as Yup from 'yup';

export enum ExchangeAccFieldsNames {
  TITLE = 'title',
  API_KEY = 'api_key',
  SECRET_KEY = 'api_secret',
  LEVERAGE = 'leverage',
  BROKER_ID = 'broker_id'
}

export const EXCHANGE_ACC_FIELDS = [
  {
    id: ExchangeAccFieldsNames.TITLE,
    label: 'Name',
    placeholder: 'Account Name'
  },
  {
    id: ExchangeAccFieldsNames.API_KEY,
    label: 'API Key',
    placeholder: 'Account API Key'
  },
  {
    id: ExchangeAccFieldsNames.LEVERAGE,
    label: 'Leverage',
    placeholder: '0-100'
  },
  {
    id: ExchangeAccFieldsNames.BROKER_ID,
    label: 'Broker Id',
    placeholder: 'Account Broker Id'
  },
  {
    id: ExchangeAccFieldsNames.SECRET_KEY,
    label: 'Secret Key',
    placeholder: 'Account Secret Key'
  }
];

export const exchangeAccValidationEdit = {
  [ExchangeAccFieldsNames.TITLE]: Yup.string()
    .required('Name can’t be blank')
    .max(64, 'Name could be maximum 64 symbols'),
  [ExchangeAccFieldsNames.API_KEY]: Yup.string()
    .required('API key can’t be blank')
    .max(64, 'API key could be maximum 64 symbols'),
  [ExchangeAccFieldsNames.LEVERAGE]: Yup.number()
    .required('Leverage can’t be blank')
    .min(0, 'Leverage could be maximum only 0-100')
    .max(100, 'Leverage could be maximum only 0-100'),
  [ExchangeAccFieldsNames.BROKER_ID]: Yup.string().max(64, 'Broker Id could be maximum 64 symbols').nullable()
};

export const exchangeAccValidationAdd = {
  [ExchangeAccFieldsNames.SECRET_KEY]: Yup.string()
    .required('Secret key can’t be blank')
    .max(64, 'Secret key could be maximum 64 symbols')
};

export enum TG_NotificationFields {
  ID = 'id',
  BOT_TOKEN = 'token',
  CHAT_ID = 'chat_id',
  INVESTOR_BOT_TOKEN = 'investor_bot_token',
  INVESTOR_CHAT_ID = 'investor_chat_id',
  INVESTOR_REPORT_TIME = 'investor_report_time'
}

export const TG_NOTIFICATION_FIELDS = [
  {
    id: TG_NotificationFields.BOT_TOKEN,
    label: 'Bot API Token',
    placeholder: 'Bot API Token'
  },
  {
    id: TG_NotificationFields.CHAT_ID,
    label: 'Chat ID',
    placeholder: 'Chat ID'
  },
  {
    id: TG_NotificationFields.INVESTOR_BOT_TOKEN,
    label: 'Investor Bot API Token',
    placeholder: 'Investor Bot API Token'
  },
  {
    id: TG_NotificationFields.INVESTOR_CHAT_ID,
    label: 'Investor Chat ID',
    placeholder: 'Investor Chat ID'
  }
];

export const tgNotificationValidationSchema = Yup.object({
  [TG_NotificationFields.BOT_TOKEN]: Yup.string()
    .required('Bot API Token can’t be blank')
    .max(512, 'Bot API Token could be maximum 512 symbols'),
  [TG_NotificationFields.CHAT_ID]: Yup.string()
    .required('Chat ID can’t be blank')
    .max(16, 'Chat ID could be maximum 16 symbols'),
  [TG_NotificationFields.INVESTOR_CHAT_ID]: Yup.number()
    .required('Investor Chat ID can’t be blank')
    .max(512, 'Chat ID could be maximum 512 symbols')
    .nullable(),
  [TG_NotificationFields.INVESTOR_BOT_TOKEN]: Yup.string()
    .nullable()
    .required('Investor Bot Token can’t be blank')
    .max(512, 'Investor Token could be maximum 512 symbols'),
  [TG_NotificationFields.INVESTOR_REPORT_TIME]: Yup.string()
    .required('Investor Report Time can’t be blank')
    .test('isValidTime', 'Allowed time is from 00:00 until 6:00', (value) => {
      const [hour] = value.split(':');
      return Number(hour) <= 6;
    })
});

// ================= //
// TELEGRAM SETTINGS //
// ================= //
export enum TG_SETTINGS_FieldIds {
  ID = 'id',
  BOT_TOKEN = 'token',
  CHAT_ID = 'chat_id'
}

export const TG_SETTINGS_FIELDS = [
  {
    id: TG_NotificationFields.BOT_TOKEN,
    label: 'Bot API Token',
    placeholder: 'Bot API Token'
  },
  {
    id: TG_NotificationFields.CHAT_ID,
    label: 'Chat ID',
    placeholder: 'Chat ID'
  }
];

export const TG_SETTINGS_FIELDS_ValidationSchema = Yup.object({
  [TG_SETTINGS_FieldIds.BOT_TOKEN]: Yup.string()
    .required('Bot API Token can’t be blank')
    .max(512, 'Bot API Token could be maximum 512 symbols'),
  [TG_SETTINGS_FieldIds.CHAT_ID]: Yup.string()
    .required('Chat ID can’t be blank')
    .max(16, 'Chat ID could be maximum 16 symbols')
});

// ======================== //
// INVERSTOR REPORT SETTING //
// ======================== //
export enum INVESTOR_REPORT_SETTINGS_FieldIds {
  INVESTOR_BOT_TOKEN = 'investor_bot_token',
  INVESTOR_CHAT_ID = 'investor_chat_id'
}

export enum INVESTOR_REPORT_SETTINGS_SCHEDULER_FieldIds {
  INVESTOR_REPORT_ID = 'id',
  INVESTOR_REPORT_TIME = 'investor_report_time',
  INVESTOR_REPORT_TYPE = 'schedule_type',
  INVESTOR_REPORT_CRONTAB = 'schedule_crontab',
  INVESTOR_REPORT_LOCALTIME = 'local_investor_report_time'
}

export const INVESTOR_REPORT_SETTINGS_FIELDS = [
  {
    id: INVESTOR_REPORT_SETTINGS_FieldIds.INVESTOR_BOT_TOKEN,
    label: 'Investor Bot API Token',
    placeholder: 'Investor Bot API Token'
  },
  {
    id: INVESTOR_REPORT_SETTINGS_FieldIds.INVESTOR_CHAT_ID,
    label: 'Investor Chat ID',
    placeholder: 'Investor Chat ID'
  }
];

export const INVESTOR_REPORT_SETTINGS_ValidationSchema = Yup.object({
  [INVESTOR_REPORT_SETTINGS_FieldIds.INVESTOR_CHAT_ID]: Yup.number()
    .required('Investor Chat ID can’t be blank')
    .max(512, 'Chat ID could be maximum 512 symbols')
    .nullable(),
  [INVESTOR_REPORT_SETTINGS_FieldIds.INVESTOR_BOT_TOKEN]: Yup.string()
    .nullable()
    .required('Investor Bot Token can’t be blank')
    .max(512, 'Investor Token could be maximum 512 symbols')
});

export const INVESTOR_REPORT_SETTINGS_SCHEDULER_ValidationSchema = Yup.object({
  [INVESTOR_REPORT_SETTINGS_SCHEDULER_FieldIds.INVESTOR_REPORT_CRONTAB]: Yup.string()
    .required('Cron expression is required when type is custom')
    .matches(/(\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*/)
});
