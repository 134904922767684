import { layoutTypes } from '../types';
import { AnyAction } from 'redux';
import { LayoutReducer } from 'interfaces/reduxRandomShapes';

const initialState = {
  isAsideOpen: false
};

export default function layoutReducer(state = initialState, { type, payload }: AnyAction): LayoutReducer {
  switch (type) {
    case layoutTypes.CHANGE_ASIDE_MENU:
      return { ...state, isAsideOpen: payload };

    default:
      return state;
  }
}
