import { strategyProfilesTypes } from '../types';
import { AnyAction } from 'redux';
import { StrategyProfilesReducer, StrategyProfilesData } from 'interfaces/strategyProfilesShapes';

const initialState: StrategyProfilesReducer = {
  profile: null,
  profileTemplate: null,
  strategySettings: null,
  strategyProfilesData: { count: null, next: null, previous: null, results: [] } as StrategyProfilesData,
  status: null,
  pairs: [],
  profiles: []
};

export default function strategyProfilesReducer(
  state = initialState,
  { type, payload }: AnyAction
): StrategyProfilesReducer {
  switch (type) {
    case strategyProfilesTypes.SET_PROFILE:
      return { ...state, profile: payload };

    case strategyProfilesTypes.SET_PROFILE_TEMPLATE:
      return { ...state, profileTemplate: payload };

    case strategyProfilesTypes.SET_STRATEGY_SETTINGS:
      return { ...state, strategySettings: payload };

    case strategyProfilesTypes.PUT_STRATEGY_PROFILES:
      return { ...state, strategyProfilesData: payload };

    case strategyProfilesTypes.CLEAR_STRATEGY_PROFILE:
      return { ...state, profile: null, strategySettings: null };

    case strategyProfilesTypes.PUT_UPDATED_IN_LIST:
      return {
        ...state,
        strategyProfilesData: {
          ...state.strategyProfilesData,
          results: state.strategyProfilesData.results.map((profile) => (profile.id === payload?.id ? payload : profile))
        }
      };

    case strategyProfilesTypes.PUT_SYSTEM_STATUS:
      return { ...state, status: payload };

    case strategyProfilesTypes.SET_STRATEGY_TICKERS:
      return {
        ...state,
        pairs: payload
      };

    case strategyProfilesTypes.UPDATE_STRATEGY_TICKERS:
      return {
        ...state,
        pairs: state.pairs.map((pair) => (pair.id === payload.id ? { ...pair, pairs: payload.pairs } : pair))
      };

    case strategyProfilesTypes.DELETE_STRATEGY_TICKERS_SUCCESS:
      return {
        ...state,
        pairs: state.pairs.filter((pair) => pair.id !== payload.id)
      };

    case strategyProfilesTypes.ADD_STRATEGY_TICKERS_SUCCESS:
      return {
        ...state,
        pairs: [...state.pairs, { ...payload, originalStatus: payload.status }]
      };

    case strategyProfilesTypes.UPDATE_STATUS_STRATEGY_TICKERS_SUCCESS:
      return {
        ...state,
        pairs: state.pairs.map((pair) => (pair.id === payload.id ? { ...pair, status: payload.status } : pair))
      };

    case strategyProfilesTypes.UPDATED_STRATEGY_TICKERS:
      return {
        ...state,
        pairs: state.pairs.map((pair) => (pair.id === payload.id ? { ...payload, originalStatus: pair.status } : pair))
      };

    case strategyProfilesTypes.SET_PROFILE_TEMPLATES:
      return {
        ...state,
        profiles: payload
      };

    default:
      return state;
  }
}
