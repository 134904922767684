import { ColumnAlignOptions } from './randomConstants';

export enum StrategyTickersIds {
  ID = 'id',
  TITLE = 'title',
  PAIRS = 'pairs',
  STATUS = 'status',
  DELETE = 'delete',
  SAVE = 'save',
  CREADTED_AT = 'created_at',
  UPDATED_AT = 'updated_at'
}

export const StrategyTickersColsNames = {
  [StrategyTickersIds.TITLE]: 'Config Name',
  [StrategyTickersIds.PAIRS]: 'Tickers List',
  [StrategyTickersIds.STATUS]: 'Status',
  [StrategyTickersIds.DELETE]: 'Removal',
  [StrategyTickersIds.SAVE]: 'Save',
  [StrategyTickersIds.CREADTED_AT]: 'Created',
  [StrategyTickersIds.UPDATED_AT]: 'Updated'
};

export const SORTING_VALUES = {
  Created: 'created_at',
  Updated: 'updated_at'
};

export const STRATEGY_TICKERS_COLS = [
  {
    id: StrategyTickersIds.TITLE,
    title: StrategyTickersColsNames[StrategyTickersIds.TITLE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.PAIRS,
    title: StrategyTickersColsNames[StrategyTickersIds.PAIRS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.STATUS,
    title: StrategyTickersColsNames[StrategyTickersIds.STATUS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.CREADTED_AT,
    title: StrategyTickersColsNames[StrategyTickersIds.CREADTED_AT],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: StrategyTickersIds.UPDATED_AT,
    title: StrategyTickersColsNames[StrategyTickersIds.UPDATED_AT],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: StrategyTickersIds.DELETE,
    title: StrategyTickersColsNames[StrategyTickersIds.DELETE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.SAVE,
    title: StrategyTickersColsNames[StrategyTickersIds.SAVE],
    align: ColumnAlignOptions.CENTER
  }
];

export const TICKER_NOT_ALLOWED_TEXT = 'You can not modify active ticker config!';
