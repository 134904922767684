import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import * as Shapes from 'interfaces/systemSettingsShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const getExchangeAccountsHelper = (): Promise<AxiosResponse<Array<Shapes.ExchangeAccountShape> | []>> => {
  return axios.get(API_ENDPOINTS.EXCHANGE_ACCOUNTS);
};

export const addExchangeAccValidateHelper = (reqData: Shapes.ExchangeAccValidateShape): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.VALIDATE_EXCHANGE_ACCOUNT, reqData);
};

export const addExchangeAccHelper = (reqData: Shapes.ExchangeAccountFormFields): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.EXCHANGE_ACCOUNTS, reqData);
};

export const editExchangeAccHelper = (
  reqData: Shapes.ExchangeAccountFormFields,
  accountId: number
): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.EDIT_EXCHANGE_ACCOUNT(accountId), reqData);
};

export const deleteExchangeAccHelper = (accountId: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.DELETE_EXCHANGE_ACCOUNT(accountId));
};

export const getTGNotificationsSettingsHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.TG_NOTIFICATIONS);
};

export const addTGNotificationsSettingsHelper = (reqData: Shapes.TELEGRAM_SETTINGS_VALUES): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.TG_NOTIFICATIONS, reqData);
};

export const editTGNotificationsSettingsHelper = (reqData: Shapes.TELEGRAM_SETTINGS_VALUES): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.TG_NOTIFICATIONS, reqData);
};

export const getInvestorReportSettings = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.INVESTOR_REPORT_SETTINGS);
};

export const editInvestorReportSettings = (reqData: Shapes.INVESTOR_REPORT_SETTINGS_VALUES): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.INVESTOR_REPORT_SETTINGS, reqData);
};

export const getInvestorReportScheduler = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.INVESTOR_REPORT_SETTINGS_SCHEDULER);
};

export const createInvestorReportScheduler = (
  reqData: Shapes.INVESTOR_REPORT_SETTINGS_SCHEDULER_VALUES
): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.INVESTOR_REPORT_SETTINGS_SCHEDULER, reqData);
};

export const deleteInvestorReportScheduler = (id: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.DELETE_INVESTOR_REPORT_SETTINGS_SCHEDULER(id));
};

export const getTradingPairsHelper = (): Promise<AxiosResponse<Array<Shapes.TradingPairShape>>> => {
  return axios.get(API_ENDPOINTS.TRADING_PAIRS);
};

export const syncTradingPairsHelper = (): Promise<AxiosResponse<Array<Shapes.TradingPairShape>>> => {
  return axios.post(API_ENDPOINTS.TRADING_PAIRS, {});
};

export const changeTradingPairHelper = (
  reqData: { ticker: string; is_active: boolean },
  pairId: number
): Promise<AxiosResponse<Shapes.TradingPairShape>> => {
  return axios.put(API_ENDPOINTS.TRADING_PAIR(pairId), reqData);
};
