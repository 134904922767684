import React, { useState } from 'react';
import cn from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import s from './OutlinedSelect.module.scss';

interface CustomizedSelectProps {
  onChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onBlur?: (e) => void;
  onClearReqError?: () => void;
  options: Array<{ value: number | string; label: number | string }>;
  value: string;
  label: string;
  id: string;
  errorMsg: string | null;
  isError: boolean;
  formControlClass?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const CustomizedSelect = ({
  options,
  value,
  label,
  id,
  isError,
  errorMsg,
  formControlClass,
  onChange,
  onBlur,
  onClearReqError,
  isLoading = false,
  disabled = false
}: CustomizedSelectProps): JSX.Element => {
  return (
    <FormControl
      variant="outlined"
      className={cn(s.formControl, { [s.inputValid]: !!value, [formControlClass]: formControlClass })}
      error={isError}
    >
      <InputLabel id={id} className={cn({ [s.labelValid]: !!value })}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClearReqError}
        label={label}
        disabled={disabled}
      >
        {isLoading ? (
          <MenuItem disabled={isLoading} value="">
            Loading...
          </MenuItem>
        ) : (
          options.map(({ value, label }) => (
            <MenuItem key={label} value={value} disabled={!value}>
              {label}
            </MenuItem>
          ))
        )}
      </Select>
      {errorMsg && <FormHelperText>{errorMsg}</FormHelperText>}
    </FormControl>
  );
};
export default CustomizedSelect;
