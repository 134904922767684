import React from 'react';
import cn from 'classnames';
import MuiButton from '@material-ui/core/Button';
import s from './PairedBtnBlock.module.scss';
import { Tooltip, withStyles } from '@material-ui/core';

interface PairedBtnBlockProps {
  onCancel: () => void;
  onSubmit: () => void;
  labels?: {
    cancelLabel?: string;
    submitLabel?: string;
  };
  btnTypeCancel?: 'submit' | 'reset' | 'button';
  btnTypeSubmit?: 'submit' | 'reset' | 'button';
  disableSubmit?: boolean;
  disableCancel?: boolean;
  tooltipDisableSubmit?: string | null;
  colors?: {
    cancelColor?: 'default' | 'inherit' | 'primary' | 'secondary';
    submitColor?: 'default' | 'inherit' | 'primary' | 'secondary';
  };
  classes?: { btnBlockWrapper?: string };
}

const Button = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  }
})(MuiButton);

const PairedBtnBlock = ({
  onCancel,
  onSubmit,
  labels,
  btnTypeCancel,
  btnTypeSubmit,
  disableCancel,
  disableSubmit,
  tooltipDisableSubmit,
  colors,
  classes
}: PairedBtnBlockProps): JSX.Element => {
  return (
    <div className={cn(s.btnBlockWrapper, { [classes?.btnBlockWrapper]: classes?.btnBlockWrapper })}>
      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type={btnTypeCancel ?? 'button'}
        color={colors?.cancelColor ?? 'secondary'}
        className={s.btnText}
        classes={{ root: s.btnRoot }}
        disabled={disableCancel}
        onClick={onCancel}
      >
        {labels.cancelLabel ?? 'Cancel'}
      </Button>
      <Tooltip
        title={
          disableSubmit && tooltipDisableSubmit ? (
            <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
              {tooltipDisableSubmit}
            </div>
          ) : (
            ''
          )
        }
      >
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type={btnTypeSubmit ?? 'submit'}
          color={colors?.submitColor ?? 'primary'}
          onClick={onSubmit}
          disabled={disableSubmit}
          className={cn({ [s.btnSavePrimary]: !tooltipDisableSubmit })}
          classes={{ root: s.btnRoot }}
        >
          {labels.submitLabel ?? 'Submit'}
        </Button>
      </Tooltip>
    </div>
  );
};

export default PairedBtnBlock;
