import { FormControl, MenuItem, Select } from '@material-ui/core';
import s from '../pages/privilegesAddPage/PrivilegesAdd.module.scss';
import React from 'react';
import {
  FIELDS_HAS_COLS,
  filterPerPageValues,
  LOGS_FIELDS,
  MM_PROFILES_FIELDS,
  ORDERS_FIELDS,
  PrivilegesColIds,
  PrivilegesGroupFormFields,
  PROFILES_FIELDS,
  SIGNALS_FIELDS,
  PRIVILEGES_OPTIONS,
  PROFILES_STATS_FIELDS,
  PrivilegesRows,
  APA_FIELDS
} from '../constants/privilegesConstants';
import EditIcon from '@material-ui/icons/SettingsApplications';
import { ButtonColorTypes } from '../constants/randomConstants';
import Button from '@material-ui/core/Button';
import {
  GroupPermission,
  GroupsShape,
  Permission,
  PrivilegesGroupFields,
  Status
} from '../interfaces/privilegesShapes';
import {
  ASIDE_LINKS,
  PAGES,
  PRIVILEGES_TABS,
  ROUTE_OPTIONS,
  SIGNALS_ORDERS_TABS,
  STATS_TABS,
  STRATEGY_TABS
} from '../constants/routes';

export const PrivilegesRenderSwitch = (
  colId: string,
  row: PrivilegesRows,
  onEdit: (value: string, id: number) => void,
  handleUpdate: (propName: string, propValue: string, rowId: number) => void,
  handleSave: (rowId: number) => void,
  handleDelete: (rowId: number) => void
): JSX.Element => {
  const value = row[colId];

  const onFilterSelect = ({ target }, id: number) => {
    handleUpdate(target.name, target.value, id);
  };

  switch (colId) {
    case PrivilegesColIds.NAME:
      return <>{value ?? ''}</>;
    case PrivilegesColIds.SAVE:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={row.save.color}
          onClick={() => handleSave(row?.id)}
          classes={{ root: s.actionBtn, outlinedPrimary: s.btnPrimary }}
        >
          {row.save.label}
        </Button>
      );
    case PrivilegesColIds.DELETE:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={row.delete.color}
          onClick={() => handleDelete(row?.id)}
          classes={{ root: s.actionBtn, outlinedPrimary: s.btnPrimary }}
        >
          {row.delete.label}
        </Button>
      );
    default:
      return (
        <div className={s.privilegePicker}>
          <FormControl variant="outlined" className={s.filterFormControl}>
            <Select
              id={colId}
              name={colId}
              className={s.select}
              displayEmpty
              value={value}
              onChange={(e) => onFilterSelect(e, row?.id)}
            >
              {PRIVILEGES_OPTIONS[colId].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {value === filterPerPageValues.LIMITED && FIELDS_HAS_COLS.indexOf(colId as PrivilegesColIds) >= 0 && (
            <EditIcon onClick={() => onEdit(colId, row?.id)} className={s.limitIcon} />
          )}
        </div>
      );
  }
};

export const statePrepareGroups = (groups: Array<GroupsShape>): Array<GroupsShape> => {
  return groups.map((group) => {
    return {
      id: group.id,
      name: group.name,
      perms: group.perms.length
        ? group.perms
        : ([
            { section_name: PrivilegesGroupFields.EXCHANGE_ACCOUNT, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.PAIRS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.TG_BOT, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.LOGS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.NOTIFICATIONS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.SIGNALS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.SIGNALS_EXPORT, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.ORDERS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.PROFILES_STATS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.MM_PROFILES, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.PROFILES, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.PROFILE_SETTINGS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.BOT_STATUS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.GROUPS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.PROFILES_STATS_EXPORT, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.APA, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.RISK_CONTROL, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.ACCOUNT_STATS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.ACCOUNT_STATS_EXPORT, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.STATS_BY_MONTHS, status: filterPerPageValues.NP },
            { section_name: PrivilegesGroupFields.TICKERS_CONFIGS, status: filterPerPageValues.NP }
          ] as Permission[])
    };
  });
};

export const createPrivilegesGroupValues = (values: PrivilegesGroupFormFields): GroupPermission => {
  return {
    name: values[PrivilegesGroupFields.NAME],
    perms: [
      {
        status: values[PrivilegesGroupFields.BOT_STATUS],
        section_name: PrivilegesGroupFields.BOT_STATUS
      },
      {
        status: values[PrivilegesGroupFields.EXCHANGE_ACCOUNT],
        section_name: PrivilegesGroupFields.EXCHANGE_ACCOUNT
      },
      {
        status: values[PrivilegesGroupFields.PAIRS],
        section_name: PrivilegesGroupFields.PAIRS
      },
      {
        status: values[PrivilegesGroupFields.TG_BOT],
        section_name: PrivilegesGroupFields.TG_BOT
      },
      {
        status: values[PrivilegesGroupFields.LOGS],
        section_name: PrivilegesGroupFields.LOGS,
        ...(values[PrivilegesGroupFields.LOGS] === Status.LIMITED && {
          fields: values['logs__fields'] ?? LOGS_FIELDS,
          filtering: values['logs__filtering'] ?? true
        })
      },
      {
        status: values[PrivilegesGroupFields.NOTIFICATIONS],
        section_name: PrivilegesGroupFields.NOTIFICATIONS
      },
      {
        status: values[PrivilegesGroupFields.SIGNALS],
        section_name: PrivilegesGroupFields.SIGNALS,
        ...(values[PrivilegesGroupFields.SIGNALS] === Status.LIMITED && {
          fields: values['signals__fields'] ?? SIGNALS_FIELDS,
          sorting: values['signals__sorting'] ?? true,
          filtering: values['signals__filtering'] ?? true,
          is_only_finished: values['signals__is_only_finished'] ?? false,
          signals_count: values['signals__signals_count'] ?? 200
        })
      },
      {
        status: values[PrivilegesGroupFields.SIGNALS_EXPORT],
        section_name: PrivilegesGroupFields.SIGNALS_EXPORT
      },
      {
        status: values[PrivilegesGroupFields.APA],
        section_name: PrivilegesGroupFields.APA,
        ...(values[PrivilegesGroupFields.APA] === Status.LIMITED && {
          fields: values['position_administration__fields'] ?? APA_FIELDS
        })
      },
      {
        status: values[PrivilegesGroupFields.ORDERS],
        section_name: PrivilegesGroupFields.ORDERS,
        ...(values[PrivilegesGroupFields.ORDERS] === Status.LIMITED && {
          fields: values['orders__fields'] ?? ORDERS_FIELDS
        })
      },
      {
        status: values[PrivilegesGroupFields.PROFILES_STATS],
        section_name: PrivilegesGroupFields.PROFILES_STATS,
        ...(values[PrivilegesGroupFields.PROFILES_STATS] === Status.LIMITED && {
          fields: values['profiles_stats__fields'] ?? PROFILES_STATS_FIELDS,
          sorting: values['profiles_stats__sorting'] ?? true,
          filtering: values['profiles_stats__filtering'] ?? true
        })
      },
      {
        status: values[PrivilegesGroupFields.MM_PROFILES],
        section_name: PrivilegesGroupFields.MM_PROFILES,
        ...(values[PrivilegesGroupFields.MM_PROFILES] === Status.LIMITED && {
          fields: values['mm_profiles__fields'] ?? MM_PROFILES_FIELDS
        })
      },
      {
        status: values[PrivilegesGroupFields.PROFILES],
        section_name: PrivilegesGroupFields.PROFILES,
        ...(values[PrivilegesGroupFields.PROFILES] === Status.LIMITED && {
          fields: values['profiles__fields'] ?? PROFILES_FIELDS
        })
      },
      {
        status: values[PrivilegesGroupFields.PROFILE_SETTINGS],
        section_name: PrivilegesGroupFields.PROFILE_SETTINGS
      },
      {
        status: values[PrivilegesGroupFields.GROUPS],
        section_name: PrivilegesGroupFields.GROUPS
      },
      {
        status: values[PrivilegesGroupFields.PROFILES_STATS_EXPORT],
        section_name: PrivilegesGroupFields.PROFILES_STATS_EXPORT
      },
      {
        status: values[PrivilegesGroupFields.RISK_CONTROL],
        section_name: PrivilegesGroupFields.RISK_CONTROL
      },
      {
        status: values[PrivilegesGroupFields.TICKERS_CONFIGS],
        section_name: PrivilegesGroupFields.TICKERS_CONFIGS
      },
      {
        status: values[PrivilegesGroupFields.USERS],
        section_name: PrivilegesGroupFields.USERS
      },
      {
        status: values[PrivilegesGroupFields.ACCOUNT_STATS],
        section_name: PrivilegesGroupFields.ACCOUNT_STATS
      },
      {
        status: values[PrivilegesGroupFields.ACCOUNT_STATS_EXPORT],
        section_name: PrivilegesGroupFields.ACCOUNT_STATS_EXPORT
      },
      {
        status: values[PrivilegesGroupFields.STATS_BY_MONTHS],
        section_name: PrivilegesGroupFields.STATS_BY_MONTHS
      },
      {
        status: values[PrivilegesGroupFields.CHARTS],
        section_name: PrivilegesGroupFields.CHARTS
      }
    ]
  };
};

export const createPrivilegesRow = ({ perms, name, id }: GroupsShape) => {
  return {
    name,
    id,
    save: { label: 'Save', color: ButtonColorTypes.PRIMARY },
    delete: { label: 'Delete', color: ButtonColorTypes.SECONDARY },
    ...perms.reduce(
      (acc, val) => ({
        ...acc,
        [val.section_name]: val.status
      }),
      {}
    )
  };
};

export const getAllowedRoutes = (privileges: PrivilegesGroupFormFields): typeof ROUTE_OPTIONS => {
  const isPageForbidden = (pageName: PrivilegesGroupFields) => privileges[pageName] === filterPerPageValues.NP;
  return ROUTE_OPTIONS.map((route) => {
    const isHidePage = (isForbiddenPage: boolean) => (isForbiddenPage ? { ...route, hide: true } : route);
    switch (true) {
      case route.privilegeId === `${PrivilegesGroupFields.PROFILES}${PrivilegesGroupFields.TICKERS_CONFIGS}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.PROFILES) && isPageForbidden(PrivilegesGroupFields.TICKERS_CONFIGS)
        );
      case route.privilegeId === PrivilegesGroupFields.PROFILES:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.PROFILES));
      case route.privilegeId === PrivilegesGroupFields.TICKERS_CONFIGS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.TICKERS_CONFIGS));
      case route.privilegeId === PrivilegesGroupFields.MM_PROFILES:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.MM_PROFILES));
      case route.privilegeId === PrivilegesGroupFields.SIGNALS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.SIGNALS));
      case route.privilegeId === PrivilegesGroupFields.APA:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.APA));
      case route.privilegeId === PrivilegesGroupFields.RISK_CONTROL:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.RISK_CONTROL));
      case route.privilegeId === PrivilegesGroupFields.ORDERS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.ORDERS));
      case route.privilegeId === PrivilegesGroupFields.PROFILES_STATS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.PROFILES_STATS));
      case route.privilegeId === PrivilegesGroupFields.ACCOUNT_STATS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.ACCOUNT_STATS));
      case route.privilegeId === PrivilegesGroupFields.STATS_BY_MONTHS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.STATS_BY_MONTHS));
      case route.privilegeId === PrivilegesGroupFields.CHARTS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.CHARTS));
      case route.privilegeId ===
        `${PrivilegesGroupFields.PROFILES_STATS}${PrivilegesGroupFields.ACCOUNT_STATS}${PrivilegesGroupFields.STATS_BY_MONTHS}${PrivilegesGroupFields.CHARTS}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.ACCOUNT_STATS) &&
            isPageForbidden(PrivilegesGroupFields.PROFILES_STATS) &&
            isPageForbidden(PrivilegesGroupFields.STATS_BY_MONTHS) &&
            isPageForbidden(PrivilegesGroupFields.CHARTS)
        );
      case route.privilegeId === PrivilegesGroupFields.NOTIFICATIONS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.NOTIFICATIONS));
      case route.privilegeId === PrivilegesGroupFields.PROFILE_SETTINGS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.PROFILE_SETTINGS));
      case route.privilegeId === PrivilegesGroupFields.LOGS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.LOGS));
      case route.privilegeId === PrivilegesGroupFields.GROUPS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.GROUPS));
      case route.privilegeId === PrivilegesGroupFields.USERS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.USERS));
      case route.privilegeId ===
        `${PrivilegesGroupFields.EXCHANGE_ACCOUNT}${PrivilegesGroupFields.PAIRS}${PrivilegesGroupFields.TG_BOT}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.EXCHANGE_ACCOUNT) &&
            isPageForbidden(PrivilegesGroupFields.PAIRS) &&
            isPageForbidden(PrivilegesGroupFields.TG_BOT)
        );
      default:
        return route;
    }
  });
};

export const getAsideLinks = (privileges: PrivilegesGroupFormFields): typeof ASIDE_LINKS => {
  const isPageForbidden = (pageName: string) => privileges[pageName] === filterPerPageValues.NP;
  return ASIDE_LINKS.map((link) => {
    const isHidePage = (isForbiddenPage: boolean) => (isForbiddenPage ? { ...link, hide: true } : link);
    switch (true) {
      case link.privilegeId ===
        `${PrivilegesGroupFields.EXCHANGE_ACCOUNT}${PrivilegesGroupFields.PAIRS}${PrivilegesGroupFields.TG_BOT}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.EXCHANGE_ACCOUNT) &&
            isPageForbidden(PrivilegesGroupFields.PAIRS) &&
            isPageForbidden(PrivilegesGroupFields.TG_BOT)
        );
      case link.privilegeId ===
        `${PrivilegesGroupFields.SIGNALS}${PrivilegesGroupFields.ORDERS}${PrivilegesGroupFields.APA}${PrivilegesGroupFields.RISK_CONTROL}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.SIGNALS) &&
            isPageForbidden(PrivilegesGroupFields.ORDERS) &&
            isPageForbidden(PrivilegesGroupFields.APA) &&
            isPageForbidden(PrivilegesGroupFields.RISK_CONTROL)
        );
      case link.privilegeId === `${PrivilegesGroupFields.PROFILES}${PrivilegesGroupFields.TICKERS_CONFIGS}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.PROFILES) && isPageForbidden(PrivilegesGroupFields.TICKERS_CONFIGS)
        );
      case link.privilegeId === PrivilegesGroupFields.MM_PROFILES:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.MM_PROFILES));
      case link.privilegeId ===
        `${PrivilegesGroupFields.PROFILES_STATS}${PrivilegesGroupFields.ACCOUNT_STATS}${PrivilegesGroupFields.STATS_BY_MONTHS}${PrivilegesGroupFields.CHARTS}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.PROFILES_STATS) &&
            isPageForbidden(PrivilegesGroupFields.ACCOUNT_STATS) &&
            isPageForbidden(PrivilegesGroupFields.STATS_BY_MONTHS) &&
            isPageForbidden(PrivilegesGroupFields.CHARTS)
        );
      case link.privilegeId === PrivilegesGroupFields.NOTIFICATIONS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.NOTIFICATIONS));
      case link.privilegeId === PrivilegesGroupFields.LOGS:
        return isHidePage(isPageForbidden(PrivilegesGroupFields.LOGS));
      case link.privilegeId === `${PrivilegesGroupFields.GROUPS}${PrivilegesGroupFields.USERS}`:
        return isHidePage(
          isPageForbidden(PrivilegesGroupFields.GROUPS) && isPageForbidden(PrivilegesGroupFields.USERS)
        );
      default:
        return link;
    }
  });
};

export const getSignalOrderTabs = (privileges: PrivilegesGroupFormFields): typeof SIGNALS_ORDERS_TABS => {
  const isPageForbidden = (pageName: string) => privileges[pageName] === filterPerPageValues.NP;
  return SIGNALS_ORDERS_TABS.filter((tab) => {
    switch (true) {
      case tab.privilegeId === PrivilegesGroupFields.SIGNALS:
        return !isPageForbidden(PrivilegesGroupFields.SIGNALS);
      case tab.privilegeId === PrivilegesGroupFields.ORDERS:
        return !isPageForbidden(PrivilegesGroupFields.ORDERS);
      case tab.privilegeId === PrivilegesGroupFields.APA:
        return !isPageForbidden(PrivilegesGroupFields.APA);
      case tab.privilegeId === PrivilegesGroupFields.RISK_CONTROL:
        return !isPageForbidden(PrivilegesGroupFields.RISK_CONTROL);
      default:
        return true;
    }
  });
};

export const getPrivilegesTabs = (privileges: PrivilegesGroupFormFields): typeof PRIVILEGES_TABS => {
  const isPageForbidden = (pageName: string) => privileges[pageName] === filterPerPageValues.NP;
  return PRIVILEGES_TABS.filter((tab) => {
    switch (true) {
      case tab.privilegeId === PrivilegesGroupFields.GROUPS:
        return !isPageForbidden(PrivilegesGroupFields.GROUPS);
      case tab.privilegeId === PrivilegesGroupFields.USERS:
        return !isPageForbidden(PrivilegesGroupFields.USERS);
      default:
        return true;
    }
  });
};

export const getStatsTabs = (privileges: PrivilegesGroupFormFields): typeof STATS_TABS => {
  const isPageForbidden = (pageName: string) => privileges[pageName] === filterPerPageValues.NP;
  return STATS_TABS.filter((tab) => {
    switch (true) {
      case tab.privilegeId === PrivilegesGroupFields.PROFILES_STATS:
        return !isPageForbidden(PrivilegesGroupFields.PROFILES_STATS);
      case tab.privilegeId === PrivilegesGroupFields.ACCOUNT_STATS:
        return !isPageForbidden(PrivilegesGroupFields.ACCOUNT_STATS);
      case tab.privilegeId === PrivilegesGroupFields.STATS_BY_MONTHS:
        return !isPageForbidden(PrivilegesGroupFields.STATS_BY_MONTHS);
      case tab.privilegeId === PrivilegesGroupFields.CHARTS:
        return !isPageForbidden(PrivilegesGroupFields.CHARTS);
      default:
        return true;
    }
  });
};

export const getProfileTabs = (privileges: PrivilegesGroupFormFields): typeof STRATEGY_TABS => {
  const isPageForbidden = (pageName: string) => privileges[pageName] === filterPerPageValues.NP;
  return STRATEGY_TABS.filter((tab) => {
    switch (true) {
      case tab.privilegeId === PrivilegesGroupFields.PROFILES:
        return !isPageForbidden(PrivilegesGroupFields.PROFILES);
      case tab.privilegeId === PrivilegesGroupFields.TICKERS_CONFIGS:
        return !isPageForbidden(PrivilegesGroupFields.TICKERS_CONFIGS);
      default:
        return true;
    }
  });
};

export const getAllowedSignalsOrdersRoute = (
  signals?: Status,
  orders?: Status,
  position_administration?: Status,
  risk_control?: Status
): string | null => {
  const routes = [
    { allowedToShow: isSectionAllowed(signals), route: PAGES.SIGNALS_ORDERS_SIGNALS },
    { allowedToShow: isSectionAllowed(orders), route: PAGES.SIGNALS_ORDERS_ORDERS },
    { allowedToShow: isSectionAllowed(position_administration), route: PAGES.SIGNALS_ORDERS_ADMINISTRATION },
    { allowedToShow: isSectionAllowed(risk_control), route: PAGES.SIGNALS_ORDERS_RISK_CONTROL },
    { allowedToShow: true, route: PAGES.DEFAULT }
  ];
  if (signals && orders && position_administration && risk_control) {
    const [route] = routes.filter((route) => route.allowedToShow);
    return route.route;
  }
  return null;
};

export const getAllowedPrivilegesRoute = (groups?: Status, users?: Status): string | null => {
  const routes = [
    { allowedToShow: isSectionAllowed(groups), route: PAGES.PRIVILEGES_GROUPS },
    { allowedToShow: isSectionAllowed(users), route: PAGES.PRIVILEGES_USER_MANAGEMENT },
    { allowedToShow: true, route: PAGES.DEFAULT }
  ];
  if (groups && users) {
    const [route] = routes.filter((route) => route.allowedToShow);
    return route.route;
  }
  return null;
};

export const getAllowedStatsRoute = (
  profileStats?: Status,
  accountStats?: Status,
  statsByMonths?: Status,
  chartsStats?: Status
): string | null => {
  const routes = [
    { allowedToShow: isSectionAllowed(profileStats), route: PAGES.STATS_STRATEGY_STATS },
    { allowedToShow: isSectionAllowed(accountStats), route: PAGES.STATS_ACCOUNT_STATS },
    { allowedToShow: isSectionAllowed(statsByMonths), route: PAGES.STATS_MONTHS_STATS },
    { allowedToShow: isSectionAllowed(chartsStats), route: PAGES.STATS_CHARTS_STATS },
    { allowedToShow: true, route: PAGES.DEFAULT }
  ];
  if (profileStats && accountStats && statsByMonths && chartsStats) {
    const [route] = routes.filter((route) => route.allowedToShow);
    return route.route;
  }
  return null;
};

export const getAllowedProfileRoute = (profiles?: Status, tickers_configs?: Status): string | null => {
  const routes = [
    { allowedToShow: isSectionAllowed(profiles), route: PAGES.STRATEGY_PROFILES_STRATEGY },
    { allowedToShow: isSectionAllowed(tickers_configs), route: PAGES.STRATEGY_PROFILES_TICKERS },
    { allowedToShow: true, route: PAGES.DEFAULT }
  ];
  if (profiles && tickers_configs) {
    const [route] = routes.filter((route) => route.allowedToShow);
    return route.route;
  }
  return null;
};

export const isSectionAllowed = (privilege: string): boolean => privilege !== filterPerPageValues.NP;
export const isSectionForbidden = (privilege: string): boolean => privilege === filterPerPageValues.NP;
export const isSectionReadOnly = (privilege: string): boolean => privilege === filterPerPageValues.READ_ONLY;
export const isSectionLimited = (privilege: string): boolean => privilege === filterPerPageValues.LIMITED;
export const isSectionFull = (privilege: string): boolean => privilege === filterPerPageValues.FULL;
