import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getAllowedSignalsOrdersRoute } from '../../helpers/privilegesHelpers';
import { Loader } from '../../components/loader/Loader';

export default function SignalsOrdersPage(): JSX.Element {
  const { signals, orders, position_administration, risk_control } = usePrivileges();
  const route = getAllowedSignalsOrdersRoute(signals, orders, position_administration, risk_control);

  return route ? <Redirect to={route} /> : <Loader />;
}
