import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import NumberFormatField from '../../fields/numberFormatField/NumberFormatField';
import OutlinedSelect from '../../selects/outlinedSelect/OutlinedSelect';
import PairedBtnBlock from '../../buttons/pairedBtnBlock/PairedBtnBlock';
import {
  FormKeys,
  FormValues,
  initialValues,
  TriggerTypes,
  options,
  validationSchema
} from '../../../constants/riskControlConstants';
import s from '../blockerFrom/BlockerForm.module.scss';
import { getTriggerFormValues, ReqValues } from '../../../helpers/signalOrdersHelpers';

interface AddTriggerFormProps {
  onClose: () => void;
  handleSubmit: (values: ReqValues) => void;
}

const AddTriggerForm: FC<AddTriggerFormProps> = ({ onClose, handleSubmit }) => {
  return (
    <div className={s.blockerContainer}>
      <h2 className="default-title">Add Trigger Form</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: FormValues) => {
          handleSubmit(getTriggerFormValues(values));
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setValues, setFieldTouched }) => {
          const onKindChange = (e: React.ChangeEvent<{ value: unknown }>) => {
            if (e.target.value === TriggerTypes.NAV_ALERTING) {
              setValues({
                ...values,
                [FormKeys.POSITION_REDUCE]: '',
                [FormKeys.X_PERCENT_DEPO]: '',
                [FormKeys.Y_PERCENT_DEPO]: ''
              });
              setFieldTouched(FormKeys.POSITION_REDUCE, false);
              setFieldTouched(FormKeys.X_PERCENT_DEPO, false);
              setFieldTouched(FormKeys.Y_PERCENT_DEPO, false);
            }
            handleChange(e);
          };
          return (
            <Form className={s.form}>
              <OutlinedSelect
                id={FormKeys.TRIGGER_TYPE}
                options={options}
                value={values[FormKeys.TRIGGER_TYPE]}
                onChange={onKindChange}
                onBlur={handleBlur}
                label="Type"
                isError={!!errors[FormKeys.TRIGGER_TYPE] && touched[FormKeys.TRIGGER_TYPE]}
                errorMsg={touched[FormKeys.TRIGGER_TYPE] && errors[FormKeys.TRIGGER_TYPE]}
                formControlClass={s.selectRoot}
              />
              <NumberFormatField
                inputProps={{
                  id: FormKeys.TRIGGER_BLOCKER,
                  label: 'Trigger',
                  placeholder: '1-100',
                  value: values[FormKeys.TRIGGER_BLOCKER],
                  isError: !!errors[FormKeys.TRIGGER_BLOCKER] && touched[FormKeys.TRIGGER_BLOCKER],
                  helperText: touched[FormKeys.TRIGGER_BLOCKER] && errors[FormKeys.TRIGGER_BLOCKER],
                  isValid: touched[FormKeys.TRIGGER_BLOCKER] && !errors[FormKeys.TRIGGER_BLOCKER],
                  onBlur: handleBlur,
                  onChange: handleChange
                }}
              />
              <NumberFormatField
                inputProps={{
                  id: FormKeys.TIMER,
                  label: 'Timer',
                  placeholder: '1-100',
                  value: values[FormKeys.TIMER],
                  isError: !!errors[FormKeys.TIMER] && touched[FormKeys.TIMER],
                  helperText: touched[FormKeys.TIMER] && errors[FormKeys.TIMER],
                  isValid: touched[FormKeys.TIMER] && !errors[FormKeys.TIMER],
                  onBlur: handleBlur,
                  onChange: handleChange
                }}
              />
              {values[FormKeys.TRIGGER_TYPE] === TriggerTypes.NAV_REDUCING && (
                <NumberFormatField
                  inputProps={{
                    id: FormKeys.POSITION_REDUCE,
                    label: 'P-red%',
                    placeholder: '1-100',
                    value: values[FormKeys.POSITION_REDUCE],
                    isError: !!errors[FormKeys.POSITION_REDUCE] && touched[FormKeys.POSITION_REDUCE],
                    helperText: touched[FormKeys.POSITION_REDUCE] && errors[FormKeys.POSITION_REDUCE],
                    isValid: touched[FormKeys.POSITION_REDUCE] && !errors[FormKeys.POSITION_REDUCE],
                    onBlur: handleBlur,
                    onChange: handleChange
                  }}
                />
              )}
              {values[FormKeys.TRIGGER_TYPE] === TriggerTypes.NAV_REDUCING && (
                <NumberFormatField
                  inputProps={{
                    id: FormKeys.X_PERCENT_DEPO,
                    label: 'x%depo',
                    placeholder: '1-100',
                    value: values[FormKeys.X_PERCENT_DEPO],
                    isError: !!errors[FormKeys.X_PERCENT_DEPO] && touched[FormKeys.X_PERCENT_DEPO],
                    helperText: touched[FormKeys.X_PERCENT_DEPO] && errors[FormKeys.X_PERCENT_DEPO],
                    isValid: touched[FormKeys.X_PERCENT_DEPO] && !errors[FormKeys.X_PERCENT_DEPO],
                    onBlur: handleBlur,
                    onChange: handleChange
                  }}
                />
              )}
              {values[FormKeys.TRIGGER_TYPE] === TriggerTypes.NAV_REDUCING && (
                <NumberFormatField
                  inputProps={{
                    id: FormKeys.Y_PERCENT_DEPO,
                    label: 'y%depo',
                    placeholder: '1-100',
                    value: values[FormKeys.Y_PERCENT_DEPO],
                    isError: !!errors[FormKeys.Y_PERCENT_DEPO] && touched[FormKeys.Y_PERCENT_DEPO],
                    helperText: touched[FormKeys.Y_PERCENT_DEPO] && errors[FormKeys.Y_PERCENT_DEPO],
                    isValid: touched[FormKeys.Y_PERCENT_DEPO] && !errors[FormKeys.Y_PERCENT_DEPO],
                    onBlur: handleBlur,
                    onChange: handleChange
                  }}
                />
              )}
              <PairedBtnBlock
                onCancel={onClose}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddTriggerForm;
