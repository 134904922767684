import React, { useMemo } from 'react';
import { Form, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import CustomizedTextField from 'components/fields/customizedTextField/CustomizedTextField';
import NumberFormatField from 'components/fields/numberFormatField/NumberFormatField';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import OutlinedSelect from 'components/selects/outlinedSelect/OutlinedSelect';
import { systemSettingsSelector } from 'redux/selectors';
import { MMProfilesAddFields, MMProfilesAddFormValues } from 'constants/mmProfilesConstants';
import s from './MMProfilesAddPage.module.scss';

interface MMProfilesFormProps {
  reqErr: null | string;
  onClearRequestError: () => void;
  onBackToProfiles: () => void;
}

const MMProfilesAddForm = ({ onBackToProfiles, onClearRequestError, reqErr }: MMProfilesFormProps): JSX.Element => {
  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormikContext<MMProfilesAddFormValues>();

  const { exchangeAccounts } = useSelector(systemSettingsSelector);
  const selectOptions = useMemo(
    () => exchangeAccounts.map(({ id, title }) => ({ value: id.toString(), label: title })),
    [exchangeAccounts]
  );

  return (
    <Form className={s.form}>
      <CustomizedTextField
        id={MMProfilesAddFields.TITLE}
        label="MM Profile name"
        placeholder="MM Profile name"
        value={values.title}
        isError={(!!errors.title && touched.title) || !!reqErr}
        isValid={!!touched.title && !errors.title && !reqErr}
        helperText={touched.title && errors.title}
        onBlur={handleBlur}
        onChange={handleChange}
        onClearRequestError={onClearRequestError}
        inputClass={s.input}
      />

      <NumberFormatField
        inputProps={{
          id: MMProfilesAddFields.MAX_AMOUNT,
          label: 'Max signals',
          placeholder: '1000',
          value: values.max_signals_amount,
          isError: (!!errors.max_signals_amount && touched.max_signals_amount) || !!reqErr,
          isValid: !!touched.max_signals_amount && !errors.max_signals_amount && !reqErr,
          helperText: touched.max_signals_amount && errors.max_signals_amount,
          inputClass: s.input,
          onBlur: handleBlur,
          onChange: handleChange,
          onClearRequestError
        }}
      />

      <NumberFormatField
        inputProps={{
          id: MMProfilesAddFields.MAX_FACTOR,
          label: 'Max factor',
          placeholder: '1000',
          value: values[MMProfilesAddFields.MAX_FACTOR],
          isError: (!!errors[MMProfilesAddFields.MAX_FACTOR] && touched[MMProfilesAddFields.MAX_FACTOR]) || !!reqErr,
          isValid: !!touched[MMProfilesAddFields.MAX_FACTOR] && !errors[MMProfilesAddFields.MAX_FACTOR] && !reqErr,
          helperText: touched[MMProfilesAddFields.MAX_FACTOR] && errors[MMProfilesAddFields.MAX_FACTOR],
          inputClass: s.input,
          onBlur: handleBlur,
          onChange: handleChange,
          onClearRequestError
        }}
      />

      <NumberFormatField
        inputProps={{
          id: MMProfilesAddFields.QUOTA,
          label: 'All signals quota($)',
          placeholder: '1000',
          value: values.all_signals_quota,
          isError: (!!errors.all_signals_quota && touched.all_signals_quota) || !!reqErr,
          isValid: !!touched.all_signals_quota && !errors.all_signals_quota && !reqErr,
          helperText: touched.all_signals_quota && errors.all_signals_quota,
          inputClass: s.input,
          onBlur: handleBlur,
          onChange: handleChange,
          onClearRequestError
        }}
      />

      <OutlinedSelect
        id={MMProfilesAddFields.EXCHANGE_ACC}
        options={selectOptions}
        value={values.exchange_account}
        onChange={handleChange}
        label="Exchange acc name"
        isError={(touched.exchange_account && !!errors.exchange_account) || !!reqErr}
        errorMsg={touched.exchange_account && errors.exchange_account}
        formControlClass={s.selectRoot}
      />

      <PairedBtnBlock
        onCancel={onBackToProfiles}
        onSubmit={handleSubmit}
        btnTypeSubmit="submit"
        labels={{ cancelLabel: 'Cancel', submitLabel: 'Save' }}
      />
    </Form>
  );
};

export default MMProfilesAddForm;
