import React, { useReducer, useEffect, useCallback, useMemo } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'constants/routes';
import {
  createProfile,
  updateProfile,
  getProfile,
  updateStrategySettings
} from 'redux/actions/strategyProfilesActions';
import { strategyRootSelector } from 'redux/selectors';
import MainLayout from 'components/mainLayout/MainLayout';
import ProfilesSettings from 'components/strategyProfileComponents/profilesSettings/ProfilesSettings';
import KeyParameters from 'components/strategyProfileComponents/keyParameters/KeyParameters';
import OrderManagement from 'components/strategyProfileComponents/orderManagement/OrderManagement';
import Summary from 'components/strategyProfileComponents/summary/Summary';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import {
  StrategyStepsIdx,
  STRATEGY_STEPS,
  STRATEGY_SCHEMA_TYPE,
  StrategySettingsFormTypes,
  TemplateContext
} from 'constants/strategyProfilesAddConstants';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import {
  ProfilesSettingsValues,
  StrategySettings,
  StrategyProfileStatuses,
  StrategyProfileShape,
  UpdateStrategyData,
  KeyParametersShape,
  OrderManagementSettings,
  IndicationsSettings
} from 'interfaces/strategyProfilesShapes';
import { getErrorMessageHelper } from 'helpers/randomHelpers';
import {
  getCreateProfileReqData,
  strategyPageInitial,
  strategyTypes,
  strategyPageReducer,
  getCurrentDraftStep,
  getInitialStep
} from 'helpers/strategyProfilesAddHelpers';
import PuffLoader from 'react-spinners/PuffLoader';
import Fade from 'components/reactTransitions/FadeTransition.module.css';
import s from './StrategyProfilesAddPage.module.scss';
import IndicationSettings from 'components/strategyProfileComponents/indicationsSettings/IndicationSettings';

interface HistoryStateShape {
  push: (path: string, state?) => void;
  location: {
    state: { profileId?: number };
  };
}

const LOADER_SIZE = 100;

const StrategyProfilesAddPage = (): JSX.Element => {
  const {
    push,
    location: { state }
  } = useHistory() as HistoryStateShape;
  const dispatch = useDispatch();
  const { profile, profileTemplate } = useSelector(strategyRootSelector);

  const [isTemplated, setTemplates] = React.useState(false);
  const handleTemplated = (isProfileTemplated: boolean) => setTemplates(isProfileTemplated);

  const [{ activeStep, errors, isLoading }, tinyDispatch] = useReducer(
    strategyPageReducer,
    strategyPageInitial,
    (initialData) => getInitialStep(initialData, state?.profileId)
  );

  const onNextStep = () => {
    if (activeStep < STRATEGY_STEPS.length - 1) {
      tinyDispatch({ type: strategyTypes.NEXT_STEP });
      handleTemplated(false);
    }
  };
  const onPrevStep = () => {
    if (activeStep > 0) {
      tinyDispatch({ type: strategyTypes.PREVIOUS_STEP });
      handleTemplated(false);
    }
  };

  const onClearRequestErrors = () => tinyDispatch({ type: strategyTypes.CLEAR_ERRORS });
  const onSetError = useCallback((err: ErrorShape, type: string) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) tinyDispatch({ type, payload: errorMsg });
  }, []);

  const onSubmitProfiles = (data: ProfilesSettingsValues) => {
    const reqData = getCreateProfileReqData(data);
    const onError = (err) => onSetError(err, strategyTypes.SET_PROFILE_ERR);

    if (state?.profileId || profile?.id) {
      dispatch(updateProfile(state?.profileId ?? profile?.id, reqData, onNextStep, onError));
      return;
    }

    dispatch(createProfile(reqData, onNextStep, onError));
  };

  const onUpdateStrategySettings = (
    settings: KeyParametersShape | OrderManagementSettings | IndicationsSettings,
    type: StrategySettingsFormTypes
  ) => {
    console.log(settings, type);

    const signalSettings =
      type === StrategySettingsFormTypes.S_SETTINGS ? settings : profile?.settings?.signal_settings;
    const orderSettings =
      type === StrategySettingsFormTypes.O_SETTINGS ? settings : profile?.settings?.order_management_settings;
    const indicationSettings =
      type === StrategySettingsFormTypes.I_SETTINGS ? settings : profile?.settings?.indications_settings;

    const reqData: Partial<UpdateStrategyData> = {
      schema_type: STRATEGY_SCHEMA_TYPE.V1,
      settings: {
        signal_settings: signalSettings,
        order_management_settings: orderSettings,
        indications_settings: indicationSettings,
        deviation_settings: {
          limit_funding_rate: 0.5,
          limit_price_deviation: 0.5
        }
      } as Partial<StrategySettings>
    };
    const onError = (err) => onSetError(err, strategyTypes.SET_SETTINGS_ERR);
    const onSuccess = () => onGetProfile(profile?.id, onError, onNextStep);

    dispatch(updateStrategySettings(profile?.id, reqData, onSuccess, onError));
  };

  const onSetCurrentDraftStep = (data: StrategyProfileShape) => {
    tinyDispatch({ type: strategyTypes.SET_CURRENT_STEP, payload: getCurrentDraftStep(data) });
  };

  const onGetProfile = useCallback(
    (profileId: number, onError: (err) => void, onSuccess: (data?) => void) =>
      dispatch(getProfile(profileId, false, onError, onSuccess)),
    [dispatch]
  );

  useEffect(() => {
    if (state?.profileId) {
      onGetProfile(state.profileId, (err) => onSetError(err, strategyTypes.SET_PROFILE_ERR), onSetCurrentDraftStep);
    }
  }, [state, dispatch, onSetError, onGetProfile]);

  const onSubmitSummarySuccess = () => push(PAGES.STRATEGY_PROFILES);

  useEffect(() => {
    if (profile?.template && +profile?.template !== profileTemplate?.id) {
      dispatch(
        getProfile(
          +profile?.template,
          true,
          () => null,
          () => null
        )
      );
    }
  }, [dispatch, profile?.template, profileTemplate?.id]);

  const showMessage = useMemo(() => {
    return profile?.template
      ? activeStep === StrategyStepsIdx.KEY_PARAMETERS || activeStep === StrategyStepsIdx.ORDER_MANAGEMENT
      : false;
  }, [profile?.template, activeStep]);

  return (
    <MainLayout contentCustomClass={s.content}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          unmountOnExit
          key={isLoading ? 'loader' : 'activeStep'}
          timeout={DEFAULT_TRANSITION_TIMEOUT}
          classNames={Fade}
        >
          {isLoading ? (
            <div className={s.loaderWrapper}>
              <PuffLoader loading size={LOADER_SIZE} />
            </div>
          ) : (
            <TemplateContext.Provider value={{ isTemplated, handleTemplated, showMessage }}>
              <SwitchTransition mode="out-in">
                <CSSTransition unmountOnExit key={activeStep} timeout={DEFAULT_TRANSITION_TIMEOUT} classNames={Fade}>
                  {() => {
                    switch (activeStep) {
                      case StrategyStepsIdx.KEY_PARAMETERS:
                        return (
                          <KeyParameters
                            profile={isTemplated ? profileTemplate : profile}
                            onUpdateKeyParameters={onUpdateStrategySettings}
                            onClearRequestErrors={onClearRequestErrors}
                            onCancelParameters={onPrevStep}
                            errors={errors}
                          />
                        );

                      case StrategyStepsIdx.ORDER_MANAGEMENT:
                        return (
                          <OrderManagement
                            profile={isTemplated ? profileTemplate : profile}
                            onUpdateOrderManagement={onUpdateStrategySettings}
                            onClearRequestErrors={onClearRequestErrors}
                            onCancel={onPrevStep}
                            errors={errors}
                            classes={{ contentWrapperClass: s.modalContentInner }}
                          />
                        );

                      case StrategyStepsIdx.INDICATIONS:
                        return (
                          <IndicationSettings
                            profile={isTemplated ? profileTemplate : profile}
                            onSubmitIndications={onUpdateStrategySettings}
                            onClearRequestErrors={onClearRequestErrors}
                            onCancel={onPrevStep}
                            errors={errors}
                            contentWrapperClass={s.modalContentInner}
                          />
                        );

                      case StrategyStepsIdx.SUMMARY:
                        return (
                          <Summary
                            onCancel={onPrevStep}
                            onSubmitSummarySuccess={onSubmitSummarySuccess}
                            submitProfileStatus={StrategyProfileStatuses.INACTIVE}
                          />
                        );

                      default:
                        return (
                          <ProfilesSettings
                            profile={profile}
                            onSubmitProfiles={onSubmitProfiles}
                            onCancelProfileSetting={() => push(PAGES.STRATEGY_PROFILES)}
                            onClearRequestErrors={onClearRequestErrors}
                            onSetError={onSetError}
                            errors={errors}
                          />
                        );
                    }
                  }}
                </CSSTransition>
              </SwitchTransition>
            </TemplateContext.Provider>
          )}
        </CSSTransition>
      </SwitchTransition>
    </MainLayout>
  );
};

export default StrategyProfilesAddPage;
