import React, { FC, useMemo } from 'react';
import s from './AccountStatsFilters.module.scss';
import { customizeFilter } from '../../../helpers/signalsStatsHelpers';
import {
  initialAccountStatsValues,
  InitialAccountStatsValuesType,
  ACCOUNT_STAT_DATE,
  ACCOUNT_STAT_FILTERS,
  AccountStatsFilterIDs
} from '../../../constants/accountStatsConstants';
import { GetStrategyStatsQuery, Profiles } from '../../../interfaces/strategyStatsShapes';
import { Button, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import { ButtonColorTypes } from '../../../constants/randomConstants';

interface AccountStatsFiltersProps {
  disabledFilter: boolean;
  filterParams: typeof initialAccountStatsValues;
  reqParams: GetStrategyStatsQuery;
  profiles: Profiles[];
  setReqParams: (params: GetStrategyStatsQuery) => void;
  setFilterParams: (params: InitialAccountStatsValuesType) => void;
  setDisabledFilter: (shouldDisable: boolean) => void;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 300
    }
  }
};

const AccountStatsFilters: FC<AccountStatsFiltersProps> = ({
  disabledFilter,
  setDisabledFilter,
  setFilterParams,
  filterParams,
  setReqParams,
  reqParams,
  profiles
}) => {
  const filters = useMemo(() => customizeFilter(profiles, ACCOUNT_STAT_FILTERS), [profiles]);
  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;

    const updatedFilterParams = {
      ...filterParams,
      page: 1,
      [name]: event.target.value
    };
    setFilterParams(updatedFilterParams);
    setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
  };

  return (
    <div className={s.filterWrapper}>
      {filters.map((filter) => {
        return (
          <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
            <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
              {filter.label}
            </InputLabel>
            <Select
              name={filter.id}
              displayEmpty
              value={filterParams[filter.id]}
              onChange={handleFilterChange}
              MenuProps={MenuProps}
            >
              {filter.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
      {ACCOUNT_STAT_DATE.map((filter) => (
        <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
          <InputLabel shrink htmlFor={AccountStatsFilterIDs.AFTER_CREATING} className={s.filterLabel}>
            {filter.label}
          </InputLabel>
          <TextField
            onChange={handleFilterChange}
            value={filterParams[filter.value]}
            id="date"
            type="date"
            name={filter.fieldName}
          />
        </FormControl>
      ))}

      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type="button"
        color={ButtonColorTypes.DEFAULT}
        classes={{ root: s.filterButton }}
        onClick={() => {
          setReqParams(filterParams);
          setDisabledFilter(true);
        }}
        disabled={disabledFilter}
      >
        Apply
      </Button>
    </div>
  );
};

export default AccountStatsFilters;
