import { call, takeLatest, ForkEffect, put, select } from 'redux-saga/effects';
import { privilegesTypes } from '../types';
import * as apiHelpers from './apiHelpers/privilegesApiHelpers';
import * as actions from '../actions/privilegesActions';
import { statePrepareGroups } from '../../helpers/privilegesHelpers';
import { groupSelector, userGroupSelector } from '../selectors';
import { callbackResolver } from '../../helpers/callbackHelpers';
import * as Shapes from '../../interfaces/privilegesShapes';

function* getPrivilegesGroupsWorker({ payload }: Shapes.GetGroupsShape) {
  try {
    const { data } = yield call(apiHelpers.getGroupsRequestHelper);
    const preparedData = statePrepareGroups(data);
    yield put(actions.setGroups(preparedData));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    console.error('getPrivilegesGroupsWorker', error);
  }
}

function* createPrivilegesGroupWorker({ payload }: Shapes.CreatePrivilegesGroupWorker) {
  try {
    yield call(apiHelpers.postGroupsRequestHelper, payload.data);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('createPrivilegesGroupWorker', error);
  }
}

function* updateGroupPrivilegesWorker({ payload }: Shapes.UpdateGroupPrivilegesWorker) {
  try {
    const { id, ...rest } = yield select((state) => groupSelector(state, payload.id));
    yield call(apiHelpers.getGroupRequestHelper, id, rest);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    console.error('updateGroupPrivilegesWorker', error);
  }
}

function* deleteGroupPrivilegesWorker({ payload }: Shapes.DeleteGroupPrivilegesWorker) {
  try {
    yield call(apiHelpers.deleteGroupsRequestHelper, payload.id);
    yield put(actions.removeGroupPrivileges(payload.id));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
  }
}

function* getUsersWorker({ payload }: Shapes.GetUsers) {
  try {
    const { data } = yield call(apiHelpers.getUsersHelper);
    yield put(actions.setUsers(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    console.error('getUsersWorker', error);
  }
}

function* updateUsersWorker({ payload }: Shapes.SaveUserShape) {
  try {
    const group = yield select(userGroupSelector(payload.id));
    yield call(apiHelpers.updateUserHelper, payload.id, group.id);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload?.onError(error);
  }
}

function* createUsersWorker({ payload }: Shapes.CreateUserShape) {
  try {
    const { onSuccess, ...reqData } = payload;
    const { data } = yield call(apiHelpers.createUsersHelper, reqData);
    yield put(actions.createUserSuccess(data));
    callbackResolver(onSuccess);
  } catch (error) {
    payload?.onError(error);
  }
}

function* deleteUsersWorker({ payload }: Shapes.SaveUserShape) {
  try {
    yield call(apiHelpers.deleteUsersHelper, payload.id);
    callbackResolver(payload?.onSuccess);
    yield put(actions.deleteUserSuccess(payload.id));
  } catch (error) {
    payload?.onError(error);
  }
}

export function* privilegesWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(privilegesTypes.GET_GROUPS, getPrivilegesGroupsWorker);
  yield takeLatest(privilegesTypes.CREATE_GROUPS, createPrivilegesGroupWorker);
  yield takeLatest(privilegesTypes.UPDATE_GROUP_PRIVILEGES, updateGroupPrivilegesWorker);
  yield takeLatest(privilegesTypes.DELETE_GROUP_PRIVILEGES, deleteGroupPrivilegesWorker);
  yield takeLatest(privilegesTypes.GET_USERS, getUsersWorker);
  yield takeLatest(privilegesTypes.UPDATE_USER, updateUsersWorker);
  yield takeLatest(privilegesTypes.CREATE_USER, createUsersWorker);
  yield takeLatest(privilegesTypes.DELETE_USER, deleteUsersWorker);
}
