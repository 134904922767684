export function getColorReinvest(value) {
  if (value <= 0) {
    return '#F92B4F';
  } else if (value > 0 && value < 5) {
    return '#FF7908';
  } else if (value >= 5 && value < 8) {
    return '#F6AC1C';
  } else if (value >= 8 && value < 15) {
    return '#30B475';
  } else if (value >= 15) {
    return '#00765A';
  }
}
