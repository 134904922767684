import { GetSignalsQuery } from '../interfaces/signalsOrdersShapes';
import { GetStrategyStatsQuery } from '../interfaces/strategyStatsShapes';

// REUSE ONE!
export const removeEmptyParams = (params: GetSignalsQuery): GetSignalsQuery =>
  Object.entries(params).reduce((acc, [key, value]): GetSignalsQuery => {
    if (value !== '' && value !== undefined) {
      return { ...acc, [key]: value };
    } else {
      return acc;
    }
  }, {});

export const removeEmptyStatsParams = (params: GetStrategyStatsQuery): GetStrategyStatsQuery =>
  Object.entries(params).reduce((acc, [key, value]): GetStrategyStatsQuery => {
    if (value !== '' && value !== undefined) {
      return { ...acc, [key]: value };
    } else {
      return acc;
    }
  }, {});
