export interface StrategyDetailsState {
  isLoading: boolean;
  errors: { profileError: null | string };
  currentTab: number;
}

export enum StrategyDetailsTabs {
  SETTINGS = 0,
  STATISTICS = 1,
  ORDERS = 2,
  SIGNALS = 3
}
