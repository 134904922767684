import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import { StrategyProfileShape, StrategyProfileStatuses } from 'interfaces/strategyProfilesShapes';
import { DYNAMIC_PAGES } from 'constants/routes';
import {
  ACTION_BTN_COLORS,
  ACTION_BTN_LABELS,
  PAIRS_SHOW_IN_SUMMARY,
  StrategyColIds,
  StrategyTableRow
} from 'constants/strategyProfilesConstants';
import s from 'components/tables/strategyProfile/StrategyProfileTable.module.scss';
import { Checkbox, FormControl } from '@material-ui/core';
import { capitalize } from 'lodash';

export const createStrategyRow = ({
  id,
  exchange_account,
  title,
  status,
  mm_profile,
  pairs,
  timeframe,
  profile_direction,
  is_show,
  ticker_configs_name
}: StrategyProfileShape): StrategyTableRow => {
  const isCollapsible = pairs?.length > PAIRS_SHOW_IN_SUMMARY;

  return {
    ticker_configs_name,
    isCollapsible,
    id,
    title,
    exchange_account: exchange_account?.title ?? '-',
    profile_direction: profile_direction ?? '-',
    timeframe: timeframe ?? '-',
    mm_profile: mm_profile?.title ?? '-',
    status,
    is_show,
    action: { label: ACTION_BTN_LABELS[status], color: ACTION_BTN_COLORS[status] }
  };
};

export const strategyRenderSwitch = (
  colId: StrategyColIds,
  row: StrategyTableRow,
  collapseCallback: () => void,
  isCollapseOpen: boolean,
  onAction: (profileId: number, status: StrategyProfileStatuses) => void,
  onVisibility: (id: number, isShow: boolean) => void,
  isCollapsible?: boolean,
  hasAccessToProfSettings?: boolean
): JSX.Element => {
  const value = row[colId];

  switch (colId) {
    case StrategyColIds.STRATEGY_TITLE:
      return hasAccessToProfSettings ? <Link to={DYNAMIC_PAGES.STRATEGY_PROFILE(row.id)}>{value}</Link> : <>{value}</>;

    case StrategyColIds.STATUS:
      return (
        <span
          className={cn(s.statusDefault, {
            [s.statusActive]: row.status === StrategyProfileStatuses.ACTIVE,
            [s.statusInactive]: row.status === StrategyProfileStatuses.INACTIVE
          })}
        >
          {capitalize(String(value))}
        </span>
      );

    case StrategyColIds.ACTION:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={row.action.color}
          onClick={() => onAction(row?.id, row.status)}
          classes={{ root: s.btnDraft, outlinedPrimary: s.btnPrimary }}
        >
          {row.action.label}
        </Button>
      );
    case StrategyColIds.VISIBILITY:
      return (
        <FormControl>
          <Checkbox
            color="primary"
            style={{ transform: 'scale(1.6)' }}
            value={row.is_show}
            checked={row.is_show}
            name={StrategyColIds.VISIBILITY}
            onChange={() => onVisibility(row.id, !row.is_show)}
          />
        </FormControl>
      );

    default:
      return <>{value ?? '-'}</>;
  }
};
