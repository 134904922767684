import { formatISODate } from '../helpers/randomHelpers';

export enum ChartStatsFilterIDs {
  DATA_TYPE = 'data_type',
  CHART_TYPE = 'chart_type',
  DATA_TYPE_2 = 'data_type_2',
  CHART_TYPE_2 = 'chart_type_2',
  DATA_TYPE_3 = 'data_type_3',
  CHART_TYPE_3 = 'chart_type_3',
  TIME_FRAME = 'tf',
  BEFORE_CREATING = 'created_at_before',
  AFTER_CREATING = 'created_at_after',
  SEPARATED = 'separated'
}

export const ChartsStatsLabels = {
  [ChartStatsFilterIDs.DATA_TYPE]: 'Data type',
  [ChartStatsFilterIDs.CHART_TYPE]: 'Chart type',
  [ChartStatsFilterIDs.DATA_TYPE_2]: 'Data type 2',
  [ChartStatsFilterIDs.CHART_TYPE_2]: 'Chart type 2',
  [ChartStatsFilterIDs.DATA_TYPE_3]: 'Data type 3',
  [ChartStatsFilterIDs.CHART_TYPE_3]: 'Chart type 3',
  [ChartStatsFilterIDs.TIME_FRAME]: 'Time frame',
  [ChartStatsFilterIDs.AFTER_CREATING]: 'From',
  [ChartStatsFilterIDs.BEFORE_CREATING]: 'Until',
  [ChartStatsFilterIDs.SEPARATED]: 'Separated'
};

export enum FilterChartTypeValues {
  NAV = 'nav',
  EQUITY = 'equity',
  IMP_LOSS = 'imp_loss',
  VAR = 'var'
}

export const FilterChartTypeLabels = {
  [FilterChartTypeValues.NAV]: 'NAV Chart',
  [FilterChartTypeValues.EQUITY]: 'Equity Chart',
  [FilterChartTypeValues.IMP_LOSS]: 'Imp.Loss Chart',
  [FilterChartTypeValues.VAR]: 'VAR Chart'
};

export enum FilterChartValues {
  CANDLES = 'candles',
  LINE = 'line',
  COLUMN = 'column'
}

export const FilterChartLabels = {
  [FilterChartValues.CANDLES]: 'Candles',
  [FilterChartValues.LINE]: 'Line',
  [FilterChartValues.COLUMN]: 'Column'
};

export enum FilterTimeFrameValues {
  TF_1m = '1m',
  TF_1H = '1h',
  TF_1D = '1d',
  TF_1W = '1w',
  TF_1M = '1M'
}

export const FilterTimeFrameMap = {
  [FilterTimeFrameValues.TF_1m]: 'dd.MM.yy HH:mm',
  [FilterTimeFrameValues.TF_1H]: 'dd.MM.yy HH:mm',
  [FilterTimeFrameValues.TF_1D]: 'dd.MM.yy',
  [FilterTimeFrameValues.TF_1W]: 'dd.MM.yy',
  [FilterTimeFrameValues.TF_1M]: 'dd.MM.yy'
};

export const FilterTimeFrameLabels = {
  [FilterTimeFrameValues.TF_1m]: '1 minute',
  [FilterTimeFrameValues.TF_1H]: '1 hour',
  [FilterTimeFrameValues.TF_1D]: '1 day',
  [FilterTimeFrameValues.TF_1W]: '1 week',
  [FilterTimeFrameValues.TF_1M]: '1 month'
};

const subtractSixMonths = (date: Date) => {
  date.setMonth(date.getMonth() - 6);
  return date;
};

export const SeparationValues = {
  separated: 'true',
  unseparated: 'false'
};

export const FilterBeforeUntilValues = {
  before: formatISODate(new Date().toISOString(), `yyyy-MM-dd'T'HH:mm`),
  until: formatISODate(subtractSixMonths(new Date()).toISOString(), `yyyy-MM-dd'T'HH:mm`)
};

export const OPTION_WHEN_EQUITY = [
  { value: FilterChartValues.CANDLES, label: FilterChartLabels[FilterChartValues.CANDLES] },
  { value: FilterChartValues.LINE, label: FilterChartLabels[FilterChartValues.LINE] }
];

export const OPTIONS = [
  { value: FilterChartValues.CANDLES, label: FilterChartLabels[FilterChartValues.CANDLES] },
  { value: FilterChartValues.LINE, label: FilterChartLabels[FilterChartValues.LINE] },
  { value: FilterChartValues.COLUMN, label: FilterChartLabels[FilterChartValues.COLUMN] }
];

export const CHART_STATS_FILTERS = [
  {
    id: ChartStatsFilterIDs.DATA_TYPE,
    label: ChartsStatsLabels[ChartStatsFilterIDs.DATA_TYPE],
    options: [
      { value: FilterChartTypeValues.NAV, label: FilterChartTypeLabels[FilterChartTypeValues.NAV] },
      { value: FilterChartTypeValues.EQUITY, label: FilterChartTypeLabels[FilterChartTypeValues.EQUITY] },
      { value: FilterChartTypeValues.IMP_LOSS, label: FilterChartTypeLabels[FilterChartTypeValues.IMP_LOSS] },
      { value: FilterChartTypeValues.VAR, label: FilterChartTypeLabels[FilterChartTypeValues.VAR] }
    ]
  },
  {
    id: ChartStatsFilterIDs.CHART_TYPE,
    label: ChartsStatsLabels[ChartStatsFilterIDs.CHART_TYPE],
    options: OPTIONS
  }
];

export const CHART_STATS_FILTERS_SECOND = [
  {
    id: ChartStatsFilterIDs.DATA_TYPE_2,
    label: ChartsStatsLabels[ChartStatsFilterIDs.DATA_TYPE_2],
    options: [
      { value: FilterChartTypeValues.NAV, label: FilterChartTypeLabels[FilterChartTypeValues.NAV] },
      { value: FilterChartTypeValues.EQUITY, label: FilterChartTypeLabels[FilterChartTypeValues.EQUITY] },
      { value: FilterChartTypeValues.IMP_LOSS, label: FilterChartTypeLabels[FilterChartTypeValues.IMP_LOSS] },
      { value: FilterChartTypeValues.VAR, label: FilterChartTypeLabels[FilterChartTypeValues.VAR] }
    ]
  },
  {
    id: ChartStatsFilterIDs.CHART_TYPE_2,
    label: ChartsStatsLabels[ChartStatsFilterIDs.CHART_TYPE_2],
    options: OPTIONS
  }
];

export type DefaultFilters = typeof CHART_STATS_FILTERS;

export const DEFAULT_CHARTS_FILTERS = CHART_STATS_FILTERS;

export const CHART_STATS_OTHER_FILTERS = [
  {
    id: ChartStatsFilterIDs.TIME_FRAME,
    label: ChartsStatsLabels[ChartStatsFilterIDs.TIME_FRAME],
    options: [
      { value: FilterTimeFrameValues.TF_1m, label: FilterTimeFrameLabels[FilterTimeFrameValues.TF_1m] },
      { value: FilterTimeFrameValues.TF_1D, label: FilterTimeFrameLabels[FilterTimeFrameValues.TF_1D] },
      { value: FilterTimeFrameValues.TF_1H, label: FilterTimeFrameLabels[FilterTimeFrameValues.TF_1H] },
      { value: FilterTimeFrameValues.TF_1W, label: FilterTimeFrameLabels[FilterTimeFrameValues.TF_1W] },
      { value: FilterTimeFrameValues.TF_1M, label: FilterTimeFrameLabels[FilterTimeFrameValues.TF_1M] }
    ]
  }
];

export const CHART_STATS_DATE = [
  {
    id: ChartStatsFilterIDs.AFTER_CREATING,
    label: ChartsStatsLabels[ChartStatsFilterIDs.AFTER_CREATING],
    fieldName: ChartStatsFilterIDs.AFTER_CREATING,
    value: ChartStatsFilterIDs.AFTER_CREATING
  },
  {
    id: ChartStatsFilterIDs.BEFORE_CREATING,
    label: ChartsStatsLabels[ChartStatsFilterIDs.BEFORE_CREATING],
    fieldName: ChartStatsFilterIDs.BEFORE_CREATING,
    value: ChartStatsFilterIDs.BEFORE_CREATING
  }
];

export enum ChartsSelectEnum {
  One = '1',
  Two = '2'
}

export const CHARTS_AMOUNT_OPTIONS = [
  { value: ChartsSelectEnum.One, label: '1 chart' },
  { value: ChartsSelectEnum.Two, label: '2 charts' }
];

export const initialChartValues = {
  [ChartStatsFilterIDs.DATA_TYPE]: FilterChartTypeValues.EQUITY,
  [ChartStatsFilterIDs.CHART_TYPE]: FilterChartValues.LINE,
  [ChartStatsFilterIDs.DATA_TYPE_2]: null as FilterChartTypeValues | null,
  [ChartStatsFilterIDs.CHART_TYPE_2]: null as FilterChartValues | null,
  [ChartStatsFilterIDs.TIME_FRAME]: FilterTimeFrameValues.TF_1D,
  [ChartStatsFilterIDs.BEFORE_CREATING]: FilterBeforeUntilValues.before,
  [ChartStatsFilterIDs.AFTER_CREATING]: FilterBeforeUntilValues.until,
  [ChartStatsFilterIDs.SEPARATED]: SeparationValues.unseparated
};

export const DEFAULT_CHART_PARAMS = {
  [ChartStatsFilterIDs.DATA_TYPE]: initialChartValues[ChartStatsFilterIDs.DATA_TYPE],
  [ChartStatsFilterIDs.CHART_TYPE]: initialChartValues[ChartStatsFilterIDs.CHART_TYPE],
  [ChartStatsFilterIDs.DATA_TYPE_2]: initialChartValues[ChartStatsFilterIDs.DATA_TYPE_2],
  [ChartStatsFilterIDs.CHART_TYPE_2]: initialChartValues[ChartStatsFilterIDs.CHART_TYPE_2],
  [ChartStatsFilterIDs.TIME_FRAME]: initialChartValues[ChartStatsFilterIDs.TIME_FRAME],
  [ChartStatsFilterIDs.BEFORE_CREATING]: initialChartValues[ChartStatsFilterIDs.BEFORE_CREATING],
  [ChartStatsFilterIDs.AFTER_CREATING]: initialChartValues[ChartStatsFilterIDs.AFTER_CREATING],
  [ChartStatsFilterIDs.SEPARATED]: initialChartValues[ChartStatsFilterIDs.SEPARATED]
};

export const initialChartIframeValues = {
  [ChartStatsFilterIDs.DATA_TYPE]: FilterChartTypeValues.EQUITY,
  [ChartStatsFilterIDs.CHART_TYPE]: FilterChartValues.LINE,
  [ChartStatsFilterIDs.DATA_TYPE_2]: null as FilterChartTypeValues | null,
  [ChartStatsFilterIDs.CHART_TYPE_2]: null as FilterChartValues | null,
  [ChartStatsFilterIDs.TIME_FRAME]: FilterTimeFrameValues.TF_1D,
  [ChartStatsFilterIDs.BEFORE_CREATING]: FilterBeforeUntilValues.before,
  [ChartStatsFilterIDs.AFTER_CREATING]: FilterBeforeUntilValues.until,
  [ChartStatsFilterIDs.SEPARATED]: SeparationValues.unseparated
};

export const DEFAULT_CHART_IFRAME_PARAMS = {
  [ChartStatsFilterIDs.DATA_TYPE]: initialChartIframeValues[ChartStatsFilterIDs.DATA_TYPE],
  [ChartStatsFilterIDs.CHART_TYPE]: initialChartIframeValues[ChartStatsFilterIDs.CHART_TYPE],
  [ChartStatsFilterIDs.DATA_TYPE_2]: initialChartIframeValues[ChartStatsFilterIDs.DATA_TYPE_2],
  [ChartStatsFilterIDs.CHART_TYPE_2]: initialChartIframeValues[ChartStatsFilterIDs.CHART_TYPE_2],
  [ChartStatsFilterIDs.TIME_FRAME]: initialChartIframeValues[ChartStatsFilterIDs.TIME_FRAME],
  [ChartStatsFilterIDs.BEFORE_CREATING]: initialChartIframeValues[ChartStatsFilterIDs.BEFORE_CREATING],
  [ChartStatsFilterIDs.AFTER_CREATING]: initialChartIframeValues[ChartStatsFilterIDs.AFTER_CREATING],
  [ChartStatsFilterIDs.SEPARATED]: initialChartIframeValues[ChartStatsFilterIDs.SEPARATED]
};

export const initialChartIframeValuesVar = {
  [ChartStatsFilterIDs.DATA_TYPE]: FilterChartTypeValues.VAR,
  [ChartStatsFilterIDs.CHART_TYPE]: FilterChartValues.COLUMN,
  [ChartStatsFilterIDs.DATA_TYPE_2]: null as FilterChartTypeValues | null,
  [ChartStatsFilterIDs.CHART_TYPE_2]: null as FilterChartValues | null,
  [ChartStatsFilterIDs.TIME_FRAME]: FilterTimeFrameValues.TF_1D,
  [ChartStatsFilterIDs.BEFORE_CREATING]: FilterBeforeUntilValues.before,
  [ChartStatsFilterIDs.AFTER_CREATING]: FilterBeforeUntilValues.until,
  [ChartStatsFilterIDs.SEPARATED]: SeparationValues.unseparated
};

export const DEFAULT_CHART_IFRAME_PARAMS_VAR = {
  [ChartStatsFilterIDs.DATA_TYPE]: initialChartIframeValuesVar[ChartStatsFilterIDs.DATA_TYPE],
  [ChartStatsFilterIDs.CHART_TYPE]: initialChartIframeValuesVar[ChartStatsFilterIDs.CHART_TYPE],
  [ChartStatsFilterIDs.DATA_TYPE_2]: initialChartIframeValuesVar[ChartStatsFilterIDs.DATA_TYPE_2],
  [ChartStatsFilterIDs.CHART_TYPE_2]: initialChartIframeValuesVar[ChartStatsFilterIDs.CHART_TYPE_2],
  [ChartStatsFilterIDs.TIME_FRAME]: initialChartIframeValuesVar[ChartStatsFilterIDs.TIME_FRAME],
  [ChartStatsFilterIDs.BEFORE_CREATING]: initialChartIframeValuesVar[ChartStatsFilterIDs.BEFORE_CREATING],
  [ChartStatsFilterIDs.AFTER_CREATING]: initialChartIframeValuesVar[ChartStatsFilterIDs.AFTER_CREATING],
  [ChartStatsFilterIDs.SEPARATED]: initialChartIframeValuesVar[ChartStatsFilterIDs.SEPARATED]
};
