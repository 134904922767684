import MainLayout from 'components/mainLayout/MainLayout';
import React, { useEffect } from 'react';
import StatsStaticGraphs from '../../../components/statsStaticGraphs/StatsStaticGraphs';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearStatsCharts } from '../../../redux/actions/statsActions';

const StaticsStats = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      history.push({
        pathname: window.location.pathname,
        search: ''
      });
      dispatch(clearStatsCharts());
    };
  }, [dispatch, history]);

  return (
    <MainLayout>
      <StatsStaticGraphs />
    </MainLayout>
  );
};

export default StaticsStats;
