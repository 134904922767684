import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import OrderManagementForm from './OrderManagementForm';
import { getInitialOrderValues, getOrderManagementReqValues } from 'helpers/strategyProfilesAddHelpers';
import { AddSPErrors, OrderManagementSettings, StrategyProfileShape } from 'interfaces/strategyProfilesShapes';
import {
  ENTER_ORDER_FIELDS_LONG,
  ENTER_ORDER_FIELDS_SHORT,
  EXIT_ORDER_FIELDS_COMMON,
  OrderManagementFields,
  orderManagementValidationCommon,
  orderManagementValidationShort,
  PriceDirectionsValues,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes,
  TOTAL_STEPS
} from 'constants/strategyProfilesAddConstants';
import s from './OrderManagement.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import { EditTypes } from '../summary/summaryConstants';
import * as Yup from 'yup';
import { FormikProps } from 'formik/dist/types';

const { SHORT } = PriceDirectionsValues;

interface OrderManagementProps {
  returnToPrevStep?: EditTypes.PARAMETERS | null;
  target?: FormTarget;
  onUpdateOrderManagement: (values: OrderManagementSettings, type: StrategySettingsFormTypes) => void;
  onCancel: () => void;
  onClearRequestErrors: () => void;
  profile: StrategyProfileShape | null;
  errors: AddSPErrors;
  isStepsHidden?: boolean;
  classes?: {
    contentWrapperClass?: string;
    inputClass?: string;
  };
}

const OrderManagement = ({
  returnToPrevStep,
  target,
  onUpdateOrderManagement,
  onCancel,
  onClearRequestErrors,
  profile,
  errors,
  isStepsHidden,
  classes
}: OrderManagementProps): JSX.Element => {
  const ref = useRef<FormikProps<OrderManagementFields> | null>(null);
  const formEnterOrderFields = profile.profile_direction === SHORT ? ENTER_ORDER_FIELDS_SHORT : ENTER_ORDER_FIELDS_LONG;

  const formInitialValues = useMemo(() => {
    return profile.profile_direction === SHORT
      ? getInitialOrderValues(profile)
      : { ...getInitialOrderValues(profile), future_price_coefficient: '' };
  }, [profile]);

  const formOrderManagementValidation =
    profile.profile_direction === SHORT
      ? { ...orderManagementValidationCommon, ...orderManagementValidationShort }
      : orderManagementValidationCommon;

  useEffect(() => {
    ref.current.values = formInitialValues;
  }, [formInitialValues]);

  return (
    <div className={cn(s.wrapper)}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.ORDERS_STEP}
        totalSteps={TOTAL_STEPS}
        title="Order management settings"
        isStepsHidden={isStepsHidden}
      />
      <Formik
        innerRef={ref}
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={Yup.object().shape(formOrderManagementValidation)}
        onSubmit={(values: OrderManagementFields, { setSubmitting }: FormikHelpers<OrderManagementFields>) => {
          onUpdateOrderManagement(getOrderManagementReqValues(values), StrategySettingsFormTypes.O_SETTINGS);
          setSubmitting(false);
        }}
      >
        <OrderManagementForm
          returnToPrevStep={returnToPrevStep}
          target={target}
          enter_order_fields={formEnterOrderFields}
          exit_order_fields={EXIT_ORDER_FIELDS_COMMON}
          onCancel={onCancel}
          onClearRequestErrors={onClearRequestErrors}
          errors={errors}
          customInputClass={classes?.inputClass}
          customWrapperClass={cn(s.form_wrapper, { [classes?.contentWrapperClass]: classes?.contentWrapperClass })}
        />
      </Formik>
    </div>
  );
};

export default OrderManagement;
