import React, { FC } from 'react';
import s from '../LogsTable.module.scss';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { filterIDs, initialFilterValues, LOGS_FILTER } from '../../../constants/logsConstants';
import { ButtonColorTypes } from '../../../constants/randomConstants';

interface LogsFilterProps {
  logsFilter: typeof LOGS_FILTER;
  filterParams: typeof initialFilterValues;
  handleFilterChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  setReqParams: (filters: typeof initialFilterValues) => void;
  setDisabledFilter: (isDisabled: boolean) => void;
  disabledFilter: boolean;
}

export const LogsFilter: FC<LogsFilterProps> = ({
  logsFilter,
  filterParams,
  handleFilterChange,
  setReqParams,
  setDisabledFilter,
  disabledFilter
}): JSX.Element => {
  return (
    <div className={s.filterWrapper}>
      {logsFilter.map(({ id, label, options }) => (
        <FormControl key={id} variant="outlined" className={s.filterFormControl}>
          <InputLabel shrink htmlFor={id} className={s.filterLabel}>
            {label}
          </InputLabel>
          <Select id={id} name={id} displayEmpty value={filterParams[id]} onChange={handleFilterChange}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
      <FormControl variant="outlined" className={s.filterFormControl}>
        <InputLabel shrink htmlFor={filterIDs.SIGNAL} className={s.filterLabel}>
          Signal
        </InputLabel>
        <TextField
          variant="outlined"
          id={filterIDs.SIGNAL}
          name={filterIDs.SIGNAL}
          value={filterParams[filterIDs.SIGNAL]}
          type="number"
          onChange={handleFilterChange}
        />
      </FormControl>

      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type="button"
        color={ButtonColorTypes.DEFAULT}
        classes={{ root: s.filterButton }}
        onClick={() => {
          setReqParams(filterParams);
          setDisabledFilter(true);
        }}
        disabled={disabledFilter}
      >
        Apply
      </Button>
    </div>
  );
};
