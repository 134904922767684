import { Switch, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

interface ScalableSwitchProps {
  scale: number;
}

export const AntSwitch = withStyles((theme: Theme) => ({
  root: {
    width: (props: ScalableSwitchProps) => 28 * props.scale,
    height: (props) => 16 * props.scale,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: (props) => 2 * props.scale,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: (props) => `translateX(${12 * props.scale}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: (props) => 12 * props.scale,
    height: (props) => 12 * props.scale,
    boxShadow: 'none'
  },
  track: {
    border: (props) => `1px solid ${theme.palette.grey[500]}`,
    borderRadius: (props) => Math.ceil((16 * props.scale) / 1.7),
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);
