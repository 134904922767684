import { makeStyles, createStyles } from '@material-ui/core/styles';
import { StylesProps } from './MyModal';

export const useModalStyles = makeStyles(() =>
  createStyles({
    modal: ({ modal }: StylesProps) => ({
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      padding: '10%',
      ...modal
    }),

    backDrop: ({ backDrop }: StylesProps) => ({
      backgroundColor: 'rgba(196, 196, 196, 0.51)',
      ...backDrop
    }),

    paper: ({ paper }: StylesProps) => ({
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      ...paper
    })
  })
);
