export const authTypes = {
  CAPTHA_REQUEST: 'CAPTHA_REQUEST',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  GET_TFA_QR: 'GET_TFA_QR',
  PUT_TFA_QR: 'PUT_TFA_QR',
  TFA: 'TFA',
  TFA_SUCCESS: 'TFA_SUCCESS',
  REFRESH_SUCCESS: 'REFRESH_SUCCESS',
  REFRESH_ERROR: 'REFRESH_ERROR',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  ACCOUNT_DATA_UPDATE_REQUEST: 'ACCOUNT_DATA_UPDATE_REQUEST',
  ACCOUNT_DATA_UPDATE_SUCCESS: 'ACCOUNT_DATA_UPDATE_SUCCESS',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  PUT_CURRENT_USER: 'PUT_CURRENT_USER',
  INITIALIZE_APP: 'INITIALIZE_APP',
  INITIALIZE_APP_SUCCESS: 'INITIALIZE_APP_SUCCESS'
};

export const layoutTypes = {
  CHANGE_ASIDE_MENU: 'CHANGE_ASIDE_MENU'
};

export const systemSettingsTypes = {
  GET_EXCHANGE_ACCOUNTS: 'GET_EXCHANGE_ACCOUNTS',
  PUT_EXCHANGE_ACCOUNTS: 'PUT_EXCHANGE_ACCOUNTS',
  ADD_EXCHANGE_ACCOUNT_REQUEST: 'ADD_EXCHANGE_ACCOUNT_REQUEST',
  EDIT_EXCHANGE_ACCOUNT_REQUEST: 'EDIT_EXCHANGE_ACCOUNT_REQUEST',
  DELETE_EXCHANGE_ACCOUNT_REQUEST: 'DELETE_EXCHANGE_ACCOUNT_REQUEST',
  GET_TG_SETTINGS: 'GET_TG_SETTINGS',
  PUT_TG_SETTINGS: 'PUT_TG_SETTINGS',
  CREATE_TG_SETTINGS_REQUEST: 'CREATE_TG_SETTINGS_REQUEST',
  EDIT_TG_SETTINGS_REQUEST: 'EDIT_TG_SETTINGS_REQUEST',
  GET_TRADING_PAIRS: 'GET_TRADING_PAIRS',
  SYNC_TRADING_PAIRS: 'SYNC_TRADING_PAIRS',
  PUT_TRADING_PAIRS: 'PUT_TRADING_PAIRS',
  CHANGE_TRADING_PAIR: 'CHANGE_TRADING_PAIR',
  CHANGE_TRADING_PAIR_SUCCESS: 'CHANGE_TRADING_PAIR_SUCCESS',
  GET_INVESTOR_REPORT_SETTINGS: 'GET_INVESTOR_REPORT_SETTINGS',
  PUT_INVESTOR_REPORT_SETTINGS: 'PUT_INVESTOR_REPORT_SETTINGS',
  EDIT_INVESTOR_REPORT_SETTINGS: 'EDIT_INVESTOR_REPORT_SETTINGS',
  GET_INVESTOR_REPORT_SETTINGS_SCHEDULER: 'GET_INVESTOR_REPORT_SETTINGS_SCHEDULER',
  PUT_INVESTOR_REPORT_SETTINGS_SCHEDULER: 'PUT_INVESTOR_REPORT_SETTINGS_SCHEDULER',
  EDIT_INVESTOR_REPORT_SETTINGS_SCHEDULER: 'EDIT_INVESTOR_REPORT_SETTINGS_SCHEDULER',
  CREATE_INVESTOR_REPORT_SETTINGS_SCHEDULER: 'CREATE_INVESTOR_REPORT_SETTINGS_SCHEDULER',
  DELETE_INVESTOR_REPORT_SETTINGS_SCHEDULER: 'DELETE_INVESTOR_REPORT_SETTINGS_SCHEDULER'
};

export const mmProfilesTypes = {
  GET_MM_PROFILES: 'GET_MM_PROFILES',
  GET_MM_PROFILES_FILTER: 'GET_MM_PROFILES_FILTER',
  PUT_MM_PROFILES: 'PUT_MM_PROFILES',
  PUT_MM_PROFILE: 'PUT_MM_PROFILE',
  GET_MM_PROFILE: 'GET_MM_PROFILE',
  ADD_MM_PROFILE: 'ADD_MM_PROFILE',
  CHANGE_MM_PROFILE: 'CHANGE_MM_PROFILE',
  CHANGE_MM_PROFILE_SUCCESS: 'CHANGE_MM_PROFILE_SUCCESS',
  CHANGE_MM_PROFILE_VISIBILITY: 'CHANGE_MM_PROFILE_VISIBILITY',
  CHANGE_DEPOSIT_MM_PROFILE: 'CHANGE_DEPOSIT_MM_PROFILE',
  GET_AUTO_DEPOSIT_MM_PROFILE: 'GET_AUTO_DEPOSIT_MM_PROFILE',
  PUT_AUTO_DEPOSIT_MM_PROFILE: 'PUT_AUTO_DEPOSIT_MM_PROFILE',
  CHANGE_AUTO_DEPOSIT_MM_PROFILE: 'CHANGE_AUTO_DEPOSIT_MM_PROFILE',
  GET_DEPOSIT_INFO_MM_PROFILE: 'GET_DEPOSIT_INFO_MM_PROFILE',
  PUT_DEPOSIT_INFO_MM_PROFILE: 'PUT_DEPOSIT_INFO_MM_PROFILE'
};

export const signalsOrdersTypes = {
  GET_SIGNALS: 'GET_SIGNALS',
  PUT_SIGNALS: 'PUT_SIGNALS',
  GET_ORDERS: 'GET_ORDERS',
  PUT_ORDERS: 'PUT_ORDERS',
  GET_SIGNAL_FILTERS: 'GET_SIGNAL_FILTERS',
  PUT_SIGNAL_FILTERS: 'PUT_SIGNAL_FILTERS',
  CANCEL_ORDER: 'CANCEL_ORDER',
  CANCEL_SIGNAL: 'CANCEL_SIGNAL',
  CLOSE_SIGNAL_MARKET: 'CLOSE_SIGNAL_MARKET',
  CLOSE_SIGNAL_MARKET_BULK: 'CLOSE_SIGNAL_MARKET_BULK',
  CLOSE_SIGNAL_MARKET_LOST_BULK: 'CLOSE_SIGNAL_MARKET_LOST_BULK',
  CLOSE_SIGNAL_LIMIT: 'CLOSE_SIGNAL_LIMIT',
  CLOSE_SIGNAL_BEST_LIMIT: 'CLOSE_SIGNAL_BEST_LIMIT',
  CLOSE_SIGNAL_BEST_LIMIT_BULK: 'CLOSE_SIGNAL_BEST_LIMIT_BULK',
  EXPORT_SIGNALS: 'EXPORT_SIGNALS',
  GET_ADMIN_TABLE: 'GET_ADMIN_TABLE',
  GET_ADMIN_LOST_POSITIONS: 'GET_ADMIN_LOST_POSITIONS',
  PUT_ADMIN_LOST_POSITIONS: 'PUT_ADMIN_LOST_POSITIONS',
  PUT_ADMIN_TABLE: 'PUT_ADMIN_TABLE',
  GET_ADMIN: 'GET_ADMIN',
  UPDATE_ADMIN_LIST: 'UPDATE_ADMIN_LIST',
  PUT_ADMIN_LIST: 'PUT_ADMIN_LIST',
  PUT_ADMIN: 'PUT_ADMIN',
  START_RECOVERY_K_EXIT: 'START_RECOVERY_K_EXIT',
  REDUCE_POSITION: 'REDUCE_POSITION',
  REDUCE_POSITION_BULK: 'REDUCE_POSITION_BULK',
  GET_RISK_CONTROL: 'GET_RISK_CONTROL',
  GET_PROFILES: 'GET_PROFILES',
  PUT_RISK_CONTROL: 'PUT_RISK_CONTROL',
  SET_RISK_PROPERTY: 'SET_RISK_PROPERTY',
  UPDATE_RISK_CONTROL: 'UPDATE_RISK_CONTROL',
  CREATE_RISK_CONTROL: 'CREATE_RISK_CONTROL',
  SET_RISK_CONTROL: 'SET_RISK_CONTROL',
  SKIP_CHECK_BY_ID: 'SKIP_CHECK_BY_ID',
  SKIP_CHECK_BULK: 'SKIP_CHECK_BULK'
};

export const statsTypes = {
  GET_STRATEGY_STATS: 'GET_STRATEGY_STATS',
  GET_STRATEGY_STATS_FILTERS: 'GET_STRATEGY_STATS_FILTERS',
  PUT_STRATEGY_STATS: 'PUT_STRATEGY_STATS',
  PUT_STRATEGY_STATS_FILTERS: 'PUT_STRATEGY_STATS_FILTERS',
  GET_EXPORT_STRATEGY_STATS: 'GET_EXPORT_STRATEGY_STATS',
  GET_ACCOUNT_STATS: 'GET_ACCOUNT_STATS',
  GET_ACCOUNT_STATS_FILTERS: 'GET_ACCOUNT_STATS_FILTERS',
  PUT_ACCOUNT_STATS: 'PUT_ACCOUNT_STATS',
  PUT_ACCOUNT_STATS_FILTERS: 'PUT_ACCOUNT_STATS_FILTERS',
  GET_ACCOUNT_STATS_EXPORT: 'GET_ACCOUNT_STATS_EXPORT',
  GET_RESULT_BY_MONTHS: 'GET_RESULT_BY_MONTHS',
  GET_RESULT_BY_MONTHS_ADMIN: 'GET_RESULT_BY_MONTHS_ADMIN',
  PUT_RESULT_BY_MONTHS: 'PUT_RESULT_BY_MONTHS',
  GET_SYSTEM_DATA_BY_MONTHS: 'GET_SYSTEM_DATA_BY_MONTHS',
  PUT_SYSTEM_DATA_BY_MONTHS: 'PUT_SYSTEM_DATA_BY_MONTHS',
  GET_STATS_GRAPH: 'GET_STATS_GRAPH',
  PUT_STATS_GRAPH: 'PUT_STATS_GRAPH',
  CLEAN_CHARTS_DATA: 'CLEAN_CHARTS_DATA'
};

export const strategyProfilesTypes = {
  GET_SYSTEM_STATUS: 'GET_SYSTEM_STATUS',
  PUT_SYSTEM_STATUS: 'PUT_SYSTEM_STATUS',
  SET_SYSTEM_STATUS: 'SET_SYSTEM_STATUS',
  CREATE_PROFILE: 'CREATE_PROFILE',
  SET_PROFILE: 'SET_PROFILE',
  SET_PROFILE_TEMPLATE: 'SET_PROFILE_TEMPLATE',
  SET_STRATEGY_SETTINGS: 'SET_STRATEGY_SETTINGS',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_STRATEGY_DETAILS: 'UPDATE_STRATEGY_DETAILS',
  GET_STRATEGY_PROFILES: 'GET_STRATEGY_PROFILES',
  PUT_STRATEGY_PROFILES: 'PUT_STRATEGY_PROFILES',
  START_STRATEGY: 'START_STRATEGY',
  STOP_STRATEGY: 'STOP_STRATEGY',
  PUT_UPDATED_IN_LIST: 'PUT_UPDATED_IN_LIST',
  GET_PROFILE: 'GET_PROFILE',
  GET_STRATEGY_SETTINGS: 'GET_STRATEGY_SETTINGS',
  CLEAR_STRATEGY_PROFILE: 'CLEAR_STRATEGY_PROFILE',
  UPDATE_STRATEGY_VISIBILITY: 'UPDATE_STRATEGY_VISIBILITY',
  GET_STRATEGY_TICKERS: 'GET_STRATEGY_TICKERS',
  SET_STRATEGY_TICKERS: 'SET_STRATEGY_TICKERS',
  UPDATED_STRATEGY_TICKERS: 'UPDATED_STRATEGY_TICKERS',
  UPDATE_STRATEGY_TICKERS: 'UPDATE_STRATEGY_TICKERS',
  SAVE_STRATEGY_TICKERS: 'SAVE_STRATEGY_TICKERS',
  DELETE_STRATEGY_TICKERS: 'DELETE_STRATEGY_TICKERS',
  DELETE_STRATEGY_TICKERS_SUCCESS: 'DELETE_STRATEGY_TICKERS_SUCCESS',
  ADD_STRATEGY_TICKERS: 'ADD_STRATEGY_TICKERS',
  ADD_STRATEGY_TICKERS_SUCCESS: 'ADD_STRATEGY_TICKERS_SUCCESS',
  UPDATE_STATUS_STRATEGY_TICKERS_SUCCESS: 'UPDATE_STATUS_STRATEGY_TICKERS_SUCCESS',
  GET_PROFILE_TEMPLATES: 'GET_PROFILE_TEMPLATES',
  SET_PROFILE_TEMPLATES: 'SET_PROFILE_TEMPLATES'
};

export const notificationTypes = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  PUT_NOTIFICATIONS: 'PUT_NOTIFICATIONS'
};

export const logsTypes = {
  GET_LOGS: 'GET_LOGS',
  PUT_LOGS: 'PUT_LOGS',
  DELETE_LOGS: 'DELETE_LOGS',
  GET_FILTER_DATA: 'GET_FILTER_DATA',
  PUT_FILTER_DATA: 'PUT_FILTER_DATA',
  GET_LOGS_COUNT: 'GET_LOGS_COUNT',
  PUT_LOGS_COUNT: 'PUT_LOGS_COUNT'
};

export const privilegesTypes = {
  GET_GROUPS: 'GET_GROUPS',
  SET_GROUPS: 'SET_GROUPS',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_FIELDS: 'UPDATE_FIELDS',
  UPDATE_SORTING: 'UPDATE_SORTING',
  UPDATE_FILTERING: 'UPDATE_FILTERING',
  UPDATE_FINISHED_ONLY: 'UPDATE_FINISHED_ONLY',
  UPDATE_SIGNALS_COUNT: 'UPDATE_SIGNALS_COUNT',
  CREATE_GROUPS: 'CREATE_GROUPS',
  UPDATE_GROUP_PRIVILEGES: 'UPDATE_GROUP_PRIVILEGES',
  DELETE_GROUP_PRIVILEGES: 'DELETE_GROUP_PRIVILEGES',
  REMOVE_GROUP_PRIVILEGES: 'REMOVE_GROUP_PRIVILEGES',
  GET_USERS: 'GET_USERS',
  SET_USERS: 'SET_USERS',
  SET_USER: 'SET_USER',
  CHANGE_USER_GROUP: 'CHANGE_USER_GROUP',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  CREATE_USER: 'CREATE_USER'
};

export const utilsTypes = {
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  HIDE_MESSAGE: 'HIDE_MESSAGE'
};
