import React, { useEffect, useMemo, useState } from 'react';
import s from './MonthsStatsTable.module.scss';
import NavTabs from '../navTabs/NavTabs';
import CustomizedTable from '../customizedTable/CustomizedTable';
import {
  getResultByMonthsStats,
  getResultByMonthsStatsAdmin,
  getSystemDataByMonths
} from '../../redux/actions/statsActions';
import { useDispatch, useSelector } from 'react-redux';
import { resultsByMonthsStatsSelector, systemDataByMonthsSelector } from '../../redux/selectors';
import { monthsStatsRenderSwitch } from '../../helpers/monthsStatsHelpers';
import { HARD_PER_PAGE } from '../../constants/randomConstants';
import { STATISTIC_CLIENT_TABLE_COL, STATISTIC_TABLE_COL } from '../../constants/monthsStatsConstants';
import { SingleResultRow } from '../../interfaces/monthsResultsShapes';
import { ErrorShape } from '../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../helpers/randomHelpers';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getStatsTabs, isSectionAllowed } from '../../helpers/privilegesHelpers';
import { useStyles } from './styles';
import StatsStaticGraphs from '../statsStaticGraphs/StatsStaticGraphs';
import { DEFAULT_CHART_IFRAME_PARAMS } from '../../constants/chartStatsConstants';
import { useQueryParams } from '../../hooks/useQueryParams';
import ChartIcon from '@material-ui/icons/BarChart';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cn from 'classnames';
import MonthStatsFilters from './filters/MonthStatsFilters';
import { Button } from '@material-ui/core';

function getSystemUptime(systemRunning: number) {
  const years = Math.floor(systemRunning / 365);
  const months = Math.floor((systemRunning % 365) / 30);
  const remainingDays = systemRunning - years * 365 - months * 30;

  return {
    days: remainingDays,
    months: months,
    years: years
  };
}

const MonthsStatsTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const data = useSelector(resultsByMonthsStatsSelector);
  const {
    turn_over_at_value,
    turn_over_week_value,
    turn_over_month_value,
    turn_over_day_value,
    positive_months,
    worst_month_return,
    best_month_return,
    avg_capital_growth_rate,
    system_is_running_days,
    system_start_date
  } = useSelector(systemDataByMonthsSelector);
  const [filterParams, setFilterParams] = useState({});
  const [reqParams, setReqParams] = useState({ order: '-period' });
  const [showGraph, setShowGraph] = useState(true);
  const [adminView, setAdminView] = useState(false);

  const systemRunning = useMemo(() => {
    return getSystemUptime(system_is_running_days);
  }, [system_is_running_days]);

  const { charts } = usePrivileges();

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  useEffect(() => {
    dispatch(getSystemDataByMonths(() => setLoading(false), onSetError));
  }, [dispatch]);

  useEffect(() => {
    onToggleLoading();
    adminView
      ? dispatch(getResultByMonthsStatsAdmin(reqParams, () => setLoading(false), onSetError))
      : dispatch(getResultByMonthsStats(reqParams, () => setLoading(false), onSetError));
  }, [reqParams, dispatch, adminView]);

  const rows = useMemo(() => [], []);

  const sortedRows = useMemo(() => {
    return rows.sort((a, b) => a.order - b.order);
  }, [rows]);

  const additionalConfig = useMemo(() => {
    const resBest = {
      value: best_month_return?.value ?? 0,
      data: best_month_return?.date ?? ''
    };
    const resWorst = {
      value: worst_month_return?.value ?? 0,
      data: worst_month_return?.date ?? ''
    };
    const resAverage = positive_months ?? 0;
    return {
      resBest,
      resAverage,
      resWorst
    };
  }, [
    best_month_return?.date,
    best_month_return?.value,
    positive_months,
    worst_month_return?.date,
    worst_month_return?.value
  ]);

  const { tooltip } = useStyles();

  const getContent = (colId, row: SingleResultRow) => {
    return monthsStatsRenderSwitch(colId, row, tooltip);
  };

  const privileges = usePrivileges();
  const tabs = getStatsTabs(privileges);

  useQueryParams(DEFAULT_CHART_IFRAME_PARAMS);

  const onMakeSortingOrdering = (value: string, hasMinusSign: boolean) => {
    if (value === '') return '';
    return hasMinusSign ? 'period' : '-period';
  };

  const sortHandler = (value: string, hasMinusSign: boolean) => {
    console.log(hasMinusSign);

    setReqParams((prev) => ({ ...prev, order: onMakeSortingOrdering(value, hasMinusSign) }));
  };

  const dataForTable = [
    { period: 'total', ...data.total },
    { period: 'avgMonth', ...data.month_average },
    { period: 'avgDay', ...data.day_average },
    ...data.by_month
  ];

  return (
    <div className={s.tableWrapper}>
      <div className={s.headerWrapper}>
        <h2 className="default-title">Stats by months</h2>
      </div>
      <div className={s.headerWrapper}>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />

        <div className={s.filter}>
          <MonthStatsFilters
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            reqParams={reqParams}
            setReqParams={(value) => setReqParams((prev) => ({ ...prev, ...value }))}
            startDate={system_start_date}
          />
          <Button
            variant="outlined"
            endIcon={showGraph ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            onClick={() => setShowGraph((s) => !s)}
            style={{ margin: '0 15px 0 0' }}
          >
            <ChartIcon />
          </Button>

          <div style={{ background: '#C7D0DD', display: 'flex', padding: 4, borderRadius: 8, cursor: 'pointer' }}>
            <span
              onClick={() => setAdminView(true)}
              style={{
                background: adminView ? '#fff' : 'transparent',
                padding: '0 8px',
                height: 28,
                lineHeight: '28px',
                borderRadius: 8
              }}
            >
              Administrator
            </span>
            <span
              onClick={() => setAdminView(false)}
              style={{
                background: adminView ? 'transparent' : '#fff',
                padding: '0 8px',
                height: 28,
                lineHeight: '28px',
                borderRadius: 8
              }}
            >
              Client
            </span>
          </div>
        </div>
      </div>

      {showGraph && (
        <div className={s.infoContainer}>
          <div className={s.turnoverBlock}>
            <div className={s.turnover}>
              <span>Turnover at all: </span>
              <p>{turn_over_at_value.toFixed(0)}$</p>
            </div>
            <div className={s.turnover}>
              <span>Turnover at month: </span>
              <p>{turn_over_month_value.toFixed(0)}$</p>
            </div>
            <div className={s.turnover}>
              <span>Turnover at week: </span>
              <p>{turn_over_week_value.toFixed(0)}$</p>
            </div>
            <div className={s.turnover}>
              <span>Turnover at day: </span>
              <p>{turn_over_day_value.toFixed(0)}$</p>
            </div>
            <div className={s.turnover}>
              <span>System works: </span>
              <p>
                {systemRunning.years} years {systemRunning.months} months {systemRunning.days} days
              </p>
            </div>
            <div className={s.turnover}>
              <span>Best Month Return: </span>
              <p>
                {additionalConfig?.resBest?.value}% ({additionalConfig?.resBest?.data})
              </p>
            </div>
            <div className={s.turnover}>
              <span>Positive Months: </span>
              <p>{additionalConfig.resAverage.toFixed(0)}%</p>
            </div>
            <div className={s.turnover}>
              <span>Worst Month Return: </span>
              <p>
                {additionalConfig.resWorst.value}% ({additionalConfig.resWorst.data})
              </p>
            </div>
            <div className={s.turnover}>
              <span>ACGR: </span>
              <p>{avg_capital_growth_rate.toFixed(2)}%</p>
            </div>
          </div>
        </div>
      )}

      {isSectionAllowed(charts) && <StatsStaticGraphs isIframe date={reqParams} />}

      <CustomizedTable
        columns={adminView ? STATISTIC_TABLE_COL : STATISTIC_CLIENT_TABLE_COL}
        rows={dataForTable}
        isLoading={isLoading}
        error={reqError}
        emptyRowsMsg="There are no results yet..."
        getContent={getContent}
        count={sortedRows.length}
        rowsPerPageOptions={[HARD_PER_PAGE]}
        customPerPage={HARD_PER_PAGE}
        getCollapsibleContent={() => null}
        hasSortingOption={true}
        sortHandler={sortHandler}
        hidePagination
        classes={{
          tableWrapper: cn({
            [s.tableContainer]: isSectionAllowed(charts),
            [s.tableContainerFull]: !isSectionAllowed(charts)
          }),
          wrapperClass: s.monthsContent,
          tHeadClasses: {
            tHeadCellClass: s.tHeadCellClass
          },
          tBodyClasses: {
            tBodyCellClass: s.tBodyCellClass
          }
        }}
      />
    </div>
  );
};

export default MonthsStatsTable;
