import React, { useEffect, useMemo, useState } from 'react';
import CustomizedTable from '../../customizedTable/CustomizedTable';
import { HARD_PER_PAGE } from '../../../constants/randomConstants';
import { SORTING_VALUES, STRATEGY_TICKERS_COLS } from '../../../constants/strategyTickersConstants';
import { strategyTickersRenderSwitch } from '../../../helpers/strategyTickersHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { strategyPairsSelector, systemSettingsSelector } from '../../../redux/selectors';
import { getTradingPairs } from '../../../redux/actions/systemSettingsActions';
import {
  addStrategiesTickers,
  deleteStrategiesTickers,
  getStrategiesTickers,
  saveStrategiesTickers,
  updateStatusStrategiesTickers,
  updateStrategiesTickers
} from '../../../redux/actions/strategyProfilesActions';
import { PairFormValues, PairsShape, PairsStatusShape } from '../../../interfaces/strategyProfilesShapes';
import { showMessage } from '../../../redux/actions/utilsActions';
import Button from '@material-ui/core/Button';
import s from './StrategyTickersTable.module.scss';
import MyModal from '../../modal/MyModal';
import AddPairListForm from './addPairList/AddPairListForm';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { isSectionReadOnly } from '../../../helpers/privilegesHelpers';
import { ErrorShape } from '../../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../../helpers/randomHelpers';
import TickerPasteModal from './tickerPasteModal/tickerPasteModal';
import { formatISODate } from '../../../helpers/randomHelpers';

const StrategyTickersTable = (): JSX.Element => {
  const [filterParams, setFilterParams] = useState({ ordering: '' });
  const dispatch = useDispatch();
  const { tradingPairs } = useSelector(systemSettingsSelector);

  const { tickers_configs } = usePrivileges();
  const isReadOnly = isSectionReadOnly(tickers_configs);

  const pairs = useSelector(strategyPairsSelector);
  const tradingPairsOptions = useMemo(() => {
    return tradingPairs.reduce(
      (acc, { id, ticker, is_active }) => (is_active ? [...acc, { value: id, label: ticker }] : acc),
      []
    );
  }, [tradingPairs]);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => setOpen((prev) => !prev);

  const [editId, setEditId] = useState(null);
  const [isOpenPasteModal, setOpenPasteModal] = useState(false);
  const togglePasteModal = () => setOpenPasteModal((prev) => !prev);

  useEffect(() => {
    onToggleLoading();
    dispatch(getTradingPairs());
    dispatch(
      getStrategiesTickers(filterParams, onToggleLoading, (err) => {
        onSetError(err);
        onToggleLoading();
      })
    );
  }, [dispatch, filterParams]);

  const onShowMessage = () => {
    dispatch(showMessage());
  };

  const onSave = (id: number) => {
    dispatch(saveStrategiesTickers(id, onShowMessage));
  };

  const onDelete = (id: number) => {
    dispatch(deleteStrategiesTickers(id, onShowMessage));
  };

  const onUpdateStatus = (id: number, status: PairsStatusShape) => {
    dispatch(updateStatusStrategiesTickers(id, status));
  };

  const onUpdatePairs = (id: number, pickedValues: number[]) => {
    dispatch(updateStrategiesTickers(id, pickedValues));
  };

  const handleOpenPasteModal = (id: number) => {
    setEditId(id);
    togglePasteModal();
  };

  const getContent = (colId, row: PairsShape) => {
    return strategyTickersRenderSwitch(
      colId,
      row,
      tradingPairsOptions,
      isReadOnly,
      onSave,
      onDelete,
      onUpdateStatus,
      onUpdatePairs,
      handleOpenPasteModal
    );
  };

  const onAddNewPairsList = (values: PairFormValues) => {
    dispatch(
      addStrategiesTickers(values, () => {
        toggleModal();
        onShowMessage();
      })
    );
  };

  const sortedRows = useMemo(() => {
    return pairs.map((item) => ({
      ...item,
      created_at: formatISODate(item.created_at, `yyyy-MM-dd HH:mm`),
      updated_at: formatISODate(item.updated_at, `yyyy-MM-dd HH:mm`)
    }));
  }, [pairs, filterParams]);

  const onSorting = (value: string) => {
    const currentOrdering = filterParams?.ordering || '';
    let ordering;

    if (currentOrdering === `-${SORTING_VALUES[value]}`) {
      ordering = SORTING_VALUES[value];
    } else if (currentOrdering === SORTING_VALUES[value]) {
      ordering = `-${SORTING_VALUES[value]}`;
    } else {
      ordering = SORTING_VALUES[value];
    }

    setFilterParams({ ordering });
  };

  return (
    <>
      <div className={s.sub_header}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.sub_header__add_btn }}
          onClick={toggleModal}
          disabled={isReadOnly}
        >
          Add
        </Button>
      </div>
      <CustomizedTable
        columns={STRATEGY_TICKERS_COLS}
        rows={sortedRows}
        isLoading={isLoading}
        error={reqError}
        emptyRowsMsg="There are no tickers yet..."
        getContent={getContent}
        count={sortedRows.length}
        onCustomChangePage={() => null}
        rowsPerPageOptions={[HARD_PER_PAGE]}
        customPerPage={HARD_PER_PAGE}
        hasSortingOption
        sortHandler={onSorting}
      />
      <MyModal isOpen={isOpen} onToggle={toggleModal}>
        <AddPairListForm
          onSubmit={onAddNewPairsList}
          onCancel={toggleModal}
          tradingPairsOptions={tradingPairsOptions}
        />
      </MyModal>
      <MyModal isOpen={isOpenPasteModal} onToggle={togglePasteModal}>
        <TickerPasteModal
          options={tradingPairsOptions}
          editId={editId}
          handleUpdateValue={(values) => onUpdatePairs(editId, values)}
          onClose={togglePasteModal}
        />
      </MyModal>
    </>
  );
};

export default StrategyTickersTable;
