import { authTypes } from '../types';
import * as Shapes from 'interfaces/authShapes';

export const captchaRequest = (
  reqData: Shapes.CaptchaRequestValues,
  onSuccess: () => void,
  onError: () => void
): Shapes.CaptchaRequestShape => ({
  type: authTypes.CAPTHA_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const loginRequest = (
  reqData: Shapes.LoginValues,
  onSuccess: () => void,
  onError: () => void
): Shapes.LoginRequestShape => ({
  type: authTypes.LOGIN_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const tfaQrRequest = (onSuccess: () => void, onError: () => void): Shapes.TfaQrShape => ({
  type: authTypes.GET_TFA_QR,
  payload: { onSuccess, onError }
});

export const tfaQrSuccess = (qrCode: string, isVerifiedBefore: string): Shapes.TfaQrSuccessShape => ({
  type: authTypes.PUT_TFA_QR,
  payload: { qrCode, isVerifiedBefore }
});

export const tfaRequest = (tokenGA: string, onSuccess: () => void, onError: () => void): Shapes.TfaShape => ({
  type: authTypes.TFA,
  payload: { tokenGA, onSuccess, onError }
});

export const refreshSuccess = (
  refreshToken: string,
  accessToken: string,
  isVerified: boolean
): Shapes.RefreshSuccessShape => ({
  type: authTypes.REFRESH_SUCCESS,
  payload: { refreshToken, accessToken, isVerified }
});

export const loginSuccess = (refreshToken: string, accessToken: string): Shapes.LoginSuccessShape => ({
  type: authTypes.LOGIN_SUCCESS,
  payload: { refreshToken, accessToken }
});

export const tfaSuccess = (refreshToken: string, accessToken: string): Shapes.LoginSuccessShape => ({
  type: authTypes.TFA_SUCCESS,
  payload: { refreshToken, accessToken }
});

export const refreshError = (): { type: string } => ({
  type: authTypes.REFRESH_ERROR
});

export const logoutRequest = (refreshToken: string): Shapes.LogoutRequest => ({
  type: authTypes.LOGOUT_REQUEST,
  payload: { refreshToken }
});

export const logoutSuccess = (): { type: string } => ({
  type: authTypes.LOGOUT_SUCCESS
});

export const accountDataUpdateRequest = (
  reqData: Shapes.AccountDetailsShape,
  userId: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.AccountDataUpdate => ({
  type: authTypes.ACCOUNT_DATA_UPDATE_REQUEST,
  payload: { reqData, userId, onSuccess, onError }
});

export const getCurrentUser = (
  onSuccess?: () => void,
  onFinally?: () => void
): { type: string; payload: { onSuccess: () => void; onFinally: () => void } } => ({
  type: authTypes.GET_CURRENT_USER,
  payload: { onSuccess, onFinally }
});

export const putCurrentUser = (user: Shapes.UserDataShape): Shapes.PutCurrentUser => ({
  type: authTypes.PUT_CURRENT_USER,
  payload: user
});

export const initializeApp = (): { type: string } => ({
  type: authTypes.INITIALIZE_APP
});

export const initializeSuccess = (isVerified: boolean): { type: string; payload: { isVerified: boolean } } => ({
  type: authTypes.INITIALIZE_APP_SUCCESS,
  payload: { isVerified }
});
