import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import {
  FIELDS_HAS_COLS,
  PRIVILEGES_OPTIONS,
  PrivilegesGroupFormFields,
  TABLE_GROUP_COLS
} from '../../../constants/privilegesConstants';
import s from './GroupForm.module.scss';
import cn from 'classnames';
import OutlinedSelect from '../../../components/selects/outlinedSelect/OutlinedSelect';
import PairedBtnBlock from '../../../components/buttons/pairedBtnBlock/PairedBtnBlock';
import { useHistory } from 'react-router-dom';
import { PAGES } from '../../../constants/routes';
import CustomizedTextField from '../../../components/fields/customizedTextField/CustomizedTextField';
import EditIcon from '@material-ui/icons/SettingsApplications';
import MyModal from '../../../components/modal/MyModal';
import AddGroup from './AddGroup';
import { PrivilegesGroupFields, Status } from '../../../interfaces/privilegesShapes';

export const GroupForm = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [colId, setColId] = useState<null | string>(null);

  const { push } = useHistory();
  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormikContext<PrivilegesGroupFormFields>();

  const getIsError = (id) => touched[id] && !!errors[id];
  const getErrorMsg = (id) => touched[id] && errors[id];

  const onCloseModal = () => setIsOpen(false);

  const onOpenLimitedModal = (colId: string) => {
    setColId(colId);
    setIsOpen(true);
  };

  return (
    <>
      <div className={s.content}>
        <CustomizedTextField
          id={PrivilegesGroupFields.NAME}
          label="Group Name"
          placeholder="Type group name..."
          value={values[PrivilegesGroupFields.NAME]}
          isError={(!!errors[PrivilegesGroupFields.NAME] && touched[PrivilegesGroupFields.NAME]) || !!''}
          isValid={!!touched[PrivilegesGroupFields.NAME] && !errors[PrivilegesGroupFields.NAME] && !''}
          helperText={touched[PrivilegesGroupFields.NAME] && errors[PrivilegesGroupFields.NAME]}
          onBlur={handleBlur}
          onChange={handleChange}
          inputClass={s.input}
        />
        <div className={s.columns}>
          {TABLE_GROUP_COLS.map((col) => {
            return (
              <div key={col.id}>
                {values[col.id] === Status.LIMITED && FIELDS_HAS_COLS.indexOf(col.id as string) >= 0 && (
                  <div className={s.icon}>
                    <EditIcon onClick={() => onOpenLimitedModal(col.id)} />
                  </div>
                )}
                <OutlinedSelect
                  id={col.id}
                  options={PRIVILEGES_OPTIONS[col.id]}
                  value={values[col.id]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={col.label}
                  isError={getIsError(col.id)}
                  errorMsg={getErrorMsg(col.id)}
                  formControlClass={cn(s.selectRoot)}
                />
              </div>
            );
          })}
        </div>

        <PairedBtnBlock
          onCancel={() => push(PAGES.PRIVILEGES_GROUPS)}
          onSubmit={handleSubmit}
          classes={{ btnBlockWrapper: s.btnBlockWrapper }}
          labels={{
            cancelLabel: 'Back',
            submitLabel: 'Save'
          }}
        />
      </div>
      <MyModal isOpen={isOpen} onToggle={onCloseModal}>
        <AddGroup colId={colId} handleClose={onCloseModal} />
      </MyModal>
    </>
  );
};
