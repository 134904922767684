import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PAGES } from '../constants/routes';

interface ProtectedRouteProps {
  component;
  isAuth: boolean;
  exact: boolean;
  path: string;
  redirectTo?: string | null;
}

const ProtectedRoute = ({
  component: Component,
  isAuth,
  path,
  exact,
  redirectTo,
  ...rest
}: ProtectedRouteProps): JSX.Element => (
  <Route
    {...rest}
    path={path}
    exact={exact}
    render={(props) => (isAuth ? <Component {...props} /> : <Redirect to={{ pathname: redirectTo ?? PAGES.LOGIN }} />)}
  />
);

export default ProtectedRoute;
