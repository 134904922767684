import React, { FC } from 'react';
import s from './AdminItem.module.scss';
import cn from 'classnames';

interface AdminItemProps {
  label: string;
  value: string | number;
  isClickable?: boolean;
  onClick?: () => void;
}

const AdminItem: FC<AdminItemProps> = ({ label, value, isClickable, onClick = () => null }) => {
  return (
    <div className={s.admin}>
      <span className={s.text}>{label}</span>
      <span className={cn(s.text, { [s.link]: isClickable })} onClick={onClick}>
        {value}
      </span>
    </div>
  );
};

export default AdminItem;
