import React, { FC } from 'react';
import Message from '../message/Message';
import { useSelector } from 'react-redux';
import { messageVisibilitySelector } from '../../redux/selectors';

interface NotificatorProps {
  children: React.ReactNode;
}

const Notificator: FC<NotificatorProps> = ({ children }) => {
  const showMessage = useSelector(messageVisibilitySelector);
  return (
    <>
      {children}
      {showMessage && <Message />}
    </>
  );
};

export default Notificator;
