import { ChangedDepositFormDTO, FormValues, MMExtendedShape, MMStatuses } from 'interfaces/mmProfilesShapes';
import {
  ACTION_BTN_COLORS,
  ACTION_BTN_LABELS,
  MMTableColIds,
  MMTableRow,
  pinnedKey,
  pinnedValue
} from 'constants/mmProfilesConstants';
import s from '../pages/mmProfilesPage/MMProfilesPage.module.scss';
import isEmpty from 'lodash/isEmpty';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import { DYNAMIC_PAGES } from 'constants/routes';
import { Checkbox, FormControl } from '@material-ui/core';
import { capitalize } from 'lodash';

const getProfilesData = (
  sliceIdx: number,
  isCollapsible: boolean,
  profilesTableData: Array<{ title: string; id: number }>
) => {
  const profiles = profilesTableData?.slice(0, sliceIdx);
  const profilesAdditional = isCollapsible ? profilesTableData.slice(sliceIdx) : [];

  return { profiles, profilesAdditional };
};

export const createRow = (rawData: MMExtendedShape): MMTableRow => {
  const { id, title, profiles, max_signals_amount, max_factor_amount, all_signals_quota, exchange_account, status } =
    rawData;

  const PROFILES_SHOW_IN_SUMMARY = 2;
  const isCollapsible = profiles?.length > PROFILES_SHOW_IN_SUMMARY;
  const profilesTableData = profiles?.map(({ title, id }) => ({ title, id }));

  const profilesData = getProfilesData(PROFILES_SHOW_IN_SUMMARY, isCollapsible, profilesTableData);
  return {
    id,
    rawData,
    isCollapsible,
    title,
    profiles: profilesData,
    max_signals_amount,
    one_signal_quota: (+all_signals_quota / max_signals_amount).toFixed(2),
    all_signals_quota,
    max_factor_amount,
    exchange_account: exchange_account?.title ?? '-',
    status,
    action: {
      label: ACTION_BTN_LABELS[status],
      color: ACTION_BTN_COLORS[status]
    }
  };
};

export const getMock = (one_signal_quota: number, all_signals_quota: number) => ({
  id: 0,
  rawData: undefined,
  isCollapsible: false,
  title: pinnedValue,
  profiles: {
    profiles: [],
    profilesAdditional: []
  },
  max_signals_amount: 0,
  one_signal_quota,
  all_signals_quota,
  max_factor_amount: 0,
  exchange_account: '-',
  status: MMStatuses.ACTIVE,
  action: {
    label: ACTION_BTN_LABELS[MMStatuses.ACTIVE],
    color: ACTION_BTN_COLORS[MMStatuses.ACTIVE]
  }
});

export const profileRenderSwitch = (
  colId: MMTableColIds,
  row: MMTableRow,
  onChangeProfile: (rawData: MMExtendedShape) => void,
  onVisibility: (id: number, isShow: boolean) => void,
  isReadOnly: boolean,
  collapseCallback?: () => void,
  isCollapseOpen?: boolean,
  isCollapsible?: boolean
): JSX.Element => {
  const value = row[colId];
  const isAnExtraRow = row[pinnedKey] === pinnedValue;
  if (isAnExtraRow && ![MMTableColIds.ALL_SIGNAL_QUOTA, MMTableColIds.ONE_SIGNAL_QUOTA, pinnedKey].includes(colId)) {
    return;
  }

  switch (colId) {
    case MMTableColIds.PROFILES:
      return (
        <div className={s.collapsibleContent}>
          {!isEmpty(value.profiles)
            ? value.profiles.map(({ title, id }, profile_index, profiles_array) => {
                const lastElement = profile_index === profiles_array.length - 1;
                return (
                  <span key={id} style={{ whiteSpace: 'nowrap' }}>
                    {`${title}${!lastElement || (lastElement && isCollapsible) ? ',' : ''}`}
                    &nbsp;
                  </span>
                );
              })
            : '-'}

          {row?.isCollapsible && (
            <IconButton size="small" onClick={collapseCallback} className={s.collapseBtn}>
              {isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </div>
      );

    case MMTableColIds.STATUS:
      return (
        <span
          className={cn(s.statusInactive, {
            [s.statusActive]: row.status === MMStatuses.ACTIVE,
            [s.statusStopping]: row.status === MMStatuses.STOPPING
          })}
        >
          {capitalize(row.status)}
        </span>
      );

    case MMTableColIds.ACTION:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          disabled={row.status === MMStatuses.STOPPING}
          color={value.color}
          onClick={() => onChangeProfile(row?.rawData)}
          classes={{ root: s.btnRoot, outlinedPrimary: s.btnPrimary }}
        >
          {value.label}
        </Button>
      );
    case MMTableColIds.TITLE:
      return row[MMTableColIds.STATUS] === MMStatuses.INACTIVE && !isReadOnly ? (
        <Link to={{ pathname: DYNAMIC_PAGES.MM_PROFILE(row.id), state: { id: row.id } }}>{value}</Link>
      ) : (
        <>{value}</>
      );

    case MMTableColIds.VISIBILITY:
      return (
        <FormControl>
          <Checkbox
            color="primary"
            style={{ transform: 'scale(1.6)' }}
            value={row.rawData?.is_show}
            checked={row.rawData?.is_show}
            name={MMTableColIds.VISIBILITY}
            onChange={() => onVisibility(row.id, !row.rawData?.is_show)}
          />
        </FormControl>
      );

    default:
      return <>{value}</>;
  }
};

export const createChangeDepositDTO = (values: FormValues): ChangedDepositFormDTO => {
  return {
    size: +values.size,
    operation_type: values.operation_type as 'increase' | 'decrease'
  };
};
