import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import NumberFormatField from '../../fields/numberFormatField/NumberFormatField';
import PairedBtnBlock from '../../buttons/pairedBtnBlock/PairedBtnBlock';
import { administrationListSelector } from '../../../redux/selectors';
import { DepositFromValue, AdministrationValues } from '../../../interfaces/administrationShapes';
import OutlinedSelect from '../../selects/outlinedSelect/OutlinedSelect';
import { DepositOptions, DepositTypes } from '../../../constants/signalsOrdersConstants';
import s from './DepositForm.module.scss';

interface DepositFormProps {
  id: number;
  propertyName: AdministrationValues.DEPOSIT_AT | AdministrationValues.DEPOSIT_MONTHLY;
  onCancel: () => void;
  onSubmit: (value: DepositFromValue) => void;
}

interface FormValues {
  value: string;
  depositType: string;
}

const DepositForm: React.FC<DepositFormProps> = ({ onCancel, onSubmit, id, propertyName }) => {
  const { deposit_at, deposit_monthly } = useSelector(administrationListSelector(id));
  const getInitValue = (value: number) => (value ? String(value) : '');
  return (
    <div className={s.formWrapper}>
      <h2 className={cn('default-title', s.title)}>Enter new value</h2>
      <Formik
        initialValues={{
          value: getInitValue(propertyName === AdministrationValues.DEPOSIT_AT ? deposit_at : deposit_monthly),
          depositType: ''
        }}
        validationSchema={Yup.object({
          value: Yup.string().required(`Please enter new ${propertyName}`),
          depositType: Yup.mixed().when(['value'], {
            is: () => propertyName === AdministrationValues.DEPOSIT_MONTHLY,
            then: Yup.mixed()
              .oneOf([DepositTypes.DEPOSIT, DepositTypes.WITHDRAWAL, DepositTypes.REINVEST])
              .required('This field is required')
          })
        })}
        onSubmit={({ value, depositType }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          const reqData: DepositFromValue = {
            [propertyName]: +value,
            ...(propertyName === AdministrationValues.DEPOSIT_MONTHLY && { deposit_type: depositType })
          };
          onSubmit(reqData);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form className={s.form}>
              <div>
                {propertyName === AdministrationValues.DEPOSIT_MONTHLY && (
                  <OutlinedSelect
                    id="depositType"
                    options={DepositOptions}
                    value={values.depositType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Deposit type"
                    isError={!!errors.depositType && touched.depositType}
                    errorMsg={touched.depositType && errors.depositType}
                  />
                )}
                <NumberFormatField
                  inputProps={{
                    id: 'value',
                    placeholder: 'Type in value',
                    isError: !!errors.value && touched.value,
                    helperText: touched.value && errors.value,
                    isValid: touched.value && !errors.value,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    value: values.value,
                    inputClass: s.input
                  }}
                />
              </div>
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DepositForm;
