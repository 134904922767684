import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelOrder, getOrders } from 'redux/actions/signalsOrdersActions';
import { getComponentsFieldsSelector, ordersSelector } from 'redux/selectors';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { createOrdersRow, hideColumnsStrategy, ordersPageRenderSwitch } from 'helpers/signalOrdersHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { OrderShape, OrderTableRow } from 'interfaces/signalsOrdersShapes';
import { HARD_PER_PAGE, ZERO_BASED_PAGE_SHIFT } from 'constants/randomConstants';
import {
  DEFAULT_ORDER_PARAMS,
  HIDE_ORDER_COLUMNS_FOR_STRATEGY,
  ORDERS_COLUMNS,
  SERVER_CLIENT_ORDER_COLS_MAP
} from 'constants/signalsOrdersConstants';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionLimited } from '../../helpers/privilegesHelpers';
import { TableColumn } from '../../interfaces/randomShapes';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';

interface OrdersTableProps {
  profileId?: string;
}

const OrdersTable: FC<OrdersTableProps> = ({ profileId }) => {
  const dispatch = useDispatch();
  const { count, results } = useSelector(ordersSelector);

  const { orders } = usePrivileges();
  const { fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.ORDERS));

  const [reqParams, setReqParams] = useState(
    profileId ? { ...DEFAULT_ORDER_PARAMS, profile: profileId } : DEFAULT_ORDER_PARAMS
  );

  const onChangePage = (newPage: number) => {
    onToggleLoading();
    setReqParams((prev) => ({ ...prev, page: newPage + ZERO_BASED_PAGE_SHIFT }));
  };

  const [isLoading, setLoading] = useState(true);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const onCancelOrder = (id: number) => {
    onToggleLoading();
    dispatch(
      cancelOrder(id, onGetOrders, (err) => {
        onSetError(err);
        onToggleLoading();
      })
    );
  };

  const onGetOrders = useCallback(() => {
    dispatch(getOrders(reqParams, onToggleLoading, onSetError));
  }, [dispatch, reqParams]);

  useEffect(() => {
    onGetOrders();
  }, [onGetOrders]);

  const getContent = (colId, row: OrderTableRow) => {
    return ordersPageRenderSwitch(colId, row, onCancelOrder);
  };

  const rows = useMemo(() => results.map((item: OrderShape) => createOrdersRow(item)), [results]);
  const columns: TableColumn[] = useMemo(
    () => (profileId ? hideColumnsStrategy(ORDERS_COLUMNS, HIDE_ORDER_COLUMNS_FOR_STRATEGY) : ORDERS_COLUMNS),
    [profileId]
  );

  const filteredColumns = useMemo(() => {
    return isSectionLimited(orders)
      ? columns.filter((col) => fields.indexOf(SERVER_CLIENT_ORDER_COLS_MAP[col.id]) >= 0)
      : columns;
  }, [columns, orders, fields]);

  return (
    <CustomizedTable
      count={count}
      columns={filteredColumns}
      rows={filteredColumns.length ? rows : []}
      isLoading={isLoading}
      error={reqError}
      emptyRowsMsg="There are no orders yet..."
      getContent={getContent}
      getCollapsibleContent={() => null}
      rowsPerPageOptions={[HARD_PER_PAGE]}
      customPerPage={HARD_PER_PAGE}
      customPage={reqParams.page - ZERO_BASED_PAGE_SHIFT}
      onCustomChangePage={onChangePage}
      offPerPage
    />
  );
};

export default OrdersTable;
