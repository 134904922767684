import React from 'react';
import MainLayout from 'components/mainLayout/MainLayout';
import NavTabs from 'components/navTabs/NavTabs';
import SignalsTable from 'components/signalsTable/SignalsTable';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { getSignalOrderTabs } from '../../../helpers/privilegesHelpers';
import s from '../SignalsOrdersPage.module.scss';

const SignalsPage = (): JSX.Element => {
  const privileges = usePrivileges();
  const tabs = getSignalOrderTabs(privileges);

  return (
    <MainLayout contentCustomClass={s.signalsContent}>
      <div className={s.titleWrapper}>
        <h2 className="default-title">Signals dashboard</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <SignalsTable />
    </MainLayout>
  );
};

export default SignalsPage;
