import React from 'react';
import cn from 'classnames';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { Form, Formik, FormikHelpers } from 'formik';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import {
  DeviationSettingsValidation,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes,
  TOTAL_STEPS
} from 'constants/strategyProfilesAddConstants';
import { AddSPErrors, StrategyProfileSettingsShape } from 'interfaces/strategyProfilesShapes';
import s from './DeviationSettings.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import NumberFormatField from 'components/fields/numberFormatField/NumberFormatField';

interface DeviationSettingsProps {
  target?: FormTarget;
  onSubmitDeviations: (values, type) => void;
  onClearRequestErrors: () => void;
  onCancel: () => void;
  profile: StrategyProfileSettingsShape | null;
  errors: AddSPErrors;
  isStepsHidden?: boolean;
  contentWrapperClass?: string;
}

interface FormValues {
  limit_funding_rate: number;
  limit_price_deviation: number;
}

const DeviationSettings = ({
  target,
  onSubmitDeviations,
  onClearRequestErrors,
  onCancel,
  profile,
  errors: { profileErr },
  isStepsHidden
}: DeviationSettingsProps): JSX.Element => {
  const initialValues: FormValues = { ...profile?.settings?.deviation_settings };

  return (
    <div className={s.wrapper}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.INDICATIONS_STEP}
        totalSteps={TOTAL_STEPS}
        title="Deviation settings"
        isStepsHidden={isStepsHidden}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={DeviationSettingsValidation}
        onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          const formatedValues = {
            limit_funding_rate: Number(values.limit_funding_rate),
            limit_price_deviation: Number(values.limit_price_deviation)
          };

          onSubmitDeviations(formatedValues, StrategySettingsFormTypes.D_SETTINGS);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
          const getIsError = (id) => touched[id] && !!errors[id];
          const getErrorMsg = (id) => touched[id] && errors[id];
          const getIsValid = (id) => !!touched[id] && !errors[id];

          return (
            <Form className={s.form}>
              <NumberFormatField
                inputProps={{
                  id: 'limit_funding_rate',
                  label: 'Funding rate',
                  placeholder: '0.5',
                  value: values['limit_funding_rate'],
                  isError: getIsError('limit_funding_rate'),
                  isValid: getIsValid('limit_funding_rate'),
                  helperText: getErrorMsg('limit_funding_rate'),
                  onBlur: handleBlur,
                  onChange: handleChange
                }}
              />
              <NumberFormatField
                inputProps={{
                  id: 'limit_price_deviation',
                  label: 'Index price',
                  placeholder: '0.5',
                  value: values['limit_price_deviation'],
                  isError: getIsError('limit_price_deviation'),
                  isValid: getIsValid('limit_price_deviation'),
                  helperText: getErrorMsg('limit_price_deviation'),
                  onBlur: handleBlur,
                  onChange: handleChange
                }}
              />
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ cancelLabel: 'Cancel', submitLabel: target === FormTarget.EDITING ? 'Save' : 'Next' }}
                disableSubmit={isSubmitting}
                disableCancel={isSubmitting}
              />
              {profileErr && <span className={cn(s.error, 'errorText')}>{profileErr}</span>}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DeviationSettings;
