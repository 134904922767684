import React from 'react';
import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core';
import cn from 'classnames';

export const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9'
  }
});

export const CustomTooltip = ({ className, ...props }: TooltipProps): JSX.Element => {
  const { tooltip } = useStyles();
  return <Tooltip {...props} classes={{ tooltip: cn(tooltip, className) }} />;
};

export default CustomTooltip;
