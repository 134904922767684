import React, { useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { asideChange } from 'redux/actions/layoutActions';
import { logoutRequest } from 'redux/actions/authActions';
import { authSelector, layoutRootSelector } from 'redux/selectors';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useAsideStyles } from './AsideMenuStyles';
import { getAsideLinks } from '../../helpers/privilegesHelpers';
import { usePrivileges } from '../../hooks/usePrivileges';
import s from './AsideMenu.module.scss';

export default function AsideMenu(): JSX.Element {
  const {
    location: { pathname }
  } = useHistory();

  const dispatch = useDispatch();
  const { isAsideOpen } = useSelector(layoutRootSelector);
  const { refreshToken } = useSelector(authSelector);

  const handleToggleDrawer = (newState: boolean) => {
    dispatch(asideChange(newState));
  };

  const privileges = usePrivileges();

  const asideLinks = useMemo(() => getAsideLinks(privileges), [privileges]);

  const handleLogout = () => {
    dispatch(logoutRequest(refreshToken));
  };

  const classes = useAsideStyles();
  return (
    <div
      className={cn(classes.root, {
        [s.menuWrapperOpen]: isAsideOpen,
        [s.menuWrapperClosed]: !isAsideOpen
      })}
    >
      <Drawer
        variant="permanent"
        className={cn(classes.drawer, {
          [classes.drawerOpen]: isAsideOpen,
          [classes.drawerClose]: !isAsideOpen
        })}
        classes={{
          paper: cn({
            [classes.drawerOpen]: isAsideOpen,
            [classes.drawerClose]: !isAsideOpen
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={() => handleToggleDrawer(!isAsideOpen)}
            className={cn(classes.menuButton, { [classes.menuButtonClosed]: !isAsideOpen })}
          >
            {isAsideOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider />
        <div className={classes.menuWrapper}>
          <List>
            {asideLinks.map(({ id, label, path, exact, IconComponent, hide }) => {
              const isRouteMatch = pathname.startsWith(path);
              if (!hide) {
                return (
                  <NavLink
                    key={id}
                    to={path}
                    exact={exact}
                    className={classes.link}
                    activeClassName={classes.linkActive}
                  >
                    <ListItem button classes={{ root: classes.listItemRoot }}>
                      <ListItemIcon>
                        <IconComponent classes={{ root: cn({ [classes.linkActive]: isRouteMatch }) }} />
                      </ListItemIcon>
                      <ListItemText primary={label} />
                    </ListItem>
                  </NavLink>
                );
              }
              return null;
            })}
          </List>
          <div>
            <Divider />
            <ListItem button classes={{ root: classes.listItemRoot }} onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
