import { systemSettingsTypes } from '../types';
import * as Shapes from 'interfaces/systemSettingsShapes';

export const getExchangeAccounts = (onFinally?: () => void, onError?: (err) => void): Shapes.GetExchangeAccounts => ({
  type: systemSettingsTypes.GET_EXCHANGE_ACCOUNTS,
  payload: { onFinally, onError }
});

export const putExchangeAccounts = (accountsList: Array<Shapes.ExchangeAccountShape>): Shapes.PutExchangeAccounts => ({
  type: systemSettingsTypes.PUT_EXCHANGE_ACCOUNTS,
  payload: accountsList
});

export const addExchangeAccountRequest = (
  reqData: Shapes.ExchangeAccountFormFields,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.AddExchangeAccShape => ({
  type: systemSettingsTypes.ADD_EXCHANGE_ACCOUNT_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const editExchangeAccountRequest = (
  reqData: Shapes.ExchangeAccountFormFields,
  accountId: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.EditExchangeAccShape => ({
  type: systemSettingsTypes.EDIT_EXCHANGE_ACCOUNT_REQUEST,
  payload: { reqData, accountId, onSuccess, onError }
});

export const deleteExchangeAccountRequest = (
  accountId: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.DeleteExchangeAccShape => ({
  type: systemSettingsTypes.DELETE_EXCHANGE_ACCOUNT_REQUEST,
  payload: { accountId, onSuccess, onError }
});

export const getTGSettings = (onFinally?: () => void, onError?: (err) => void): Shapes.GetExchangeAccounts => ({
  type: systemSettingsTypes.GET_TG_SETTINGS,
  payload: { onFinally, onError }
});

export const putTGSettings = (tg_settings: Shapes.TG_NotificationValues): Shapes.PutTGSettings => ({
  type: systemSettingsTypes.PUT_TG_SETTINGS,
  payload: tg_settings
});

export const createTGSettings = (
  reqData: Shapes.TELEGRAM_SETTINGS_VALUES,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CreateTGSettings => ({
  type: systemSettingsTypes.CREATE_TG_SETTINGS_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const editTGSettings = (
  reqData: Shapes.TELEGRAM_SETTINGS_VALUES,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.EditTGSettings => ({
  type: systemSettingsTypes.EDIT_TG_SETTINGS_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const getTradingPairs = (onSuccess?: () => void, onError?: (err) => void): Shapes.GetTradingPairs => ({
  type: systemSettingsTypes.GET_TRADING_PAIRS,
  payload: { onSuccess, onError }
});

export const syncTradingPairs = (onSuccess: () => void, onError: (err) => void): Shapes.GetTradingPairs => ({
  type: systemSettingsTypes.SYNC_TRADING_PAIRS,
  payload: { onSuccess, onError }
});

export const putTradingPairs = (tradingPairs: Array<Shapes.TradingPairShape>): Shapes.PutTradingPairs => ({
  type: systemSettingsTypes.PUT_TRADING_PAIRS,
  payload: tradingPairs
});

export const changeTradingPair = (
  reqData: { ticker: string; is_active: boolean },
  pairId: number,
  onError: (err) => void
): Shapes.ChangeTradingPair => ({
  type: systemSettingsTypes.CHANGE_TRADING_PAIR,
  payload: { reqData, pairId, onError }
});

export const changeTradingPairSuccess = (tradingPair: Shapes.TradingPairShape): Shapes.ChangeTradingPairSuccess => ({
  type: systemSettingsTypes.CHANGE_TRADING_PAIR_SUCCESS,
  payload: tradingPair
});

export const getInvestorReportSettings = (
  onFinally?: () => void,
  onError?: (err) => void
): Shapes.GetInvestorReportSettings => ({
  type: systemSettingsTypes.GET_INVESTOR_REPORT_SETTINGS,
  payload: { onFinally, onError }
});

export const putInvestorReportSettings = (
  reqData: Shapes.INVESTOR_REPORT_SETTINGS_VALUES,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.PutInvestorReportSettings => ({
  type: systemSettingsTypes.PUT_INVESTOR_REPORT_SETTINGS,
  payload: { reqData, onSuccess, onError }
});

export const editInvestorReportSettings = (
  reqData: Shapes.INVESTOR_REPORT_SETTINGS_VALUES,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.EditInvestorReportSettings => ({
  type: systemSettingsTypes.EDIT_INVESTOR_REPORT_SETTINGS,
  payload: { reqData, onSuccess, onError }
});

export const getInvestorReportSettingsScheduler = (
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.GetInvestorReportScheduler => ({
  type: systemSettingsTypes.GET_INVESTOR_REPORT_SETTINGS_SCHEDULER,
  payload: { onSuccess, onError }
});

export const putInvestorReportSettingsScheduler = (
  reqData: Shapes.INVESTOR_REPORT_SETTINGS_SCHEDULER_VALUES,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.PutInvestorReportScheduler => ({
  type: systemSettingsTypes.PUT_INVESTOR_REPORT_SETTINGS_SCHEDULER,
  payload: { reqData, onSuccess, onError }
});

export const deteleInvestorReportSettingsScheduler = (
  id: number,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.DeleteInvestorReportScheduler => ({
  type: systemSettingsTypes.DELETE_INVESTOR_REPORT_SETTINGS_SCHEDULER,
  payload: { id, onSuccess, onError }
});

export const createInvestorReportSettingsScheduler = (
  reqData: Shapes.INVESTOR_REPORT_SETTINGS_SCHEDULER_VALUES,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.CreateInvestorReportScheduler => ({
  type: systemSettingsTypes.CREATE_INVESTOR_REPORT_SETTINGS_SCHEDULER,
  payload: { reqData, onSuccess, onError }
});
