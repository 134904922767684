import { mmProfilesTypes } from '../types';
import { AnyAction } from 'redux';
import { MMProfilesReducer } from 'interfaces/mmProfilesShapes';

const initialState: MMProfilesReducer = {
  mmProfiles: {
    count: 0,
    next: null,
    previous: null,
    results: [],
    summary: {
      sum_all_signals_quota: 0,
      sum_one_signal_quota: 0
    }
  },
  autoDepositDepo: {
    auto_change_mm_depo_percent: 0,
    is_auto_change_mm_depo_enabled: false
  },
  depositInfo: {
    ideo_r_long: 0,
    ideo_r_short: 0,
    mm_asset_sum: 0,
    mm_deposit: 0
  }
};

export default function mmProfilesReducer(state = initialState, { type, payload }: AnyAction): MMProfilesReducer {
  switch (type) {
    case mmProfilesTypes.PUT_MM_PROFILES:
      return { ...state, mmProfiles: payload };

    case mmProfilesTypes.PUT_MM_PROFILE:
      return { ...state, mmProfiles: { ...state.mmProfiles, results: payload } };

    case mmProfilesTypes.PUT_AUTO_DEPOSIT_MM_PROFILE:
      return { ...state, autoDepositDepo: payload };

    case mmProfilesTypes.CHANGE_MM_PROFILE_SUCCESS:
      return {
        ...state,
        mmProfiles: {
          ...state.mmProfiles,
          results: state.mmProfiles.results.map((profile) => (profile.id === payload.id ? payload : profile))
        }
      };

    case mmProfilesTypes.PUT_DEPOSIT_INFO_MM_PROFILE:
      return {
        ...state,
        depositInfo: payload
      };

    default:
      return state;
  }
}
