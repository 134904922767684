import { createContext } from 'react';
import * as Yup from 'yup';
import { AtrSmoothAlgoTypes, PriceDirectionsTypes, AtrMinus1Types } from 'interfaces/strategyProfilesShapes';
import { SignalTimeframes } from 'constants/signalsOrdersConstants';

interface TemplateContextI {
  showMessage: boolean;
  isTemplated?: boolean;
  handleTemplated?: (isTemplated: boolean) => void;
}

export const TemplateContext = createContext<TemplateContextI | null>(null);

export enum STRATEGY_SCHEMA_TYPE {
  V1 = 'strategy_v1'
}
export enum StrategySettingsFormTypes {
  S_SETTINGS = 'SIGNAL_SETTINGS',
  O_SETTINGS = 'ORDER_MANAGEMENT',
  I_SETTINGS = 'INDICATION_SETTINGS',
  D_SETTINGS = 'DEVIATION_SETTINGS'
}

export const PRICE_DIRECTION_LABELS = {
  [PriceDirectionsTypes.GROW]: 'Grow-up',
  [PriceDirectionsTypes.FALL]: 'Falling-down'
};

export const SMOOTH_ALGO_LABELS = {
  [AtrSmoothAlgoTypes.EMA]: 'EMA',
  [AtrSmoothAlgoTypes.RMA]: 'RMA',
  [AtrSmoothAlgoTypes.SMA]: 'SMA'
};

export const ATR_MINUS_1_TYPE_LABELS = {
  [AtrMinus1Types.GTE]: '>=',
  [AtrMinus1Types.LTE]: '<=',
  [AtrMinus1Types.RNG]: 'range'
};

export const TOTAL_STEPS = 5;

enum StrategyStepsNames {
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  KEY_PARAMETERS = 'KEY_PARAMETERS',
  ORDER_MANAGEMENT = 'ORDER_MANAGEMENT',
  INDICATION_SETTINGS = 'INDICATION_SETTINGS',
  SUMMARY = 'SUMMARY'
}

export enum StrategyStepsIdx {
  PROFILE_SETTINGS = 0,
  KEY_PARAMETERS = 1,
  ORDER_MANAGEMENT = 2,
  INDICATIONS = 3,
  SUMMARY = 4
}

export const STRATEGY_STEPS_LABELS = {
  PROFILE_SETTINGS_STEP: 1,
  KEY_PARAMETERS_STEP: 2,
  ORDERS_STEP: 3,
  INDICATIONS_STEP: 4,
  SUMMARY_STEP: 5
};

export const STRATEGY_STEPS = [
  StrategyStepsNames.PROFILE_SETTINGS,
  StrategyStepsNames.KEY_PARAMETERS,
  StrategyStepsNames.ORDER_MANAGEMENT,
  StrategyStepsNames.INDICATION_SETTINGS,
  StrategyStepsNames.SUMMARY
];

export enum StrategyProfilesFields {
  TEMPLATE = 'template',
  TITLE = 'title',
  MM_PROFILE = 'mmProfile',
  TICKER_PAIRS = 'tickerPairs',
  TICKERS = 'tickers'
}

export enum PriceDirectionsValues {
  SHORT = 'short',
  LONG = 'long'
}

export const profileSettingsSchema = Yup.object({
  [StrategyProfilesFields.TITLE]: Yup.string()
    .required('Strategy profile name can’t be blank')
    .max(64, 'Strategy profile name could be maximum 64 symbols'),
  [StrategyProfilesFields.MM_PROFILE]: Yup.string().required('MM Profile can’t be blank'),
  [StrategyProfilesFields.TICKERS]: Yup.string().required('Tickers list can’t be blank')
});

export const ATR_ALGO_OPTIONS = [
  { value: AtrSmoothAlgoTypes.EMA, label: 'EMA' },
  { value: AtrSmoothAlgoTypes.RMA, label: 'RMA' },
  { value: AtrSmoothAlgoTypes.SMA, label: 'SMA' }
];

export const PRICE_DIRECTIONS_OPTIONS = [
  { value: PriceDirectionsTypes.GROW, label: 'Grow-up' },
  { value: PriceDirectionsTypes.FALL, label: 'Falling-down' }
];

export const TIME_FRAME_OPTIONS = [
  { value: SignalTimeframes.HOUR, label: '1 hour' },
  { value: SignalTimeframes.FOUR_HOURS, label: '4 hours' },
  { value: SignalTimeframes.DAY, label: '1 day' },
  { value: SignalTimeframes.WEEK, label: '1 week' },
  { value: SignalTimeframes.MONTH, label: '1 month' }
];

export const ATR_MINUS_1_TYPE_OPTIONS = [
  { value: AtrMinus1Types.GTE, label: '>=' },
  { value: AtrMinus1Types.LTE, label: '<=' }
];

export enum KeyParametersFields {
  TIMEFRAME = 'timeframe',
  ATR_N_PERIODS = 'atr_n_periods',
  ATR_SMOOTH_ALGO = 'atr_smooth_algo',
  ATR_THRESHOLD = 'atr_threshold_percents',
  PRICE_DIRECTION = 'price_direction',
  START_COEFFICIENT = 'start_point_coefficient',
  TIMER_MINS = 'timer_minutes',
  SIGNAL_DUR_MINS = 'signal_duration_minutes',
  IS_ATR_MINUS_1 = 'is_atr_minus_1',
  ATR_MINUS_1_TYPE = 'atr_minus_1_type',
  ATR_MINUS_1_VALUE = 'atr_minus_1_value',
  ENABLE_SEC_ATR = 'enable_sec_atr',
  ATR_MINUS_2_TYPE = 'atr_minus_2_type',
  ATR_MINUS_2_VALUE = 'atr_minus_2_value',
  REVERT_THRESHOLD = 'revert_threshold',
  TR_IN_ATR_THRESHOLD = 'tr_in_atr_threshold'
}

export const {
  TIMEFRAME,
  PRICE_DIRECTION,
  START_COEFFICIENT,
  TIMER_MINS,
  ATR_THRESHOLD,
  ATR_N_PERIODS,
  ATR_SMOOTH_ALGO,
  SIGNAL_DUR_MINS,
  IS_ATR_MINUS_1,
  ATR_MINUS_1_TYPE,
  ATR_MINUS_1_VALUE,
  ATR_MINUS_2_TYPE,
  ATR_MINUS_2_VALUE,
  ENABLE_SEC_ATR,
  TR_IN_ATR_THRESHOLD,
  REVERT_THRESHOLD
} = KeyParametersFields;

export interface KeyParametersFormFields {
  [TIMEFRAME]: SignalTimeframes;
  [PRICE_DIRECTION]: PriceDirectionsTypes;
  [START_COEFFICIENT]: string;
  [TIMER_MINS]: string;
  [ATR_THRESHOLD]: string;
  [ATR_N_PERIODS]: string;
  [ATR_SMOOTH_ALGO]: AtrSmoothAlgoTypes;
  [SIGNAL_DUR_MINS]: string;
  [IS_ATR_MINUS_1]: boolean;
  [ATR_MINUS_1_TYPE]: AtrMinus1Types;
  [ATR_MINUS_1_VALUE]: string;
  [ATR_MINUS_2_TYPE]: AtrMinus1Types;
  [ATR_MINUS_2_VALUE]: string;
  [ENABLE_SEC_ATR]: boolean;
  [TR_IN_ATR_THRESHOLD]: string;
  [REVERT_THRESHOLD]: string;
}

export const keyParametersValidation = Yup.object({
  [TIMEFRAME]: Yup.string().required('Working time frame can’t be blank'),
  [PRICE_DIRECTION]: Yup.string().required('Price direction can’t be blank'),
  [START_COEFFICIENT]: Yup.number().required('Start coefficient can’t be blank'),
  [TIMER_MINS]: Yup.number().required('ROC timer can’t be blank'),
  [ATR_THRESHOLD]: Yup.number().required('ATR threshold can’t be blank'),
  [ATR_N_PERIODS]: Yup.number()
    .integer('N-periods must be integer number')
    .min(5, 'The value can be only in interval of 5 - 50')
    .max(50, 'The value can be only in interval of 5 - 50')
    .required('N-periods can’t be blank'),
  [ATR_SMOOTH_ALGO]: Yup.string().required('Smoothing (for ATR) can’t be blank'),
  [SIGNAL_DUR_MINS]: Yup.number().required('Signal max duration can’t be blank'),
  [TR_IN_ATR_THRESHOLD]: Yup.number().notRequired(),
  [REVERT_THRESHOLD]: Yup.number()
    .min(0, 'Valid value from 0 to 100')
    .max(100, 'Valid value from 0 to 100')
    .notRequired(),
  [IS_ATR_MINUS_1]: Yup.bool().required('ATR-1 can’t be blank'),
  [ATR_MINUS_1_TYPE]: Yup.string().when([IS_ATR_MINUS_1], {
    is: (isEnabled) => isEnabled,
    then: Yup.string().required('ATR-1 type can’t be blank')
  }),
  [ATR_MINUS_1_VALUE]: Yup.number().when([IS_ATR_MINUS_1], {
    is: (isEnabled) => isEnabled,
    then: Yup.number()
      .required('ATR-1 value can’t be blank')
      .when([ENABLE_SEC_ATR, ATR_MINUS_2_VALUE], {
        is: (isEnabled, opponentValue) => isEnabled && !!opponentValue,
        then: Yup.number().lessThan(Yup.ref(ATR_MINUS_2_VALUE), ({ less }) => `ATR-1 should be less then ${less}`)
      })
  }),
  [ATR_MINUS_2_TYPE]: Yup.string().when([ENABLE_SEC_ATR], {
    is: (isEnabled) => isEnabled,
    then: Yup.string().required('ATR-1(2) type can’t be blank')
  }),
  [ATR_MINUS_2_VALUE]: Yup.number().when([ENABLE_SEC_ATR], {
    is: (isEnabled) => isEnabled,
    then: Yup.number()
      .required('ATR-1(2) value can’t be blank')
      .when([IS_ATR_MINUS_1, ATR_MINUS_1_VALUE], {
        is: (isEnabled, opponentValue) => isEnabled && !!opponentValue,
        then: Yup.number().moreThan(Yup.ref(ATR_MINUS_1_VALUE), ({ more }) => `ATR-1(2) should be greater then ${more}`)
      })
  })
});

export const DeviationSettingsValidation = Yup.object({
  limit_funding_rate: Yup.number().min(0.1, 'Must be greater than or equal to 0.1').required('Field is required'),
  limit_price_deviation: Yup.number().min(0.1, 'Must be greater than or equal to 0.1').required('Field is required')
});

export const IndicationsAddValidation = Yup.object({
  indications: Yup.array().of(
    Yup.object().shape({
      opp_tf: Yup.string()
        .nullable()
        .when('opp_enabled', {
          is: true,
          then: Yup.string().required('OPP-TF can’t be blank').typeError('OPP-TF can’t be blank'),
          otherwise: Yup.string().nullable()
        }),
      opp_sp: Yup.number()
        .typeError('OPP-SP must be a number')
        .when('opp_enabled', {
          is: true,
          then: Yup.number()
            .required('OPP-SP can’t be blank')
            .typeError('OPP-SP must be a number')
            .min(1, 'OPP-SP must be 1-10')
            .max(10, 'OPP-SP must be 1-10'),
          otherwise: Yup.number()
        }),
      opp_atr: Yup.number()
        .typeError('OPP-ATR must be a number')
        .when(['opp_enabled', 'opp_tf'], {
          is: (opp_enabled, opp_tf) => opp_enabled === true,
          then: Yup.number()
            .required('OPP-ATR can’t be blank')
            .typeError('OPP-ATR must be a number')
            .when('opp_tf', {
              is: SignalTimeframes.HOUR,
              then: Yup.number().min(1, 'OPP-ATR must be 1-10').max(10, 'OPP-ATR must be 1-10')
            })
            .when('opp_tf', {
              is: SignalTimeframes.FOUR_HOURS,
              then: Yup.number().min(1, 'OPP-ATR must be 1-15').max(15, 'OPP-ATR must be 1-15')
            })
            .when('opp_tf', {
              is: SignalTimeframes.DAY,
              then: Yup.number().min(2, 'OPP-ATR must be 2-25').max(25, 'OPP-ATR must be 2-25')
            })
            .when('opp_tf', {
              is: SignalTimeframes.WEEK,
              then: Yup.number().min(3, 'OPP-ATR must be 3-50').max(50, 'OPP-ATR must be 3-50')
            })
            .when('opp_tf', {
              is: SignalTimeframes.MONTH,
              then: Yup.number().min(4, 'OPP-ATR must be 4-70').max(70, 'OPP-ATR must be 4-70')
            }),
          otherwise: Yup.number()
        }),
      le_tf: Yup.string()
        .nullable()
        .when('le_enabled', {
          is: true,
          then: Yup.string().required('LE-TF can’t be blank').typeError('LE-TF can’t be blank'),
          otherwise: Yup.string().nullable()
        }),
      le_enabled: Yup.boolean(),
      le_periods: Yup.number()
        .typeError('LE-Periods must be an integer')
        .integer('LE-Periods must be an integer')
        .when('le_enabled', {
          is: true,
          then: Yup.number()
            .required('LE-Periods can’t be blank')
            .min(1, 'LE-Periods must be greater than or equal to 1')
            .max(1000, 'LE-Periods must be less than or equal to 1000'),
          otherwise: Yup.number().nullable()
        }),
      opp_enabled: Yup.boolean()
    })
  )
});

export enum OrderTimeDistance {
  ORDER = 'order',
  TIME = 'time',
  DISTANCE = 'distance'
}

export enum OrderManagementNames {
  FUTURE_PRICE_COEF = 'future_price_coefficient',
  ATR_TIMER_MINS = 'atr_timer',
  CHANGE_OF_ATR_PERCENT = 'change_of_atr_percentage',
  ORDERS_QUANTITY = 'orders_quantity',
  COUNT_TIME_DISTANCE_FIELDS = 'by_time_or_by_distance',
  ENTER_ORDERS = 'enter_orders',
  EXITS_TIME = 'exit_timer_coeff_settings',
  EXITS_TIME_QUANTITY = 'exists_time_quantity',
  EXITS_DISTANCE = 'exit_d_threshold_settings',
  EXITS_DISTANCE_QUANTITY = 'exists_distance_quantity',
  EXITS_DISTANCE_TIME = 'exists_distance_time'
}

export enum EnterOrdersNames {
  ATR_DISTANCE_COEF = 'atr_distance_coefficient',
  SIZE_PERCENT = 'size_percent',
  TIMER_MINUTES = 'timer_minutes'
}

export enum EnterExitProperties {
  TIME_1 = 'change_exit_timer_minutes',
  TIME_2 = 'change_exit_coefficient_by_time',
  DISTANCE_1 = 'change_exit_d_threshold_by_distance',
  DISTANCE_2 = 'change_exit_coefficient_by_distance'
}

export const ExitOrdersSwitchPositionValues = {
  UNSET: '0',
  BY_TIME: '1',
  BY_DISTANCE: '2',
  BY_TIME_AND_DISTANCE: '3'
};

export enum ExitOrdersSwitchPositionLabels {
  BY_TIME = 'By time',
  BY_DISTANCE = 'By distance',
  BY_TIME_AND_DISTANCE = 'By time and distance'
}

export const ExitOrdersSwitchPosition = [
  { value: ExitOrdersSwitchPositionValues.BY_TIME, label: ExitOrdersSwitchPositionLabels.BY_TIME },
  { value: ExitOrdersSwitchPositionValues.BY_DISTANCE, label: ExitOrdersSwitchPositionLabels.BY_DISTANCE },
  {
    value: ExitOrdersSwitchPositionValues.BY_TIME_AND_DISTANCE,
    label: ExitOrdersSwitchPositionLabels.BY_TIME_AND_DISTANCE
  }
];

export enum ExitOrdersNames {
  EXIT_ORDER_PLACE_COEF = 'exit_order_place_coefficient',
  SIZE_TIMER_MINS = 'size_timer_minutes',
  HIGH_LOW_CHANGE_PERCENT = 'high_low_change_percentage',
  HIGH_LOW_CHANGE_TIMER = 'high_low_change_timer',
  IS_EXIST_RULE_BY_TIME = 'is_exit_rule_by_time'
}

export enum ExitTimeDistanceNames {
  EXIT_TIMES = 'exit_timer_coeff_settings',
  EXIT_DISTANCES = 'exit_d_threshold_settings'
}

export interface EnterOrdersFields {
  [EnterOrdersNames.ATR_DISTANCE_COEF]: string;
  [EnterOrdersNames.SIZE_PERCENT]: string;
  [EnterOrdersNames.TIMER_MINUTES]: string;
}

export interface ExitTimeFields {
  [EnterExitProperties.TIME_1]: string;
  [EnterExitProperties.TIME_2]: string;
}

export interface ExitDistanceFields {
  [EnterExitProperties.DISTANCE_1]: string;
  [EnterExitProperties.DISTANCE_2]: string;
}

export enum ExitOptions {
  UNSET = '',
  BY_TIME = '1',
  BY_DISTANCE = '2',
  BY_TIME_AND_DISTANCE = '3'
}

export interface OrderManagementFields {
  [OrderManagementNames.FUTURE_PRICE_COEF]?: string;
  [OrderManagementNames.ATR_TIMER_MINS]: string;
  [OrderManagementNames.CHANGE_OF_ATR_PERCENT]: string;
  [OrderManagementNames.ORDERS_QUANTITY]: string;
  [OrderManagementNames.ENTER_ORDERS]: Array<EnterOrdersFields>;
  [ExitOrdersNames.EXIT_ORDER_PLACE_COEF]: string;
  [ExitOrdersNames.SIZE_TIMER_MINS]: string;
  [ExitOrdersNames.HIGH_LOW_CHANGE_PERCENT]: string;
  [ExitOrdersNames.HIGH_LOW_CHANGE_TIMER]: string;
  [ExitOrdersNames.HIGH_LOW_CHANGE_TIMER]: string;
  [ExitOrdersNames.IS_EXIST_RULE_BY_TIME]?: ExitOptions;
  [ExitTimeDistanceNames.EXIT_TIMES]: Array<ExitTimeFields>;
  [ExitTimeDistanceNames.EXIT_DISTANCES]: Array<ExitDistanceFields>;
  [OrderManagementNames.EXITS_TIME_QUANTITY]: string;
  [OrderManagementNames.EXITS_DISTANCE_QUANTITY]: string;
  [OrderManagementNames.EXITS_DISTANCE_TIME]: ExitOptions;
}

export const initialOrderValues: OrderManagementFields = {
  future_price_coefficient: '',
  atr_timer: '',
  change_of_atr_percentage: '',
  orders_quantity: '',
  enter_orders: [],
  exit_order_place_coefficient: '',
  size_timer_minutes: '',
  high_low_change_percentage: '',
  high_low_change_timer: '',
  is_exit_rule_by_time: ExitOptions.UNSET,
  exit_timer_coeff_settings: [],
  exit_d_threshold_settings: [],
  exists_time_quantity: '',
  exists_distance_quantity: '',
  exists_distance_time: ExitOptions.UNSET
};

export const OrderManagementMap = {
  [OrderTimeDistance.ORDER]: OrderManagementNames.ENTER_ORDERS,
  [OrderTimeDistance.DISTANCE]: ExitTimeDistanceNames.EXIT_DISTANCES,
  [OrderTimeDistance.TIME]: ExitTimeDistanceNames.EXIT_TIMES
};

export const QuantityMap = {
  [OrderTimeDistance.ORDER]: OrderManagementNames.ORDERS_QUANTITY,
  [OrderTimeDistance.DISTANCE]: OrderManagementNames.EXITS_DISTANCE_QUANTITY,
  [OrderTimeDistance.TIME]: OrderManagementNames.EXITS_TIME_QUANTITY
};

export const orderManagementValidationCommon = {
  atr_timer: Yup.number().required('ATR timer is required'),
  change_of_atr_percentage: Yup.number().required('Change of ATR is required'),
  orders_quantity: Yup.number().required('Quantity of orders is required'),
  enter_orders: Yup.array().of(
    Yup.object().shape({
      atr_distance_coefficient: Yup.number().required('Distance is required'),
      size_percent: Yup.number().required('Size is required'),
      timer_minutes: Yup.number().required('ROC timer is required')
    })
  ),
  exit_timer_coeff_settings: Yup.array().when('exists_distance_time', {
    is: (exists_distance_time: ExitOptions) =>
      exists_distance_time === ExitOptions.BY_TIME || exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE,
    then: Yup.array().of(
      Yup.object().shape({
        change_exit_timer_minutes: Yup.number().required('Timer is required'),
        change_exit_coefficient_by_time: Yup.number().required('Coefficient is required')
      })
    )
  }),
  exit_d_threshold_settings: Yup.array().when('exists_distance_time', {
    is: (exists_distance_time: ExitOptions) =>
      exists_distance_time === ExitOptions.BY_DISTANCE || exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE,
    then: Yup.array().of(
      Yup.object().shape({
        change_exit_d_threshold_by_distance: Yup.number().required('Threshold is required'),
        change_exit_coefficient_by_distance: Yup.number().required('Coefficient is required')
      })
    )
  }),
  exists_time_quantity: Yup.string().when('exists_distance_time', {
    is: (exists_distance_time) =>
      exists_distance_time === ExitOptions.BY_TIME || exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE,
    then: Yup.string().notOneOf(['0']).required('Exit quantity is required')
  }),
  exists_distance_quantity: Yup.string().when('exists_distance_time', {
    is: (exists_distance_time) =>
      exists_distance_time === ExitOptions.BY_DISTANCE || exists_distance_time === ExitOptions.BY_TIME_AND_DISTANCE,
    then: Yup.string().notOneOf(['0']).required('Exit quantity is required')
  }),
  exit_order_place_coefficient: Yup.number()
    .min(0, 'Valid value from 0 to 1')
    .max(1, 'Valid value from 0 to 1')
    .required('Exit order place is required'),
  size_timer_minutes: Yup.number().required('Size timer is required'),
  high_low_change_percentage: Yup.number().required('Change of high/low is required'),
  high_low_change_timer: Yup.number().required('Change of high/low timer is required'),
  exists_distance_time: Yup.string().notRequired()
};

export const orderManagementValidationShort = {
  future_price_coefficient: Yup.number().required('Future price is required')
};

export const ENTER_ORDER_FIELDS_LONG = [
  { id: OrderManagementNames.ATR_TIMER_MINS, label: 'ATR timer (min)', placeholder: '10' },
  { id: OrderManagementNames.CHANGE_OF_ATR_PERCENT, label: 'Change of ATR (%)', placeholder: '100' }
];

export const ENTER_ORDER_FIELDS_SHORT = [
  { id: OrderManagementNames.FUTURE_PRICE_COEF, label: 'Future price (ATR coefficient)', placeholder: '100' },
  ...ENTER_ORDER_FIELDS_LONG
];

export const EXIT_ORDER_FIELDS_COMMON = [
  { id: ExitOrdersNames.EXIT_ORDER_PLACE_COEF, label: 'Exit order place (in ATR)', placeholder: '1' },
  { id: ExitOrdersNames.SIZE_TIMER_MINS, label: 'Size timer (min)', placeholder: '10' },
  { id: ExitOrdersNames.HIGH_LOW_CHANGE_PERCENT, label: 'Change of high/low (%)', placeholder: '100' },
  { id: ExitOrdersNames.HIGH_LOW_CHANGE_TIMER, label: 'Change of high/low timer (min)', placeholder: '10' }
];

export const newOrder = { atr_distance_coefficient: '', size_percent: '', timer_minutes: '' };
export const newTimeExit = { change_exit_timer_minutes: '', change_exit_coefficient_by_time: '' };
export const newDistanceExit = { change_exit_d_threshold_by_distance: '', change_exit_coefficient_by_distance: '' };

type GetNewDataItem = typeof newOrder | typeof newTimeExit | typeof newDistanceExit;

export const getNewDataItem = (name: OrderTimeDistance): GetNewDataItem => {
  switch (name) {
    case OrderTimeDistance.ORDER:
      return newOrder;
    case OrderTimeDistance.TIME:
      return newTimeExit;
    default:
      return newDistanceExit;
  }
};
