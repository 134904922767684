import { ColumnAlignOptions } from './randomConstants';
import { formatISODate } from '../helpers/randomHelpers';
import { GetAccountStatsQuery } from '../interfaces/accountStatsShapes';

export enum AccountStatsColIds {
  DIR = 'dir',
  Q_SIGN = 'q_sign',
  P_SIGN = 'p_sign',
  L_SIGN = 'l_sign',
  W_PERCENT = 'w_percent',
  RES_USD = 'res_usd',
  RES_PERCENT = 'res_percent',
  P_SUMM = 'p_summ',
  L_SUMM = 'l_summ',
  PF = 'pf',
  AVG_DEAL_PERCENT = 'avg_deal_percent',
  AVG_PROFIT_PERCENT = 'avg_profit_percent',
  AVG_LOSS_PERCENT = 'avg_loss_percent',
  MAX_PROFIT_PERCENT = 'max_profit_percent',
  MAX_LOSS_PERCENT = 'max_loss_percent',
  AVG_T = 'avg_t',
  MAX_T = 'max_t',
  AVG_IMP_LOSS = 'avg_imp_loss',
  IMP_LOSS = 'imp_loss',
  MAX_DD = 'max_dd',
  MAX_DDD = 'max_ddd',
  RF = 'rf',
  SHARP_RATIO = 'sharp_ratio',
  STDEV_PERCENT = 'stdev_percent'
}

export const ACCOUNT_STATS_TABLE_COL_NAMES = {
  [AccountStatsColIds.DIR]: 'Dir',
  [AccountStatsColIds.Q_SIGN]: 'Q-sign',
  [AccountStatsColIds.P_SIGN]: 'P-sign',
  [AccountStatsColIds.L_SIGN]: 'L-sign',
  [AccountStatsColIds.W_PERCENT]: 'W%',
  [AccountStatsColIds.RES_USD]: 'Res-$',
  [AccountStatsColIds.RES_PERCENT]: 'Res-%',
  [AccountStatsColIds.P_SUMM]: 'P-summ',
  [AccountStatsColIds.L_SUMM]: 'L-summ',
  [AccountStatsColIds.PF]: 'PF',
  [AccountStatsColIds.AVG_DEAL_PERCENT]: 'Avg.D%',
  [AccountStatsColIds.AVG_PROFIT_PERCENT]: 'Avg.P%',
  [AccountStatsColIds.AVG_LOSS_PERCENT]: 'Avg.L%',
  [AccountStatsColIds.MAX_PROFIT_PERCENT]: 'Max.P%',
  [AccountStatsColIds.MAX_LOSS_PERCENT]: 'Max.L%',
  [AccountStatsColIds.AVG_T]: 'Avg.T',
  [AccountStatsColIds.MAX_T]: 'Max.Dur',
  [AccountStatsColIds.AVG_IMP_LOSS]: 'Avg.Imp.Loss',
  [AccountStatsColIds.IMP_LOSS]: 'Imp.Loss',
  [AccountStatsColIds.MAX_DD]: 'MaxDD%',
  [AccountStatsColIds.MAX_DDD]: 'MaxDDD%',
  [AccountStatsColIds.RF]: 'RF',
  [AccountStatsColIds.SHARP_RATIO]: 'SharpeR',
  [AccountStatsColIds.STDEV_PERCENT]: 'Stdev%'
};

export const ACCOUNT_STATS_COLUMNS = [
  {
    id: AccountStatsColIds.DIR,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.DIR],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.Q_SIGN,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.Q_SIGN],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.P_SIGN,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.P_SIGN],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.L_SIGN,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.L_SIGN],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.W_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.W_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.P_SUMM,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.P_SUMM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.L_SUMM,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.L_SUMM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.RES_USD,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.RES_USD],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.RES_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.RES_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.PF,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.PF],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.AVG_DEAL_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.AVG_DEAL_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.AVG_PROFIT_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.AVG_PROFIT_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.AVG_LOSS_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.AVG_LOSS_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.MAX_PROFIT_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.MAX_PROFIT_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.MAX_LOSS_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.MAX_LOSS_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.AVG_T,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.AVG_T],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.MAX_T,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.MAX_T],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.AVG_IMP_LOSS,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.AVG_IMP_LOSS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.IMP_LOSS,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.IMP_LOSS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.MAX_DD,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.MAX_DD],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.MAX_DDD,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.MAX_DDD],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.RF,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.RF],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.SHARP_RATIO,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.SHARP_RATIO],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AccountStatsColIds.STDEV_PERCENT,
    title: ACCOUNT_STATS_TABLE_COL_NAMES[AccountStatsColIds.STDEV_PERCENT],
    align: ColumnAlignOptions.CENTER
  }
];

export enum AccountStatsFilterIDs {
  PROFILE = 'id__in',
  BEFORE_CREATING = 'created_at__lte',
  AFTER_CREATING = 'created_at__gte'
}

export const AccountStatsLabels = {
  [AccountStatsFilterIDs.PROFILE]: 'Profile',
  [AccountStatsFilterIDs.AFTER_CREATING]: 'From',
  [AccountStatsFilterIDs.BEFORE_CREATING]: 'Until'
};

enum FilterAccountStatsValues {
  initValue = ''
}

export const FilterBeforeUntilValues = {
  before: formatISODate(new Date().toISOString(), 'yyyy-MM-dd'),
  until: ''
};

export const ACCOUNT_STAT_FILTERS = [
  {
    id: AccountStatsFilterIDs.PROFILE,
    label: AccountStatsLabels[AccountStatsFilterIDs.PROFILE],
    options: []
  }
];

export const ACCOUNT_STAT_DATE = [
  {
    id: AccountStatsFilterIDs.AFTER_CREATING,
    label: AccountStatsLabels[AccountStatsFilterIDs.AFTER_CREATING],
    fieldName: AccountStatsFilterIDs.AFTER_CREATING,
    value: ''
  },
  {
    id: AccountStatsFilterIDs.BEFORE_CREATING,
    label: AccountStatsLabels[AccountStatsFilterIDs.BEFORE_CREATING],
    fieldName: AccountStatsFilterIDs.BEFORE_CREATING,
    value: AccountStatsFilterIDs.BEFORE_CREATING
  }
];

export const initialAccountStatsValues = {
  [AccountStatsFilterIDs.PROFILE]: FilterAccountStatsValues.initValue as string,
  [AccountStatsFilterIDs.BEFORE_CREATING]: FilterBeforeUntilValues.before,
  [AccountStatsFilterIDs.AFTER_CREATING]: FilterBeforeUntilValues.until
};

export type InitialAccountStatsValuesType = typeof initialAccountStatsValues;

export const DEFAULT_ACCOUNT_STATS_PARAMS: GetAccountStatsQuery = {
  [AccountStatsFilterIDs.PROFILE]: initialAccountStatsValues[AccountStatsFilterIDs.PROFILE],
  [AccountStatsFilterIDs.BEFORE_CREATING]: initialAccountStatsValues[AccountStatsFilterIDs.BEFORE_CREATING],
  [AccountStatsFilterIDs.AFTER_CREATING]: initialAccountStatsValues[AccountStatsFilterIDs.AFTER_CREATING]
};
