import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getAllowedPrivilegesRoute } from '../../helpers/privilegesHelpers';
import { Loader } from '../../components/loader/Loader';

const Privileges = (): JSX.Element => {
  const { groups, users } = usePrivileges();
  const route = getAllowedPrivilegesRoute(groups, users);

  return route ? <Redirect to={route} /> : <Loader />;
};

export default Privileges;
