import { call, ForkEffect, put, select, takeLeading } from 'redux-saga/effects';
import { signalsOrdersTypes } from '../types';
import * as apiHelpers from './apiHelpers/signalsOrdersApiHelpers';
import * as actions from '../actions/signalsOrdersActions';
import * as Shapes from 'interfaces/signalsOrdersShapes';
import { callbackResolver } from 'helpers/callbackHelpers';
import { riskControlObjectSelector } from '../selectors';
import { SignalStateTypes } from '../../constants/signalsOrdersConstants';
import { isArray } from 'lodash';

function* getSignalsWorker({ payload }: Shapes.GetSignalsShape) {
  try {
    const { data } = yield call(apiHelpers.getSignalsHelper, payload.queryObj);
    yield put(actions.putSignals(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload.onError(error);
    callbackResolver(payload.onFinally);
    console.error('getSignalsWorker', error);
  }
}

function* getOrdersWorker({ payload }: Shapes.GetOrdersShape) {
  try {
    const { data } = yield call(apiHelpers.getOrdersHelper, payload.queryObj);
    yield put(actions.putOrders(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload.onError(error);
    callbackResolver(payload.onFinally);
    console.error('getOrdersWorker', error);
  }
}

function* cancelOrderWorker({ payload }: Shapes.CancelOrder) {
  try {
    yield call(apiHelpers.cancelOrderHelper, payload.id);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('cancelOrderWorker', error);
  }
}

function* cancelSignalWorker({ payload }: Shapes.CancelSignal) {
  try {
    yield call(apiHelpers.cancelSignalHelper, payload.id);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('cancelSignalWorker', error);
  }
}

function* closeSignalMarketWorker({ payload }: Shapes.CancelSignal) {
  try {
    yield call(apiHelpers.closeSignalMarketHelper, payload.id);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('closeSignalMarketWorker', error);
  }
}

function* closeSignalMarketBulkWorker({ payload }: Shapes.CancelSignalBulk) {
  try {
    yield call(apiHelpers.closeSignalMarketBulkHelper, payload.ids);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('closeSignalMarketBulkWorker', error);
  }
}

function* closeSignalMarketLostBulkWorker({ payload }: Shapes.CancelSignalLostBulk) {
  try {
    yield call(apiHelpers.closeSignalMarketLostBulkHelper, payload.lostPositions);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('closeSignalMarketLostBulkWorker', error);
  }
}

function* closeSignalLimitWorker({ payload }: Shapes.CancelSignalLimit) {
  try {
    yield call(apiHelpers.closeSignalLimitHelper, payload.id, payload.coefficient);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('closeSignalLimitWorker', error);
  }
}

function* closeSignalBestLimitWorker({ payload }: Shapes.CloseBestLimitShape) {
  try {
    yield call(apiHelpers.closeSignalBestLimitHelper, payload.id);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('closeSignalBestLimitWorker', error);
  }
}

function* closeSignalBestLimitBulkWorker({ payload }: Shapes.CloseBestLimitBulkShape) {
  try {
    yield call(apiHelpers.closeSignalBestLimitBulkHelper, payload.signals_ids);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('closeSignalBestLimitBulkWorker', error);
  }
}

function* exportSignalsWorker({ payload }: Shapes.ExportSignals) {
  try {
    const { data } = yield call(apiHelpers.exportSignalsHelper);
    yield call(apiHelpers.pollingSignals, data?.task_id, payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('exportSignalsWorker', error);
  }
}

function* getSignalsFiltersWorker({ payload }: Shapes.GetSignalFilters) {
  try {
    const profileFilter = yield call(apiHelpers.getSignalsDashboardProfileFilters);
    const pairFilter = yield call(apiHelpers.getSignalsDashboarPairFilters);
    const filters = {
      profiles: profileFilter.data,
      pairs: pairFilter.data.map((pair) => ({
        id: pair.id,
        title: pair.ticker
      }))
    };
    yield put(actions.putFilters(filters));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getSignalsFiltersWorker', error);
  }
}

function* getAdministrationWorker() {
  try {
    const { data } = yield call(apiHelpers.getAdministration);
    yield put(actions.putAdminInfo(data));
  } catch (error) {
    console.error('getAdministrationWorker', error);
  }
}

function* updateAdministrationListWorker({ payload }: Shapes.UpdateAdminShape) {
  try {
    yield call(apiHelpers.updateAdministrationData, payload.id, payload.reqData);
    yield put(actions.putAdminList(payload));
  } catch (error) {
    console.error('updateAdministrationListWorker', error);
  }
}

function* getAdministrationTableInfoWorker({ payload }: Shapes.GetAdminTableShape) {
  try {
    const positions = yield call(apiHelpers.getAdministrationTableInfo, payload.reqParams);
    const tickers = yield call(apiHelpers.getSignalsPairFilters);
    yield put(actions.putAdminTableData({ positions: positions.data.positions, tickers: tickers.data }));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    payload?.onError(error);
    console.error('getAdministrationTableInfoWorker', error);
  }
}

function getRandomNumber() {
  return Math.floor(Math.random() * 1000000000) + 1;
}

const prepareLostData = (data): Shapes.AdminTableRow[] => {
  if (isArray(data)) {
    return data?.map((el) => ({
      ...el,
      id: getRandomNumber(),
      state: SignalStateTypes.EMPTY,
      k_exit: null,
      k_exit_coeff: null,
      profile: null,
      r_pnl: null
    }));
  }
  return [];
};

function* getAdministrationLostPositionWorker({ payload }: Shapes.GetAdminLostTableShape) {
  try {
    const { data } = yield call(apiHelpers.getAdministrationLostPosition);
    yield put(actions.putAdminLostData(prepareLostData(data)));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    console.error('getAdministrationLostPositionWorker', error);
  }
}

function* startRecoveryKExit({ payload }: Shapes.StartRecoveryExitShape) {
  try {
    yield call(apiHelpers.startRecoveryId, payload.id);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('startRecoveryKExit', error);
  }
}

function* skipCheckById({ payload }: Shapes.SkipCheckById) {
  try {
    yield call(apiHelpers.skipCheckById, payload.id, payload.skip_check_by_timer, payload.skip_check_by_distance);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('skipCheckById', error);
  }
}

function* skipCheckBulk({ payload }: Shapes.SkipCheckBulk) {
  try {
    yield call(
      apiHelpers.skipCheckBulk,
      payload.signals_ids,
      payload.skip_check_by_timer,
      payload.skip_check_by_distance
    );
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('skipCheckById', error);
  }
}

function* reducePositionWorker({ payload }: Shapes.ReducePositionShape) {
  try {
    yield call(apiHelpers.reducePosition, payload.id, payload.percent);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload?.onError(error);
    console.error('reducePositionWorker', error);
  }
}

function* reducePositionBulkWorker({ payload }: Shapes.ReducePositionBulkShape) {
  try {
    yield call(apiHelpers.reducePositionBulk, payload.ids, payload.percent);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload?.onError(error);
    console.error('reducePositionBulkWorker', error);
  }
}

function* riskControlWorker({ payload }: Shapes.GetRiskControl) {
  try {
    const { data } = yield call(apiHelpers.riskControl, payload.reqParams);
    yield put(actions.putRiskControl(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    payload?.onError(error);
    console.error('riskControlWorker', error);
  }
}

function* updateControlWorker({ payload }: Shapes.UpdateRiskControl) {
  try {
    const riskData = yield select(riskControlObjectSelector(payload.id));
    yield call(apiHelpers.updateRiskControl, payload.id, riskData);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('updateControlWorker', error);
  }
}

function* createControlWorker({ payload }: Shapes.CreateRiskProperty) {
  try {
    const { data } = yield call(apiHelpers.createRiskControl, payload.reqParams);
    yield put(actions.setRiskControl(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    callbackResolver(payload?.onFinally);
    console.error('createControlWorker', error);
  }
}

export function* signalsOrdersWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLeading(signalsOrdersTypes.GET_SIGNALS, getSignalsWorker);
  yield takeLeading(signalsOrdersTypes.GET_ORDERS, getOrdersWorker);
  yield takeLeading(signalsOrdersTypes.CANCEL_ORDER, cancelOrderWorker);
  yield takeLeading(signalsOrdersTypes.CANCEL_SIGNAL, cancelSignalWorker);
  yield takeLeading(signalsOrdersTypes.CLOSE_SIGNAL_MARKET, closeSignalMarketWorker);
  yield takeLeading(signalsOrdersTypes.CLOSE_SIGNAL_MARKET_BULK, closeSignalMarketBulkWorker);
  yield takeLeading(signalsOrdersTypes.CLOSE_SIGNAL_MARKET_LOST_BULK, closeSignalMarketLostBulkWorker);
  yield takeLeading(signalsOrdersTypes.CLOSE_SIGNAL_LIMIT, closeSignalLimitWorker);
  yield takeLeading(signalsOrdersTypes.CLOSE_SIGNAL_BEST_LIMIT, closeSignalBestLimitWorker);
  yield takeLeading(signalsOrdersTypes.CLOSE_SIGNAL_BEST_LIMIT_BULK, closeSignalBestLimitBulkWorker);
  yield takeLeading(signalsOrdersTypes.EXPORT_SIGNALS, exportSignalsWorker);
  yield takeLeading(signalsOrdersTypes.GET_SIGNAL_FILTERS, getSignalsFiltersWorker);
  yield takeLeading(signalsOrdersTypes.GET_ADMIN, getAdministrationWorker);
  yield takeLeading(signalsOrdersTypes.UPDATE_ADMIN_LIST, updateAdministrationListWorker);
  yield takeLeading(signalsOrdersTypes.GET_ADMIN_TABLE, getAdministrationTableInfoWorker);
  yield takeLeading(signalsOrdersTypes.GET_ADMIN_LOST_POSITIONS, getAdministrationLostPositionWorker);
  yield takeLeading(signalsOrdersTypes.START_RECOVERY_K_EXIT, startRecoveryKExit);
  yield takeLeading(signalsOrdersTypes.SKIP_CHECK_BY_ID, skipCheckById);
  yield takeLeading(signalsOrdersTypes.SKIP_CHECK_BULK, skipCheckBulk);
  yield takeLeading(signalsOrdersTypes.REDUCE_POSITION, reducePositionWorker);
  yield takeLeading(signalsOrdersTypes.REDUCE_POSITION_BULK, reducePositionBulkWorker);
  yield takeLeading(signalsOrdersTypes.GET_RISK_CONTROL, riskControlWorker);
  yield takeLeading(signalsOrdersTypes.UPDATE_RISK_CONTROL, updateControlWorker);
  yield takeLeading(signalsOrdersTypes.CREATE_RISK_CONTROL, createControlWorker);
}
