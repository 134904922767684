import React, { useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { accountDataUpdateRequest } from 'redux/actions/authActions';
import { authSelector, layoutRootSelector } from 'redux/selectors';
import MainLayout from 'components/mainLayout/MainLayout';
import Modal from 'components/modal/MyModal';
import Button from '@material-ui/core/Button';
import AccountSettingsForm from './AccountSettingsForm';
import { AccountDetailsShape } from 'interfaces/authShapes';
import { FieldsNames, validationSchema } from 'constants/accountSettingsConstants';
import { getModalContentIndent, getErrorMessageHelper } from 'helpers/randomHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import s from './AccountSettingsPage.module.scss';
import { useHistory } from 'react-router-dom';

const AccountSettingsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const { isAsideOpen } = useSelector(layoutRootSelector);
  const history = useHistory();

  const [reqErr, setReqErr] = useState<string | null>(null);

  const onSetReqError = (err: ErrorShape) => {
    const errMsg = getErrorMessageHelper(err);
    setReqErr(errMsg);
    onToggleModal();
  };
  const onClearRequestError = () => setReqErr(null);

  const [isEditing, setEditing] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);

  const modalContentIndent = getModalContentIndent(isAsideOpen);
  return (
    <MainLayout>
      <h2 className={cn(s.title, 'default-title')}>Account details</h2>

      <div className={s.formWrapper}>
        <Formik
          enableReinitialize
          initialValues={{
            [FieldsNames.EMAIL]: user?.email ?? '',
            [FieldsNames.OLD_PASS]: '',
            [FieldsNames.NEW_PASS]: ''
          }}
          validationSchema={validationSchema}
          validate={({ old_password, new_password }) => {
            const errors = {};
            const passwordFields = [old_password, new_password];
            const isSomePassFilled = passwordFields.some((item) => !!item);
            const isEveryPassFilled = passwordFields.every((item) => !!item);

            if (isSomePassFilled && !isEveryPassFilled) {
              Object.assign(errors, {
                new_password: 'If you want to change password, please fill in both password fields.'
              });
            }

            if (isEveryPassFilled && old_password === new_password) {
              Object.assign(errors, {
                new_password: 'The new password should differs to old one.'
              });
            }

            return errors;
          }}
          onSubmit={async (
            { email, old_password, new_password }: AccountDetailsShape,
            { setSubmitting }: FormikHelpers<AccountDetailsShape>
          ) => {
            const reqData = { email };
            if (old_password && new_password) Object.assign(reqData, { old_password, new_password });

            if (user?.id) {
              dispatch(accountDataUpdateRequest(reqData, user?.id, onToggleModal, onSetReqError));
            }

            setSubmitting(false);
          }}
        >
          {({ setFieldValue, setFieldError, setFieldTouched }) => {
            return (
              <AccountSettingsForm
                reqErr={reqErr}
                onClearRequestError={onClearRequestError}
                isEditing={isEditing}
                onCloseEditing={() => {
                  setFieldValue('old_password', '');
                  setFieldValue('new_password', '');
                  setFieldError('old_password', undefined);
                  setFieldError('new_password', undefined);
                  setFieldTouched('old_password', false);
                  setFieldTouched('new_password', false);
                  setEditing(false);
                }}
                onOpenEditing={() => setEditing(true)}
              />
            );
          }}
        </Formik>
      </div>

      <Modal
        isOpen={isModalOpen}
        onToggle={onToggleModal}
        stylesProps={{
          paper: {
            left: `calc(50% + ${modalContentIndent}px)`,
            padding: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        <h2 className={cn('default-title', { [s.errorTitle]: reqErr })} style={{ marginBottom: 40 }}>
          {reqErr ?? 'Your data updated.'}
        </h2>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot, text: s.btnText }}
          onClick={() => {
            onToggleModal();
            setEditing(false);
            history.push('/strategy-profiles');
          }}
        >
          Ok
        </Button>
      </Modal>
    </MainLayout>
  );
};

export default AccountSettingsPage;
