import React from 'react';
import cn from 'classnames';
import AsideMenu from 'components/asideMenu/AsideMenu';
import s from './MainLayout.module.scss';
import Notificator from '../notificator/Notificator';

interface MainLayoutProps {
  children: React.ReactNode;
  contentCustomClass?: string;
}

const MainLayout = ({ children, contentCustomClass }: MainLayoutProps): JSX.Element => (
  <>
    <AsideMenu />
    <div className={cn(s.content, { [contentCustomClass]: contentCustomClass })}>
      <Notificator>{children}</Notificator>
    </div>
  </>
);

export default MainLayout;
