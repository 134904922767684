import React, { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import PuffLoader from 'react-spinners/PuffLoader';
import { systemSettingsSelector } from 'redux/selectors';
import { getTradingPairs, syncTradingPairs, changeTradingPair } from 'redux/actions/systemSettingsActions';
import TradingPairsList from './tradingPairsList/TradingPairsList';
import Fade from 'components/reactTransitions/FadeTransitionLong.module.css';
import { getErrorMessageHelper } from 'helpers/randomHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { TradingPairShape } from 'interfaces/systemSettingsShapes';
import { tradingPairsTypes, tradingPairsInitialState, tradingPairsReducer } from './tradingPairsConstants';
import s from './TradingPairs.module.scss';
import { usePrivileges } from '../../../../hooks/usePrivileges';
import { isSectionReadOnly } from '../../../../helpers/privilegesHelpers';

const LOADER_SIZE = 50;
const TRANSITION_TIMEOUT = 250;

const TradingPairs = (): JSX.Element => {
  const [{ isLoading, reqError }, localDispatch] = useReducer(tradingPairsReducer, tradingPairsInitialState);
  const dispatch = useDispatch();
  const { pairs } = usePrivileges();
  const isReadOnly = isSectionReadOnly(pairs);

  const { tradingPairs } = useSelector(systemSettingsSelector);

  const onReqError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    localDispatch({ type: tradingPairsTypes.REQUEST_ERROR, payload: errorMsg });
  };

  const handleSyncData = () => {
    localDispatch({ type: tradingPairsTypes.START_LOADING });
    dispatch(syncTradingPairs(() => localDispatch({ type: tradingPairsTypes.STOP_LOADING }), onReqError));
  };

  const handleChangePairChecked = (e, tradingPair: TradingPairShape) => {
    const reqData = omit({ ...tradingPair, is_active: !tradingPair.is_active }, ['id']);
    dispatch(changeTradingPair(reqData, tradingPair.id, onReqError));
  };

  useEffect(() => {
    dispatch(getTradingPairs(() => localDispatch({ type: tradingPairsTypes.STOP_LOADING }), onReqError));
  }, [dispatch]);

  return (
    <div className={s.wrapper}>
      <h2 className={cn(s.title, 'default-title')}>Trading pairs</h2>

      <div className={s.listWrapper}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={isLoading ? 'loader' : 'collection'}
            timeout={TRANSITION_TIMEOUT}
            classNames={{ ...Fade }}
          >
            {isLoading ? (
              <div className={s.loaderWrapper}>
                <PuffLoader size={LOADER_SIZE} loading={isLoading} />
              </div>
            ) : isEmpty(tradingPairs) ? (
              <div className={s.noDataWrapper}>
                <p className="default-text">No trading pairs yet</p>
                {reqError && <span className={cn(s.reqError, 'errorText')}>{reqError}</span>}
              </div>
            ) : (
              <TradingPairsList isReadOnly={isReadOnly} list={tradingPairs} onChange={handleChangePairChecked} />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>

      {!isReadOnly && (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color="primary"
          onClick={handleSyncData}
          classes={{ outlinedPrimary: s.btnPrimary }}
        >
          Sync
        </Button>
      )}
    </div>
  );
};

export default TradingPairs;
