import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { initializeApp } from 'redux/actions/authActions';
import { authSelector } from 'redux/selectors';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from './Routes';
import { Loader } from './components/loader/Loader';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isInitialized, isVerified } = useSelector(authSelector, shallowEqual);

  useEffect(() => {
    dispatch(initializeApp());
  }, [dispatch]);

  return (
    <div className="app">
      <CssBaseline />
      {isInitialized && <Routes isAuth={isVerified} />}
      {!isInitialized && <Loader />}
    </div>
  );
};

export default App;
