import React, { useState } from 'react';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import s from './CustomizedTextField.module.scss';
import { FormControl, MenuItem } from '@material-ui/core';
import { useClickOutside } from '../../../hooks/useClickOutside';

export interface CustomizedTextFieldProps {
  id: string;
  label?: string;
  placeholder: string;
  value: string | number;
  isError: boolean;
  helperText: string;
  isValid: boolean;
  onBlur: (e) => void;
  onChange: (e) => void;
  title?: string;
  setFieldValue?: (name: string, value) => void;
  onClearRequestError?: () => void;
  inputClass?: string;
  disabled?: boolean;
  options?: Array<{ value: number | string; label: number | string }>;
  min?: number;
  max?: number;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
}

const CustomizedTextField = ({
  id,
  label,
  placeholder,
  value,
  isError,
  helperText,
  isValid,
  inputClass,
  onBlur,
  onChange,
  onClearRequestError,
  disabled = false,
  title = '',
  options,
  setFieldValue
}: CustomizedTextFieldProps): JSX.Element => {
  const [openedOptions, setOpenOptions] = useState(false);
  const ref = useClickOutside(() => setOpenOptions(false));

  return (
    <FormControl style={{ width: '100%', position: 'relative' }}>
      <TextField
        ref={ref}
        title={title}
        autoComplete="off"
        fullWidth
        id={id}
        name={id}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        error={isError}
        helperText={helperText}
        onBlur={onBlur}
        onChange={onChange}
        onClick={() => {
          onClearRequestError?.();
          setOpenOptions(true);
        }}
        disabled={disabled}
        classes={{
          root: cn(s.input, { [s.inputValid]: isValid, [inputClass]: inputClass })
        }}
      />
      {options?.length > 0 && setFieldValue && openedOptions && (
        <div className={s.options}>
          {options.map(({ value, label }) => (
            <MenuItem
              classes={{
                root: s.option
              }}
              key={label}
              onClick={() => {
                setFieldValue(id, +value);
                setOpenOptions(false);
              }}
              value={value.toString()}
            >
              {label}
            </MenuItem>
          ))}
        </div>
      )}
    </FormControl>
  );
};

export default CustomizedTextField;
