import React, { useMemo, useRef } from 'react';
import cn from 'classnames';
import MainLayout from 'components/mainLayout/MainLayout';
import NavTabs from 'components/navTabs/NavTabs';
import s from '../SignalsOrdersPage.module.scss';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { getSignalOrderTabs, isSectionFull, isSectionLimited } from '../../../helpers/privilegesHelpers';
import AdministrationTable from 'components/administrationTable/AdministrationTable';
import AdministrationList from 'components/administrationList/AdministrationList';
import { useSelector } from 'react-redux';
import { getComponentsFieldsSelector } from '../../../redux/selectors';
import { PrivilegesGroupFields } from '../../../interfaces/privilegesShapes';
import { APAColIds } from '../../../constants/privilegesConstants';
import { useResize } from '../../../hooks/useResize';

const minimumListWidth = 270;

const Administration = (): JSX.Element => {
  const privileges = usePrivileges();
  const tabs = getSignalOrderTabs(privileges);
  const { position_administration } = usePrivileges();
  const { fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.APA));
  const hasAdminList =
    (isSectionLimited(position_administration) && fields.indexOf(APAColIds.PROFILE_LIST) >= 0) ||
    isSectionFull(position_administration);

  const ref = useRef();

  const { width } = useResize(ref);
  const tableWidth = useMemo(() => {
    return width / 5 < minimumListWidth ? (width / 5) * 4 - (minimumListWidth - width / 5) : (width / 5) * 4;
  }, [width]);

  const listWidth = useMemo(() => {
    return width / 5 < minimumListWidth ? minimumListWidth : width / 5;
  }, [width]);

  return (
    <MainLayout contentCustomClass={s.signalsContent}>
      <div className={s.titleWrapper}>
        <h2 className={cn('default-title', s.title)}>Active position administration</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <div className={cn({ [s.administration]: hasAdminList })} ref={ref}>
        <div className={s.table} style={hasAdminList ? { width: tableWidth } : {}}>
          <AdministrationTable />
        </div>
        {hasAdminList && (
          <div className={s.list} style={{ width: listWidth }}>
            <AdministrationList />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Administration;
