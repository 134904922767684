import React from 'react';
import cn from 'classnames';
import s from './SummaryTextRow.module.scss';

interface SummaryTextRowProps {
  label: string;
  value: string | number | Array<{ id; title: string }>;
  isColumn?: boolean;
  isValueList?: boolean;
  onLinkClick?: () => void | null;
}

const SummaryTextRow = ({ label, value, isColumn, isValueList, onLinkClick }: SummaryTextRowProps): JSX.Element => {
  const currentValue =
    isValueList && typeof value === 'object'
      ? value.reduce((acc, { title }, idx, arr) => `${acc} ${title}${arr.length - 1 === idx ? '' : ','}`, ``)
      : value;

  return (
    <div className={cn(s.row, { [s.column]: isColumn })}>
      <span className={cn(s.rowText, { [s.labelOnTop]: isColumn })}>{label}</span>
      <span className={cn(s.rowText, { [s.link]: onLinkClick })} onClick={onLinkClick ?? null}>
        {currentValue}
      </span>
    </div>
  );
};

export default SummaryTextRow;
