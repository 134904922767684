import React, { FC } from 'react';
import s from '../SignalsOrdersPage.module.scss';
import NavTabs from 'components/navTabs/NavTabs';
import MainLayout from 'components/mainLayout/MainLayout';
import RiskControlTable from 'components/riskControlTable/RiskControlTable';
import { getSignalOrderTabs } from '../../../helpers/privilegesHelpers';
import { usePrivileges } from '../../../hooks/usePrivileges';

const RiskControlPage: FC = () => {
  const privileges = usePrivileges();
  const tabs = getSignalOrderTabs(privileges);
  return (
    <MainLayout contentCustomClass={s.signalsContent}>
      <div className={s.titleWrapper}>
        <h2 className="default-title">Risk Control Service</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <RiskControlTable />
    </MainLayout>
  );
};

export default RiskControlPage;
