import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeBestLimit,
  closeBestLimitBulk,
  closeSignalLimit,
  closeSignalLostMarketBulk,
  closeSignalMarket,
  closeSignalMarketBulk,
  getAdminLostData,
  getAdminTableData,
  reducePosition,
  reducePositionBulk,
  skipCheckBulkByDistance,
  skipCheckBulkByTimer,
  skipCheckByDistance,
  skipCheckByTimer,
  startRecoveryKExit
} from 'redux/actions/signalsOrdersActions';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { adminPageRenderSwitch, createAdministrationRow } from 'helpers/signalOrdersHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { AdminTableRow, AdminTableShape, LimitCoefficient, LostPositionLoseDTO } from 'interfaces/signalsOrdersShapes';
import { ButtonColorTypes, HARD_PER_PAGE } from 'constants/randomConstants';
import {
  ADMINISTRATION_COLUMNS,
  ADMINISTRATION_DEFAULTS,
  AdministrationColIds,
  SERVER_CLIENT_APA_COLS_MAP,
  SortingAdministrationValues
} from 'constants/signalsOrdersConstants';
import MyModal from '../modal/MyModal';
import s from './AdministrationTable.module.scss';
import { administrationDataSelector, getComponentsFieldsSelector } from '../../redux/selectors';
import ReducePercentForm from './reducePercentForm/ReducePercentForm';
import { showMessage } from '../../redux/actions/utilsActions';
import CancelLimitForm from '../signalsTable/CancelLimitForm';
import isNil from 'lodash/isNil';
import { usePrivileges } from '../../hooks/usePrivileges';
import { PrivilegesGroupFields, Status } from '../../interfaces/privilegesShapes';
import { isSectionLimited } from '../../helpers/privilegesHelpers';
import { removeEmptyParams } from '../../helpers/emptyParamsRemoverHelpers';
import { AdministrationFilters } from '../../interfaces/administrationShapes';
import TradingPairsSelect from '../tables/strategyTickers/tradingPairsSelect/TradingPairsSelect';
import { getTradingPairs } from '../../redux/actions/systemSettingsActions';
import { CustomCheckbox } from '../ui/CustomCheckbox';
import Button from '@material-ui/core/Button';
import { ErrorRowContext, ErrorRowKeys } from '../../constants/privilegesConstants';
import Pieces from 'components/pieces/Pieces';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import cn from 'classnames';
import { number } from 'yup';

enum AdminActions {
  REDUCE_POSITION,
  CLOSE_POSITION_BY_MARKET
}

const AdministrationTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const administrationData = useSelector(administrationDataSelector);
  const { position_administration } = usePrivileges();
  const { fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.APA));

  const [isOpenLimitCloser, setOpenLimitCloser] = useState(false);
  const onToggleModalLimitCloser = useCallback(() => setOpenLimitCloser((prev) => !prev), []);

  const [isOpen, setOpen] = useState(false);
  const onToggleModal = useCallback(() => setOpen((prev) => !prev), []);

  const idRef = useRef<string[]>([]);
  const [reqParams, setReqParams] = useState<AdministrationFilters>(ADMINISTRATION_DEFAULTS);
  const [filterParams, setFilterParams] = useState<AdministrationFilters>(ADMINISTRATION_DEFAULTS);
  const [disabledFilter, setDisabledFilter] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const checkPermission = (field) => {
    if (position_administration === Status.NP) return false;
    if (position_administration === Status.LIMITED) return fields?.includes(field);
    return true;
  };

  const onClearReqError = () => setReqErr(null);
  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [reqFormError, setReqFormErr] = useState<null | string>(null);
  const onSetFormError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqFormErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [signalId, setSignalId] = useState<null | number>(null);

  const onShowMessage = useCallback(() => {
    dispatch(showMessage());
  }, [dispatch]);

  const onReducePosition = useCallback(
    (reducing: number) => {
      dispatch(
        reducePosition(
          +idRef.current[0],
          reducing,
          () => {
            onShowMessage();
            onToggleModal();
          },
          onSetFormError
        )
      );
    },
    [dispatch, onShowMessage, onToggleModal]
  );

  const onReducePositionBulk = useCallback(
    (reducing: number) => {
      dispatch(
        reducePositionBulk(
          idRef.current,
          reducing,
          () => {
            onShowMessage();
            onToggleModal();
          },
          onSetFormError
        )
      );
    },
    [dispatch, onShowMessage, onToggleModal]
  );

  const onKERecovery = (id: number) => {
    dispatch(startRecoveryKExit(id, onShowMessage));
  };

  useEffect(() => {
    onToggleLoading();
    dispatch(
      getAdminTableData(removeEmptyParams(reqParams), () => dispatch(getAdminLostData(onToggleLoading)), onSetError)
    );
  }, [reqParams, dispatch]);

  const onAction = (colId: AdministrationColIds, id: number) => {
    switch (colId) {
      case AdministrationColIds.REDUCE_P:
        idRef.current = [String(id)];
        setIsBulk(false);
        setReqFormErr(null);
        onToggleModal();
        break;
      case AdministrationColIds.KE_RECOVERY:
        onKERecovery(id);
        break;
      default:
        return null;
    }
  };

  const onCloseMarket = useCallback(
    (id: number) => {
      onToggleLoading();
      dispatch(
        closeSignalMarket(
          id,
          () => {
            dispatch(
              getAdminTableData(
                removeEmptyParams(reqParams),
                () => dispatch(getAdminLostData(onToggleLoading)),
                onSetError
              )
            );
          },
          (err) => {
            onSetError(err);
            onToggleLoading();
          }
        )
      );
    },
    [dispatch, reqParams]
  );

  const onCloseMarketBulk = useCallback(
    (ids: string[], turnLoaders: boolean) => {
      if (turnLoaders) {
        onToggleLoading();
      }
      dispatch(
        closeSignalMarketBulk(
          ids,
          () => {
            dispatch(
              getAdminTableData(
                removeEmptyParams(reqParams),
                () =>
                  dispatch(
                    getAdminLostData(() => {
                      if (turnLoaders) {
                        onToggleLoading();
                      }
                    })
                  ),
                onSetError
              )
            );
          },
          (err) => {
            onSetError(err);
            if (turnLoaders) {
              onToggleLoading();
            }
          }
        )
      );
    },
    [dispatch, reqParams]
  );

  const onCloseMarketLostBulk = useCallback(
    (lostPositions: LostPositionLoseDTO[]) => {
      onToggleLoading();
      dispatch(
        closeSignalLostMarketBulk(
          lostPositions,
          () => {
            dispatch(
              getAdminTableData(
                removeEmptyParams(reqParams),
                () => dispatch(getAdminLostData(onToggleLoading)),
                onSetError
              )
            );
          },
          onSetError
        )
      );
    },
    [dispatch, reqParams]
  );

  const onBestLimit = useCallback(
    (id: number) => {
      onToggleLoading();
      dispatch(
        closeBestLimit(
          id,
          () => {
            dispatch(
              getAdminTableData(
                removeEmptyParams(reqParams),
                () => dispatch(getAdminLostData(onToggleLoading)),
                onSetError
              )
            );
          },
          (err) => {
            onSetError(err);
            onToggleLoading();
          }
        )
      );
    },
    [dispatch, reqParams]
  );

  const onBestLimitBulk = () => {
    onToggleLoading();
    dispatch(
      closeBestLimitBulk(
        Object.keys(pickers).map(Number),
        () => {
          dispatch(
            getAdminTableData(
              removeEmptyParams(reqParams),
              () => dispatch(getAdminLostData(onToggleLoading)),
              onSetError
            )
          );
        },
        (err) => {
          onSetError(err);
          onToggleLoading();
        }
      )
    );
  };

  const onCloseLimit = useCallback(
    (coefficient: LimitCoefficient) => {
      if (!isNil(signalId)) {
        onToggleLoading();
        dispatch(
          closeSignalLimit(
            signalId,
            coefficient,
            () => {
              dispatch(
                getAdminTableData(
                  removeEmptyParams(reqParams),
                  () =>
                    dispatch(
                      getAdminLostData(() => {
                        onToggleLoading();
                        onToggleModalLimitCloser();
                      })
                    ),
                  onSetError
                )
              );
            },
            (err) => {
              onSetError(err);
              onToggleLoading();
            }
          )
        );
      }
    },
    [dispatch, onToggleModalLimitCloser, reqParams, signalId]
  );

  const handleCloseMarketPosition = (id: number) => {
    const foundRow = rows.find((row) => row.id === id);
    if (foundRow.isLostPosition) {
      const reqData: LostPositionLoseDTO = {
        pair: foundRow.ticker,
        side: foundRow.side,
        quantity: foundRow.active_p_coins
      };
      onCloseMarketLostBulk([reqData]);
    } else {
      onCloseMarket(id);
    }
  };

  const onCloseBy = (colId: AdministrationColIds, id: number) => {
    switch (colId) {
      case AdministrationColIds.CLOSE_MARKET:
        handleCloseMarketPosition(id);
        break;

      case AdministrationColIds.CLOSE_LIMIT:
        setSignalId(id);
        setReqFormErr(null);
        onToggleModalLimitCloser();
        break;

      default:
        return null;
    }
  };

  const onSkipByTimer = (id: number, state: boolean) => {
    onToggleLoading();
    dispatch(
      skipCheckByTimer(id, !state, () => {
        dispatch(
          getAdminTableData(
            reqParams,
            () => onToggleLoading(),
            () => console.log('chage skip by timer error')
          )
        );
      })
    );
  };

  const onSkipByDistance = (id: number, state: boolean) => {
    onToggleLoading();
    dispatch(
      skipCheckByDistance(id, !state, () => {
        dispatch(
          getAdminTableData(
            reqParams,
            () => onToggleLoading(),
            () => console.log('chage skip by distance error')
          )
        );
      })
    );
  };

  const [canSkipByTimer, setSkipByTimer] = useState({ canUpdate: false, state: false });
  const [canSkipByDistance, setSkipByDistance] = useState({ canUpdate: false, state: false });

  const onSkipBulkTimer = (state: boolean) => {
    onToggleLoading();
    dispatch(
      skipCheckBulkByTimer(Object.keys(pickers).map(Number), !state, () => {
        setSkipByTimer((prevState) => ({ ...prevState, state: !state }));
        dispatch(
          getAdminTableData(
            reqParams,
            () => onToggleLoading(),
            () => console.log('bulk chage skip by distance error')
          )
        );
      })
    );
  };

  const onSkipBulkDistance = (state: boolean) => {
    onToggleLoading();
    dispatch(
      skipCheckBulkByDistance(Object.keys(pickers).map(Number), !state, () => {
        setSkipByDistance((prevState) => ({ ...prevState, state: !state }));
        dispatch(
          getAdminTableData(
            reqParams,
            () => onToggleLoading(),
            () => console.log('bulk chage skip by distance error')
          )
        );
      })
    );
  };

  const [pickers, setPickers] = useState<{ [id: number]: boolean }>({});

  const handleCheckAllBoolean = (values: { [val: number]: boolean }, shouldBe: boolean) => {
    return Object.values(values).every((value) => (shouldBe ? !!value : !value));
  };

  const onChangePicker = (id: number, isChecked: boolean) => {
    const picked = { ...pickers, [id]: isChecked };

    const pickedRows = Object.keys(picked).map((id) =>
      administrationData.positions.find((item) => item.id === Number(id))
    );

    const checkCanSkipByTimer = () => {
      if (!checkPermission('skip_check_k_exit')) return false;
      return pickedRows.every((obj) => obj.skip_check_by_timer === pickedRows[0].skip_check_by_timer);
    };

    const checkCanSkipByDistance = () => {
      if (!checkPermission('skip_check_k_exit')) return false;
      return pickedRows.every((obj) => obj.skip_check_by_distance === pickedRows[0].skip_check_by_distance);
    };

    setSkipByTimer({
      canUpdate: checkCanSkipByTimer(),
      state: pickedRows[0].skip_check_by_timer
    });

    setSkipByDistance({
      canUpdate: checkCanSkipByDistance(),
      state: pickedRows[0].skip_check_by_distance
    });

    setPickers(picked);

    if (Object.keys(picked).length === administrationData.positions.length && handleCheckAllBoolean(picked, true)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  const getContent = (colId, row: AdminTableShape) => {
    const canSkipKexit = checkPermission('skip_check_k_exit');

    return adminPageRenderSwitch(
      colId,
      row,
      onAction,
      onCloseBy,
      canSkipKexit,
      onSkipByTimer,
      onSkipByDistance,
      onBestLimit,
      onChangePicker,
      pickers[row.id] ?? false,
      pickers
    );
  };

  const filteredColumns = useMemo(() => {
    if (isSectionLimited(position_administration)) {
      return [
        ADMINISTRATION_COLUMNS[0],
        ...ADMINISTRATION_COLUMNS.slice(1).filter((col) => fields.indexOf(SERVER_CLIENT_APA_COLS_MAP[col.id]) >= 0)
      ];
    }

    return ADMINISTRATION_COLUMNS;
  }, [fields, position_administration]);

  const onSorting = (value: string, withMinusSign: boolean) => {
    const ordering = withMinusSign ? `-${SortingAdministrationValues[value]}` : SortingAdministrationValues[value];
    setFilterParams((prev) => {
      const updatedFilterParams = { ...prev, ordering };
      setDisabledFilter(true);
      setReqParams(updatedFilterParams);
      return updatedFilterParams;
    });
  };

  const rows = useMemo(
    () => administrationData.positions.map((item: AdminTableRow) => createAdministrationRow(item)),
    [administrationData.positions]
  );

  const tradingPairsOptions = useMemo(() => {
    return administrationData.tickers.reduce(
      (acc, val) => [...acc, { value: val.ticker, label: `${val.ticker} (${val.size})` }],
      []
    );
  }, [administrationData.tickers]);

  useEffect(() => {
    dispatch(getTradingPairs());
  }, [dispatch]);

  const [pairs, setPairs] = useState<string[]>([]);
  const [selected, setSelected] = useState(false);

  const handleCheckAll = useCallback(
    (isChecked: boolean) => {
      setSelected(isChecked);
      const result = rows.reduce((acc, val) => {
        return { ...acc, [val.id]: isChecked };
      }, {});
      setPickers(result);
    },
    [rows]
  );

  const hasCheckedValues = useMemo(() => {
    const arrayOfPicked = Object.values(pickers);
    return (arrayOfPicked.length !== 0 && arrayOfPicked.some(Boolean)) || false;
  }, [pickers]);

  const hasCheckedLostValues = useMemo(() => {
    const pickedKeys = Object.entries(pickers).reduce((acc, [key, val]) => (val ? [...acc, key] : acc), []);
    return rows.some((el) => el.isLostPosition && pickedKeys.indexOf(String(el.id)) >= 0);
  }, [pickers, rows]);

  const [isBulk, setIsBulk] = useState(false);

  const handleAction = (actionType: AdminActions) => {
    const pickedKeys = Object.entries(pickers).reduce((acc, [key, val]) => (val ? [...acc, key] : acc), []);
    if (actionType === AdminActions.REDUCE_POSITION) {
      idRef.current = pickedKeys;
      setIsBulk(true);
      setReqFormErr(null);
      onToggleModal();
    } else {
      const { regular, lost } = rows.reduce(
        (acc, val) => {
          if (!val.isLostPosition && pickedKeys.indexOf(String(val.id)) >= 0) {
            return { ...acc, regular: [...acc.regular, val.id] };
          }
          if (val.isLostPosition && pickedKeys.indexOf(String(val.id)) >= 0) {
            return { ...acc, lost: [...acc.lost, { quantity: val.active_p_coins, pair: val.ticker, side: val.side }] };
          }
          return acc;
        },
        { regular: [], lost: [] }
      );
      if (regular.length > 0) {
        const runLoadersWhen = lost.length === 0;
        onCloseMarketBulk(regular, runLoadersWhen);
      }
      if (lost.length > 0) {
        onCloseMarketLostBulk(lost);
      }
    }
  };

  const handleUpdatePairs = (value) => {
    setPairs(value);
    setFilterParams((prev) => {
      const updatedFilterParams = { ...prev, pair__ticker: value.join() };
      setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
      return updatedFilterParams;
    });
  };

  const sortBasedOnLostPosition = useCallback((a: AdminTableShape, b: AdminTableShape) => {
    if (a.isLostPosition && !b.isLostPosition) {
      return -1;
    }
    if (!a.isLostPosition && b.isLostPosition) {
      return 1;
    }
    return 0;
  }, []);

  const sortedRows = useMemo(() => rows.sort(sortBasedOnLostPosition), [sortBasedOnLostPosition, rows]);

  const [isLostPositionsOnly, setLostPositionsOnly] = useState(false);

  const handleFilterChange = () => {
    setPickers({});
    setSelected(false);
    setLostPositionsOnly((prev) => !prev);
  };

  const filteredRows = useMemo(() => {
    return isLostPositionsOnly ? sortedRows.filter((rows) => rows.isLostPosition) : sortedRows;
  }, [isLostPositionsOnly, sortedRows]);

  const dataPieces = useMemo(() => {
    return filteredRows.reduce(
      (acc, val) => {
        return {
          active_p_size_usd: acc.active_p_size_usd + +val.active_p_size_usd,
          u_pnl: acc.u_pnl + +val.u_pnl
        };
      },
      { active_p_size_usd: 0, u_pnl: 0 }
    );
  }, [filteredRows]);

  const hasLostPositions = useMemo(() => {
    return rows.some((row) => row.isLostPosition);
  }, [rows]);

  const getCustomTitle = useCallback(() => {
    if (filteredRows.length > 0) {
      return (
        <CustomCheckbox value={selected} onChange={(e) => handleCheckAll(e.target.checked)} name="main_selector" />
      );
    }
    return null;
  }, [selected, handleCheckAll, filteredRows]);

  return (
    <div className={s.adminContainer}>
      <div className={s.admin}>
        <div className={s.adminFilter}>
          {filteredRows.length > 0 && (
            <TradingPairsSelect values={pairs} options={tradingPairsOptions} handleUpdateValue={handleUpdatePairs} />
          )}
          {hasCheckedValues && (
            <div className={s.buttons}>
              {checkPermission('reduce_p') && !hasCheckedLostValues && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  color={ButtonColorTypes.DEFAULT}
                  onClick={() => handleAction(AdminActions.REDUCE_POSITION)}
                  className={s.btn}
                >
                  REDUCE-P
                </Button>
              )}
              {checkPermission('close_market') && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  color={ButtonColorTypes.DEFAULT}
                  onClick={() => handleAction(AdminActions.CLOSE_POSITION_BY_MARKET)}
                  className={s.btn}
                >
                  CLOSE (MARKET)
                </Button>
              )}
              {checkPermission('best_limit') && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  color={ButtonColorTypes.DEFAULT}
                  onClick={() => onBestLimitBulk()}
                  className={s.btn}
                >
                  BEST LIMIT
                </Button>
              )}
              {canSkipByTimer.canUpdate && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  color={ButtonColorTypes.DEFAULT}
                  onClick={() => onSkipBulkTimer(canSkipByTimer.state)}
                  className={s.btn}
                >
                  Change k-exit (timer)
                </Button>
              )}
              {canSkipByDistance.canUpdate && (
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  color={ButtonColorTypes.DEFAULT}
                  onClick={() => onSkipBulkDistance(canSkipByDistance.state)}
                  className={s.btn}
                >
                  Change k-exit (distance)
                </Button>
              )}
            </div>
          )}
          {hasLostPositions && (
            <FormControlLabel
              className={cn(s.filterFormControl, s.filterFlexed)}
              label="Display only lost positions"
              control={
                <Checkbox
                  color="primary"
                  style={{ transform: 'scale(1.2)' }}
                  checked={isLostPositionsOnly}
                  value={isLostPositionsOnly}
                  onChange={handleFilterChange}
                />
              }
            />
          )}
        </div>
        <div className={s.flexed}>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            type="button"
            color={ButtonColorTypes.DEFAULT}
            classes={{ root: s.filterButton }}
            disabled={disabledFilter}
            onClick={() => {
              setReqParams(filterParams);
              setDisabledFilter(true);
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <ErrorRowContext.Provider value={{ errorKey: ErrorRowKeys.LostPosition }}>
        <CustomizedTable
          count={administrationData.positions.length}
          columns={filteredColumns}
          rows={filteredRows}
          isLoading={isLoading}
          error={reqError}
          emptyRowsMsg="There are no administration data yet..."
          getContent={getContent}
          getCollapsibleContent={() => null}
          rowsPerPageOptions={[HARD_PER_PAGE]}
          customPerPage={HARD_PER_PAGE}
          offPerPage
          sortHandler={onSorting}
          getCustomTitle={getCustomTitle}
          hasSortingOption
          hidePagination
          classes={{
            tableWrapper: s.tableContainer,
            wrapperClass: s.signalsContent,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            }
          }}
        />
      </ErrorRowContext.Provider>
      <MyModal isOpen={isOpen} onToggle={onToggleModal}>
        <ReducePercentForm
          onCancel={onToggleModal}
          onSubmit={isBulk ? onReducePositionBulk : onReducePosition}
          reqError={reqFormError}
          onClearReqError={onClearReqError}
        />
      </MyModal>
      <MyModal isOpen={isOpenLimitCloser} onToggle={onToggleModalLimitCloser}>
        <CancelLimitForm
          onCancel={onToggleModalLimitCloser}
          onSubmit={onCloseLimit}
          reqError={reqFormError}
          onClearReqError={onClearReqError}
        />
      </MyModal>
      {filteredRows.length > 0 && <Pieces data={dataPieces} />}
    </div>
  );
};

export default AdministrationTable;
