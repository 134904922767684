import React from 'react';
import MainLayout from 'components/mainLayout/MainLayout';
import StrategyProfileTable from '../../../components/tables/strategyProfile/StrategyProfileTable';

const StrategyProfilesPage = (): JSX.Element => {
  return (
    <MainLayout>
      <StrategyProfileTable />
    </MainLayout>
  );
};

export default StrategyProfilesPage;
