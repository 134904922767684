import React, { useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import cn from 'classnames';
import NumberFormatField from 'components/fields/numberFormatField/NumberFormatField';
import OutlinedSelect from 'components/selects/outlinedSelect/OutlinedSelect';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  ATR_ALGO_OPTIONS,
  ATR_MINUS_1_TYPE,
  ATR_MINUS_1_TYPE_OPTIONS,
  ATR_MINUS_1_VALUE,
  ATR_MINUS_2_TYPE,
  ATR_MINUS_2_VALUE,
  ATR_N_PERIODS,
  ATR_SMOOTH_ALGO,
  ATR_THRESHOLD,
  ENABLE_SEC_ATR,
  IS_ATR_MINUS_1,
  KeyParametersFormFields,
  PRICE_DIRECTION,
  PRICE_DIRECTIONS_OPTIONS,
  SIGNAL_DUR_MINS,
  START_COEFFICIENT,
  TIME_FRAME_OPTIONS,
  TIMEFRAME,
  TIMER_MINS,
  REVERT_THRESHOLD,
  TR_IN_ATR_THRESHOLD
} from 'constants/strategyProfilesAddConstants';
import s from './KeyParameters.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import { AtrMinus1Types, StrategyProfileShape } from '../../../interfaces/strategyProfilesShapes';

interface KeyParametersFormProps {
  profile?: StrategyProfileShape | null;
  target: FormTarget;
  reqErr: null | string;
  onCancel: () => void;
  onClearReqError: () => void;
  customInputClass?: string;
  customWrapperClass?: string;
}

const KeyParametersForm = ({
  profile,
  target,
  onCancel,
  onClearReqError,
  reqErr,
  customInputClass,
  customWrapperClass
}: KeyParametersFormProps): JSX.Element => {
  const { errors, values, touched, handleChange, handleBlur, handleSubmit, setValues, setFieldTouched } =
    useFormikContext<KeyParametersFormFields>();

  const getIsError = (id) => (touched[id] && !!errors[id]) || !!reqErr;
  const getErrorMsg = (id) => touched[id] && errors[id];
  const getIsValid = (id) => !!touched[id] && !errors[id] && !reqErr;

  const [isPriceDirectionChanged, setPriceDirectionChanged] = useState(false);
  const prevPriceDirection = profile?.settings?.signal_settings?.price_direction;

  useEffect(() => {
    const currentPriceDirection = values[PRICE_DIRECTION];
    if (currentPriceDirection !== prevPriceDirection && prevPriceDirection !== undefined) {
      setPriceDirectionChanged(true);
    } else {
      setPriceDirectionChanged(false);
    }
  }, [prevPriceDirection, values]);

  const onEnableSecATR = () => {
    if (!values[ENABLE_SEC_ATR]) {
      setFieldTouched(ATR_MINUS_2_TYPE, false);
      setFieldTouched(ATR_MINUS_2_VALUE, false);
    }
    setValues({
      ...values,
      [ENABLE_SEC_ATR]: !values[ENABLE_SEC_ATR],
      ...(!values[ENABLE_SEC_ATR] && { [ATR_MINUS_2_VALUE]: '' }),
      ...(!values[ENABLE_SEC_ATR] && { [ATR_MINUS_1_TYPE]: AtrMinus1Types.GTE })
    });
  };

  const onEnableART = () => {
    setValues({
      ...values,
      [IS_ATR_MINUS_1]: !values[IS_ATR_MINUS_1],
      [ATR_MINUS_1_TYPE]: AtrMinus1Types.GTE,
      [ATR_MINUS_1_VALUE]: '0',
      [ENABLE_SEC_ATR]: false,
      [ATR_MINUS_2_VALUE]: ''
    });
  };

  return (
    <Form className={s.form}>
      <div className={customWrapperClass}>
        <OutlinedSelect
          id={TIMEFRAME}
          options={TIME_FRAME_OPTIONS}
          value={values[TIMEFRAME]}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Working time frame"
          isError={getIsError(TIMEFRAME)}
          errorMsg={getErrorMsg(TIMEFRAME)}
          formControlClass={cn(s.selectRoot, { [customInputClass]: customInputClass })}
          onClearReqError={onClearReqError}
        />

        <OutlinedSelect
          id={PRICE_DIRECTION}
          options={PRICE_DIRECTIONS_OPTIONS}
          value={values[PRICE_DIRECTION]}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Price direction"
          isError={getIsError(PRICE_DIRECTION)}
          errorMsg={getErrorMsg(PRICE_DIRECTION)}
          formControlClass={cn(s.selectRoot, { [customInputClass]: customInputClass })}
          onClearReqError={onClearReqError}
        />

        <div className={s.twoInputsWrapper}>
          <NumberFormatField
            inputProps={{
              id: START_COEFFICIENT,
              label: 'SP(start point, in ATR)',
              placeholder: '1',
              value: values[START_COEFFICIENT],
              isError: getIsError(START_COEFFICIENT),
              isValid: getIsValid(START_COEFFICIENT),
              helperText: getErrorMsg(START_COEFFICIENT),
              inputClass: cn(s.input, { [customInputClass]: customInputClass }),
              onBlur: handleBlur,
              onChange: handleChange,
              onClearRequestError: onClearReqError
            }}
          />

          <NumberFormatField
            inputProps={{
              id: TIMER_MINS,
              label: 'ROC timer (for SP, min)',
              placeholder: '2',
              value: values[TIMER_MINS],
              isError: getIsError(TIMER_MINS),
              isValid: getIsValid(TIMER_MINS),
              helperText: getErrorMsg(TIMER_MINS),
              inputClass: cn(s.input, { [customInputClass]: customInputClass }),
              onBlur: handleBlur,
              onChange: handleChange,
              onClearRequestError: onClearReqError
            }}
          />
        </div>

        <NumberFormatField
          inputProps={{
            id: ATR_THRESHOLD,
            label: 'ATR threshold (%)',
            placeholder: '100',
            value: values[ATR_THRESHOLD],
            isError: getIsError(ATR_THRESHOLD),
            isValid: getIsValid(ATR_THRESHOLD),
            helperText: getErrorMsg(ATR_THRESHOLD),
            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
            onBlur: handleBlur,
            onChange: handleChange,
            onClearRequestError: onClearReqError
          }}
        />

        <NumberFormatField
          inputProps={{
            id: ATR_N_PERIODS,
            label: 'N-periods (for ATR)',
            placeholder: '5 - 50',
            value: values[ATR_N_PERIODS],
            isError: getIsError(ATR_N_PERIODS),
            isValid: getIsValid(ATR_N_PERIODS),
            helperText: getErrorMsg(ATR_N_PERIODS),
            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
            onBlur: handleBlur,
            onChange: handleChange,
            onClearRequestError: onClearReqError
          }}
        />

        <OutlinedSelect
          id={ATR_SMOOTH_ALGO}
          options={ATR_ALGO_OPTIONS}
          value={values[ATR_SMOOTH_ALGO]}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Smoothing (for ATR)"
          isError={getIsError(ATR_SMOOTH_ALGO)}
          errorMsg={getErrorMsg(ATR_SMOOTH_ALGO)}
          formControlClass={cn(s.selectRoot, { [customInputClass]: customInputClass })}
          onClearReqError={onClearReqError}
        />

        <NumberFormatField
          inputProps={{
            id: SIGNAL_DUR_MINS,
            label: 'Signal max duration (min)',
            placeholder: '1',
            value: values[SIGNAL_DUR_MINS],
            isError: getIsError(SIGNAL_DUR_MINS),
            isValid: getIsValid(SIGNAL_DUR_MINS),
            helperText: getErrorMsg(SIGNAL_DUR_MINS),
            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
            onBlur: handleBlur,
            onChange: handleChange,
            onClearRequestError: onClearReqError
          }}
        />
        <div className={s.threeInputsWrapper}>
          <div className={s.range_settings}>
            <FormControlLabel
              label={'Is ATR-1 enabled?'}
              control={
                <Checkbox
                  color="primary"
                  style={{ transform: 'scale(1)' }}
                  checked={values[IS_ATR_MINUS_1]}
                  name={IS_ATR_MINUS_1}
                  onChange={onEnableART}
                />
              }
            />
            {values[IS_ATR_MINUS_1] && (
              <FormControlLabel
                label={values[ENABLE_SEC_ATR] ? 'Disable second' : 'Enable second'}
                control={
                  <Checkbox
                    color="primary"
                    style={{ transform: 'scale(1)' }}
                    checked={values[ENABLE_SEC_ATR]}
                    name={ENABLE_SEC_ATR}
                    onChange={onEnableSecATR}
                  />
                }
              />
            )}
          </div>

          {values[IS_ATR_MINUS_1] && (
            <div className={s.range_settings}>
              <OutlinedSelect
                id={ATR_MINUS_1_TYPE}
                options={values[ENABLE_SEC_ATR] ? [ATR_MINUS_1_TYPE_OPTIONS[0]] : ATR_MINUS_1_TYPE_OPTIONS}
                value={values[ATR_MINUS_1_TYPE]}
                onChange={handleChange}
                onBlur={handleBlur}
                label="ATR-1 type"
                isError={getIsError(ATR_MINUS_1_TYPE)}
                errorMsg={getErrorMsg(ATR_MINUS_1_TYPE)}
                onClearReqError={onClearReqError}
              />
              {!values[ENABLE_SEC_ATR] && <div style={{ marginRight: '6px' }} />}
              <NumberFormatField
                inputProps={{
                  id: ATR_MINUS_1_VALUE,
                  label: 'ATR-1 value',
                  placeholder: '1',
                  value: values[ATR_MINUS_1_VALUE],
                  isError: getIsError(ATR_MINUS_1_VALUE),
                  isValid: getIsValid(ATR_MINUS_1_VALUE),
                  helperText: getErrorMsg(ATR_MINUS_1_VALUE),
                  onBlur: handleBlur,
                  onChange: handleChange,
                  onClearRequestError: onClearReqError
                }}
              />
              {values[ENABLE_SEC_ATR] && (
                <>
                  <div style={{ marginRight: '6px' }} />
                  <OutlinedSelect
                    id={ATR_MINUS_2_TYPE}
                    options={[ATR_MINUS_1_TYPE_OPTIONS[1]]}
                    value={values[ATR_MINUS_2_TYPE]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="ATR-1 type (2)"
                    isError={getIsError(ATR_MINUS_2_TYPE)}
                    errorMsg={getErrorMsg(ATR_MINUS_2_TYPE)}
                    onClearReqError={onClearReqError}
                  />

                  <NumberFormatField
                    inputProps={{
                      id: ATR_MINUS_2_VALUE,
                      label: 'ATR-1 value (2)',
                      placeholder: '1',
                      value: values[ATR_MINUS_2_VALUE],
                      isError: getIsError(ATR_MINUS_2_VALUE),
                      isValid: getIsValid(ATR_MINUS_2_VALUE),
                      helperText: getErrorMsg(ATR_MINUS_2_VALUE),
                      onBlur: handleBlur,
                      onChange: handleChange,
                      onClearRequestError: onClearReqError
                    }}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <NumberFormatField
          inputProps={{
            id: TR_IN_ATR_THRESHOLD,
            label: 'TRinATR',
            placeholder: '1',
            value: values[TR_IN_ATR_THRESHOLD],
            isError: getIsError(TR_IN_ATR_THRESHOLD),
            isValid: getIsValid(TR_IN_ATR_THRESHOLD),
            helperText: getErrorMsg(TR_IN_ATR_THRESHOLD),
            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
            onBlur: handleBlur,
            onChange: handleChange,
            onClearRequestError: onClearReqError
          }}
        />
        <NumberFormatField
          inputProps={{
            id: REVERT_THRESHOLD,
            label: 'Revert',
            placeholder: '1',
            value: values[REVERT_THRESHOLD],
            isError: getIsError(REVERT_THRESHOLD),
            isValid: getIsValid(REVERT_THRESHOLD),
            helperText: getErrorMsg(REVERT_THRESHOLD),
            inputClass: cn(s.input, { [customInputClass]: customInputClass }),
            onBlur: handleBlur,
            onChange: handleChange,
            onClearRequestError: onClearReqError
          }}
        />
      </div>

      <PairedBtnBlock
        onCancel={onCancel}
        onSubmit={handleSubmit}
        labels={{
          cancelLabel: target === FormTarget.EDITING ? 'Cancel' : 'Back',
          submitLabel: target === FormTarget.EDITING && !isPriceDirectionChanged ? 'Save' : 'Next'
        }}
      />

      {reqErr && <span className={cn(s.error, 'errorText')}>{reqErr}</span>}
    </Form>
  );
};

export default KeyParametersForm;
