import React, { FC } from 'react';
import s from '../StatsStaticGraphs.module.scss';
import { Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import {
  CHART_STATS_DATE,
  CHART_STATS_FILTERS,
  CHART_STATS_OTHER_FILTERS,
  CHARTS_AMOUNT_OPTIONS,
  ChartsSelectEnum,
  ChartStatsFilterIDs,
  DEFAULT_CHART_PARAMS,
  initialChartValues
} from '../../../constants/chartStatsConstants';
import { ButtonColorTypes } from '../../../constants/randomConstants';
import OutlinedSelect from '../../selects/outlinedSelect/OutlinedSelect';
import { getChartTypeOptions } from '../../../helpers/chartsHelpers';
import CustomTooltip from '../../customTooltip/CustomTooltip';
import { useStyles } from '../../monthsStatsTable/styles';
import cn from 'classnames';

interface ChartsFiltrationProps {
  chartsFilters: typeof CHART_STATS_FILTERS;
  disabledFilter: boolean;
  isIframe: boolean;
  filterParams: typeof initialChartValues;
  handleFilterChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  setReqParams: (params: typeof initialChartValues) => void;
  setDisabledFilter: (isDisabled: boolean) => void;
  chartsCount: ChartsSelectEnum;
  onSelectChartsAmount: (v: ChartsSelectEnum) => void;
  handleClearFilters: () => void;
}

const ChartsFiltration: FC<ChartsFiltrationProps> = ({
  chartsFilters,
  filterParams,
  handleFilterChange,
  setDisabledFilter,
  disabledFilter,
  setReqParams,
  chartsCount,
  onSelectChartsAmount,
  handleClearFilters,
  isIframe
}) => {
  const { tooltip } = useStyles();
  return (
    <div className={s.subHeader} style={isIframe ? { display: 'none' } : {}}>
      <div className={s.selectors}>
        {chartsFilters.map((filter, idx, arr) => {
          const isChartTypeFilter = idx % 2 !== 0;
          const options = isChartTypeFilter ? getChartTypeOptions(filterParams[arr[idx - 1].id]) : filter.options;
          return (
            <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
              <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
                {filter.label}
              </InputLabel>
              <Select
                id={filter.id}
                name={filter.id}
                displayEmpty
                value={filterParams[filter.id]}
                onChange={handleFilterChange}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        })}
        {CHART_STATS_OTHER_FILTERS.map((filter) => (
          <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
            <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
              {filter.label}
            </InputLabel>
            <Select
              id={filter.id}
              name={filter.id}
              displayEmpty
              value={filterParams[filter.id]}
              onChange={handleFilterChange}
            >
              {filter.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        {CHART_STATS_DATE.map((filter) => (
          <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
            <InputLabel shrink htmlFor={filter.fieldName} className={s.filterLabel}>
              {filter.label}
            </InputLabel>
            <TextField
              onChange={handleFilterChange}
              value={filterParams[filter.value]}
              id="date"
              type="datetime-local"
              name={filter.fieldName}
              className={s.dataFilter}
            />
          </FormControl>
        ))}
        <FormControl variant="outlined" className={s.filterFormControl}>
          <OutlinedSelect
            id="charts"
            options={CHARTS_AMOUNT_OPTIONS}
            value={chartsCount}
            onChange={(e) => onSelectChartsAmount(e.target.value as ChartsSelectEnum)}
            label="Charts"
            errorMsg={null}
            isError={false}
          />
        </FormControl>
        <CustomTooltip
          leaveDelay={300}
          title={
            <div style={{ textAlign: 'center' }}>
              <span>
                Separates charts into two independent parts. <br />
                (If there is no data for some dates you better separate charts because chart library may provide some
                visual inaccuracies)
              </span>
            </div>
          }
          className={tooltip}
        >
          <FormControl className={cn(s.filterFormControlCheckBox, { [s.disability]: +chartsCount <= 1 })}>
            <InputLabel shrink htmlFor={ChartStatsFilterIDs.SEPARATED} className={s.filterLabelCheckBox}>
              Separate
            </InputLabel>
            <Checkbox
              color="primary"
              style={{ transform: 'scale(1.6)' }}
              value={filterParams[ChartStatsFilterIDs.SEPARATED] === 'true'}
              checked={filterParams[ChartStatsFilterIDs.SEPARATED] === 'true'}
              name={ChartStatsFilterIDs.SEPARATED}
              onChange={handleFilterChange}
              disabled={+chartsCount <= 1}
            />
          </FormControl>
        </CustomTooltip>
      </div>
      <div className={s.actionBtns}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.PRIMARY}
          classes={{ root: s.filterButton }}
          onClick={handleClearFilters}
          disabled={JSON.stringify(DEFAULT_CHART_PARAMS) === JSON.stringify(filterParams)}
        >
          Clear Filters
        </Button>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.DEFAULT}
          classes={{ root: s.filterButton }}
          onClick={() => {
            setReqParams(filterParams);
            setDisabledFilter(true);
          }}
          disabled={disabledFilter}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ChartsFiltration;
