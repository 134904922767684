import React from 'react';
import cn from 'classnames';
import MainLayout from 'components/mainLayout/MainLayout';
import NavTabs from 'components/navTabs/NavTabs';
import OrdersTable from 'components/ordersTable/OrdersTable';
import s from '../SignalsOrdersPage.module.scss';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { getSignalOrderTabs } from '../../../helpers/privilegesHelpers';

const OrdersPage = (): JSX.Element => {
  const privileges = usePrivileges();
  const tabs = getSignalOrderTabs(privileges);
  return (
    <MainLayout contentCustomClass={s.signalsContent}>
      <div className={s.titleWrapper}>
        <h2 className={cn('default-title', s.title)}>Orders dashboard</h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <OrdersTable />
    </MainLayout>
  );
};

export default OrdersPage;
