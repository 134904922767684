import React from 'react';
import cn from 'classnames';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { Form, Formik, FieldArray, FormikHelpers } from 'formik';
import CustomizedTextField from 'components/fields/customizedTextField/CustomizedTextField';
import OutlinedSelect from 'components/selects/outlinedSelect/OutlinedSelect';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import {
  IndicationsAddValidation,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes,
  TIME_FRAME_OPTIONS,
  TOTAL_STEPS
} from 'constants/strategyProfilesAddConstants';
import { AddSPErrors, IndicationSettingsValues, StrategyProfileSettingsShape } from 'interfaces/strategyProfilesShapes';
import s from './IndicationSettings.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';

interface IndicationsSettingsProps {
  target?: FormTarget;
  onSubmitIndications: (values, type) => void;
  onClearRequestErrors: () => void;
  onCancel: () => void;
  profile: StrategyProfileSettingsShape | null;
  errors: AddSPErrors;
  isStepsHidden?: boolean;
  contentWrapperClass?: string;
}

interface FormValues {
  indications: Array<IndicationSettingsValues>;
}

const IndicationSettings = ({
  target,
  onSubmitIndications,
  onClearRequestErrors,
  onCancel,
  profile,
  errors: { profileErr },
  isStepsHidden
}: IndicationsSettingsProps): JSX.Element => {
  const initialValues: FormValues = {
    indications:
      profile?.settings?.indications_settings?.map((setting) => ({
        opp_tf: setting.opp_settings?.timeframe ?? null,
        opp_sp: setting.opp_settings?.opp_sp?.toString() ?? '',
        opp_atr: setting.opp_settings?.opp_atr_t_percent?.toString() ?? '',
        opp_enabled: setting.opp_settings?.is_enabled ?? false,
        le_tf: setting.le_settings?.timeframe ?? null,
        le_enabled: setting.le_settings?.is_enabled ?? false,
        le_periods: setting.le_settings?.periods_amount ?? 0
      })) ?? []
  };

  return (
    <div className={s.wrapper}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.INDICATIONS_STEP}
        totalSteps={TOTAL_STEPS}
        title="Indications settings"
        isStepsHidden={isStepsHidden}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={IndicationsAddValidation}
        onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          const formatedValues = values.indications.map((indication) => ({
            le_settings: {
              timeframe: indication.le_tf,
              is_enabled: indication.le_enabled,
              periods_amount: !indication.le_periods ? null : Number(indication.le_periods)
            },
            opp_settings: {
              timeframe: indication.opp_tf,
              opp_sp: parseFloat(indication.opp_sp),
              opp_atr_t_percent: parseFloat(indication.opp_atr),
              is_enabled: indication.opp_enabled
            }
          }));

          onSubmitIndications(formatedValues, StrategySettingsFormTypes.I_SETTINGS);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
          const isError = (index: number, value: string) =>
            touched?.indications?.[index]?.[value] && !!errors?.indications?.[index]?.[value];
          const getErrorMsg = (index: number, value: string) =>
            touched?.indications?.[index]?.[value] && errors?.indications?.[index]?.[value];
          const isValid = (index: number, value: string) =>
            !!touched?.indications?.[index]?.[value] && !errors?.indications?.[index]?.[value];

          return (
            <Form className={s.form}>
              <FieldArray
                name="indications"
                render={({ remove, push }) => (
                  <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
                    {values.indications.map((indication, index) => (
                      <Grid container spacing={3} key={index}>
                        <Grid item xs>
                          <Grid item xs>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={`indications.${index}.opp_enabled`}
                                  checked={indication.opp_enabled}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setFieldValue(`indications.${index}.opp_enabled`, checked);
                                    if (!checked) {
                                      setFieldValue(`indications.${index}.opp_tf`, null);
                                      setFieldValue(`indications.${index}.opp_sp`, '');
                                      setFieldValue(`indications.${index}.opp_atr`, '');
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              }
                              label="OPP Enabled"
                            />
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs>
                              <div className={s.inputWrapper}>
                                <OutlinedSelect
                                  id={`indications[${index}].opp_tf`}
                                  options={TIME_FRAME_OPTIONS}
                                  value={indication.opp_tf}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={`OPP-TF`}
                                  isError={isError(index, 'opp_tf')}
                                  errorMsg={getErrorMsg(index, 'opp_tf')}
                                  formControlClass={s.selectRoot}
                                  onClearReqError={onClearRequestErrors}
                                  disabled={!indication.opp_enabled}
                                />
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div className={s.inputWrapper}>
                                <CustomizedTextField
                                  id={`indications[${index}].opp_sp`}
                                  label={`OPP-SP`}
                                  placeholder="OPP-SP"
                                  value={indication.opp_sp}
                                  isError={getErrorMsg(index, 'opp_sp')}
                                  isValid={isValid(index, 'opp_sp')}
                                  helperText={getErrorMsg(index, 'opp_sp')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  onClearRequestError={onClearRequestErrors}
                                  inputClass={s.input}
                                  disabled={!indication.opp_enabled || !indication.opp_tf}
                                />
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div className={s.inputWrapper}>
                                <CustomizedTextField
                                  id={`indications[${index}].opp_atr`}
                                  label={`OPP-ATR`}
                                  placeholder="OPP-ATR"
                                  value={indication.opp_atr}
                                  isError={isError(index, 'opp_atr')}
                                  isValid={isValid(index, 'opp_atr')}
                                  helperText={getErrorMsg(index, 'opp_atr')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  onClearRequestError={onClearRequestErrors}
                                  inputClass={s.input}
                                  disabled={!indication.opp_enabled || !indication.opp_tf}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs>
                          <Grid item xs>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id={`indications[${index}].le_enabled`}
                                  checked={indication.le_enabled}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setFieldValue(`indications.${index}.le_enabled`, checked);
                                    if (!checked) {
                                      setFieldValue(`indications.${index}.le_tf`, null);
                                      setFieldValue(`indications.${index}.le_periods`, '');
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              }
                              label="LE Enabled"
                            />
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs>
                              <div className={s.inputWrapper}>
                                <OutlinedSelect
                                  id={`indications[${index}].le_tf`}
                                  options={TIME_FRAME_OPTIONS}
                                  value={indication.le_tf}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={`LE-TF`}
                                  isError={isError(index, 'le_tf')}
                                  errorMsg={getErrorMsg(index, 'le_tf')}
                                  formControlClass={s.selectRoot}
                                  onClearReqError={onClearRequestErrors}
                                  disabled={!indication.le_enabled}
                                />
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div className={s.inputWrapper}>
                                <CustomizedTextField
                                  id={`indications[${index}].le_periods`}
                                  label={`LE-Periods`}
                                  placeholder="LE Periods"
                                  value={indication.le_periods.toString()}
                                  isError={isError(index, 'le_periods')}
                                  isValid={isValid(index, 'le_periods')}
                                  helperText={getErrorMsg(index, 'le_periods')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  onClearRequestError={onClearRequestErrors}
                                  inputClass={s.input}
                                  disabled={!indication.le_enabled || !indication.le_tf}
                                />
                              </div>
                            </Grid>
                            <Grid item style={{ paddingTop: 12 }} xs>
                              <Button
                                className={s.btnText}
                                variant="outlined"
                                classes={{ root: s.btnRoot }}
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Button
                      type="button"
                      variant="outlined"
                      style={{ margin: '10px 0' }}
                      classes={{ root: s.btnRoot }}
                      className={s.btnText}
                      onClick={() =>
                        push({
                          opp_tf: null,
                          opp_sp: '',
                          opp_atr: '',
                          le_tf: null,
                          le_periods: '',
                          le_enabled: true,
                          opp_enabled: true
                        })
                      }
                    >
                      Add Indication
                    </Button>
                  </div>
                )}
              />
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ cancelLabel: 'Cancel', submitLabel: target === FormTarget.EDITING ? 'Save' : 'Next' }}
                disableSubmit={isSubmitting}
                disableCancel={isSubmitting}
              />
              {profileErr && <span className={cn(s.error, 'errorText')}>{profileErr}</span>}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default IndicationSettings;
