import React, { ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';
import TableBody from '@material-ui/core/TableBody';
import FakeRow from 'components/customizedTable/rows/fakeRow/FakeRow';
import CollapsibleRow from 'components/customizedTable/rows/collapsibleRow/CollapsibleRow';
import { TableColumn, TBodyCssClasses } from 'interfaces/randomShapes';
import s from './TBody.module.scss';

interface TBodyProps {
  isLoading: boolean;
  columns: Array<TableColumn>;
  rows: Array<{ id: number; isCollapsible?: boolean; isLostPosition?: boolean }>;
  error: null | string;
  emptyRowsMsg: string;
  getContent: (colId: string, row, collapseCallback?: () => void, isCollapseOpen?: boolean) => ReactNode;
  getCollapsibleContent?: (colId, row: Record<string, unknown>) => ReactNode;
  classes?: TBodyCssClasses;
}

const TBody = ({
  isLoading,
  columns,
  rows,
  error,
  emptyRowsMsg,
  getContent,
  getCollapsibleContent,
  classes
}: TBodyProps): JSX.Element => {
  return (
    <TableBody classes={{ root: s.tableBodyRoot }}>
      {isLoading ? (
        <FakeRow isLoader />
      ) : (
        <React.Fragment>
          {error ? (
            <FakeRow isError msg={error} />
          ) : isEmpty(rows) ? (
            <FakeRow msg={emptyRowsMsg} />
          ) : (
            rows.map((item) => (
              <CollapsibleRow
                key={item.id}
                row={item}
                columns={columns}
                getContent={getContent}
                getCollapsibleContent={getCollapsibleContent}
                classes={classes}
              />
            ))
          )}
        </React.Fragment>
      )}
    </TableBody>
  );
};

export default TBody;
