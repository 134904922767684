import React from 'react';
import MainLayout from 'components/mainLayout/MainLayout';
import StrategyStatsTable from 'components/strategyStatsTable/StrategyStatsTable';

const StrategyStatsPage = (): JSX.Element => {
  return (
    <MainLayout>
      <StrategyStatsTable />
    </MainLayout>
  );
};

export default StrategyStatsPage;
