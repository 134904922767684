import React, { useState, useMemo, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import THead from 'components/customizedTable/tableHead/THead';
import TBody from 'components/customizedTable/tableBody/TBody';
import { TableColumn, THeadCssClasses, TBodyCssClasses } from 'interfaces/randomShapes';
import s from './CustomizedTable.module.scss';
import { Pagination } from './pagination/Pagination';

interface CustomizedTableProps {
  columns: TableColumn[];
  rows: Array<{ id: number }> | unknown;
  isLoading: boolean;
  error: null | string;
  emptyRowsMsg: string;
  getContent: (
    colId: string,
    row,
    collapseCallback?: () => void,
    isCollapseOpen?: boolean,
    isCollapsible?: boolean
  ) => ReactNode;
  count: number;
  rowsPerPageOptions?: number[];
  customPerPage?: number;
  customPage?: number;
  offPerPage?: boolean;
  isPaginationPerPage?: boolean;
  classes?: {
    wrapperClass?: string;
    tHeadClasses?: THeadCssClasses;
    tBodyClasses?: TBodyCssClasses;
    tableWrapper?: string;
  };
  deactivated?: boolean;
  defaultCount?;
  hasSortingOption?: boolean;
  hidePagination?: boolean;
  getCustomTitle?: () => JSX.Element;
  getCollapsibleContent?: (colId, row) => JSX.Element;
  onCustomChangePage?: (newPage: number) => void;
  sortHandler?: (columnName: string, withMinusSign: boolean) => void;
}

const DEFAULT_PER_PAGE = 10;
const DEFAULT_PAGE = 0;

const CustomizedTable = ({
  columns,
  rows,
  isLoading,
  error,
  emptyRowsMsg,
  count,
  rowsPerPageOptions,
  getContent,
  getCollapsibleContent,
  customPerPage,
  customPage,
  offPerPage,
  onCustomChangePage,
  classes,
  deactivated,
  hasSortingOption,
  isPaginationPerPage,
  getCustomTitle = () => null,
  defaultCount = 0,
  sortHandler = () => void 0,
  hidePagination = false
}: CustomizedTableProps): JSX.Element => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(customPerPage ?? DEFAULT_PER_PAGE);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (typeof onCustomChangePage === 'function') onCustomChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(customPage ?? DEFAULT_PAGE);
  };

  const rowsWithPerPage = useMemo(
    () => (rows as Array<{ id: number }>).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, page, rowsPerPage]
  );

  useEffect(() => {
    setRowsPerPage(customPerPage);
    setPage(customPage ?? DEFAULT_PAGE);
  }, [customPerPage, customPage]);

  return (
    <div className={cn(s.wrapper, { [classes?.wrapperClass]: classes?.wrapperClass })}>
      {deactivated && <div className={s.overlay} />}
      <Paper className={s.root}>
        <TableContainer className={cn(s.tableContainer, classes?.tableWrapper)}>
          <Table stickyHeader aria-label="sticky table">
            <THead
              hasSortingOption={hasSortingOption}
              sortHandler={sortHandler}
              columns={columns}
              classes={classes?.tHeadClasses}
              getCustomTitle={getCustomTitle}
            />
            <TBody
              columns={columns}
              rows={offPerPage ? (rows as Array<{ id: number }>) : rowsWithPerPage}
              isLoading={isLoading}
              error={error}
              emptyRowsMsg={emptyRowsMsg}
              getContent={getContent}
              getCollapsibleContent={getCollapsibleContent}
              classes={classes?.tBodyClasses}
            />
          </Table>
        </TableContainer>
        {!hidePagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions ?? [10, 25, 100]}
            component={isPaginationPerPage ? (props) => <Pagination {...props} /> : 'div'}
            count={isNil(count) ? defaultCount : count}
            defaultValue={defaultCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
};

export default CustomizedTable;
