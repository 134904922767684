import { AdministrationColIds } from '../constants/signalsOrdersConstants';
import * as Shapes from '../interfaces/signalsOrdersShapes';
import React, { ReactNode } from 'react';

export const accountStatsRenderSwitch = (colId: AdministrationColIds, row: Shapes.AdminTableRow): ReactNode => {
  const value = row[colId];
  switch (colId) {
    default:
      return <>{value ?? '-'}</>;
  }
};
