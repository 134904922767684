import { mmProfilesTypes } from '../types';
import * as Shapes from 'interfaces/mmProfilesShapes';
import {
  AutoChangeDepo,
  ChangedAutoDepositFormDTO,
  ChangedDepositFormDTO,
  DepositInfo,
  GetAutoDepositMMProfile,
  GetDepositInfoMMProfile,
  PutDepositInfoMMProfile
} from 'interfaces/mmProfilesShapes';
import { MMReqParams } from '../../constants/mmProfilesConstants';

export const addMMProfile = (
  reqData: Shapes.AddMMProfileReqShape,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.AddMMProfileShape => ({
  type: mmProfilesTypes.ADD_MM_PROFILE,
  payload: { reqData, onSuccess, onError }
});

export const getMMProfiles = (
  reqParams: MMReqParams,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.GetMMProfilesShape => ({
  type: mmProfilesTypes.GET_MM_PROFILES,
  payload: { reqParams, onSuccess, onError }
});

export const getMMProfilesFilter = (
  reqParams: MMReqParams,
  onSuccess: (data) => void,
  onError: (err) => void
): Shapes.GetMMProfilesFilterShape => ({
  type: mmProfilesTypes.GET_MM_PROFILES_FILTER,
  payload: { reqParams, onSuccess, onError }
});

export const putMMProfiles = (mmProfiles: Shapes.PaginatedMMProfiles): Shapes.PutMMProfiles => ({
  type: mmProfilesTypes.PUT_MM_PROFILES,
  payload: mmProfiles
});

export const putMMProfile = (mmProfiles: Shapes.MMExtendedShape[]): Shapes.PutMMProfile => ({
  type: mmProfilesTypes.PUT_MM_PROFILE,
  payload: mmProfiles
});

export const changeMMProfile = (
  profileId: number,
  reqData: Shapes.ChangeMMProfileReqShape,
  onError: (err) => void,
  onSuccess?: () => void
): Shapes.ChangeMMProfile => ({
  type: mmProfilesTypes.CHANGE_MM_PROFILE,
  payload: { profileId, reqData, onError, onSuccess }
});

export const changeMMProfileVisibility = (
  profileId: number,
  is_show: boolean,
  onSuccess: () => void
): Shapes.ChangeMMProfileVisibility => ({
  type: mmProfilesTypes.CHANGE_MM_PROFILE_VISIBILITY,
  payload: { profileId, reqData: { is_show }, onSuccess }
});

export const changeMMProfileSuccess = (updatedProfile: Shapes.MMExtendedShape): Shapes.ChangeMMProfileSuccess => ({
  type: mmProfilesTypes.CHANGE_MM_PROFILE_SUCCESS,
  payload: updatedProfile
});

export const getMMProfile = (id: number, onError: () => void): Shapes.GetMMProfile => ({
  type: mmProfilesTypes.GET_MM_PROFILE,
  payload: { id, onError }
});

export const changeDepositMMProfile = (
  reqData: ChangedDepositFormDTO,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.ChangeDepositMMProfile => ({
  type: mmProfilesTypes.CHANGE_DEPOSIT_MM_PROFILE,
  payload: { reqData, onSuccess, onError }
});

export const getAutoDepositMMProfile = (onSuccess: () => void, onError: (err) => void): GetAutoDepositMMProfile => ({
  type: mmProfilesTypes.GET_AUTO_DEPOSIT_MM_PROFILE,
  payload: { onSuccess, onError }
});

export const getDepositInfoMMProfile = (onError: (err) => void): GetDepositInfoMMProfile => ({
  type: mmProfilesTypes.GET_DEPOSIT_INFO_MM_PROFILE,
  payload: { onError }
});

export const putDepositInfoMMProfile = (updatedProfile: DepositInfo): PutDepositInfoMMProfile => ({
  type: mmProfilesTypes.PUT_DEPOSIT_INFO_MM_PROFILE,
  payload: updatedProfile
});

export const changeAutoDepositMMProfile = (
  reqData: ChangedAutoDepositFormDTO,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.ChangeAutoDepositMMProfile => ({
  type: mmProfilesTypes.CHANGE_AUTO_DEPOSIT_MM_PROFILE,
  payload: { reqData, onSuccess, onError }
});

export const saveAutoDepositMMProfile = (data: AutoChangeDepo): Shapes.SaveAutoDepositMMProfile => ({
  type: mmProfilesTypes.PUT_AUTO_DEPOSIT_MM_PROFILE,
  payload: data
});
