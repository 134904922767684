import React, { useContext } from 'react';
import { FormControlLabel, IconButton } from '@material-ui/core';
import s from './StrategyTitleBlock.module.scss';
import { Fingerprint } from '@material-ui/icons';
import { TemplateContext } from '../../../constants/strategyProfilesAddConstants';

interface StrategyTitleBlockShape {
  step: number;
  totalSteps: number;
  title: string;
  isStepsHidden?: boolean;
}

const StrategyTitleBlock = ({ step, totalSteps, title, isStepsHidden }: StrategyTitleBlockShape): JSX.Element => {
  const { isTemplated, handleTemplated, showMessage } = useContext(TemplateContext);
  return (
    <div className={s.titleBlockWrapper}>
      <div className={s.stepsTemplated}>
        {!isStepsHidden && (
          <p className={s.steps}>
            Step&nbsp;{step}&nbsp;of&nbsp;{totalSteps}
          </p>
        )}
        {showMessage && (
          <FormControlLabel
            control={
              <IconButton
                aria-label="fingerprint"
                color={isTemplated ? 'secondary' : 'primary'}
                onClick={() => handleTemplated?.(!isTemplated)}
              >
                <Fingerprint />
              </IconButton>
            }
            label={isTemplated ? 'Templated' : 'Apply template'}
          />
        )}
      </div>

      <h2 className="default-title">{title}</h2>
    </div>
  );
};

export default StrategyTitleBlock;
