import React, { useReducer, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PuffLoader from 'react-spinners/PuffLoader';
import Button from '@material-ui/core/Button';
import Modal from 'components/modal/MyModal';
import CustomizableList from 'components/customizableList/CustomizableList';
import ExchangeAccountForm from './exchangeAccountsForm/ExchangeAccountForm';
import DeleteAccountContent from './deleteAccountContent/DeleteAccountContent';
import {
  getExchangeAccounts,
  addExchangeAccountRequest,
  editExchangeAccountRequest,
  deleteExchangeAccountRequest
} from 'redux/actions/systemSettingsActions';
import { systemSettingsSelector } from 'redux/selectors';
import { getErrorMessageHelper } from 'helpers/randomHelpers';
import { ExchangeAccountShape, ExchangeAccountFormFields } from 'interfaces/systemSettingsShapes';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import Fade from 'components/reactTransitions/FadeTransition.module.css';
import {
  exchangeAccountsTypes,
  exchangeAccountsInitialState,
  exchangeAccountsReducer
} from './exchangeAccountsConstants';
import s from './ExchangeAccounts.module.scss';
import { usePrivileges } from '../../../../hooks/usePrivileges';
import { isSectionReadOnly } from '../../../../helpers/privilegesHelpers';

const LOADER_SIZE = 50;

const ExchangeAccounts = (): JSX.Element => {
  const [{ isOpen, isEdit, isDelete, isLoading, currentAccount, deleteError }, localDispatch] = useReducer(
    exchangeAccountsReducer,
    exchangeAccountsInitialState
  );
  const { exchangeAccounts } = useSelector(systemSettingsSelector);
  const dispatch = useDispatch();
  const { exchange_accounts } = usePrivileges();
  const isReadOnly = isSectionReadOnly(exchange_accounts);

  const handleAddAccount = () => {
    localDispatch({
      type: exchangeAccountsTypes.CREATE_INIT
    });
  };

  const handleSubmitAdd = (values: ExchangeAccountFormFields, onError: (err) => void) => {
    dispatch(addExchangeAccountRequest(values, onCloseModal, onError));
  };

  const handleEditAcc = (account: ExchangeAccountShape) => {
    localDispatch({
      type: exchangeAccountsTypes.EDIT_INIT,
      payload: account
    });
  };

  const handleSubmitEdit = (values: ExchangeAccountFormFields, onError: (err) => void, accountId: number) => {
    dispatch(editExchangeAccountRequest(values, accountId, onCloseModal, onError));
  };

  const handleDeleteAcc = (account: ExchangeAccountShape) => {
    localDispatch({
      type: exchangeAccountsTypes.DELETE_INIT,
      payload: account
    });
  };

  const onDeleteAccError = (error: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(error);

    localDispatch({
      type: exchangeAccountsTypes.DELETE_ERROR,
      payload: errorMsg
    });
  };

  const handleSubmitDelete = () => {
    dispatch(deleteExchangeAccountRequest(currentAccount?.id, onCloseModal, onDeleteAccError));
  };

  const onCloseModal = () => {
    localDispatch({
      type: exchangeAccountsTypes.CLOSE_MODAL
    });
  };

  const onStopLoading = () => {
    localDispatch({
      type: exchangeAccountsTypes.STOP_LOADING
    });
  };

  const onGetAccountsList = useCallback(() => {
    dispatch(getExchangeAccounts(onStopLoading));
  }, [dispatch]);

  useEffect(() => {
    onGetAccountsList();
  }, [onGetAccountsList]);

  return (
    <div className={s.wrapper}>
      <h2 className={cn(s.title, 'default-title')}>Exchange accounts</h2>

      <div className={s.listWrapper}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={isLoading ? 'loader' : 'collection'}
            timeout={DEFAULT_TRANSITION_TIMEOUT}
            classNames={{ ...Fade }}
          >
            {isLoading ? (
              <div className={s.loaderWrapper}>
                <PuffLoader size={LOADER_SIZE} loading={isLoading} />
              </div>
            ) : isEmpty(exchangeAccounts) ? (
              <p className="default-text">No accounts yet</p>
            ) : (
              <CustomizableList
                isReadOnly={isReadOnly}
                isEditable
                list={exchangeAccounts}
                onEdit={handleEditAcc}
                onDelete={handleDeleteAcc}
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>

      {!isReadOnly && (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot, text: s.btnText }}
          onClick={handleAddAccount}
        >
          Add
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onToggle={onCloseModal}
        stylesProps={{
          paper: { padding: '40px 50px 50px', top: '43%' }
        }}
      >
        {isDelete ? (
          <DeleteAccountContent
            onCancel={onCloseModal}
            onSubmit={handleSubmitDelete}
            deleteError={deleteError}
            accountLabel={currentAccount?.title}
          />
        ) : (
          <ExchangeAccountForm
            onCancel={onCloseModal}
            onSubmit={isEdit ? handleSubmitEdit : handleSubmitAdd}
            isEdit={isEdit}
            account={currentAccount}
          />
        )}
      </Modal>
    </div>
  );
};

export default ExchangeAccounts;
