import React from 'react';
import MainLayout from '../../components/mainLayout/MainLayout';
import cn from 'classnames';
import s from './LogsPage.module.scss';
import LogsTable from '../../components/logsTable/LogsTable';

export default function LogsPage(): JSX.Element {
  return (
    <MainLayout>
      <h2 className={cn('default-title', s.title)}>Logs dashboard</h2>
      <LogsTable />
    </MainLayout>
  );
}
