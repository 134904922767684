import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects';
import { systemSettingsTypes } from '../types';
import * as apiHelpers from './apiHelpers/systemSettingsApiHelpers';
import * as actions from '../actions/systemSettingsActions';
import * as Shapes from 'interfaces/systemSettingsShapes';
import { callbackResolver } from 'helpers/callbackHelpers';

function* getExchangeAccountsWorker({ payload }: Shapes.GetExchangeAccounts) {
  try {
    const { data } = yield call(apiHelpers.getExchangeAccountsHelper);
    yield put(actions.putExchangeAccounts(data));
    callbackResolver(payload?.onFinally);
  } catch (error) {
    payload?.onError(error);
    callbackResolver(payload?.onFinally);
    console.error('getExchangeAccountsWorker', error);
  }
}

function* addExchangeAccWorker({ payload }: Shapes.AddExchangeAccShape) {
  try {
    yield call(apiHelpers.addExchangeAccHelper, payload.reqData);
    yield put(actions.getExchangeAccounts());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('addExchangeAccWorker', error);
  }
}

function* editExchangeAccWorker({ payload }: Shapes.EditExchangeAccShape) {
  try {
    yield call(apiHelpers.editExchangeAccHelper, payload.reqData, payload.accountId);
    yield put(actions.getExchangeAccounts());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('editExchangeAccWorker', error);
  }
}

function* deleteExchangeAccWorker({ payload }: Shapes.DeleteExchangeAccShape) {
  try {
    yield call(apiHelpers.deleteExchangeAccHelper, payload.accountId);
    yield put(actions.getExchangeAccounts());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('deleteExchangeAccWorker', error);
  }
}

function* getTelegramNotificationsWorker({ payload }: Shapes.GetTGSettings) {
  try {
    const { data } = yield call(apiHelpers.getTGNotificationsSettingsHelper);
    yield put(actions.putTGSettings(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload?.onError(error);
    callbackResolver(payload.onFinally);
    console.error('getTelegramNotificationsWorker', error);
  }
}

function* createTelegramNotificationsWorker({ payload }: Shapes.CreateTGSettings) {
  try {
    const { data } = yield call(apiHelpers.addTGNotificationsSettingsHelper, payload.reqData);
    yield put(actions.putTGSettings(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('createTelegramNotificationsWorker', error);
  }
}

function* editTelegramNotificationsWorker({ payload }: Shapes.EditTGSettings) {
  try {
    const { data } = yield call(apiHelpers.editTGNotificationsSettingsHelper, payload.reqData);
    yield put(actions.putTGSettings(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('editTelegramNotificationsWorker', error);
  }
}

function* getInvestorReportSettingsWorker({ payload }: Shapes.GetInvestorReportSettings) {
  try {
    const { data } = yield call(apiHelpers.getInvestorReportSettings);
    yield put(actions.putInvestorReportSettings(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload.onError(error);
    console.error('getInvestorReportSettingsWorker', error);
  }
}

function* editInvestorReportSettingsWorker({ payload }: Shapes.EditInvestorReportSettings) {
  try {
    const { data } = yield call(apiHelpers.editInvestorReportSettings, payload.reqData);
    yield put(actions.putInvestorReportSettings(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('editInvestorReportSettingsWorker', error);
  }
}

function* getInvestorReportSchedulerWorker({ payload }: Shapes.GetInvestorReportSettings) {
  try {
    const { data } = yield call(apiHelpers.getInvestorReportScheduler);
    yield put(actions.putInvestorReportSettingsScheduler(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload.onError(error);
    console.error('getInvestorReportSchedulerWorker', error);
  }
}

function* deleteInvestorReportSchedulerWorker({ payload }: Shapes.DeleteInvestorReportScheduler) {
  try {
    yield call(apiHelpers.deleteInvestorReportScheduler, payload.id);
    yield put(actions.getInvestorReportSettingsScheduler());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('deleteInvestorReportSchedulerWorker', error);
  }
}

function* createInvestorReportSchedulerWorker({ payload }: Shapes.CreateInvestorReportScheduler) {
  try {
    yield call(apiHelpers.createInvestorReportScheduler, payload.reqData);
    yield put(actions.getInvestorReportSettingsScheduler());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('deleteInvestorReportSchedulerWorker', error);
  }
}

function* getTradingPairsWorker({ payload }: Shapes.GetTradingPairs) {
  try {
    const { data } = yield call(apiHelpers.getTradingPairsHelper);
    yield put(actions.putTradingPairs(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload?.onError(error);
    console.error('getTradingPairsWorker', error);
  }
}

function* syncTradingPairsWorker({ payload }: Shapes.GetTradingPairs) {
  try {
    const { data } = yield call(apiHelpers.syncTradingPairsHelper);
    yield put(actions.putTradingPairs(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('syncTradingPairsWorker', error);
  }
}

function* changeTradingPairWorker({ payload }: Shapes.ChangeTradingPair) {
  try {
    const { data } = yield call(apiHelpers.changeTradingPairHelper, payload.reqData, payload.pairId);
    yield put(actions.changeTradingPairSuccess(data));
  } catch (error) {
    payload.onError(error);
    console.error('changeTradingPairWorker', error);
  }
}

export function* systemSettingsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(systemSettingsTypes.GET_EXCHANGE_ACCOUNTS, getExchangeAccountsWorker);
  yield takeLatest(systemSettingsTypes.ADD_EXCHANGE_ACCOUNT_REQUEST, addExchangeAccWorker);
  yield takeLatest(systemSettingsTypes.EDIT_EXCHANGE_ACCOUNT_REQUEST, editExchangeAccWorker);
  yield takeLatest(systemSettingsTypes.DELETE_EXCHANGE_ACCOUNT_REQUEST, deleteExchangeAccWorker);
  yield takeLatest(systemSettingsTypes.GET_TG_SETTINGS, getTelegramNotificationsWorker);
  yield takeLatest(systemSettingsTypes.CREATE_TG_SETTINGS_REQUEST, createTelegramNotificationsWorker);
  yield takeLatest(systemSettingsTypes.EDIT_TG_SETTINGS_REQUEST, editTelegramNotificationsWorker);
  yield takeLatest(systemSettingsTypes.GET_TRADING_PAIRS, getTradingPairsWorker);
  yield takeLatest(systemSettingsTypes.SYNC_TRADING_PAIRS, syncTradingPairsWorker);
  yield takeLatest(systemSettingsTypes.CHANGE_TRADING_PAIR, changeTradingPairWorker);
  yield takeLatest(systemSettingsTypes.GET_INVESTOR_REPORT_SETTINGS, getInvestorReportSettingsWorker);
  yield takeLatest(systemSettingsTypes.EDIT_INVESTOR_REPORT_SETTINGS, editInvestorReportSettingsWorker);
  yield takeLatest(systemSettingsTypes.GET_INVESTOR_REPORT_SETTINGS_SCHEDULER, getInvestorReportSchedulerWorker);
  yield takeLatest(systemSettingsTypes.CREATE_INVESTOR_REPORT_SETTINGS_SCHEDULER, createInvestorReportSchedulerWorker);
  yield takeLatest(systemSettingsTypes.DELETE_INVESTOR_REPORT_SETTINGS_SCHEDULER, deleteInvestorReportSchedulerWorker);
}
