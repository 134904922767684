import React from 'react';
import MainLayout from 'components/mainLayout/MainLayout';
import ExchangeAccounts from './components/exchangeAccounts/ExchangeAccounts';
import TelegramNotifications from './components/telegramNotifications/TelegramNotifications';
import TradingPairs from './components/tradingPairs/TradingPairs';
import s from './SystemSettingsPage.module.scss';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionAllowed } from '../../helpers/privilegesHelpers';
import ReportInvestorSettings from './components/reportInvestorSettings/ReportInvestorSettings';
import ReportInvestorScheduler from './components/reportInvestorScheduler/ReportInvestorScheduler';

const SystemSettingsPage = (): JSX.Element => {
  const { pairs, exchange_accounts, tg_bot } = usePrivileges();

  return (
    <MainLayout>
      <div className={s.contentWrapper}>
        <div className={s.column}>
          {isSectionAllowed(exchange_accounts) && <ExchangeAccounts />}
          {isSectionAllowed(tg_bot) && (
            <>
              <TelegramNotifications />
              <ReportInvestorSettings />
              <ReportInvestorScheduler />
            </>
          )}
        </div>
        <div className={s.column}>{isSectionAllowed(pairs) && <TradingPairs />}</div>
      </div>
    </MainLayout>
  );
};

export default SystemSettingsPage;
