import React, { FC, useState } from 'react';
import PairedBtnBlock from '../../buttons/pairedBtnBlock/PairedBtnBlock';
import s from './UserForm.module.scss';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { groupsSelector } from '../../../redux/selectors';
import { userFormValidation, UserFormValues } from '../../../constants/userManagementConstants';

interface FormValues {
  [UserFormValues.EMAIL]: string;
  [UserFormValues.PASSWORD]: string;
  [UserFormValues.REPEAT_PASSWORD]: string;
  [UserFormValues.GROUP]: number;
}

interface UserFormProps {
  onCancel: () => void;
  onSubmit: (email: string, password: string, group: number) => void;
}

const UserForm: FC<UserFormProps> = ({ onCancel, onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const onClickShowPassword = () => setShowPassword((prev) => !prev);
  const onClickShowRepeatPassword = () => setShowRepeatPassword((prev) => !prev);
  const groups = useSelector(groupsSelector);

  return (
    <div className={s.formWrapper}>
      <h2 className={cn('default-title', s.title)}>User Form</h2>
      <Formik
        initialValues={{
          [UserFormValues.EMAIL]: '',
          [UserFormValues.PASSWORD]: '',
          [UserFormValues.REPEAT_PASSWORD]: '',
          [UserFormValues.GROUP]: 0
        }}
        validationSchema={userFormValidation}
        onSubmit={({ email, password, group }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          onSubmit(email, password, group);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <Form className={s.form}>
              <TextField
                fullWidth
                id="email"
                name={UserFormValues.EMAIL}
                label="Email"
                placeholder="example@gmail.com"
                variant="outlined"
                value={values[UserFormValues.EMAIL]}
                error={!!errors[UserFormValues.EMAIL] && touched[UserFormValues.EMAIL]}
                helperText={touched[UserFormValues.EMAIL] && errors[UserFormValues.EMAIL]}
                onBlur={handleBlur}
                onChange={handleChange}
                classes={{ root: s.input }}
              />

              <TextField
                fullWidth
                autoComplete="off"
                id={UserFormValues.PASSWORD}
                name={UserFormValues.PASSWORD}
                label="Password"
                placeholder="********"
                variant="outlined"
                value={values[UserFormValues.PASSWORD]}
                type={showPassword ? 'text' : 'password'}
                error={!!errors[UserFormValues.PASSWORD] && touched[UserFormValues.PASSWORD]}
                helperText={touched[UserFormValues.PASSWORD] && errors[UserFormValues.PASSWORD]}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={(e) => e.target.setAttribute('autocomplete', 'off')}
                classes={{ root: s.input }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onClickShowPassword}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                fullWidth
                autoComplete="off"
                id={UserFormValues.REPEAT_PASSWORD}
                name={UserFormValues.REPEAT_PASSWORD}
                label="Repeat Password"
                placeholder="********"
                variant="outlined"
                value={values[UserFormValues.REPEAT_PASSWORD]}
                type={showRepeatPassword ? 'text' : 'password'}
                error={!!errors[UserFormValues.REPEAT_PASSWORD] && touched[UserFormValues.REPEAT_PASSWORD]}
                helperText={touched[UserFormValues.REPEAT_PASSWORD] && errors[UserFormValues.REPEAT_PASSWORD]}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={(e) => e.target.setAttribute('autocomplete', 'off')}
                classes={{ root: s.input }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onClickShowRepeatPassword}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormControl id="by" variant="outlined" className={s.select} classes={{ root: s.input }}>
                <InputLabel shrink id="by" className={s.selectLabel}>
                  Choose user group
                </InputLabel>
                <Select
                  id="by"
                  displayEmpty
                  value={values[UserFormValues.GROUP]}
                  onChange={({ target }) => setFieldValue(UserFormValues.GROUP, target.value)}
                  error={touched[UserFormValues.GROUP] && !!errors[UserFormValues.GROUP]}
                >
                  {groups.map((group) => {
                    return (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {touched[UserFormValues.GROUP] && !!errors[UserFormValues.GROUP] && (
                  <FormHelperText style={{ color: 'red' }}>
                    {touched[UserFormValues.GROUP] && errors[UserFormValues.GROUP]}
                  </FormHelperText>
                )}
              </FormControl>
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserForm;
