import React, { FC, useEffect, useMemo, useState } from 'react';
import s from './UserManagementTable.module.scss';
import CustomizedTable from '../customizedTable/CustomizedTable';
import { USER_MANAGEMENT } from '../../constants/userManagementConstants';
import { createUsersRow, userManagementRenderSwitch } from '../../helpers/userManagementHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeUserGroup,
  createUser,
  deleteUser,
  getGroups,
  getUsers,
  updateUser
} from '../../redux/actions/privilegesActions';
import { usersSelector } from '../../redux/selectors';
import { UserRow } from '../../interfaces/privilegesShapes';
import { ButtonColorTypes } from '../../constants/randomConstants';
import { Button } from '@material-ui/core';
import MyModal from '../modal/MyModal';
import UserForm from './userForm/UserForm';
import { showMessage } from '../../redux/actions/utilsActions';
import { MessageType } from '../../interfaces/utilsShapes';
import { ErrorShape } from '../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../helpers/randomHelpers';

const UserManagementTable: FC = () => {
  const dispatch = useDispatch();
  const users = useSelector(usersSelector);

  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      const msg = getErrorCutMessageHelper(errorMsg);
      onShowMessage(msg, 'error');
    }
  };

  useEffect(() => {
    onToggleLoading();
    dispatch(getUsers(onToggleLoading));
    dispatch(getGroups());
  }, [dispatch]);

  const onShowMessage = (messageText = 'Success', type: MessageType = 'success') => {
    dispatch(showMessage(messageText, type));
  };

  const onUserGroupChange = (userId: number, groupName: string, groupId: number) => {
    dispatch(changeUserGroup(userId, groupName, groupId));
  };

  const onSave = (id: number) => {
    dispatch(updateUser(id, onShowMessage, onSetError));
  };

  const onDelete = (id: number) => {
    dispatch(deleteUser(id, onShowMessage, onSetError));
  };

  const onCreateNewUser = (email: string, password: string, group: number) => {
    dispatch(createUser(email, password, group, onShowMessage, onSetError));
    onToggleModal();
  };

  const rows = useMemo(() => {
    return users.sort((a, b) => b.id - a.id).map((user, index) => createUsersRow(user, index));
  }, [users]);

  const getContent = (colId, row: UserRow) => {
    return userManagementRenderSwitch(colId, row, onUserGroupChange, onSave, onDelete);
  };

  return (
    <>
      <div className={s.header}>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.DEFAULT}
          classes={{ root: s.newUserButton }}
          onClick={onToggleModal}
        >
          Add New User
        </Button>
      </div>
      <CustomizedTable
        count={rows.length}
        columns={USER_MANAGEMENT}
        rows={rows}
        isLoading={isLoading}
        error={null}
        emptyRowsMsg="There are no privileged groups yet..."
        getContent={getContent}
        getCollapsibleContent={() => null}
        rowsPerPageOptions={[40]}
        customPerPage={40}
        customPage={0}
        onCustomChangePage={() => null}
        offPerPage
        classes={{
          wrapperClass: s.groupContent,
          tHeadClasses: {
            tHeadCellClass: s.tHeadCellClass
          },
          tBodyClasses: {
            tBodyCellClass: s.tBodyCellClass
          },
          tableWrapper: s.tableContainer
        }}
      />
      <MyModal isOpen={isModalOpen} onToggle={onToggleModal}>
        <UserForm onCancel={onToggleModal} onSubmit={onCreateNewUser} />
      </MyModal>
    </>
  );
};

export default UserManagementTable;
