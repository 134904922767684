import { AnyAction } from 'redux';
import { MessageType, UtilsReducer } from '../../interfaces/utilsShapes';
import { utilsTypes } from '../types';
import { getRandomNumber } from '../../helpers/utilsHelpers';

const initialState = {
  message: {
    type: 'success' as MessageType,
    messageId: null,
    messageText: '',
    showMessage: false
  }
};

export default function utilsReducer(state = initialState, { type, payload }: AnyAction): UtilsReducer {
  switch (type) {
    case utilsTypes.SHOW_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          messageText: payload?.messageText ?? 'Success',
          messageId: getRandomNumber(),
          type: (payload?.type as MessageType) ?? 'success',
          showMessage: payload?.showMessage ?? true
        }
      };
    case utilsTypes.HIDE_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          messageText: '',
          messageId: null,
          type: 'success' as MessageType,
          showMessage: false
        }
      };
    default:
      return state;
  }
}
