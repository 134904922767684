import * as Shapes from 'interfaces/logsShapes';
import { logsTypes } from '../types';

export const getLogs = (
  queryObj: Partial<Shapes.GetLogsQuery>,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.GetLogsShape => ({
  type: logsTypes.GET_LOGS,
  payload: { queryObj, onSuccess, onError }
});

export const putLogs = (logs: Array<Shapes.LogsData>): Shapes.PutLogsShape => ({
  type: logsTypes.PUT_LOGS,
  payload: logs
});

export const getLogsFilters = (onError: (err) => void): Shapes.GetLogsFiltersShape => ({
  type: logsTypes.GET_FILTER_DATA,
  payload: { onError }
});

export const putLogsFilters = (filterData: Shapes.FilterData): Shapes.PutFilterDataShape => ({
  type: logsTypes.PUT_FILTER_DATA,
  payload: filterData
});

export const getLogsCount = (
  params: Partial<Shapes.GetLogsQuery>,
  onSuccess?: () => void,
  onError?: (err) => void
): Shapes.GetLogsCount => ({
  type: logsTypes.GET_LOGS_COUNT,
  payload: { params, onSuccess, onError }
});

export const putLogsCount = (data: { count: number }): Shapes.PutLogsCount => ({
  type: logsTypes.PUT_LOGS_COUNT,
  payload: data
});
