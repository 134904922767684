import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { groupsSelector } from '../../../redux/selectors';
import s from './GroupSelect.module.scss';
import { FormControl, MenuItem, Select } from '@material-ui/core';

interface GroupSelectProps {
  id: number;
  name: string;
  onGroupChange: (name: string, id: number) => void;
}

const GroupSelect: FC<GroupSelectProps> = ({ id, name, onGroupChange }) => {
  const groups = useSelector(groupsSelector);
  const handleFilterChange = ({ target }) => {
    onGroupChange(target.name, target.value);
  };
  return (
    <FormControl variant="outlined" className={s.filterFormControl}>
      <Select name="group-select" displayEmpty value={id} onChange={handleFilterChange} className={s.select}>
        {groups.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GroupSelect;
