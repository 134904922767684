import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const YearMonthSelect = ({ onChange, startDate, initialFromDate, initialToDate }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = startDate ? new Date(startDate).getFullYear() : currentYear;
  const startMonth = startDate ? new Date(startDate).getMonth() + 1 : 1;

  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];

  const [fromYear, setFromYear] = useState(initialFromDate ? new Date(initialFromDate).getFullYear() : startYear);
  const [fromMonth, setFromMonth] = useState(initialFromDate ? new Date(initialFromDate).getMonth() + 1 : startMonth);
  const [toYear, setToYear] = useState(initialToDate ? new Date(initialToDate).getFullYear() : currentYear);
  const [toMonth, setToMonth] = useState(
    initialToDate ? new Date(initialToDate).getMonth() + 1 : currentDate.getMonth() + 1
  );

  const [changedFrom, setChangedFrom] = useState(false);
  const [changedTo, setChangedTo] = useState(false);

  const handleFromYearChange = (event) => {
    const selectedYear = event.target.value;
    setFromYear(selectedYear);

    let validFromMonth = fromMonth;

    if (selectedYear === toYear) {
      validFromMonth = Math.min(fromMonth, toMonth);
    } else if (selectedYear === startYear) {
      validFromMonth = Math.max(startMonth, fromMonth);
    }

    setFromMonth(validFromMonth);
    setChangedFrom(true);
  };

  const handleFromMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setFromMonth(selectedMonth);
    setChangedFrom(true);
  };

  const handleToYearChange = (event) => {
    const selectedYear = event.target.value;
    setToYear(selectedYear);

    let validToMonth = toMonth;

    if (selectedYear === fromYear) {
      validToMonth = Math.max(fromMonth, toMonth);
    } else if (selectedYear === startYear) {
      validToMonth = Math.max(startMonth, toMonth);
    }

    setToMonth(validToMonth);
    setChangedTo(true);
  };

  const handleToMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setToMonth(selectedMonth);
    setChangedTo(true);
  };

  useEffect(() => {
    if ((changedFrom || changedTo) && onChange) {
      onChange({ date_from: formatYearMonth(fromYear, fromMonth), date_to: formatYearMonth(toYear, toMonth) });
      setChangedFrom(false);
      setChangedTo(false);
    }
  }, [fromYear, fromMonth, toYear, toMonth, onChange, changedFrom, changedTo]);

  const formatYearMonth = (year, month) => {
    const formattedMonth = month.toString().padStart(2, '0');
    return `${year}-${formattedMonth}-01`;
  };

  const maxDate = new Date();
  const maxYear = maxDate.getFullYear();
  const maxMonth = maxDate.getMonth() + 1;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            select
            label="From"
            variant="outlined"
            fullWidth
            margin="normal"
            value={fromYear}
            onChange={handleFromYearChange}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year} disabled={year > toYear}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            variant="outlined"
            fullWidth
            margin="normal"
            value={fromMonth}
            onChange={handleFromMonthChange}
          >
            {months.map((month) => (
              <MenuItem
                key={month.value}
                value={month.value}
                disabled={
                  (fromYear === toYear && month.value > toMonth) ||
                  (fromYear === toYear && month.value === toMonth && fromMonth > toMonth) ||
                  (fromYear === startYear && month.value < startMonth)
                }
              >
                {month.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            select
            label="To"
            variant="outlined"
            fullWidth
            margin="normal"
            value={toYear}
            onChange={handleToYearChange}
          >
            {years
              .filter((year) => year <= maxYear)
              .map((year) => (
                <MenuItem key={year} value={year} disabled={year > maxYear}>
                  {year}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField select variant="outlined" fullWidth margin="normal" value={toMonth} onChange={handleToMonthChange}>
            {months
              .filter((month) => toYear !== maxYear || month.value <= maxMonth)
              .map((month) => (
                <MenuItem
                  key={month.value}
                  value={month.value}
                  disabled={
                    (toYear === fromYear && month.value < fromMonth) ||
                    (toYear === fromYear && month.value === fromMonth && toMonth < fromMonth) ||
                    (toYear === startYear && month.value < startMonth)
                  }
                >
                  {month.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default YearMonthSelect;
