import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import CustomizedTextField, {
  CustomizedTextFieldProps
} from 'components/fields/customizedTextField/CustomizedTextField';

interface NumberFormaFieldProps {
  inputProps: CustomizedTextFieldProps;
  allowNegative?: boolean;
  onAllowed?: (values: NumberFormatValues) => boolean;
}

const NumberFormatField = ({ inputProps, allowNegative, onAllowed }: NumberFormaFieldProps): JSX.Element => {
  return (
    <NumberFormat
      isAllowed={onAllowed ? onAllowed : (values) => true}
      allowNegative={allowNegative ?? false}
      customInput={CustomizedTextField}
      {...inputProps}
    />
  );
};

export default NumberFormatField;
