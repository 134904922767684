import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  CALS_MAP,
  COUNT_FIELDS,
  FIELD_DEPENDENCIES_MAP,
  FIELDS_MAP,
  FILTERING_FIELDS,
  ONLY_FINISHED_FILED,
  PrivilegesGroupFormFields,
  SORTING_FIELDS
} from '../../../constants/privilegesConstants';
import { useFormikContext } from 'formik';
import PrivilegesModal from '../../../components/privilegesModal/PrivilegesModal';

interface AddGroupProps {
  colId: string;
  handleClose: () => void;
}

const AddGroup: FC<AddGroupProps> = ({ colId, handleClose }): JSX.Element => {
  const { setFieldValue } = useFormikContext<PrivilegesGroupFormFields>();

  const [fields, setFields] = useState<string[]>(FIELDS_MAP[colId]);
  const [filteringValue, setFilteringValue] = useState(true);
  const [sortingValue, setSortingValue] = useState(true);
  const [onlyFinishedValue, setOnlyFinishedValue] = useState(true);
  const [signalsCount, setSignalsCount] = useState(200);
  const fieldsDeps = useMemo(() => FIELD_DEPENDENCIES_MAP[colId] || [], [colId]);

  const handleChange = useCallback(
    ({ target }, { props }) => {
      if (fieldsDeps.length > 0 && fieldsDeps.indexOf(props.value) >= 0 && target.value.indexOf(props.value) >= 0) {
        setFields([...target.value.filter((el) => fieldsDeps.indexOf(el) < 0), props.value]);
      } else {
        setFields(target.value);
      }
    },
    [fieldsDeps]
  );

  const onFilteringCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFilteringValue(target.checked);
  };
  const onSortingCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSortingValue(target.checked);
  };
  const onOnlyFinishedClick = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyFinishedValue(target.checked);
  };
  const onSignalsCountChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSignalsCount(+target.value);
  };

  const hasFilteringProperty = useMemo(() => FILTERING_FIELDS.some((field) => field === colId), [colId]);
  const hasSortingProperty = useMemo(() => SORTING_FIELDS.some((field) => field === colId), [colId]);
  const hasOnlyFinishedProperty = useMemo(() => ONLY_FINISHED_FILED.some((field) => field === colId), [colId]);
  const hasSignalsCountProperty = useMemo(() => COUNT_FIELDS.some((field) => field === colId), [colId]);

  const onSave = () => {
    setFieldValue(`${colId}__fields`, fields);
    if (hasFilteringProperty) {
      setFieldValue(`${colId}__filtering`, filteringValue);
    }
    if (hasSortingProperty) {
      setFieldValue(`${colId}__sorting`, sortingValue);
    }
    if (hasOnlyFinishedProperty) {
      setFieldValue(`${colId}__is_only_finished`, onlyFinishedValue);
    }
    if (hasSignalsCountProperty) {
      setFieldValue(`${colId}__signals_count`, signalsCount);
    }
    handleClose();
  };

  return (
    <PrivilegesModal
      fields={fields}
      colId={colId}
      filteringValue={filteringValue}
      sortingValue={sortingValue}
      onSortingCheck={onSortingCheck}
      onlyFinishedValue={onlyFinishedValue}
      onOnlyFinishedClick={onOnlyFinishedClick}
      onClose={handleClose}
      handleChange={handleChange}
      onFilteringCheck={onFilteringCheck}
      signalsCount={signalsCount}
      onSignalsCountChange={onSignalsCountChange}
      onSave={onSave}
      columns={CALS_MAP[colId]}
    />
  );
};

export default AddGroup;
