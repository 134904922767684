import React, { FC, useState } from 'react';
import s from './ChangeDepositeForm.module.scss';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PairedBtnBlock from '../../../components/buttons/pairedBtnBlock/PairedBtnBlock';
import NumberFormatField from '../../../components/fields/numberFormatField/NumberFormatField';
import * as Yup from 'yup';
import { ChangedDepositFormDTO, FormValues } from '../../../interfaces/mmProfilesShapes';
import { createChangeDepositDTO } from '../../../helpers/mmProfilesHelpers';
import { percentOptions } from '../../../constants/mmProfilesConstants';
import { ErrorShape } from '../../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../../helpers/randomHelpers';

interface ChangeDepositFormProps {
  onCancel: () => void;
  onSubmit: (reqData: ChangedDepositFormDTO, onSetError: (err: ErrorShape) => void) => void;
}

const ChangeDepositForm: FC<ChangeDepositFormProps> = ({ onCancel, onSubmit }) => {
  const [reqErr, setReqErr] = useState<string | null>(null);
  const onClearRequestError = () => setReqErr(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };
  return (
    <div className={s.formWrapper}>
      <h2 className={cn('default-title', s.title)}>Change Deposit</h2>
      <Formik
        initialValues={{
          operation_type: 'increase',
          size: ''
        }}
        validationSchema={Yup.object({
          operation_type: Yup.string().required('Pick one option is required'),
          size: Yup.number().when(['operation_type'], {
            is: (operation_type) => operation_type === 'increase',
            then: Yup.number().min(0.01, 'min 0.01%').required('Field is required'),
            otherwise: Yup.number().min(0.01, 'min 0.01%').max(100, 'max 100%').required('Field is required')
          })
        })}
        onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          onSubmit(createChangeDepositDTO(values), onSetError);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <Form className={s.form}>
              <RadioGroup
                classes={{ root: s.radio }}
                row
                aria-label="position"
                name="operation_type"
                defaultValue="top"
                onChange={handleChange}
                value={values.operation_type}
              >
                <FormControlLabel
                  value="decrease"
                  control={<Radio color="primary" />}
                  label="Decrease"
                  labelPlacement="start"
                />
                <FormControlLabel value="increase" control={<Radio color="primary" />} label="Increase" />
              </RadioGroup>
              <NumberFormatField
                inputProps={{
                  id: 'size',
                  placeholder: 'Type in size',
                  value: values.size,
                  helperText: touched.size && errors.size,
                  isValid: touched.size && !errors.size,
                  isError: (!!errors.size && touched.size) || !!reqErr,
                  onBlur: handleBlur,
                  onChange: handleChange,
                  onClearRequestError: onClearRequestError,
                  setFieldValue: (name, value) => {
                    setFieldValue(name, value);
                  },
                  options: percentOptions
                }}
              />
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangeDepositForm;
