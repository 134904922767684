import React, { FC, useCallback, useMemo } from 'react';
import s from './PrivilegesModal.module.scss';
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import {
  COUNT_FIELDS,
  FILTERING_FIELDS,
  ONLY_FINISHED_FILED,
  SIGNALS_FIELDS_PICK,
  SORTING_FIELDS
} from '../../constants/privilegesConstants';
import PairedBtnBlock from '../buttons/pairedBtnBlock/PairedBtnBlock';
import NumberFormatField from '../fields/numberFormatField/NumberFormatField';
import { START_COEFFICIENT } from '../../constants/strategyProfilesAddConstants';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 300
    }
  }
};

interface PrivilegesModalProps {
  fields: Array<string>;
  columns: typeof SIGNALS_FIELDS_PICK;
  sortingValue: boolean;
  filteringValue: boolean;
  onlyFinishedValue: boolean;
  signalsCount: number;
  colId: string;
  handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>, child: unknown) => void;
  onSortingCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilteringCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOnlyFinishedClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSignalsCountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onSave: () => void;
}

const PrivilegesModal: FC<PrivilegesModalProps> = ({
  onClose,
  sortingValue,
  filteringValue,
  onSortingCheck,
  onFilteringCheck,
  colId,
  fields,
  columns,
  onSave,
  handleChange,
  onOnlyFinishedClick,
  onlyFinishedValue,
  signalsCount,
  onSignalsCountChange
}): JSX.Element => {
  const handleIsChecked = useCallback((fieldName: string) => fields?.indexOf(fieldName) > -1 || false, [fields]);
  const hasFilteringProperty = useMemo(() => FILTERING_FIELDS.some((field) => field === colId), [colId]);
  const hasSortingProperty = useMemo(() => SORTING_FIELDS.some((field) => field === colId), [colId]);
  const hasOnlyFinishedProperty = useMemo(() => ONLY_FINISHED_FILED.some((field) => field === colId), [colId]);
  const hasCountProperty = useMemo(() => COUNT_FIELDS.some((field) => field === colId), [colId]);
  return (
    <div className={s.limitsForm}>
      <FormControl className={s.formControl} variant="outlined">
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={fields}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={MenuProps}
          autoWidth
        >
          {columns.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              <Checkbox checked={handleIsChecked(field.id)} />
              <ListItemText primary={field.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={s.flexed}>
        {hasFilteringProperty && (
          <FormControlLabel
            label="Filtering"
            control={
              <Checkbox
                color="primary"
                style={{ transform: 'scale(1.2)', marginRight: '10px' }}
                checked={filteringValue}
                value={filteringValue}
                name={colId}
                onChange={onFilteringCheck}
              />
            }
          />
        )}
        {hasSortingProperty && (
          <FormControlLabel
            label="Sorting"
            control={
              <Checkbox
                color="primary"
                style={{ transform: 'scale(1.2)', marginRight: '10px' }}
                checked={sortingValue}
                value={sortingValue}
                name={colId}
                onChange={onSortingCheck}
              />
            }
          />
        )}
        {hasOnlyFinishedProperty && (
          <FormControlLabel
            label="Only Finished"
            control={
              <Checkbox
                color="primary"
                style={{ transform: 'scale(1.2)', marginRight: '10px' }}
                checked={onlyFinishedValue}
                value={onlyFinishedValue}
                name={colId}
                onChange={onOnlyFinishedClick}
              />
            }
          />
        )}
        {hasCountProperty && (
          <FormControl className={s.NumericFiled} variant="outlined">
            <NumberFormatField
              inputProps={{
                id: START_COEFFICIENT,
                label: 'Signals per page',
                placeholder: '200',
                value: signalsCount,
                isError: false,
                isValid: true,
                onChange: onSignalsCountChange,
                onBlur: () => null,
                helperText: null,
                onClearRequestError: null
              }}
            />
          </FormControl>
        )}
      </div>
      <PairedBtnBlock
        onCancel={onClose}
        onSubmit={onSave}
        btnTypeSubmit="submit"
        labels={{
          cancelLabel: 'Cancel',
          submitLabel: 'Save'
        }}
        classes={{ btnBlockWrapper: s.btnBlockWrapper }}
      />
    </div>
  );
};

export default PrivilegesModal;
