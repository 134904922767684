import { AnyAction } from 'redux';
import { privilegesTypes } from '../types';
import {
  COUNT_FIELDS,
  FIELDS_HAS_COLS,
  FILTERING_FIELDS,
  filterPerPageValues,
  LIMITED_FIELDS_MAP,
  ONLY_FINISHED_FILED,
  PrivilegesColIds,
  SORTING_FIELDS
} from '../../constants/privilegesConstants';
import { PrivilegesReducer } from '../../interfaces/privilegesShapes';

const initialState: PrivilegesReducer = {
  groups: [],
  users: []
};

export default function privilegesReducer(state = initialState, { type, payload }: AnyAction): PrivilegesReducer {
  switch (type) {
    case privilegesTypes.SET_GROUPS:
      return { ...state, groups: payload };
    case privilegesTypes.UPDATE_STATUS:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.id) {
            return {
              ...group,
              perms: group.perms.map((perm) => {
                if (payload.name === perm.section_name) {
                  return {
                    ...perm,
                    status: payload.status,
                    ...(FIELDS_HAS_COLS.indexOf(payload.name as PrivilegesColIds) >= 0 && {
                      fields: payload.status === filterPerPageValues.NP ? [] : LIMITED_FIELDS_MAP[perm.section_name]
                    }),
                    ...(FILTERING_FIELDS.indexOf(payload.name as PrivilegesColIds) >= 0 && {
                      filtering: payload.status !== filterPerPageValues.NP
                    }),
                    ...(SORTING_FIELDS.indexOf(payload.name as PrivilegesColIds) >= 0 && {
                      sorting: payload.status !== filterPerPageValues.NP
                    }),
                    ...(ONLY_FINISHED_FILED.indexOf(payload.name as PrivilegesColIds) >= 0 && {
                      is_only_finished: payload.status === filterPerPageValues.NP
                    }),
                    ...(COUNT_FIELDS.indexOf(payload.name as PrivilegesColIds) >= 0 && {
                      signals_count: 200
                    })
                  };
                }
                return perm;
              })
            };
          }
          return group;
        })
      };
    case privilegesTypes.UPDATE_FIELDS:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.id) {
            return {
              ...group,
              perms: group.perms.map((perm) => {
                if (perm.section_name === payload.name) {
                  return { ...perm, fields: payload.fields };
                }
                return perm;
              })
            };
          }
          return group;
        })
      };
    case privilegesTypes.UPDATE_SORTING:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.id) {
            return {
              ...group,
              perms: group.perms.map((perm) => {
                if (perm.section_name === payload.name) {
                  return { ...perm, sorting: payload.sorting };
                }
                return perm;
              })
            };
          }
          return group;
        })
      };
    case privilegesTypes.UPDATE_FILTERING:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.id) {
            return {
              ...group,
              perms: group.perms.map((perm) => {
                if (perm.section_name === payload.name) {
                  return { ...perm, filtering: payload.filtering };
                }
                return perm;
              })
            };
          }
          return group;
        })
      };
    case privilegesTypes.UPDATE_FINISHED_ONLY:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.id) {
            return {
              ...group,
              perms: group.perms.map((perm) => {
                if (perm.section_name === payload.name) {
                  return { ...perm, is_only_finished: payload.finishedOnly };
                }
                return perm;
              })
            };
          }
          return group;
        })
      };
    case privilegesTypes.UPDATE_SIGNALS_COUNT:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === payload.id) {
            return {
              ...group,
              perms: group.perms.map((perm) => {
                if (perm.section_name === payload.name) {
                  return { ...perm, signals_count: payload.signalsCount };
                }
                return perm;
              })
            };
          }
          return group;
        })
      };
    case privilegesTypes.REMOVE_GROUP_PRIVILEGES:
      return {
        ...state,
        groups: state.groups.filter((group) => {
          return group.id !== payload.id;
        })
      };
    case privilegesTypes.SET_USERS:
      return {
        ...state,
        users: payload
      };
    case privilegesTypes.SET_USER:
      return {
        ...state,
        users: [...state.users, payload]
      };

    case privilegesTypes.CHANGE_USER_GROUP:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === payload.id
            ? { ...user, group: { ...user.group, id: payload.groupId, name: payload.groupName } }
            : user
        )
      };
    case privilegesTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => (user.id === payload.id ? { ...user, is_active: false } : user))
      };
    default:
      return state;
  }
}
