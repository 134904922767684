import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import { CreateUser, GroupPermission } from '../../../interfaces/privilegesShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const getGroupsRequestHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.USERS_GROUPS);
};

export const getGroupRequestHelper = (groupId: number, data: GroupPermission): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.USERS_GROUP(groupId), data);
};

export const postGroupsRequestHelper = (data: GroupPermission): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.USERS_GROUPS, data);
};

export const deleteGroupsRequestHelper = (groupId: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.USERS_GROUP(groupId));
};

export const getUsersHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.USERS);
};

export const updateUserHelper = (id: number, group: string): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.USER(id), { group });
};

export const createUsersHelper = (data: CreateUser): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.USERS, data);
};

export const deleteUsersHelper = (id: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.USER_UPDATE(id));
};
