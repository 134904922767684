import React from 'react';
import { StrategyTickersIds, TICKER_NOT_ALLOWED_TEXT } from '../constants/strategyTickersConstants';
import s from '../pages/privilegesAddPage/PrivilegesAdd.module.scss';
import Button from '@material-ui/core/Button';
import { ButtonColorTypes } from '../constants/randomConstants';
import TradingPairsSelect from '../components/tables/strategyTickers/tradingPairsSelect/TradingPairsSelect';
import { PairsListFormValues, PairsShape, PairsStatusShape } from '../interfaces/strategyProfilesShapes';
import { FormControl, MenuItem, Select, Tooltip } from '@material-ui/core';
import { Paste } from '../assets/images/Paste';

export const strategyTickersRenderSwitch = (
  colId: StrategyTickersIds,
  row: PairsShape,
  tradingPairsOptions: Array<{ value; label: string }>,
  isReadOnly: boolean,
  handleSave: (id: number) => void,
  handleDelete: (id: number) => void,
  onUpdateStatus: (id: number, status: PairsStatusShape) => void,
  onUpdatePairs: (id: number, pairsIds: number[]) => void,
  onOpenPasteModal: (id: number) => void
): JSX.Element => {
  const value = row[colId];
  switch (colId) {
    case StrategyTickersIds.PAIRS:
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <TradingPairsSelect
            values={value}
            options={tradingPairsOptions}
            handleUpdateValue={(values) => onUpdatePairs(row?.id, values as number[])}
            isDisabled={row.originalStatus === PairsStatusShape.ACTIVE && row.status === PairsStatusShape.ACTIVE}
          />
          <Tooltip title="Paste Tickers">
            <Button onClick={() => onOpenPasteModal(row?.id)}>
              <Paste />
            </Button>
          </Tooltip>
        </div>
      );
    case StrategyTickersIds.SAVE:
      const Btn = (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.PRIMARY}
          onClick={() => handleSave(row?.id)}
          classes={{ root: s.actionBtn, outlinedPrimary: s.btnPrimary }}
          disabled={
            isReadOnly || (row.originalStatus === PairsStatusShape.ACTIVE && row.status === PairsStatusShape.ACTIVE)
          }
        >
          SAVE
        </Button>
      );
      return row.originalStatus === PairsStatusShape.ACTIVE && row.status === PairsStatusShape.ACTIVE ? (
        <Tooltip
          title={
            <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
              {TICKER_NOT_ALLOWED_TEXT}
            </div>
          }
        >
          <span>{Btn}</span>
        </Tooltip>
      ) : (
        Btn
      );
    case StrategyTickersIds.DELETE: {
      const Btn = (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.SECONDARY}
          onClick={() => handleDelete(row?.id)}
          classes={{ root: s.actionBtn, outlinedPrimary: s.btnPrimary }}
          disabled={
            isReadOnly || (row.originalStatus === PairsStatusShape.ACTIVE && row.status === PairsStatusShape.ACTIVE)
          }
        >
          DELETE
        </Button>
      );
      return row.originalStatus === PairsStatusShape.ACTIVE && row.status === PairsStatusShape.ACTIVE ? (
        <Tooltip
          title={
            <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
              {TICKER_NOT_ALLOWED_TEXT}
            </div>
          }
        >
          <span>{Btn}</span>
        </Tooltip>
      ) : (
        Btn
      );
    }
    case StrategyTickersIds.STATUS:
      return (
        <FormControl variant="outlined">
          <Select
            labelId={PairsListFormValues.STATUS}
            id={PairsListFormValues.STATUS}
            name={PairsListFormValues.STATUS}
            value={value}
            onChange={({ target }) => onUpdateStatus(row?.id, target.value as PairsStatusShape)}
            placeholder="Select pairs"
          >
            <MenuItem value={PairsStatusShape.ACTIVE}>{PairsStatusShape.ACTIVE}</MenuItem>
            <MenuItem value={PairsStatusShape.INACTIVE}>{PairsStatusShape.INACTIVE}</MenuItem>
          </Select>
        </FormControl>
      );
    default:
      return <>{value ?? '-'}</>;
  }
};
