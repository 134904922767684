import { ExchangeAccountShape } from 'interfaces/systemSettingsShapes';

export const exchangeAccountsTypes = {
  CREATE_INIT: 'CREATE_INIT',
  CLOSE_MODAL: 'CLOSE_MODAL',
  STOP_LOADING: 'STOP_LOADING',
  EDIT_INIT: 'EDIT_INIT',
  DELETE_INIT: 'DELETE_INIT',
  DELETE_ERROR: 'DELETE_ERROR'
};

export const exchangeAccountsInitialState = {
  isLoading: true,
  isOpen: false,
  isEdit: false,
  isDelete: false,
  deleteError: null,
  currentAccount: null
};

interface ExchangeAccountsReducer {
  isLoading: boolean;
  isOpen: boolean;
  isEdit: boolean;
  isDelete: boolean;
  deleteError: null | string;
  currentAccount: null | ExchangeAccountShape;
}

export const exchangeAccountsReducer = (
  state: ExchangeAccountsReducer,
  { type, payload }: { type: string; payload? }
): ExchangeAccountsReducer => {
  switch (type) {
    case exchangeAccountsTypes.CREATE_INIT:
      return { ...state, isOpen: true, isEdit: false, isDelete: false };
    case exchangeAccountsTypes.CLOSE_MODAL:
      return { ...state, isOpen: false, currentAccount: null, deleteError: null };
    case exchangeAccountsTypes.STOP_LOADING:
      return { ...state, isLoading: false };
    case exchangeAccountsTypes.EDIT_INIT:
      return { ...state, isOpen: true, isEdit: true, isDelete: false, currentAccount: payload };
    case exchangeAccountsTypes.DELETE_INIT:
      return { ...state, isOpen: true, isEdit: false, isDelete: true, currentAccount: payload };
    case exchangeAccountsTypes.DELETE_ERROR:
      return { ...state, deleteError: payload };
    default:
      return state;
  }
};
