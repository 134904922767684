import { ColumnAlignOptions } from './randomConstants';
import * as Yup from 'yup';

export enum UserManagementIds {
  ID = 'id',
  EMAIL = 'email',
  GROUPS = 'groups',
  ACTIVE_STATUS = 'is_active',
  SAVE = 'save',
  DELETE = 'delete'
}

export const userManagementColsNames = {
  [UserManagementIds.ID]: 'Id',
  [UserManagementIds.EMAIL]: 'Email',
  [UserManagementIds.GROUPS]: 'Groups',
  [UserManagementIds.ACTIVE_STATUS]: 'Activity Status',
  [UserManagementIds.SAVE]: 'Save',
  [UserManagementIds.DELETE]: 'Delete'
};

export const USER_MANAGEMENT = [
  {
    id: UserManagementIds.ID,
    title: userManagementColsNames[UserManagementIds.ID],
    align: ColumnAlignOptions.CENTER,
    width: '3%'
  },
  {
    id: UserManagementIds.EMAIL,
    title: userManagementColsNames[UserManagementIds.EMAIL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: UserManagementIds.GROUPS,
    title: userManagementColsNames[UserManagementIds.GROUPS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: UserManagementIds.ACTIVE_STATUS,
    title: userManagementColsNames[UserManagementIds.ACTIVE_STATUS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: UserManagementIds.DELETE,
    title: userManagementColsNames[UserManagementIds.DELETE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: UserManagementIds.SAVE,
    title: userManagementColsNames[UserManagementIds.SAVE],
    align: ColumnAlignOptions.CENTER
  }
];

export enum UserFormValues {
  EMAIL = 'email',
  PASSWORD = 'password',
  REPEAT_PASSWORD = 'repeat_password',
  GROUP = 'group'
}

export const userFormValidation = Yup.object({
  [UserFormValues.EMAIL]: Yup.string().email('Please enter the valid email').required('Email can’t be blank'),
  [UserFormValues.PASSWORD]: Yup.string()
    .required('Password can’t be blank')
    .min(8, 'This password is too short. It must contain at least 8 characters.')
    .matches(/[a-zA-Z]/, 'Password must contain at least 1 letter.')
    .matches(/[0-9]/, 'At least 1 number')
    .matches(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g, 'Password must contain at least 1 special character.'),
  [UserFormValues.REPEAT_PASSWORD]: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Repeat password can’t be blank'),
  [UserFormValues.GROUP]: Yup.number().required('Group must be picked').min(1, 'Group must be picked')
});
