import React from 'react';
import cn from 'classnames';
import truncate from 'lodash/truncate';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import s from './CustomizableList.module.scss';

export interface ListCssClasses {
  listWrapper?: string;
  listItem?: string;
  listClass?: string;
}

interface CustomizableListProps {
  list: Array<{ id; title: string }>;
  isEditable?: boolean;
  onEdit?: (item) => void;
  onDelete: (item) => void;
  scrollAfterItems?: number;
  isReadOnly: boolean;
  classes?: ListCssClasses;
}

const DEFAULT_TRUNCATE_LENGTH = 35;

const CustomizableList = ({
  list,
  classes,
  isEditable,
  scrollAfterItems = 6,
  onEdit,
  onDelete,
  isReadOnly
}: CustomizableListProps): JSX.Element => {
  return (
    <div className={cn(s.rootWrapper, { [classes?.listWrapper]: classes?.listWrapper })}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className={s.listWrapper}>
            <List
              classes={{
                root: cn(s.listRoot, {
                  [s.listRootScrollable]: list.length >= scrollAfterItems,
                  [classes?.listClass]: classes?.listClass
                })
              }}
            >
              {list.map((item) => (
                <ListItem
                  key={item.id}
                  classes={{ root: cn(s.listItemRoot, { [classes?.listItem]: classes?.listItem }) }}
                >
                  <ListItemText
                    primary={truncate(item.title, { length: DEFAULT_TRUNCATE_LENGTH })}
                    classes={{ root: s.listTitleRoot }}
                  />
                  {!isReadOnly && (
                    <ListItemSecondaryAction>
                      {isEditable && (
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => onEdit(item)}
                          classes={{ root: s.editBtnRoot }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      )}

                      <IconButton edge="end" aria-label="delete" onClick={() => onDelete(item)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomizableList;
