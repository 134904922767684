import React from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import NumberFormatField from 'components/fields/numberFormatField/NumberFormatField';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { LimitCoefficient } from 'interfaces/signalsOrdersShapes';
import s from './SignalsTable.module.scss';
import ModalLoader from '../ui/modalLoader/ModalLoader';

interface CancelLimitFormProps {
  onCancel: () => void;
  onSubmit: (coefficient: LimitCoefficient) => void;
  onClearReqError: () => void;
  reqError: string | null;
}

interface FormValues {
  coefficient: string;
}

const CancelLimitForm: React.FC<CancelLimitFormProps> = ({
  onCancel,
  onSubmit,
  onClearReqError,
  reqError
}): React.ReactElement => {
  return (
    <div className={s.formWrapper}>
      <h2 className={cn('default-title', s.title)}>Enter new coefficient for order</h2>
      <Formik
        initialValues={{ coefficient: '' }}
        validationSchema={Yup.object({
          coefficient: Yup.number()
            .required('Please enter the new coefficient')
            .min(0.001, 'Coefficient must be greater than or equal to 0.001')
            .max(0.999, 'Coefficient must be less than or equal to 0.999')
            .typeError('Please enter a valid number')
        })}
        onSubmit={({ coefficient }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          onSubmit({ coefficient: +coefficient });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form className={s.form}>
              {isSubmitting && <ModalLoader />}
              <NumberFormatField
                inputProps={{
                  id: 'coefficient',
                  placeholder: 'New coefficient',
                  isError: (!!errors.coefficient && touched.coefficient) || !!reqError,
                  helperText: reqError || (touched.coefficient && errors.coefficient),
                  isValid: touched.coefficient && !errors.coefficient && !reqError,
                  onBlur: handleBlur,
                  onChange: handleChange,
                  onClearRequestError: onClearReqError,
                  value: values.coefficient,
                  inputClass: s.input,
                  disabled: isSubmitting,
                  min: 0.001,
                  max: 0.999,
                  decimalScale: 3,
                  fixedDecimalScale: true,
                  allowNegative: false
                }}
              />
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting || !!errors.coefficient}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const isEqual = (prevProps: CancelLimitFormProps, nextProps: CancelLimitFormProps) =>
  prevProps.reqError === nextProps.reqError;

export default React.memo(CancelLimitForm, isEqual);
