import React, { FC } from 'react';
import s from './BlockerForm.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormControl } from '@material-ui/core';
import NumberFormatField from '../../fields/numberFormatField/NumberFormatField';
import PairedBtnBlock from '../../buttons/pairedBtnBlock/PairedBtnBlock';
import { ChangeableTriggerProperty } from '../../../constants/signalsOrdersConstants';
import { useBlockerForm } from '../../../helpers/signalOrdersHelpers';

interface FormValues {
  trigger_blocker: number;
}

interface BlockerFormProps {
  triggerId: number;
  changingProperty: ChangeableTriggerProperty;
  onClose: () => void;
  onSubmit: (id: number, valueToChange: number, property: string) => void;
}

const BlockerForm: FC<BlockerFormProps> = ({ triggerId, onSubmit, onClose, changingProperty }) => {
  const { initValue, modalTitle, isNegativeAllowed, validationSchema } = useBlockerForm(triggerId, changingProperty);
  return (
    <div className={s.blockerContainer}>
      <h2 className="default-title">{modalTitle}</h2>
      <Formik
        initialValues={{ trigger_blocker: initValue }}
        validationSchema={validationSchema}
        onSubmit={({ trigger_blocker }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          onSubmit(triggerId, trigger_blocker, changingProperty);
          onClose();
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
          return (
            <Form className={s.form}>
              <FormControl className={s.NumericField} variant="outlined">
                <NumberFormatField
                  inputProps={{
                    id: 'trigger_blocker',
                    placeholder: 'Type in value',
                    isError: !!errors.trigger_blocker && touched.trigger_blocker,
                    helperText: touched.trigger_blocker && errors.trigger_blocker,
                    isValid: touched.trigger_blocker && !errors.trigger_blocker,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    value: values.trigger_blocker
                  }}
                  allowNegative={isNegativeAllowed}
                />
              </FormControl>
              <PairedBtnBlock
                onCancel={onClose}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlockerForm;
