import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { strategyPairsByIdSelector } from '../../../../redux/selectors';
import { TextField, Tooltip } from '@material-ui/core';
import s from '../addPairList/AddPairListForm.module.scss';
import Button from '@material-ui/core/Button';
import { parseTickers } from '../../../../helpers/parseTickers';
import PairedBtnBlock from '../../../buttons/pairedBtnBlock/PairedBtnBlock';
import { PairsStatusShape } from '../../../../interfaces/strategyProfilesShapes';
import { TICKER_NOT_ALLOWED_TEXT } from '../../../../constants/strategyTickersConstants';

interface TickerPasteModalProps {
  editId: number;
  options: { value; label: string }[];
  handleUpdateValue: (values: number[]) => void;
  onClose: () => void;
}

const TickerPasteModal: FC<TickerPasteModalProps> = ({ editId, options, handleUpdateValue, onClose }) => {
  const [pairs, setPairs] = useState<number[]>([]);
  const pairsData = useSelector(strategyPairsByIdSelector(editId));

  useEffect(() => {
    setPairs(pairsData.pairs);
  }, [pairsData.pairs]);

  const selectedPairsMap = useMemo(() => {
    return options.reduce((acc, { value, label }) => {
      return { ...acc, [label]: value };
    }, {});
  }, [options]);

  const selectedPairsMapOpposite = useMemo(() => {
    return options.reduce((acc, { value, label }) => {
      return { ...acc, [value]: label };
    }, {});
  }, [options]);

  const valuesToDisplay = useMemo(() => {
    return pairs.map((el) => selectedPairsMapOpposite[el] || el);
  }, [pairs, selectedPairsMapOpposite]);

  const onPasteClick = (event) => {
    const pastedText = event.clipboardData.getData('text/plain');
    const tickers = parseTickers(pastedText);
    const validTickers: number[] = [];
    for (const ticker of tickers) {
      if (selectedPairsMap[ticker]) {
        validTickers.push(selectedPairsMap[ticker]);
      }
    }

    const uniquePairs = [...new Set(validTickers)];
    setPairs(uniquePairs);
    event.preventDefault();
  };

  const handleSave = useCallback(() => {
    onClose();
    handleUpdateValue(pairs);
  }, [handleUpdateValue, onClose, pairs]);

  return (
    <div style={{ padding: '20px', margin: '20px' }}>
      <div style={{ display: 'flex', width: '100%', gap: '4px', marginBottom: '30px' }}>
        <TextField
          fullWidth
          id="pairs"
          name="pairs"
          label="Tickers"
          placeholder="Paste only..."
          variant="outlined"
          value={valuesToDisplay}
          onPaste={onPasteClick}
          classes={{ root: s.input }}
          autoComplete="off"
        />
        {valuesToDisplay.length > 0 && (
          <Button
            variant="outlined"
            size="medium"
            type="button"
            classes={{ root: s.exportBtn }}
            onClick={() => setPairs([])}
          >
            Clear
          </Button>
        )}
        {valuesToDisplay.length > 0 && (
          <Tooltip
            arrow
            placement="right"
            enterDelay={250}
            interactive
            title={
              <div className={s.tooltipPairs}>
                {valuesToDisplay.map((el) => {
                  return <span key={el}>{el}, </span>;
                })}
              </div>
            }
          >
            <Button variant="outlined" size="medium" type="button" classes={{ root: s.exportBtn }}>
              List
            </Button>
          </Tooltip>
        )}
      </div>
      <PairedBtnBlock
        onCancel={onClose}
        onSubmit={handleSave}
        labels={{ submitLabel: 'Save' }}
        disableSubmit={
          pairsData.status === PairsStatusShape.ACTIVE && pairsData.originalStatus === PairsStatusShape.ACTIVE
        }
        tooltipDisableSubmit={
          pairsData.status === PairsStatusShape.ACTIVE && pairsData.originalStatus === PairsStatusShape.ACTIVE
            ? TICKER_NOT_ALLOWED_TEXT
            : null
        }
        btnTypeCancel="button"
        btnTypeSubmit="submit"
      />
    </div>
  );
};

export default TickerPasteModal;
