import React, { createContext } from 'react';

export const DRAWER_WIDTH = 240;
export const DRAWER_WIDTH_CLOSED = 74;

export const HARD_PER_PAGE = 50;

export enum ColumnAlignOptions {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

export enum ButtonColorTypes {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INHERIT = 'inherit'
}

export const DEFAULT_TRANSITION_TIMEOUT = 150;

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';

export const ZERO_BASED_PAGE_SHIFT = 1;

export interface IPaginationContext {
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  value: string;
  options: string[];
  isLoading: boolean;
}

export const PaginationContext = createContext<IPaginationContext>({
  onChange: () => null,
  value: '',
  options: [],
  isLoading: false
});
