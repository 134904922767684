import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import KeyParametersForm from './KeyParametersForm';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import { AddSPErrors, KeyParametersShape, StrategyProfileShape } from 'interfaces/strategyProfilesShapes';
import { getInitialKeyParametersValues, getKeyParamsReqValues } from 'helpers/strategyProfilesAddHelpers';
import {
  KeyParametersFormFields,
  keyParametersValidation,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes,
  TOTAL_STEPS
} from 'constants/strategyProfilesAddConstants';
import s from './KeyParameters.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import { FormikProps } from 'formik/dist/types';

interface KeyParametersProps {
  target?: FormTarget;
  onUpdateKeyParameters: (values: KeyParametersShape, type: StrategySettingsFormTypes) => void;
  onCancelParameters: () => void;
  onClearRequestErrors: () => void;
  profile: StrategyProfileShape | null;
  errors: AddSPErrors;
  isStepsHidden?: boolean;
  classes?: { contentWrapperClass?: string; inputClass?: string };
}

const KeyParameters = ({
  target,
  onUpdateKeyParameters,
  onCancelParameters,
  onClearRequestErrors,
  profile,
  errors: { settingsErr },
  isStepsHidden,
  classes
}: KeyParametersProps): JSX.Element => {
  const ref = useRef<FormikProps<KeyParametersFormFields> | null>(null);
  const formInitialValues = getInitialKeyParametersValues(profile);
  useEffect(() => {
    ref.current.values = formInitialValues;
  }, [formInitialValues]);

  return (
    <div className={cn(s.wrapper)}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.KEY_PARAMETERS_STEP}
        totalSteps={TOTAL_STEPS}
        title="Key parameters"
        isStepsHidden={isStepsHidden}
      />
      <Formik
        innerRef={ref}
        initialValues={formInitialValues}
        validationSchema={keyParametersValidation}
        onSubmit={(values: KeyParametersFormFields, { setSubmitting }: FormikHelpers<KeyParametersFormFields>) => {
          onUpdateKeyParameters(getKeyParamsReqValues(values), StrategySettingsFormTypes.S_SETTINGS);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        <KeyParametersForm
          profile={profile}
          target={target}
          reqErr={settingsErr}
          onCancel={onCancelParameters}
          onClearReqError={onClearRequestErrors}
          customInputClass={classes?.inputClass}
          customWrapperClass={
            target === FormTarget.EDITING
              ? cn(s.form_wrapper, { [classes?.contentWrapperClass]: classes?.contentWrapperClass })
              : ''
          }
        />
      </Formik>
    </div>
  );
};

export default KeyParameters;
