import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LoginValues, TfaValues } from 'interfaces/authShapes';
import { captchaRequest, getCurrentUser, loginRequest, tfaQrRequest, tfaRequest } from 'redux/actions/authActions';
import { authSelector } from 'redux/selectors';
import { PAGES } from 'constants/routes';
import s from './LoginPage.module.scss';
import { AuthErrors, CAPTCHA_SITE_KEY } from '../../constants/authConstants';
import Modal from 'components/modal/MyModal';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionAllowed } from '../../helpers/privilegesHelpers';

const LoginPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    qrCode,
    isVerifiedBefore,
    isVerified,
    user: isPrivilegesInitialized
  } = useSelector(authSelector);

  const { profiles } = usePrivileges();
  const { replace } = useHistory();
  const captchaRef = useRef<ReCAPTCHA>();
  const [showPassword, setShowPassword] = useState(false);
  const [isTFAModalOpen, setTFAModalOpen] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const [requestError, setRequestError] = useState<null | string>(null);
  const [modalRequestError, setModalRequestError] = useState<null | string>(null);
  const onClearRequestError = () => setRequestError(null);

  const handleCaptcha = async () => {
    const token = await captchaRef.current.executeAsync();
    captchaRef.current.reset();
    return token;
  };

  const onLoginSubmit = async (values: LoginValues, { setSubmitting }: FormikHelpers<LoginValues>) => {
    const captchaToken = await handleCaptcha();
    try {
      dispatch(
        captchaRequest(
          {
            captchaToken
          },
          () => {
            dispatch(
              loginRequest(
                values,
                () => {
                  dispatch(
                    tfaQrRequest(
                      () => {
                        setTFAModalOpen(true);
                      },
                      () => setRequestError(AuthErrors.TFA_QR_CODE_ERROR)
                    )
                  );
                },
                () => setRequestError(AuthErrors.LOGIN_ERROR)
              )
            );
          },
          () => setRequestError(AuthErrors.CAPTCHA_ERROR)
        )
      );
    } catch (error) {
      setRequestError(AuthErrors.LOGIN_ERROR);
    }
    setSubmitting(false);
  };

  const onTfaSubmit = async (values: TfaValues, { setSubmitting, resetForm }: FormikHelpers<TfaValues>) => {
    try {
      dispatch(
        tfaRequest(
          values.code,
          () => {
            setTFAModalOpen(false);
            resetForm();
            dispatch(
              getCurrentUser(
                () => replace(PAGES.SIGNALS_ORDERS_SIGNALS),
                () => replace(PAGES.ACCOUNT_DETAILS)
              )
            );
          },
          () => setModalRequestError(AuthErrors.TFA_ERROR)
        )
      );
    } catch (error) {
      setModalRequestError(AuthErrors.TFA_ERROR);
    }
    setSubmitting(false);
  };

  const onCloseTfaModal = () => {
    setTFAModalOpen(false);
    setModalRequestError(null);
  };

  if (isAuthenticated && isVerified && isPrivilegesInitialized) {
    return <Redirect to={isSectionAllowed(profiles) ? PAGES.SIGNALS_ORDERS_SIGNALS : PAGES.ACCOUNT_DETAILS} />;
  }

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.titleWrapper}>
          <h1 className={s.title}>Login</h1>

          {requestError && <span className={s.requestError}>{requestError}</span>}
        </div>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string().email('Please enter the valid email').required('Email can’t be blank'),
            password: Yup.string().required('Password can’t be blank')
          })}
          onSubmit={onLoginSubmit}
        >
          {({ errors, values, touched, handleChange, handleBlur }) => (
            <Form className={s.form}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                placeholder="example@gmail.com"
                variant="outlined"
                value={values.email}
                error={(!!errors.email && touched.email) || !!requestError}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                onClick={onClearRequestError}
                classes={{ root: s.input }}
              />

              <TextField
                fullWidth
                autoComplete="off"
                id="password"
                name="password"
                label="Password"
                placeholder="********"
                variant="outlined"
                value={values.password}
                type={showPassword ? 'text' : 'password'}
                error={(!!errors.password && touched.password) || !!requestError}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                onClick={onClearRequestError}
                onFocus={(e) => e.target.setAttribute('autocomplete', 'off')}
                classes={{ root: s.input }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                type="submit"
                classes={{ root: s.btnRoot, text: s.btnText }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        isOpen={isTFAModalOpen}
        onToggle={onCloseTfaModal}
        paperCustomClass={s.modalContent}
        stylesProps={{
          paper: {
            padding: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        {!isVerifiedBefore && qrCode && (
          <>
            <p className={s.modalTitle}>{'Scan QR-code with\nGoogle Authenticator'}</p>
            <img src={`data:image/png;base64, ${qrCode}`} className={s.tfa_qr_code} alt={'qr-code'} />
            <p className={s.modalSubTitle}>and</p>
          </>
        )}

        <p className={s.modalTitle}>Enter authentication code</p>

        {modalRequestError && <span className={s.modalRequestError}>{modalRequestError}</span>}

        <Formik
          initialValues={{
            code: ''
          }}
          validationSchema={Yup.object({
            code: Yup.string().required('2FA code is required')
          })}
          onSubmit={onTfaSubmit}
        >
          {({ errors, values, touched, handleChange, handleBlur }) => (
            <Form className={s.form}>
              <TextField
                fullWidth
                id="code"
                name="code"
                label="Google authentication code"
                autoComplete="off"
                placeholder="000000"
                variant="outlined"
                value={values.code}
                error={(!!errors.code && touched.code) || !!requestError}
                helperText={touched.code && errors.code}
                onBlur={handleBlur}
                onChange={handleChange}
                onClick={onClearRequestError}
                classes={{ root: s.tfa_input }}
              />
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                type="submit"
                classes={{ root: s.btnRoot, text: s.btnText }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>

      <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} ref={captchaRef} size="invisible" badge="bottomright" />
    </div>
  );
};

export default LoginPage;
