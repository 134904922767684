export enum AdministrationValues {
  ACCOUNT_NAME = 'account_name',
  IMP_LOSS = 'imp_loss',
  MAX_DD = 'max_dd',
  MAX_DDD = 'max_ddd',
  NAV = 'nav',
  VAR = 'var',
  VAR_ORDERS = 'open_orders_volume ',
  FREE_VAR = 'free_var ',
  ID = 'id',
  BALANCE_DAILY = 'balance_daily',
  DEPOSIT_MONTHLY = 'deposit_monthly',
  DEPOSIT_AT = 'deposit_at',
  RETURN_ALL = 'return_at_percent',
  RETURN_DAY = 'return_day_percent',
  RETURN_MONTH = 'return_month_percent',
  AVG_CAPITAL_GROWTH_RATE = 'avg_capital_growth_rate',
  ASSET_USDT = 'asset_usdt',
  ASSET_USDC = 'asset_usdc',
  OTHER_STABLECOIN = 'other_stable_coins',
  ASSET_SUM = 'asset_sum'
}

export const AdministrationLabels = {
  [AdministrationValues.IMP_LOSS]: 'Imp.Loss ($)',
  [AdministrationValues.MAX_DD]: 'MaxDD (%)',
  [AdministrationValues.MAX_DDD]: 'MaxDDD (%)',
  [AdministrationValues.NAV]: 'Nav ($)',
  [AdministrationValues.VAR]: 'Var-position ($)',
  [AdministrationValues.FREE_VAR]: 'Free VAR',
  [AdministrationValues.VAR_ORDERS]: 'VAR-orders ($)',
  [AdministrationValues.ID]: 'SDA',
  [AdministrationValues.BALANCE_DAILY]: 'NAV Day',
  [AdministrationValues.DEPOSIT_MONTHLY]: 'High Water Mark',
  [AdministrationValues.DEPOSIT_AT]: 'Deposit Beginning',
  [AdministrationValues.RETURN_DAY]: 'Return day (%)',
  [AdministrationValues.RETURN_MONTH]: 'Return month (%)',
  [AdministrationValues.RETURN_ALL]: 'Return all time (%)',
  [AdministrationValues.AVG_CAPITAL_GROWTH_RATE]: 'ACGR (%)',
  [AdministrationValues.ASSET_USDT]: 'Asset USDT',
  [AdministrationValues.ASSET_USDC]: 'Asset USDC',
  [AdministrationValues.OTHER_STABLECOIN]: 'Other stable coins',
  [AdministrationValues.ASSET_SUM]: 'Asset sum'
};

export type DepositFromValue = {
  [AdministrationValues.DEPOSIT_AT]?: number;
  [AdministrationValues.DEPOSIT_MONTHLY]?: number;
  deposit_type?: string;
};

export interface AdministrationFilters {
  ordering?: string;
}
