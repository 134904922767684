import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import Modal from 'components/modal/MyModal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { editInvestorReportSettings, getInvestorReportSettings } from 'redux/actions/systemSettingsActions';
import { getErrorCutMessageHelper, getErrorMessageHelper, getModalContentIndent } from 'helpers/randomHelpers';
import { layoutRootSelector, systemSettingsSelector } from 'redux/selectors';
import {
  INVESTOR_REPORT_SETTINGS_FieldIds,
  INVESTOR_REPORT_SETTINGS_ValidationSchema,
  INVESTOR_REPORT_SETTINGS_FIELDS
} from 'constants/systemSettingsConstants';
import { INVESTOR_REPORT_SETTINGS_VALUES } from 'interfaces/systemSettingsShapes';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import s from './ReportInvestorSettings.module.scss';
import 'react-time-picker-input/dist/components/TimeInput.css';

const ReportInvestorSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isAsideOpen } = useSelector(layoutRootSelector);
  const { investorReportSettings } = useSelector(systemSettingsSelector);
  const { INVESTOR_BOT_TOKEN, INVESTOR_CHAT_ID } = INVESTOR_REPORT_SETTINGS_FieldIds;
  const [isReqErr, setReqErr] = useState<string | null>(null);
  const onClearRequestError = () => setReqErr(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);

  const onAddSettingsSuccess = () => {
    onToggleModal();
    onClearRequestError();
  };

  const modalContentIndent = getModalContentIndent(isAsideOpen);

  useEffect(() => {
    dispatch(
      getInvestorReportSettings(
        () => null,
        () => null
      )
    );
  }, [dispatch]);

  return (
    <div className={s.wrapper}>
      <div className={s.titleWrapper}>
        <h2 className={cn(s.title, 'default-title')}>Investor report settings</h2>
        {isReqErr && <span className={cn(s.reqError, 'errorText')}>{isReqErr}</span>}
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          [INVESTOR_BOT_TOKEN]: investorReportSettings[INVESTOR_BOT_TOKEN],
          [INVESTOR_CHAT_ID]: investorReportSettings[INVESTOR_CHAT_ID]
        }}
        validationSchema={INVESTOR_REPORT_SETTINGS_ValidationSchema}
        onSubmit={(values, { setSubmitting }: FormikHelpers<INVESTOR_REPORT_SETTINGS_VALUES>) => {
          if (
            investorReportSettings[INVESTOR_BOT_TOKEN] !== values[INVESTOR_BOT_TOKEN] ||
            investorReportSettings[INVESTOR_CHAT_ID] !== values[INVESTOR_CHAT_ID]
          ) {
            dispatch(editInvestorReportSettings(values, onAddSettingsSuccess, onSetError));
          } else {
            setReqErr('All values has not changed');
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange }) => {
          return (
            <Form className={s.tgNotificationForm}>
              {INVESTOR_REPORT_SETTINGS_FIELDS.map(({ id, label, placeholder }) => {
                const isError = (!!errors[id] && touched[id]) || !!isReqErr;
                const isValid = !!touched[id] && !errors[id] && !isReqErr;

                return (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    key={id}
                    id={id}
                    name={id}
                    label={label}
                    placeholder={placeholder}
                    value={values[id]}
                    error={isError}
                    helperText={touched[id] && errors[id]}
                    onBlur={() => setReqErr(null)}
                    onChange={handleChange}
                    onClick={onClearRequestError}
                    classes={{
                      root: cn(s.input, { [s.inputValid]: isValid })
                    }}
                  />
                );
              })}

              <Button fullWidth variant="outlined" size="medium" type="submit" classes={{ root: s.btnRoot }}>
                Save
              </Button>
            </Form>
          );
        }}
      </Formik>

      <Modal
        isOpen={isModalOpen}
        onToggle={onToggleModal}
        stylesProps={{
          paper: {
            left: `calc(50% + ${modalContentIndent}px)`,
            padding: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        <h2 className="default-title" style={{ marginBottom: 40 }}>
          Telegram notification was successfully created.
        </h2>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot }}
          onClick={onToggleModal}
        >
          Ok
        </Button>
      </Modal>
    </div>
  );
};

export default ReportInvestorSettings;
